<template>
  <CCard v-if="box" class="mb-1" :style="`background: ${background};`">
    <CCardBody class="p-2">
      <!-- SKU -->
      <div class="d-flex align-items-center">
        <div>
          <TSpinner v-if="updating" :loading="updating" />
          <TMessage
            :content="data.id"
            size="h4"
            noTranslate
            :addClasses="['mb-0']"
            title="SKU"
          />

          <div class="d-flex">
            <CLink
              v-if="data.pallets && data.pallets.length && showPallet"
              class="my-auto mr-1"
              @click="showSelectPallet = !showSelectPallet"
            >
              <CIcon
                name="cis-chart-table"
                width="15"
                :title="$t('Select pallet')"
              />
            </CLink>
            <div class="d-flex">
              <span v-if="data.pallet_id && showPallet">
                <CBadge color="success" class="my-auto">
                  {{ data.pallet_id }}
                </CBadge>
              </span>

              <div v-if="data.tags" class="ml-1">
                <SMessageBoxTag
                  v-for="tag in tags"
                  :key="tag.id"
                  :tag="tag"
                  class="mr-1 fs-8 py-1"
                />
              </div>
            </div>

            <!-- Modal chọn pallet -->
            <TModal
              title="Select pallet"
              v-if="showSelectPallet"
              :show.sync="showSelectPallet"
              @update:show="$emit('update:show', $event)"
            >
              <template #actions> <span></span></template>
              <CRow>
                <CCol
                  col="12"
                  md="6"
                  v-for="pallet in data.pallets"
                  :key="pallet.id"
                  @click="selectPallet(pallet.id)"
                  style="cursor: pointer"
                >
                  <div
                    class="card p-3"
                    :class="{
                      'border-success': data.pallet_id == pallet.id,
                    }"
                  >
                    <div class="d-flex align-items-start">
                      <TMessage
                        :content="pallet.id"
                        size="h4"
                        color="primary"
                        noTranslate
                      />

                      <!-- <CBadge :color="!(pallet.quantity - pallet.quantity_in_container_picker) ? 'danger' : 'success'"
                          class="ml-2">
                          {{ pallet.quantity - pallet.quantity_in_container_picker }}
                        </CBadge> -->
                    </div>

                    <CBadge color="info" class="mr-auto" v-if="pallet.location">
                      {{ pallet.location.name }}
                    </CBadge>
                    <CBadge color="warning" class="mr-auto" v-else>
                      {{ $t("Pallets have not been stored") }}
                    </CBadge>
                  </div>
                </CCol>
              </CRow>
            </TModal>

            <div v-if="$slots['picked_by_pallet']">
              <slot name="picked_by_pallet"></slot>
            </div>
          </div>
        </div>

        <div class="flex-grow-1 d-flex justify-content-end">
          <!-- Số lượng incont - outcont - lading_bill -->

          <div
            class="flex-grow-1 d-flex justify-content-end align-items-center"
          >
            <slot name="prepend-quantity"> </slot>
            <strong v-if="$slots['prepend-quantity']" class="mx-1"> / </strong>
            <div style="font-size: 20px; font-weight: 400">
              <TMessageNumber
                :value="focusQuantity"
                :editable="focusQuantityEdiable"
                @change="$emit('focus-quantity:change', $event)"
                @update:editing="editing = $event"
                :title="focusQuantityTitle"
              />
            </div>
            <strong v-if="focusQuantity >= 0" class="mx-1"> / </strong>
            <div style="font-size: 20px; font-weight: 400">
              <slot name="main-quantity">
                <TMessageNumber
                  :value="data.duplicate"
                  :editable="editable"
                  @update:editing="mainEditing = $event"
                  @change="updateDuplicate"
                />
              </slot>
            </div>
          </div>

          <!-- Xoá sửa SKU -->

          <div v-if="!editing && !mainEditing" class="ml-auto d-flex">
            <div
              v-if="removable"
              style="
                position: absolute;
                top: -3px;
                right: -5px;
                background: #fff;
                border-radius: 10px;
              "
            >
              <TSpinner v-if="removing" :loading="removing" />
              <CLink
                v-else
                :title="$t('Remove')"
                class="text-danger"
                @click="removeHandle"
              >
                <CIcon name="cil-x-circle" width="18" />
              </CLink>
            </div>

            <TModalConfirm
              :show.sync="showModalDelete"
              @click-confirm="confirm"
            />
            <slot name="append-actions"> </slot>
          </div>
        </div>
      </div>

      <slot name="append-content"> </slot>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    box: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    removing: {
      type: Boolean,
      default: false,
    },
    updating: {
      type: Boolean,
      default: false,
    },
    focusQuantity: {
      type: Number,
      required: false,
      default: -1,
    },
    focusQuantityEdiable: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      required: false,
    },
    showPallet: {
      type: Boolean,
      default: false,
    },
    noConfirmOnRemove: {
      type: Boolean,
      default: false,
    },
    focusQuantityTitle: {
      type: String,
      default: "",
    },
  },
  watch: {
    box: {
      immediate: true,
      handler(box) {
        this.data = box;
      },
    },
    "data.items"(data) {
      if (data && !data.length) this.itemsVisible = false;
    },
  },
  data() {
    return {
      editing: false,
      mainEditing: false,
      itemsVisible: false,
      box_fetching: false,
      data: this.box,
      showSelectPallet: false,
      showModalDelete: false,
    };
  },
  computed: {
    tags() {
      return (
        this.lodash.orderBy(this.data?.tags, ["name.length"], ["desc"]) || []
      );
    },
  },
  methods: {
    deleteSku() {
      this.$store.dispatch("warehouse.boxes.delete", this.data.id).then(() => {
        this.$emit("deleted", this.data);
      });
    },
    removeSku() {
      this.$emit("remove", this.data);
    },
    removeHandle() {
      this.noConfirmOnRemove ? this.removeSku() : (this.showModalDelete = true);
    },
    updateDuplicate(quantity) {
      this.$store.commit("warehouse.boxes.select", this.data.id);
      this.$store
        .dispatch("warehouse.boxes.update", {
          id: this.data.id,
          attributes: { duplicate: quantity },
        })
        .then((data) => {
          this.$emit("updated", data);
        });
    },
    fetchSku() {
      this.box_fetching = true;
      this.$tomoni.warehouse.boxes
        .get(
          this.data.id,
          this.$store.state.boxes["warehouse_boxes_detail_query"]
        )
        .then((data) => {
          this.data = data.data;
        })
        .finally(() => {
          this.box_fetching = false;
        });
    },
    selectPallet(pallet_id) {
      if (this.box.pallet_id == pallet_id) {
        this.box.pallet_id = "";
        this.$emit("updated", { ...this.data, pallet_id: "" });
      } else {
        this.box.pallet_id = pallet_id;
        this.$emit("updated", { ...this.data, pallet_id });
      }
    },
    confirm() {
      this.removable ? this.removeSku() : this.deleteSku();
      this.showModalDelete = false;
    },
  },
};
</script>

<style scoped>
.fs-8 {
  font-size: 10px !important;
}
</style>
