import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'auth',
    resource: 'users'
  },
  {
    default_query: {
      sort: "-created_at",
      include: 'role,organization'
    },
    detail_query: {
      include: 'role,organization'
    },
    default_detail: {
      id: null,
      email: null,
      role_id: null,
      updated_at: null,
      created_at: null,
    }
  },
).store()

export default resource
