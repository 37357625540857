const Home = () => import("../views/Home");
const MarketTomoni = () => import("../views/MarketTomoni");
const Login = () => import("../views/Login");
const ForgotPassword = () => import("../views/ForgotPassword");

export default [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/markets/tomoni",
    name: "Tomoni market",
    component: MarketTomoni
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword
  }
];
