import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'accounting',
    resource: 'credit_orders',
  },
  {
    default_query: {
      sort: "-id",
      include: 'transactions.receipts,wallet,wallet.object',
    },
    detail_query: {
      include: 'transactions.receipts,wallet,wallet.object',
    }
  }
).store()

export default resource
