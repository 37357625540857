import { AuthService } from '../services'

export const SERVICE = AuthService
export const RESOURCE = "me"

export default {
  update(attributes) {
    return SERVICE.put(`api/${RESOURCE}`, attributes);
  },
};
