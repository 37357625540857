<template>
  <div>
    <TMessage content="Shipment method" class="mb-2" bold />
    <CInputRadioGroup
      class="pt-1"
      :options="options"
      @update:checked="$emit('update:value', $event)"
      inline
      custom
      :checked="value"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, null],
      default: "",
    },
    allowEmptyOption: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    list() {
      const emptyOption = {
        id: "",
        name: this.$t("Undefined"),
      };
      const defaultL = this.$store.getters["warehouse.shipment_methods.list"];
      return this.allowEmptyOption ? [...defaultL, emptyOption] : defaultL;
    },
    options() {
      return this.list.map((x) => {
        let newObj = this.lodash.mapKeys(x, (value, key) => {
          switch (key) {
            case "id":
              return "value";
            case "name":
              return "label";
          }
        });
        return newObj;
      });
    },
  },
};
</script>
