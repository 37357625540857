import provinces from "./helper/provinces"
import districts from "./helper/districts"
import wards from "./helper/wards"
import countries from "./helper/countries"
import transactions from "./accounting/transactions"
import users from "./auth/users"
import organizations from "./auth/organizations"
import transaction_types from "./accounting/transaction_types"
import roles from "./auth/roles";
import addresses from "./auth/addresses"
import logs from "./helper/logs"
import statuses from "./order/statuses"
import permissions from "./auth/permissions"
import distributions from './order/distributions'
import contract_statuses from './order/contract_statuses'
import trackings from './order/trackings'
import shipment_methods from './warehouse/shipment_methods'
import suppliers from './product/suppliers'
import purchases from './order/purchases'
import containers from './warehouse/containers'
import products from './product/products'
import origins from './product/origins'
import orders from './order/orders'
import order_items from './order/order_items'
import purchase_items from './order/purchase_items'
import owning_boxes from './warehouse/owning_boxes'
import product_statistics from './product/product_statistics'
import purchase_statuses from './order/purchase_statuses'
import shipment_infos from './order/shipment_infos'
import contracts from './order/contracts'
import lading_bills_of_order from './warehouse/lading_bills_of_order'
import sfas from './warehouse/sfas'
import lading_bills from './warehouse/lading_bills'
import areas from './warehouse/areas'
import goods_deliveries from './warehouse/goods_deliveries'
import order_types from './order/order_types'
import currencies from "./accounting/currencies"
import exports from "./helper/exports"
import categories from "./product/categories"
import prices from "./helper/prices"
import price_timelines from "./helper/price_timelines"
import price_amounts from "./helper/price_amounts"
import conditions from "./helper/conditions"
import supports from "./helper/supports"
import support_types from "./helper/support_types"
import support_contents from "./helper/support_contents"
import role_support_types from "./helper/role_support_types"
import lading_bill_compensations from "./warehouse/lading_bill_compensations"
import freight_charge_types from './warehouse/freight_charge_types';
import product_categories from './product/product_categories';
import box_lading_bills from './warehouse/box_lading_bills'
import purchase_items_inventory from './order/purchase_items_inventory'
import user_notifications from './helper/user_notifications'
import product_statistics_available_for_sale from './product/product_statistics_available_for_sale'
import notification_subscribers from './helper/notification_subscribers'
import owning_boxes_available from './warehouse/owning_boxes_available'
import available_for_distribution from './order/available_for_distribution'
import order_compensation from './order/order_compensations'
import user_recipient from "./auth/user_recipient"
import cards from "./accounting/cards"
import accounts from "./accounting/accounts"
import credit_orders from "./accounting/credit_orders"
import product_reviews from "./product/product_reviews"
import product_tags from "./product/product_tags"
import post_categories from "./helper/post_categories"
import wards_sender from "./helper/wards_sender"
import provinces_sender from "./helper/provinces_sender"
import districts_sender from "./helper/districts_sender"
import ecommerce_channels from "./product/ecommerce_channels"
import affiliate_stores from "./product/affiliate_stores"
import product_affiliates from "./product/product_affiliates"
import product_affiliates_list from "./product/product_affiliates_list"
import category_affiliates from "./product/category_affiliates"
import affiliate_store_list from "./product/affiliate_store_list"
import category_affiliate_list from "./product/category_affiliate_list"
import product_affiliate_store_list from "./product/product_affiliate_store_list"
import product_affiliate_tags from "./product/product_affiliate_tags"
import category_affiliate_parent_list from "./product/category_affiliate_parent_list"

export default {
  countries,
  provinces,
  districts,
  wards,
  transactions,
  users,
  organizations,
  transaction_types,
  statuses,
  logs,
  addresses,
  contract_statuses,
  distributions,
  trackings,
  roles,
  permissions,
  shipment_methods,
  suppliers,
  purchases,
  containers,
  products,
  origins,
  orders,
  order_items,
  purchase_items,
  owning_boxes,
  product_statistics,
  purchase_statuses,
  contracts,
  shipment_infos,
  lading_bills_of_order,
  sfas,
  lading_bills,
  goods_deliveries,
  order_types,
  currencies,
  exports,
  categories,
  prices,
  price_timelines,
  price_amounts,
  conditions,
  areas,
  supports,
  support_types,
  support_contents,
  role_support_types,
  lading_bill_compensations,
  freight_charge_types,
  product_categories,
  box_lading_bills,
  purchase_items_inventory,
  user_notifications,
  product_statistics_available_for_sale,
  notification_subscribers,
  owning_boxes_available,
  available_for_distribution,
  order_compensation,
  user_recipient,
  cards,
  accounts,
  credit_orders,
  product_reviews,
  product_tags,
  post_categories,
  wards_sender,
  provinces_sender,
  districts_sender,
  ecommerce_channels,
  affiliate_stores,
  product_affiliates,
  product_affiliates_list,
  category_affiliates,
  affiliate_store_list,
  category_affiliate_list,
  product_affiliate_store_list,
  product_affiliate_tags,
  category_affiliate_parent_list
}
