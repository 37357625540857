var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.label)?_c('TMessage',{staticClass:"mb-2",attrs:{"content":_vm.label,"bold":""}}):_vm._e(),_c('DatePicker',{attrs:{"mode":_vm.mode,"value":_vm.valueC,"model-config":_vm.modelConfig,"masks":_vm.masksConfig,"is-range":"","attributes":[
      {
        key: 'today',
        highlight: 'red',
        dates: new Date(),
      },
    ],"locale":{ id: _vm.locale }},on:{"input":_vm.input},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, inputEvents }){return [_c('div',{staticClass:"d-inline-flex w-100",staticStyle:{"min-width":"200px"}},[_c('input',_vm._g({class:['form-control', { 'form-control-lg': _vm.size == 'lg' }],attrs:{"placeholder":_vm.$t('Start'),"disabled":_vm.disabled},domProps:{"value":inputValue.start}},inputEvents.start)),_c('input',_vm._g({class:['form-control', { 'form-control-lg': _vm.size == 'lg' }],attrs:{"placeholder":_vm.$t('End'),"disabled":_vm.disabled},domProps:{"value":inputValue.end}},inputEvents.end))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }