<template>
  <div>
    <div>
      <label>
        <TMessage bold :content="label" />
      </label>
      <CLink v-if="userId" class="float-right" @click="showModal = true">
        <TMessage content="Add new" />
      </CLink>
    </div>
    <TSelect
      class="m-0"
      :options="list"
      :value="valueSelected"
      :selectOptions="{
        internalSearch: true,
        'custom-label': customLabel,
        preserveSearch: true,
        disabled: userId ? false : true,
        ...selectOptions,
      }"
      :normalizer="{ label: 'full_address', value: 'id' }"
      placeholder="Select"
      :loading="loading"
      @select="select"
    />
    <SModalAddress
      :show.sync="showModal"
      :userId="userId"
      @created="showModal = false"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    userId: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: "Shipment info",
    },
    selectOptions: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      valueSelected: null,
      showModal: false,
    };
  },
  watch: {
    userId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.valueSelected = null;
          this.$store.dispatch("auth.addresses.push-query", {
            "filter[user_id]": id,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "auth.addresses.list",
      loading: "auth.addresses.fetching",
    }),
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value);
      this.$emit("change");
    },
    customLabel({ consignee, full_address }) {
      return `${consignee} - ${full_address}`;
    },
  },
  destroyed() {
    this.$store.commit("auth.addresses.reset-query");
  },
};
</script>
