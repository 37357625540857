<template>
  <CRow>
    <CCol md="3">
      <TSelect
        label="Country"
        :options="countries"
        :loading="countries_fetching"
        @select="selectCountry"
        :value="countries_selected"
      />
    </CCol>
    <CCol md="3">
      <TSelect
        label="Province"
        :options="provinces"
        :loading="provinces_fetching"
        @select="selectProvince"
        :value="provinces_selected"
      />
    </CCol>
    <CCol md="3">
      <TSelect
        label="District"
        :options="districts"
        :loading="districts_fetching"
        :value="districts_selected"
        @select="selectDistrict"
      />
    </CCol>
    <CCol md="3">
      <TSelect
        label="Ward"
        :options="wards"
        :loading="wards_fetching"
        :value="wards_selected"
        @select="selectWard"
      />
    </CCol>
    <CCol col="12">
      <TInputText
        label="Address detail"
        :value="address_detail"
        @update:value="$emit('update:address_detail', $event)"
      />
    </CCol>
  </CRow>
</template>

<script>
export default {
  props: {
    ward_id: {
      type: [String, Number],
      required: false,
    },
    address_detail: {
      type: String,
      required: false,
    },
    countries_store: {
      type: String,
      required: false,
      default: "countries",
    },
    provinces_store: {
      type: String,
      required: false,
      default: "provinces",
    },
    districts_store: {
      type: String,
      required: false,
      default: "districts",
    },
    wards_store: {
      type: String,
      required: false,
      default: "wards",
    },
    country_default: {
      type: String,
      required: false,
      default: "vn",
    },
  },
  data() {
    return {
      countries_selected: null,
    };
  },
  created() {
    this.$store.dispatch(`helper.${this.provinces_store}.fetch.if-first-time`);
    if (!this.ward_id) {
      this.countries_selected = this.$store.getters[
        `helper.countries.list`
      ].find((x) => x.id == this.country_default);
      this.$store.commit(`helper.${this.provinces_store}.detail.purge`);
      this.$store.commit(`helper.${this.districts_store}.purge`);
      this.$store.commit(`helper.${this.wards_store}.purge`);
    }
  },
  watch: {
    ward_id: {
      immediate: true,
      handler(id) {
        if (id) {
          if (!this.wards.some((x) => x.id == id))
            this.$store.dispatch(
              `helper.${this.wards_store}.detail.fetch-missing`,
              id
            );
        }
      },
    },
    provinces_selected: {
      immediate: true,
      handler(obj) {
        if (obj.country_id)
          this.countries_selected = this.countries.find(
            (x) => x.id == obj.country_id
          );
      },
    },
  },
  computed: {
    countries() {
      return this.$store.getters[`helper.${this.countries_store}.list`];
    },
    countries_fetching() {
      return this.$store.getters[`helper.${this.countries_store}.fetching`];
    },
    provinces() {
      return this.$store.getters[`helper.${this.provinces_store}.list`].filter(
        (x) => x.country_id == this.countries_selected.id
      );
    },
    provinces_fetching() {
      return this.$store.getters[`helper.${this.provinces_store}.fetching`];
    },
    provinces_selected() {
      return this.$store.getters[`helper.${this.provinces_store}.detail`];
    },
    districts() {
      return this.$store.getters[`helper.${this.districts_store}.list`];
    },
    districts_fetching() {
      return this.$store.getters[`helper.${this.districts_store}.fetching`];
    },
    districts_selected() {
      return this.$store.getters[`helper.${this.districts_store}.detail`];
    },
    wards() {
      return this.$store.getters[`helper.${this.wards_store}.list`];
    },
    wards_fetching() {
      return this.$store.getters[`helper.${this.wards_store}.fetching`];
    },
    wards_selected() {
      return this.$store.getters[`helper.${this.wards_store}.detail`];
    },
  },
  methods: {
    selectCountry(country) {
      this.countries_selected = country;
      this.$store.commit(`helper.${this.provinces_store}.detail.purge`);
      this.$store.commit(`helper.${this.districts_store}.purge`);
      this.$store.commit(`helper.${this.wards_store}.purge`);
      this.$emit("update:ward_id", null);
    },
    selectProvince(province) {
      this.$store.commit(`helper.${this.provinces_store}.select`, province.id);
      this.$store.commit(`helper.${this.districts_store}.purge`);
      this.$store.commit(`helper.${this.wards_store}.purge`);
      this.$store.dispatch(`helper.${this.districts_store}.push-query`, {
        "filter[province_id]": province.id,
      });
      this.$emit("update:ward_id", null);
    },
    selectDistrict(district) {
      this.$store.commit(`helper.${this.districts_store}.select`, district.id);
      this.$store.commit(`helper.${this.wards_store}.purge`);
      this.$store.dispatch(`helper.${this.wards_store}.push-query`, {
        "filter[district_id]": district.id,
      });
      this.$emit("update:ward_id", null);
    },
    selectWard(ward) {
      this.$store.commit(`helper.${this.wards_store}.select`, ward.id);
      this.$emit("update:ward_id", ward.id);
    },
  },
};
</script>
