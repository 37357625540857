<template>
  <CRow>
    <CCol col="12">
      <v-select
        :value="id"
        :options="options"
        @input="updateId"
        label="name"
        :loading="searching"
        :clearable="false"
        transition=""
      >
        <template #option="option">
          <SCardProductItems :item="option" v-if="option" />
        </template>
        <template #search="{ attributes, events }">
          <CInput
            class="vs__search mb-0"
            v-bind="attributes"
            v-on="{
              ...events,
              input: (e) => e,
            }"
            @update:value="searchProducts"
            @change="$emit('input', $event)"
            :value="id"
            :placeholder="$t(placeholder)"
          />
        </template>
        <template #selected-option-container><div></div></template>
        <template #no-options>
          <TMessage content="No products found" class="ml-3" />
        </template>
      </v-select>
    </CCol>
    <CCol col="12" v-if="!!id && showItem" class="mt-4">
      <SCardProductItems :item="product">
        <template #append="{ product }">
          <slot name="append-product-selected" :product="product"></slot>
        </template>
      </SCardProductItems>
    </CCol>
  </CRow>
</template>

<script>
import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SCardProductItems from "../Card/ProductItems.vue";

export default {
  components: {
    VSelect,
    SCardProductItems,
  },
  props: {
    id: {
      type: [Number, String],
      required: false,
    },
    showItem: {
      type: Boolean,
      required: false,
      default: true,
    },
    placeholder: {
      type: String,
      default: "Search product",
    },
  },
  data() {
    return {
      options: [],
      searching: false,
      product: {},
      inputSearch: "",
    };
  },
  methods: {
    searchProducts(input) {
      if (input == this.inputSearch) {
        return;
      }
      this.inputSearch = input;
      if (!input) {
        return;
      }
      this.searching = true;
      this.$tomoni.product.products_search
        .all({ q: input })
        .then(({ data }) => {
          this.searching = false;
          this.options = data.data;
        })
        .catch(() => {
          this.searching = true;
        });
    },
    normalizeProductItem(product) {
      return [
        {
          product_id: product.id,
          product: product,
        },
      ];
    },
    updateId(product) {
      this.$emit("update:id", product.id, product);
      this.product = product;
    },
  },
};
</script>
