import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'containers',
  },
  {
    default_query: {
      sort: "-created_at",
    },
    detail_query: {
      include: "inPickersCount,outPickersCount,ladingBills,inPickers,outPickers",
    }
  }
).store()

export default resource
