<template>
  <div v-if="id" class="my-auto d-flex">
    <CBadge :color="getBadgeColor" class="d-inline-flex">
      <CBadge color="dark">IKOM</CBadge> <TMessage class="ml-1" :content="id" />
    </CBadge>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    getBadgeColor() {
      switch (this.id) {
        case "NotYetApprove":
          return "secondary";
        case "CompletedApprove":
        case "CompletedPurchases":
        case "CompletedReceiveJp":
        case "CompletedShippingJp":
        case "CompletedReceiveVn":
        case "CompletedShippingVn":
          return "info";
        case "CompletedPurchaseCostPayment":
          return "primary";
        case "":
        case "CompletedServicePayment":
        case "CompletedFinish":
          return "success";
        case "CompletedCancel":
          return "danger";
        default:
          return "warning";
      }
    },
  },
};
</script>
