<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="list"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      preserveSearch: true,
    }"
    :normalizer="{ label: 'order_id', value: 'order_id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
  />
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: [Number, String],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value == null) return;
        this.checkValue(value);
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.order_owning_boxes.list",
      loading: "warehouse.order_owning_boxes.fetching",
    }),
  },
  methods: {
    select(value) {
      this.$emit("update:value", value.order_id);
      this.$emit("change", value);
    },
    checkValue(value) {
      const firstValue = this.list.find((x) => x.order_id === value);
      // Nếu value rỗng
      if (!value && !this.prependAll) {
        this.valueSelected = null;
        return;
      }
      // Value có trong list
      if (firstValue) {
        this.valueSelected = firstValue;
        this.$emit("change", firstValue);
      }
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("warehouse.order_owning_boxes.apply-query", {
        "filter[id]": param,
      });
    }, 300),
  },
};
</script>
