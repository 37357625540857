<template>
  <CButton
    variant="outline"
    color="primary"
    class="float-right"
    :to="lodash.getReferenceLink('lading_bill.search', customer.id)"
  >
  <div class="d-flex">
    <TMessage content="Next to lading bills of" class="mr-1"/>
    <strong>
      {{ customer.name }}
    </strong>
    <CIcon name="cil-arrow-right" class="ml-1"/>
  </div>
  </CButton>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },
};
</script>
