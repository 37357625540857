<template>
  <div>
    <TMessage v-if="label" :content="label" bold class="mb-2" />
    <DatePicker
      :mode="mode"
      :value="valueC"
      :model-config="modelConfig"
      :masks="masksConfig"
      @input="input"
      is-range
      :attributes="[
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ]"
      :locale="{ id: locale }"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="d-inline-flex w-100" style="min-width: 200px">
          <input
            :class="['form-control', { 'form-control-lg': size == 'lg' }]"
            :value="inputValue.start"
            v-on="inputEvents.start"
            :placeholder="$t('Start')"
            :disabled="disabled"
          />
          <input
            :class="['form-control', { 'form-control-lg': size == 'lg' }]"
            :value="inputValue.end"
            v-on="inputEvents.end"
            :placeholder="$t('End')"
            :disabled="disabled"
          />
        </div>
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
import i18n from "@/core/plugins/i18n";
import mixin from "./mixin";

export default {
  mixins: [mixin],
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({ start: "", end: "" }),
    },
    formatDate: {
      type: String,
      default: "DD-MM-YYYY",
    },
    formatTime: {
      type: String,
      default: "HH:mm:ss",
    },
    dateOnly: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelConfig: {
        type: "number",
        mask: this.mask(),
      },
      masksConfig: {
        input: this.mask(),
      },
      mode: this.dateOnly ? "date" : "dateTime",
    };
  },
  methods: {
    input(data) {
      const timeRange = {
        start: this.lodash.toTimeStamp(data?.start, "start") || "infinity",
        end: this.lodash.toTimeStamp(data?.end, "end") || "infinity",
      };
      this.$emit("update:value", timeRange);
      this.$emit("change", timeRange);
    },
    mask() {
      return this.dateOnly
        ? this.formatDate
        : this.formatDate + " " + this.formatTime;
    },
  },
  computed: {
    locale() {
      return i18n._vm._data.locale;
    },
    valueC() {
      return {
        start: this.value.start * 1000,
        end: this.value.end * 1000,
      };
    },
  },
};
</script>
