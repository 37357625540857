<template>
  <TModal
    title="Add product"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="add"
    :creating="item.product_id ? false : true"
  >
    <template #actions>
      <TButton
        content="Create"
        :options="{ disabled: loading || !item.product_id }"
        icon="cil-plus"
        size=""
        @click="add"
      />
    </template>
    <SSelectProduct :id="item.product_id" @update:id="updateItem" class="mb-3">
      <template #append-product-selected="{ product }">
        <TTableAsForm
          :data="product"
          :fields="itemFields"
          class="my-3 mb-4"
          :addRowClasses="['py-2']"
        >
          <template #quantity>
            <TInputNumber
              :value="item.quantity"
              @update:value="item.quantity = $event"
            />
          </template>
          <template #expiry_date>
            <TInputDateTime
              :value.sync="item.expiry_date"
              @update:value="item.expiry_date = $event"
            />
          </template>
        </TTableAsForm>
      </template>
    </SSelectProduct>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    box_id: {
      type: String,
      required: true,
    },
  },
  defaultItem: {
    product_id: null,
    quantity: 1,
    expiry_date: null,
  },
  data() {
    return {
      item: this.defaultItem(),
      itemFields: [
        { key: "quantity", label: "Quantity" },
        { key: "expiry_date", label: "Expiration date" },
      ],
      loading: false,
    };
  },
  methods: {
    updateItem(id) {
      this.item.product_id = id;
    },
    add() {
      this.loading = true;
      const item = this.lodash.mapKeys(this.item, (value, key) => {
        return `products[0][${key}]`;
      });
      this.$store
        .dispatch("warehouse.box_items.create", {
          ...this.lodash.pickBy(item, this.lodash.identity),
          box_id: this.box_id,
        })
        .then((data) => {
          this.$emit("created", data);
        })
        .finally(() => {
          this.item = this.defaultItem();
          this.loading = false;
          this.$emit("update:show", false);
        });
    },
    defaultItem() {
      return { ...this.$options.defaultItem };
    },
  },
};
</script>
