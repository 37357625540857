<template>
  <TSelectEditable
    v-if="editable"
    class="m-0"
    :options="statuses"
    :value="valueSelected"
    :selectOptions="selectOptions"
    @close="$emit('close')"
    @change="$emit('change', $event)"
  />
  <TSelect
    :label="label"
    v-else
    class="m-0"
    placeholder="Select"
    :options="statuses"
    :value="valueSelected"
    :selectOptions="selectOptions"
    @select="select"
  />
</template>
<script>
export default {
  props: {
    prepend: {
      type: Array,
      required: false,
      default: function () {
        return [{ id: "", name: this.$t("All") }];
      },
    },
    value: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
    selectOptions: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.valueSelected = this.status;
      },
    },
    status(value) {
      this.valueSelected = value;
    },
  },
  computed: {
    statuses() {
      return [...this.prepend, ...this.list];
    },
    list() {
      return this.$store.getters["auth.organizations.list"];
    },
    status() {
      return this.statuses.find((x) => x.id === this.value);
    },
  },
  methods: {
    select(status) {
      this.valueSelected = status;
      this.$emit("update:value", status.id);
      this.$emit("change");
    },
  },
};
</script>
