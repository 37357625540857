import Resource from "@/core/store/Resource";

const resource = new Resource(
  {
    service: "accounting",
    resource: "transactions",
  },
  {
    default_query: {
      sort: "-voucher_date",
      append: "object,wallet,references",
      include: "receipts,card,preparedBy",
    },
    detail_query: {
      append: "object,files,references,parent.object,children.object",
      include: "children,parent,receipts,card,preparedBy,parent.preparedBy",
    },
  },
).store()

export default resource
