const Users = () => import("../views/users/Index");
const User = () => import("../views/users/Detail");
const Dashboard = () => import("../views/Dashboard");
const Roles = () => import("../views/roles/Index");
const Role = () => import("../views/roles/Detail");
const Products = () => import("../views/goods/Index");
const Product = () => import("../views/goods/Detail");
const NewProduct = () => import("../views/goods/New");
const Origins = () => import("../views/goods/Origin");
const Tags = () => import("../views/goods/Tag");
const Category = () => import("../views/goods/Category");
const Organizations = () => import("../views/organizations/Index");
const Prices = () => import("../views/prices/Index");
const Support = () => import("../views/support/Index");
const NewSupport = () => import("../views/support/NewSupport");
const SupportDetail = () => import("../views/support/SupportDetail");
const SupportTypes = () => import("../views/support/SupportTypes");
const SubscriberIndex = () => import("../views/support/SubscriberIndex");
const Provinces = () => import("../views/address/Provinces");
const Posts = () => import("../views/post/Index");
const PostDetail = () => import("../views/post/Detail");
const NewPost = () => import("../views/post/New");
const PostCategory = () => import("../views/post/PostCategory");
const EcommerceChannel = () => import("../views/ecommerce/EcommerceChannel");
const AffiliateStore = () => import("../views/ecommerce/AffiliateStore");
const AffiliateDetail = () => import("../views/ecommerce/AffiliateDetail");
const AffiliateCategory = () => import("../views/ecommerce/AffiliateCategory");
const AffiliateProduct = () => import("../views/ecommerce/AffiliateProduct");
const CreateAffiliateProduct = () => import("../views/ecommerce/CreateAffiliateProduct");
const AffiliateProductDetail = () => import("../views/ecommerce/AffiliateProductDetail");
const AffiliateProductTags = () => import("../views/ecommerce/AffiliateProductTags");

export default [
  {
    path: "dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "users",
    meta: { label: "Users" },
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        meta: {
          label: "Users",
          title: "Users",
        },
        component: Users,
      },
      {
        path: ":id",
        meta: {
          label: "User detail",
        },
        name: "User detail",
        component: User,
      },
    ],
  },
  {
    path: "",
    meta: { label: "Group Management" },
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "roles",
        name: "Roles",
        component: Roles,
      },
      {
        path: "roles/:id",
        meta: {
          label: "Role Detail",
        },
        name: "Role detail",
        component: Role,
      },
      {
        path: "organizations",
        name: "Organizations",
        component: Organizations,
      },
    ],
  },
  {
    path: "goods",
    meta: { label: "Goods" },
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "products",
        meta: { label: "Product" },
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            meta: {
              label: "Product",
              title: "Product",
            },
            component: Products,
          },
          {
            path: "new",
            meta: {
              label: "New Product",
              title: "New product",
            },
            component: NewProduct,
          },
          {
            path: ":id",
            meta: {
              label: "Detail",
              title: "Detail",
            },
            component: Product,
          },
        ],
      },
      {
        path: "origins",
        meta: {
          label: "Origin",
          title: "Origin",
        },
        component: Origins,
      },
      {
        path: "tags",
        meta: {
          label: "Tag",
          title: "Tag",
        },
        component: Tags,
      },
      {
        path: "categories",
        meta: {
          label: "Category",
          title: "Category",
        },
        component: Category,
      },
      {
        path: "prices",
        meta: {
          label: "Prices",
          title: "Price",
        },
        component: Prices,
      },
    ],
  },
  {
    path: "ecommerce",
    meta: { label: "Ecommerce" },
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "ecommerce-channel",
        meta: {
          label: "Ecommerce channel",
          title: "Ecommerce channel",
        },
        component: EcommerceChannel,
      },
      {
        path: "affiliate-store",
        meta: {
          label: "Affiliate store",
          title: "Affiliate store",
        },
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            meta: {
              label: "Affiliate store",
              title: "Affiliate store",
            },
            component: AffiliateStore,
          },
          {
            path: ":id",
            meta: {
              label: "Detail",
              title: "Detail",
            },
            component: AffiliateDetail,
          },
        ],
      },
      {
        path: "affiliate-category",
        meta: {
          label: "Affiliate category",
          title: "Affiliate category",
        },
        component: AffiliateCategory,
      },
      {
        path: "affiliate-product-tags",
        meta: {
          label: "Tag",
          title: "Tag",
        },
        component: AffiliateProductTags,
      },
      {
        path: "affiliate-product",
        meta: {
          label: "Affiliate product",
          title: "Affiliate product",
        },
        component: {
          render(c) {
            return c("router-view");
          },
        },
        children: [
          {
            path: "",
            meta: {
              label: "Affiliate product",
              title: "Affiliate product",
            },
            component: AffiliateProduct,
          },
          {
            path: "new",
            meta: {
              label: "Create new",
              title: "Create new",
            },
            component: CreateAffiliateProduct,
          },
          {
            path: ":id",
            meta: {
              label: "Detail",
              title: "Detail",
            },
            component: AffiliateProductDetail,
          },
        ],
      },
    ]
  },
  {
    path: "addresses",
    meta: { label: "Addresses", title: "Addresses", },
    component: Provinces,
  },
  {
    path: "post-categories",
    meta: { label: "Post category", title: "Post category", },
    component: PostCategory,
  },
  {
    path: "posts",
    meta: { label: "Post" },
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        meta: {
          label: "Post",
          title: "Post",
        },
        component: Posts,
      },
      {
        path: "new",
        meta: {
          label: "New post",
          title: "New post",
        },
        component: NewPost,
      },
      {
        path: ":id",
        meta: {
          label: "Post",
          title: "Post",
        },
        component: PostDetail,
      },
    ],
  },

  {
    path: "supports",
    meta: { label: "Support" },
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        meta: {
          label: "Support",
          title: "Support",
        },
        component: Support,
      },
      {
        path: "new",
        meta: {
          label: "New support",
          title: "New support",
        },
        component: NewSupport,
      },
      {
        path: ":id",
        meta: {
          label: "Support",
          title: "Support",
        },
        component: SupportDetail,
      },
    ],
  },
  {
    path: "support-types",
    meta: { label: "Support types" },
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        meta: {
          label: "Support types",
          title: "Support types",
        },
        component: SupportTypes,
      },
    ],
  },
  {
    path: 'notification-subscriber',
    meta: { label: "Support types" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        meta: {
          label: 'Subscribe notification',
          title: 'Subscribe notification'
        },
        component: SubscriberIndex
      },
    ]
  },
  {
    path: "/admin/*",
    meta: {
      title: "Not found",
      label: "Not found",
    },
    component: () => import("@/workspaces/admin/views/Page404"),
  },
];
