<template>
  <CBadge :color="color">
    <TMessage :content="id.toUpperCase()" noTranslate class="p-1" />
  </CBadge>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    color() {
      switch (this.id.toUpperCase()) {
        case "VND":
          return "danger";
        case "JPY":
          return "info";
        case "USD":
          return "dark";
        default:
          return "primary";
      }
    },
  },
};
</script>
