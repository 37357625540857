<template>
  <TSelectEditable
    v-if="editable"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      preserveSearch: true,
    }"
    :loading="loading"
    @select="select"
    @search="search"
    :normalizer="{ label: 'name', value: 'id' }"
    @close="$emit('close')"
    @change="$emit('change', $event)"
  />
  <TSelect
    v-else
    :label="label"
    class="m-0"
    addLabelClasses="font-weight-bold"
    :options="list"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      preserveSearch: true,
    }"
    :normalizer="{ label: 'name', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
  />
</template>
<script>
import mixins from "./mixins";
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";

export default {
  mixins: [mixins],
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: function () {
        return "";
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
  },
  data() {
    return {
      firstValue: null,
      valueSelected: null,
    };
  },
  created() {
    if (!this.noFetchStore)
      this.$store.dispatch("warehouse.delivery_partners.fetch.if-first-time");
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value == null) {
          this.checkValue("");
          return;
        }
        this.checkValue(value);
      },
    },
    valueSelected: {
      immediate: false,
      handler(value) {
        if (value) {
          this.$emit("update:value", value.id);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.delivery_partners.list",
      loading: "warehouse.delivery_partners.fetching",
    }),
  },
  methods: {
    checkValue(value) {
      const firstValue = this.listC.find((x) => x.id === value);
      // Nếu value rỗng
      if (!value && !this.prependAll) {
        this.valueSelected = null;
        return;
      }
      // Value k có trong list
      if (!firstValue) {
        this.$tomoni.warehouse.delivery_partners
          .all({ "filter[id]": value })
          .then(({ data }) => {
            this.firstValue = data.data[0];
            this.valueSelected = data.data[0];
          });
      }
      // Value có trong list
      if (firstValue) {
        this.valueSelected = firstValue;
      }
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("warehouse.delivery_partners.push-query", {
        "filter[name]": param,
      });
      if (this.firstValue) this.firstValue = null;
    }, 300),
  },
};
</script>
