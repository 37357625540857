import Resource, { Touch } from "@/core/store/Resource";

const touchs = [
  new Touch({ key: "purchase_id", store: "order.order_purchase" }),
];

const resource = new Resource(
  {
    service: "order",
    resource: "available_for_distribution",
  },
  {
    default_query: {
      ["filter[available_for_distribution]"]: 1,
      append: "currency_id",
      sort: "purchase_id",
      include: "purchase,product",
    },
  },

  touchs
).store();

export default resource;
