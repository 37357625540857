<template>
  <CInput
    v-bind="inputOptions"
    :value="value"
    @change="$emit('update:value', $event)"
    :type="toggle ? 'text' : 'password'"
    :placeholder="$t('Enter password')"
    :label="$t(label)"
    :autocomplete="autocomplete"
  >
    <template #prepend-content><CIcon name="cil-lock-locked" /></template>
    <template #append v-if="viewable">
      <CButton
        @click="toggle = !toggle"
        size="sm"
        variant="ghost"
        class="border"
      >
        <CIcon :name="toggle ? 'cisEyeSlash' : 'cisEye'" />
      </CButton> </template
  ></CInput>
</template>

<script>
import mixins from "./mixins";

export default {
  mixins: [mixins],
  data() {
    return {
      toggle: false,
    };
  },
  props: {
    viewable: {
      type: Boolean,
      required: false,
      default: false,
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off',
    },
  },
};
</script>
