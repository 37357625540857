import {
  cisEye, cisEyeSlash,
  cilCart,
  cilDescription,
  cilCheckAlt,
  cisPlus,
  cifVn, cifJp, cifGb,
  cibApple,
  cisWallet, cisCreditCard, cisBank,
  cilMoney,
  cidFileAdd,
  cidFile,
  cidCloudDownload,
  cisFlightTakeoff, cisBoat, cilNote, cilAddressCard,
  cisTruck, cisMap, cisLibraryBooks, cisSpeechNotes, cisNote,
  cilReload,
  cisLibraryAdd,
  cisUserFollow, cisDocument, cisQueue, cisLibrary,
  cilActionUndo,
  cilMail,
  cilCalendarCheck,
  cilBookOpen,
  cisUser, cisPeople, cilStore, cilLink,
  cidGripDots,
  cilChartBubble,
  cilChartTable,
  cilToll,
  cisImages,
  cisChartTable,
  cisStore,
  cilWallet, cilBank, cisPlusCircle,
  cidInboxIn,
  cidInboxOut,
  cilStorage,
  cilSettings,
  cilExpandRight,
  cilQueue,
  cilVericalSplit,
  cilLockLocked,
  cilExclamation,
  cilFileAdd,
  cilMinusCircle,
  cisChevronDoubleRight,
  cisListRich,
  cisToll,
  cidToll,
  cisInfoCircle,
  cilDiamond,
  cilLibraryBookmark,
  cilFolderPlus,
  cisBoxAlt,
  cisVote,
  cisFiberNew,
  cilLockUnlocked,
  cilDataTransferDown,
  cilListHighPriority,
  cilScaleImage,
  cilTruck,
  cilCartArrowDown,
  cisBorderAll,
  cilBox,
  cisBox,
  cisCalculator,
  cisBraille,
  cisBalanceScale,
  cisCallSwapCalls,
  cilPowerStandby,
  cilArchive,
  cisPrint,
  cilEye,
  cilViewList,
  cilFilter,
  cilBoxAlt,
  cilSearch,
  cilFolderAlt,
  cilDonate,
  cilWidgets,
  cisX,
  cilCommentBubbleQuestion,
  cilDollarCircle,
  cilChatBubble,
  cisCommentBubble,
  cilChartLine,
  cidCloudUpload,
  cilBellPlus,
  cilGlobe
} from "@coreui/icons-pro";

import { logo } from "@/core/assets/icons/logo";

export const iconsSet = Object.assign(
  {},
  {
    logo
  },
  {
    //
    cilFolderAlt,
    cilEye,
    cilCartArrowDown,
    cilTruck,
    cilScaleImage,
    cilListHighPriority,
    cilDataTransferDown,
    cilLockUnlocked,
    cilMinusCircle,
    cilFileAdd,
    cilExclamation,
    cilLockLocked,
    cilVericalSplit,
    cilQueue,
    cilExpandRight,
    cilSettings,
    cilStorage,
    cidInboxIn,
    cidInboxOut,
    cisPlus,
    cilCheckAlt,
    cilDescription,
    cilCart,
    cilMoney,
    cilAddressCard,
    cilNote,
    cilReload,
    cilActionUndo,
    cilCalendarCheck,
    cilMail,
    cilBookOpen,
    cilStore,
    cilLink,
    cilChartBubble,
    cilChartTable,
    cilToll,
    cilWallet,
    cilBank,
    cilDiamond,
    cilLibraryBookmark,
    cilFolderPlus,
    cilPowerStandby,
    cilBox,
    cilArchive,
    cilViewList,
    cilFilter,
    cilBoxAlt,
    cilSearch,
    cilDonate,
    cilWidgets,
    cilCommentBubbleQuestion,
    cilDollarCircle,
    cilChatBubble,
    cilChartLine,
    cilBellPlus,
    cilGlobe,
  },
  {
    cifVn,
    cifJp,
    cifGb
  },
  {
    //
    cibApple,
  },
  {
    cidGripDots,
    cidToll,
    cidCloudUpload
  },
  {
    cisEye,
    cisEyeSlash,
    cisWallet,
    cisCreditCard,
    cisBank,
    cidFileAdd,
    cidFile,
    cidCloudDownload,
    cisFlightTakeoff,
    cisBoat,
    cisMap,
    cisTruck,
    cisLibraryBooks,
    cisSpeechNotes,
    cisNote,
    cisLibraryAdd,
    cisUserFollow,
    cisDocument,
    cisQueue,
    cisLibrary,
    cisUser,
    cisPeople,
    cisImages,
    cisChartTable,
    cisStore,
    cisPlusCircle,
    cisChevronDoubleRight,
    cisListRich,
    cisToll,
    cisInfoCircle,
    cisBoxAlt,
    cisVote,
    cisFiberNew,
    cisBorderAll,
    cisBox,
    cisCalculator,
    cisBraille,
    cisBalanceScale,
    cisCallSwapCalls,
    cisPrint,
    cisX,
    cisCommentBubble,
  }
);
