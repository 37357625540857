import lodash from 'lodash'
import mixin from '@/mixins/lodash'

import { conformToMask } from "vue-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Compressor from "compressorjs"

export const masks = {
  /**
   *
   * @param {String} currency
   * @returns
   */
  symbolCurrency(currency) {
    switch (currency) {
      case "VND":
        return "₫";
      case "USD":
        return "$";
      case "JPY":
        return "¥";
      default:
        return "";
    }
  },

  moneyMask({ currency, decimalLimit = 6, noSymbol = false, prefix = '' }) {
    return createNumberMask({
      prefix: prefix,
      suffix: noSymbol ? '' : " " + this.symbolCurrency(currency.toUpperCase()),
      allowDecimal: true,
      decimalLimit: decimalLimit,
      allowNegative: true
    });
  },

  /**
   *
   * @param {Number} amount
   * @param {Object} options
   * @returns
   */
  toMoney(amount, { prefix, currency, decimalLimit = 6, noSymbol }) {
    if (typeof amount != 'number') {
      amount = 0;
    }
    if (!decimalLimit) {
      amount = amount.toFixed(0);
    }
    return conformToMask(amount.toString(), this.moneyMask({ currency, decimalLimit, prefix, noSymbol }))
      .conformedValue;
  },

  numberMask({ allowDecimal = true, decimalLimit = 2, integerLimit = 12, suffix = "", ...options }) {
    return createNumberMask({
      prefix: "",
      suffix: suffix,
      allowDecimal: allowDecimal,
      decimalLimit: decimalLimit,
      integerLimit: integerLimit,
      ...options
    });
  },
  toNumberMask(number, options = {}) {
    return conformToMask(number.toString(), this.numberMask({ thousandsSeparatorSymbol: " ", allowNegative: true, ...options }))
      .conformedValue;
  },

  toPercent(number, options = {}) {
    if (!options.decimalLimit) {
      number = number.toFixed(0);
    }
    return conformToMask(number.toString(), this.numberMask({ suffix: '%', ...options }))
      .conformedValue;
  },
}

lodash.mixin({
  /**
   *
   * @param {array} source
   * @param {array} target
   * @returns
   */
  hasAll: function (source, target) {
    if (!source) {
      return false
    }
    return target.filter(i => source.includes(i)).length === target.length
  },

  /**
   *
   * @param {array} source
   * @param {array} target
   * @returns
   */
  hasAny: function (source, target) {
    if (!source) {
      return false
    }
    return target.filter(i => source.includes(i)).length > 0
  },

  /**
   * Convert data for CSelect
   *
   * @param {Array} source
   * @param {Object} targets
   * @param {Object} keys
   * @return {Object}
   */
  'normalizeCSelect': function (source, targets = { value: 'id', label: 'name' }, keys = { value: 'id', label: 'label' }) {
    const result = !source ? null : source.map(function (item) {
      return {
        [keys.value]: item[targets.value],
        [keys.label]: item[targets.label]
      }
    })
    return result
  },

  /**
   * Kiểm tra phải có tất cả các key trong danh sách object
   *
   * @param {array} source
   * @param {string} key
   */
  hasKeyObjects(source, key) {
    return source.filter((item) => this.has(item, key)).length ==
      source.length
  },

  /**
   *
   * @param {*} source
   * @param {*} replacement
   * @returns
   */
  arrayToString(source, replacement = ' ') {
    return this.replace(this.filter(source).toString(), /,/g, replacement)
  },

  /**
   *
   * @param {string} key
   * @returns
   */
  resetKey(key = '') {
    return key + '-' + lodash.random(10000, 100000);
  },

  /**
   *
   */
  getAppendRouteQuery(route, param) {
    const query = new URLSearchParams({ ...route.query, ...param });
    return "?" + query.toString();
  },

  /**
   *
   */
  appendRouteQuery(router, route, param) {
    return new Promise((resolve) => {
      router.push({ query: { ...route.query, ...param } })
        .then((route) => {
          resolve(route.query)
        })
        .catch(() => { });
    })
  },

  /**
   *
   */
  toTimeStamp(time, type) {
    const date = new Date(time);
    const start = new Date(
      `${date.getMonth() + 1} ${date.getDate()} ${date.getFullYear()}`
    );
    const end = new Date(
      `${date.getMonth() + 1
      } ${date.getDate()} ${date.getFullYear()} 23:59:59`
    );
    const startDateConvert = Math.floor(start.getTime() / 1000);
    const endDateConvert = Math.floor(end.getTime() / 1000);
    let result
    if (type == "start") result = startDateConvert
    if (type == "end") result = endDateConvert
    if (type == "between") result = `${startDateConvert}|${endDateConvert}`
    if (!type) result = Math.floor(time / 1000);
    return result
  },

  getReferenceLink(path, id) {
    switch (path) {
      //! ADMIN

      case "user":
        return `/admin/users/${id}`;

      case "role":
        return `/admin/roles/${id}`;

      case "product": //cho nay nua
        return `/admin/goods/products/${id}`;

      case "address":
        return `/admin/addresses/${id}`;

      case "support":
        return `/admin/supports/${id}`;

      //! CUSTOMER
      //! SALE
      case "order":
        switch (`${id.substring(0, 2)}`) {
          case "OS":
            return `/sale/orders/shipment/${id}`;
          case "OW":
            return `/sale/orders/wholesale/${id}`;
          case "OP":
            return `/sale/orders/payment/${id}`;
          case "OA":
            return `/sale/orders/auction/${id}`;
          case "OR":
            return `/sale/orders/retail/${id}`;
        }
        break;

      case "contract":
        return `/sale/contracts/contracts/${id}`;

      //! PURCHASE
      case "available-for-distribution":
        return `/purchase/available-for-distribution/${id}`;

      case "purchase":
        return `/purchase/purchases/${id}`;

      //! ACCOUNTING
      case "customer":
        return `/accounting/account/customer/${id}`;

      case "supplier":
        return `/accounting/account/supplier/${id}`;

      case "card":
        return `/accounting/account/bank-card/${id}`;

      case "fund":
        return `/accounting/account/funds/${id}`;

      case "credit_order":
        return `/accounting/account/credit/${id}`;

      //! WAREHOUSE
      case "sfa":
        return `/warehouse/inbound/purchase-order?sfa_id=${id}`;

      case "distributed":
        return `/warehouse/internal/owning-boxes/distributed?owning_box_id=${id}`;

      case "invoice":
        return `/warehouse/internal/invoices/?invoice_id=${id}`;

      case "goods_delivery":
        return `/warehouse/internal/goods-deliveries?goods_delivery_id=${id}`;

      case "lading_bill":
        return `/warehouse/internal/lading-bills?lading_bill_id=${id}`;

      case "lading_bill.search":
        return `/warehouse/internal/lading-bills?search=${id}`;

      case "assemble-to-stock.pallet":
        return `/warehouse/internal/assemble-to-stock?pallet_id=${id}`;

      case "assemble-to-stock.box":
        return `/warehouse/internal/assemble-to-stock?box_id=${id}`;

      case "box":
        return `/warehouse/internal/boxes/management?box_id=${id}`;

      case "assemble-to-order":
        return `/warehouse/internal/owning-boxes/assemble-to-order?order_id=${id}`;

      case "transaction":
        return `/accounting/dashboard?transaction_id=${id}`;

    }
  },
  /**
   *
   * @param {Object} options
   * @param {Number} quality
   * @param {File} file
   * @returns
   */
  compressor(file, quality = 0.6, options = {}) {
    return new Promise((resolve) => {
      if(file instanceof File)
      {
        new Compressor(file, {
          quality: quality,
          convertSize: 2000,
          ...options,
          success(result) {
            resolve(new File([result], result.name, {
              type: result.type,
            }))
          }
        });
      }
      else resolve(file)
    })
  },

  ...masks,
  //...
  ...mixin,
})

export default lodash
