<template>
  <div class="img-wrapper">
    <CIcon
      v-if="deletable"
      class="icon-delete cursor-pointer text-danger"
      name="cil-x-circle"
      size="lg"
      @click.native="$emit('click-delete')"
    />
    <div
      class="rounded t-image h-100"
      :class="{ 't-image-click': clickable || showFull }"
      @click="showFullScreen"
    >
      <CImg
        style="object-fit: contain; height: 100%; width: 100%"
        thumbnail
        :src="src"
        placeholderColor
        class="p-0"
      />
      <div class="title-wrapper text-white p-2" v-if="$slots['title']">
        <slot name="title"></slot>
      </div>
      <CIcon
        class="icon cursor-pointer text-white"
        name="cilFullscreen"
        size="lg"
      />
    </div>
    <CModal
      class="modal-custom p-0"
      :show="show"
      size="xl"
      centered
      @update:show="show = $event"
    >
      <template #header v-if="downloadable">
        <TButton
          color="primary"
          size="sm"
          variant=""
          @click="download"
          icon="cid-cloud-download"
          class="ml-auto"
          content="Download"
          v-if="downloadable"
        />
        <TButton
          color="dark"
          size="sm"
          variant="outline"
          class="ml-1"
          @click="show = false"
          tooltip="Close"
          icon="cis-x"
        />
      </template>

      <CImg
        style="object-fit: contain"
        thumbnail
        fluidGrow
        :src="src"
        placeholderColor
        class="p-0 d-flex mx-auto"
      />
      <slot name="modal-content"></slot>
    </CModal>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    showFull: {
      type: Boolean,
      default: false,
    },
    downloadable: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showFullScreen() {
      if (this.showFull) this.show = true;
    },
    download() {
      window.open(this.src);
    },
  },
};
</script>
<style scoped>
* >>> .modal-custom .modal-footer {
  display: none;
}
.t-image {
  position: relative;
  overflow: hidden;
}
.img-wrapper {
  position: relative;
}
.t-image-click:hover {
  cursor: pointer;
}
.t-image.t-image-click:hover .icon {
  opacity: 1;
  transition: all 0.5s;
}
.img-wrapper:hover .icon-delete {
  opacity: 1;
  transition: all 0.5s;
}
.t-image .title-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #00000078;
}
.icon {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
  transition: all 0.5s;
  transform: translate(-50%, -50%);
}
.icon-delete {
  position: absolute;
  right: -3%;
  top: -3%;
  transition: all 0.5s;
  z-index: 1;
  opacity: 0;
  background: white;
  border-radius: 50px;
}
.t-image-click:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: all 0.5s;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  background-size: 100% 100%;
}
.t-image-click:hover:before {
  opacity: 0.8;
}
</style>
