import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'product',
    resource: 'product_affiliates',
    prefix: 'product.product_affiliate_store_list',
    prefix_state: 'product_affiliate_store_list',
  },
  {
    default_query: {
      include: "categories,affiliateStore",
      sort: "-id"
    },
  }
).store()

export default resource
