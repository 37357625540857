import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'order_id', store: 'warehouse.assemble_orders' })
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'owning_boxes',
  },
  {
    default_query: {
      include: "box.tags,user",
      append: "is_cancel_distributed",
      sort: "-updated_at",
    },
    detail_query: {
      append: "is_cancel_distributed",
      include: "user"
    }
  },
  touchs
).store()

export default resource
