import Resource from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const resource = new Resource(
  {
    service: 'order',
    resource: 'contracts'
  },
  {
    default_query: {
      include: "customer",
      sort: "-updated_at",
    },
    detail_query: {
      append: "items,freight_charge,freight_charge_paid,freight_charge_unsolved,freight_charge_debited,freight_charge_outstanding,discount",
      include: "orders,customer"
    },
    default_detail: {
      status_id: '',
      customer: {},
      items: []
    }
  },
).store()

const PREFIX = "order.contracts";

const actions = {
  [PREFIX + ".detail.attach-order"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.order.contracts
        .attachOrder(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".attach-order"](context, { id, attributes }) {
    return new Promise((resolve, reject) => {
      tomoni.order.contracts
        .attachOrder(id, attributes)
        .then(({ data }) => {
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.detach-order"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.order.contracts
        .detachOrder(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};
