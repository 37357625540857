<template>
  <div></div>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
div {
  height: 50px;
  background-color: darkgray;
  opacity: 30%;
}
</style>