<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <TModal
        title="Address"
        v-if="editing"
        :show.sync="editing"
        @update:show="$emit('update:show', $event)"
        size="lg"
      >
        <template #actions>
          <TButtonSave
            variant="outline"
            @click="
              $emit('change', input, full_address);
              setEditing(false);
            "
            :options="{ disabled: disabled }"
          />
        </template>
        <SSelectAddress
          :ward_id.sync="input.ward_id"
          :address_detail.sync="input.address"
        />
      </TModal>
    </slot>
    <TMessage
      :content="content"
      noTranslate
      :italic="editable"
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      @click-edit="editing = true"
    >
      <template #suffix>
        <slot name="suffix"></slot>
      </template>
    </TMessage>
  </div>
</template>

<script>
import actions from "@/core/components/T/Button/actions";
import { mapGetters } from "vuex";

export default {
  mixins: [actions],
  props: {
    address: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    wardId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      wardDetail: "",
      input_data: this.getInput(),
    };
  },
  computed: {
    ...mapGetters({
      ward: "helper.wards.detail",
    }),
    full_address() {
      return (
        this.ward.name +
        ", " +
        this.ward?.district?.name +
        ", " +
        this.ward?.province?.name
      );
    },
    input: {
      get: function () {
        return this.input_data;
      },
      set: function (newValue) {
        this.input_data = newValue;
      },
    },
    disabled() {
      return !this.ward.id || !this.input_data.address;
    },
  },
  methods: {
    getInput() {
      return {
        address: this.address,
        ward_id: this.wardId,
      };
    },
  },
};
</script>
