<template>
  <CBadge :color="getColor" style="font-size: 85%" class="px-2">
    <TMessage
      :content="tag.name"
      noTranslate
      :removable="removable"
      @click-remove="showModalConfirm = true"
    >
    </TMessage
    ><TModalConfirm
      class="text-dark"
      v-if="removable"
      :show.sync="showModalConfirm"
      @click-confirm="removeTag"
    />
  </CBadge>
</template>

<script>
export default {
  props: {
    tag: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showModalConfirm: false,
    };
  },
  computed: {
    getColor() {
      switch (this.tag.id) {
        case "compensation":
          return "warning";
        case "leave_container":
          return "dark";
        case "canceled":
          return "danger";
        case "liquidation":
          return "info";
        case "special":
          return "primary";
        default:
          return "secondary";
      }
    },
  },
  methods: {
    removeTag() {
      this.$store.dispatch("warehouse.boxes_management.detail.detach-tags", {
        tag_ids: [this.tag.id],
      });
    },
  },
};
</script>
