import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'trackings',
  },
  {
    default_query: {
      sort: "-created_at",
      include: "trackingReferences",
    }
  }
).store()

export default resource
