<template>
  <TSelect
    :label="label"
    class="m-0"
    placeholder="Select"
    :options="statuses"
    :value="valueSelected"
    :selectOptions="selectOptions"
    @select="select"
  />
</template>
<script>
export default {
  props: {
    prepend: {
      type: Array,
      required: false,
      default: function() {
        return [{ id: "", name: this.$t("All") }];
      }
    },
    value: {
      type: String,
      required: false,
      default: function() {
        return "";
      }
    },
    selectOptions: {
      type: Object,
      required: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      valueSelected: null
    };
  },
  watch: {
    status: {
      immediate: true,
      handler(status) {
        this.valueSelected = status;
      }
    }
  },
  computed: {
    statuses() {
      return [...this.prepend, ...this.list];
    },
    list() {
      return this.$store.getters["accounting.banks.list"];
    },
    status() {
      return this.statuses.find(x => x.id === this.value);
    }
  },
  methods: {
    select(status) {
      this.valueSelected = status;
      this.$emit("update:value", status.id);
      this.$emit("change");
    }
  }
};
</script>
