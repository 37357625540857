<template>
  <div :class="['d-flex align-items-center', serializeClass]">
    <TMessage
      :content="content"
      :size="size"
      class="m-0"
      :addClasses="['m-0']"
    />
    <CIcon
      name="cil-ban"
      size="custom"
      :style="`height: ${iconSize}px`"
      customClasses="text-danger ml-2"
    />
  </div>
</template>

<script>
export default {
  props: {
    alignment: {
      type: String,
      required: false,
      default: "center",
    },
    vertical: {
      type: String,
      required: false,
      default: "center",
    },
    spacing: {
      type: String,
      required: false,
      default: "my-5",
    },
    iconSize: {
      type: [String, Number],
      required: false,
      default: "30",
    },
    size: {
      type: String,
      required: false,
      default: "h3",
    },
    addClasses: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    content: {
      type: String,
      required: false,
      default: "No data",
    },
  },
  computed: {
    serializeClass() {
      const properties = [
        this.alignment ? `justify-content-${this.alignment}` : null,
        this.vertical ? `align-items-${this.alignment}` : null,
        this.spacing,
        ...this.addClasses,
      ];
      return this.lodash.arrayToString(properties);
    },
  },
};
</script>
