<template>
  <TModal
    title="Create order credit"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="add"
  >
    <template #actions>
      <TButton
        content="Create"
        :options="{
          disabled:
            loading ||
            paymentLoading ||
            !data.payment_due_date ||
            !data.amount ||
            lodash.isEmpty(walletSelected),
        }"
        icon="cil-plus"
        @click="add"
      />
    </template>
    <div v-if="!lodash.isEmpty(walletSelected)">
      <TInputMoney
        label="Amount"
        :value.sync="data.amount"
        :currency="data.currency_id"
        class="mb-3"
      />
      <TInputDateTime
        label="Payment due date"
        :value.sync="data.payment_due_date"
      />
    </div>
    <TMessageNoData
      v-else
      class="mt-2"
      content="Add wallets"
      color="danger"
      bold
    />
    <!-- <div>
      <TMessage content="Payments" class="my-2" bold />
      <CInputRadioGroup
        class="pt-1"
        :options="options"
        @update:checked="$emit('update:value', $event)"
        :checked.sync="paymentSelected"
        inline
        custom
      />
    </div> -->
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    walletList: {
      type: Array,
      required: false,
    },
    order: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      data: {
        payment_due_date: null,
        amount: 0,
        wallet_id: "",
      },
      loading: false,
      paymentLoading: false,
      paymentSelected: "payment_order",
    };
  },
  computed: {
    walletSelected() {
      if (!this.walletList.length) return {};
      if (this.paymentSelected == "payment_order")
        return this.walletList.find((x) => x.currency_id == "JPY");
      else return this.walletList.find((x) => x.currency_id == "VND");
    },
    options() {
      return [
        {
          value: "payment_order",
          label: this.$t("Payment for goods"),
        },
        {
          value: "payment_service",
          label: this.$t("Service fee payment"),
        },
      ];
    },
  },
  methods: {
    refreshData() {
      this.data = this.lodash.pick(this.data, ["organization_id"]);
    },
    add() {
      this.loading = true;
      this.$tomoni.accounting.credit_orders
        .create({
          ...this.data,
          wallet_id: this.walletSelected.id,
        })
        .then(({ data }) => {
          this.paymentLoading = true;
          this.$tomoni.accounting["transactions_" + this.paymentSelected]
            .create({
              transactionable_id: this.order.customer_id,
              amount: this.data.amount,
              currency_id: this.walletSelected.currency_id,
              transactionable_type: "user",
              credit_order_id: data.id,
              voucher_date: this.lodash.toTimeStamp(Date.now()),
              "receipts[0][receiptable_id]": this.order.id,
              "receipts[0][receiptable_type]": "order",
            })
            .then(() => {
              this.$emit("update:show", false);
              this.$emit("change", data);
            })
            .catch((error) => {
              this.$store.dispatch("errors.push-http-error", error);
            })
            .finally(() => {
              this.paymentLoading = false;
            });
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
