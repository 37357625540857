<template>
  <CRow>
    <CCol>
      <TInputRadio
        label="Object"
        :list="types"
        :value.sync="temp.transactionable_type"
        custom
        class="h-auto"
      />
      <div>
        <SSelectCustomer
          v-if="value.transactionable_type === 'user'"
          :value.sync="temp.transactionable_id"
          class="mb-3"
        />
        <SSelectSupplier
          v-if="value.transactionable_type === 'supplier'"
          :value.sync="temp.transactionable_id"
          class="mb-3"
        />
      </div>
    </CCol>
  </CRow>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    typesPick: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      transactionableTypes: [
        { value: "user", label: this.$t("Customer") },
        { value: "supplier", label: this.$t("Supplier") },
      ],
      temp: this.value,
    };
  },
  watch: {
    temp: {
      immediate: true,
      deep: true,
      handler(new_temp) {
        if (!this.lodash.isEqual(this.value, new_temp))
          this.$emit("update:value", new_temp);
      },
    },
    value(new_value) {
      if (!this.lodash.isEqual(this.temp, new_value)) this.temp = new_value;
    },
  },
  computed: {
    types() {
      if (this.typesPick[0] == "all") return this.transactionableTypes;
      return this.transactionableTypes.filter((x) =>
        this.typesPick.includes(x.value)
      );
    },
  },
};
</script>
