const UserInfo = () => import("../views/UserInfo");
const Orders = () => import("../views/orders/Orders");
const OrderDetail = () => import("../views/orders/OrderDetail");
const Address = () => import("../views/addresses/Address");
const Contract = () => import("../views/contracts/Contract");
const Wallet = () => import("../views/wallets/Wallet");
const WalletDetail = () => import("../views/wallets/WalletDetail");
const ContractDetail = () => import("../views/contracts/ContractDetail");

export default [
  {
    path: "/",
    redirect: "me",
  },
  {
    path: "me",
    name: "User info",
    component: UserInfo,
  },

  {
    path: "orders",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "Orders",
        meta: {
          label: "Orders",
        },
        component: Orders,
      },
      {
        path: ":id",
        meta: {
          label: "Customer order",
        },
        name: "Order detail",
        component: OrderDetail,
      },
    ],
  },

  {
    path: "contract",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "Contracts",
        meta: {
          label: "Contract",
        },
        component: Contract,
      },
      {
        path: ":id",
        meta: {
          label: "Customer contract",
        },
        name: "Contract detail",
        component: ContractDetail,
      },
    ],
  },
  {
    path: "wallets",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "/",
        name: "Wallet",
        meta: {
          label: "Wallet detail",
        },
        component: Wallet,
      },
      {
        path: ":id",
        meta: {
          label: "Customer wallet",
        },
        name: "Wallet detail",
        component: WalletDetail,
      },
    ],
  },
  {
    path: "address",
    name: "Address",
    component: Address,
  },
];
