<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <SSelectOrganization
        v-if="editing"
        :value="id"
        @close="setEditing(false)"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        :editable="editable"
      />
    </slot>
    <TMessage
      :content="name"
      noTranslate
      :italic="editable"
      v-show="!editing || dontHideWhenEditing"
      :editable="editable"
      @click-edit="showEdit()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mixin from "@/core/components/T/Button/actions";
export default {
  mixins: [mixin],
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    },
  },
  computed: {
    ...mapGetters({
      list: "auth.organizations.list",
    }),
    name() {
      const selected = this.lodash.find(this.list, { id: this.id });
      if(!selected) {
        return '';
      }
      return selected.name;
    },
  },
};
</script>
