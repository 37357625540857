import tomoni from '../services/tomoni'

export class Touch {
  constructor({ key, store }) {
    this.KEY = key // foreign key
    this.STORE = store
  }
}

export default class Resource {

  constructor({ prefix = null, prefix_state = null, service, resource, primary_key = 'id', paginate = true }, defaults = {}, touches = []) {
    this.config = {
      SERVICE: service,
      RESOURCE: resource,
      PREFIX: prefix ?? service + '.' + resource,
      PREFIX_STATE: prefix_state ?? service + '_' + resource,
      PRIMARY_KEY: primary_key,
      PAGINATE: paginate,
    }
    this.defaults = defaults
    this.touches = touches ?? []

    return this
  }

  store() {
    return {
      state: this.state(this.defaults, this.config),
      getters: this.getters(this.config),
      mutations: this.mutations(this.config, this.touches),
      actions: this.actions(this.config, this.touches),
    }
  }

  state({
    paginate = {
      current: 1,
      last: 1,
      per: 15,
    },
    default_query = {},
    detail_query = {},
    default_detail = {},
    default_list = []
  }, { PREFIX_STATE }) {
    return {
      [PREFIX_STATE + '_list']: default_list,
      [PREFIX_STATE + '_paginate']: paginate,
      [PREFIX_STATE + '_default_query']: default_query,
      [PREFIX_STATE + '_query']: {},
      [PREFIX_STATE + '_fetching']: false,
      [PREFIX_STATE + '_creating']: false,
      [PREFIX_STATE + '_updating']: false,
      [PREFIX_STATE + '_deleting']: false,
      [PREFIX_STATE + '_default_detail']: default_detail,
      [PREFIX_STATE + '_detail']: default_detail,
      [PREFIX_STATE + '_detail_query']: detail_query,
      [PREFIX_STATE + '_detail_fetching']: false,
      [PREFIX_STATE + '_detail_updating']: false,
      [PREFIX_STATE + '_detail_deleting']: false,
    }
  }

  getters({ PREFIX, PREFIX_STATE, PRIMARY_KEY }) {
    return {
      [PREFIX + '.list'](state) {
        return state[PREFIX_STATE + '_list']
      },
      [PREFIX + '.paginate'](state) {
        return state[PREFIX_STATE + '_paginate']
      },
      [PREFIX + '.fetching'](state) {
        return state[PREFIX_STATE + '_fetching']
      },
      [PREFIX + '.creating'](state) {
        return state[PREFIX_STATE + '_creating']
      },
      [PREFIX + '.updating'](state) {
        return state[PREFIX_STATE + '_updating']
      },
      [PREFIX + '.deleting'](state) {
        return state[PREFIX_STATE + '_deleting']
      },
      [PREFIX + '.loading'](state) {
        return state[PREFIX_STATE + '_fetching'] || state[PREFIX_STATE + '_creating'] || state[PREFIX_STATE + '_updating'] || state[PREFIX_STATE + '_deleting']
      },
      [PREFIX + '.query'](state) {
        return {
          ...state[PREFIX_STATE + '_default_query'],
          ...state[PREFIX_STATE + '_query'],
        }
      },
      [PREFIX + '.detail'](state) {
        return {
          ...state[PREFIX_STATE + '_default_detail'],
          ...state[PREFIX_STATE + '_detail'],
        }
      },
      [PREFIX + '.detail.id'](state) {
        return state[PREFIX_STATE + '_detail'][PRIMARY_KEY]
      },
      [PREFIX + '.detail.fetching'](state) {
        return state[PREFIX_STATE + '_detail_fetching']
      },
      [PREFIX + '.detail.updating'](state) {
        return state[PREFIX_STATE + '_detail_updating']
      },
      [PREFIX + '.detail.deleting'](state) {
        return state[PREFIX_STATE + '_detail_deleting']
      },
      [PREFIX + '.detail.loading'](state) {
        return state[PREFIX_STATE + '_detail_fetching'] || state[PREFIX_STATE + '_detail_updating'] || state[PREFIX_STATE + '_detail_deleting']
      },
      [PREFIX + '.detail.query'](state) {
        return state[PREFIX_STATE + '_detail_query']
      }
    }
  }

  mutations({ PREFIX, PREFIX_STATE, PRIMARY_KEY }, touches) {
    return {
      [PREFIX + '.set-list'](state, list) {
        state[PREFIX_STATE + '_list'] = list;
      },
      [PREFIX + '.unshift-list'](state, items) {
        if (!Array.isArray(items)) {
          items = [items]
        }

        state[PREFIX_STATE + '_list'].unshift(...items);

        // touch to relations
        items.forEach((item) => {
          touches.forEach((touch) => {
            this.commit(touch.STORE + '.merge', item[touch.KEY])
          })
        })
      },
      [PREFIX + '.push-list'](state, items) {
        if (!Array.isArray(items)) {
          items = [items]
        }

        state[PREFIX_STATE + '_list'].push(...items);

        // touch to relations
        items.forEach((item) => {
          touches.forEach((touch) => {
            this.commit(touch.STORE + '.merge', item[touch.KEY])
          })
        })
      },
      [PREFIX + '.merge'](state, data) {
        if (state[PREFIX_STATE + '_detail'][PRIMARY_KEY] == data[PRIMARY_KEY]) {
          this.commit(PREFIX + '.detail.merge', data)
        }

        state[PREFIX_STATE + '_list'] = state[PREFIX_STATE + '_list'].map((item) => {
          if (item[PRIMARY_KEY] == data[PRIMARY_KEY]) {
            item = { ...item, ...data }
          }
          return item
        })
      },
      [PREFIX + '.set-fetching'](state, fetching) {
        state[PREFIX_STATE + '_fetching'] = fetching;
      },
      [PREFIX + '.set-creating'](state, creating) {
        state[PREFIX_STATE + '_creating'] = creating;
      },
      [PREFIX + '.set-updating'](state, updating) {
        state[PREFIX_STATE + '_updating'] = updating;
      },
      [PREFIX + '.set-deleting'](state, deleting) {
        state[PREFIX_STATE + '_deleting'] = deleting;
      },
      [PREFIX + '.set-paginate'](state, { current_page, last_page, per_page }) {
        state[PREFIX_STATE + '_paginate'] = {
          current: current_page,
          last: last_page,
          per: per_page,
        }
      },
      [PREFIX + '.push-query'](state, query) {
        state[PREFIX_STATE + '_query'] = { ...state[PREFIX_STATE + '_query'], ...query };
      },
      [PREFIX + '.reset-query'](state) {
        state[PREFIX_STATE + '_query'] = {};
      },
      [PREFIX + '.detail.set-fetching'](state, fetching) {
        state[PREFIX_STATE + '_detail_fetching'] = fetching;
      },
      [PREFIX + '.detail.set-updating'](state, updating) {
        state[PREFIX_STATE + '_detail_updating'] = updating;
      },
      [PREFIX + '.detail.set-deleting'](state, deleting) {
        state[PREFIX_STATE + '_detail_deleting'] = deleting;
      },
      [PREFIX + '.detail.set-detail'](state, data) {
        state[PREFIX_STATE + '_detail'] = data;
      },
      [PREFIX + '.detail.purge'](state) {
        state[PREFIX_STATE + '_detail'] = null;
      },
      [PREFIX + '.list.purge'](state) {
        state[PREFIX_STATE + '_list'] = [];
      },
      [PREFIX + '.purge'](state) {
        state[PREFIX_STATE + '_list'] = [];
        state[PREFIX_STATE + '_detail'] = null;
      },
      [PREFIX + '.select'](state, id) {
        const selected = state[PREFIX_STATE + '_list'].find((item) => item[PRIMARY_KEY] == id)
        this.commit(PREFIX + '.detail.set-detail', selected);
      },
      [PREFIX + '.detail.merge'](state, data) {
        if (state[PREFIX_STATE + '_detail'] == data) {
          return
        }

        // merge detail
        state[PREFIX_STATE + '_detail'] = { ...state[PREFIX_STATE + '_detail'], ...data };

        // merge list
        this.commit(PREFIX + '.merge', state[PREFIX_STATE + '_detail'])
      },
      [PREFIX + '.delete'](state, { id, data }) {
        state[PREFIX_STATE + '_list'] = state[PREFIX_STATE + '_list'].filter((item) => item[PRIMARY_KEY] != id)

        // touch to relations
        if (data) {
          touches.forEach((touch) => {
            this.commit(touch.STORE + '.merge', data[touch.KEY])
          })
        }
      },
    }
  }

  actions({ PREFIX, PREFIX_STATE, SERVICE, RESOURCE, PAGINATE }, touches) {
    return {
      [PREFIX + '.fetch'](context, option = { append: false }) {
        return new Promise((resolve, reject) => {
          context.commit(PREFIX + '.set-fetching', true);
          tomoni[SERVICE][RESOURCE]
            .all(context.getters[PREFIX + '.query'])
            .then(({ data }) => {
              if (PAGINATE) {
                context.commit(PREFIX + '.set-paginate', data)
                if (option.append) {
                  context.commit(PREFIX + '.push-list', data.data)
                } else {
                  context.commit(PREFIX + '.set-list', data.data)
                }
              } else {
                context.commit(PREFIX + '.set-list', data)
              }
              context.commit(PREFIX + '.set-fetching', false);
              resolve(data)
            }).catch((error) => {
              context.dispatch('errors.push-http-error', error);
              reject(error);
            }).finally(() => {
              context.commit(PREFIX + '.set-fetching', false);
            })
        });
      },
      [PREFIX + '.fetch.if-first-time'](context) {
        if (context.getters[PREFIX + '.list'].length) {
          return context.getters[PREFIX + '.list'];
        }
        return context.dispatch(PREFIX + '.fetch');
      },
      [PREFIX + '.append-next-page'](context) {
        context.commit(PREFIX + '.push-query', {
          page: context.getters[PREFIX + '.paginate'].current + 1,
        })
        return context.dispatch(PREFIX + '.fetch', { append: true })
      },
      [PREFIX + '.change-page'](context, page) {
        context.commit(PREFIX + '.push-query', { page })
        return context.dispatch(PREFIX + '.fetch')
      },
      [PREFIX + '.push-query'](context, query) {
        context.commit(PREFIX + '.push-query', query)
        return context.dispatch(PREFIX + '.fetch')
      },
      [PREFIX + '.apply-query'](context, query) {
        context.commit(PREFIX + '.reset-query')
        context.commit(PREFIX + '.push-query', query)
        return context.dispatch(PREFIX + '.fetch')
      },
      [PREFIX + '.create'](context, attributes) {
        return new Promise((resolve, reject) => {
          context.commit(PREFIX + '.set-creating', true);
          tomoni[SERVICE][RESOURCE].create(attributes)
            .then(({ data }) => {
              context.commit(PREFIX + '.set-creating', false);
              context.commit(PREFIX + '.unshift-list', data)
              touches.forEach(touch => {
                if (context.getters[touch.STORE + '.detail.id'] == data[touch.KEY]) {
                  context.dispatch(touch.STORE + '.detail.refresh')
                }
              })
              context.commit("toasts.push", {
                message: "Created",
                type: "success",
              });
              resolve(data)
            }).catch((error) => {
              context.dispatch('errors.push-http-error', error);
              reject(error);
            }).finally(() => {
              context.commit(PREFIX + '.set-creating', false);
            })
        });
      },
      [PREFIX + '.update'](context, { id, attributes }) {
        return new Promise((resolve, reject) => {
          context.commit(PREFIX + '.set-updating', true);
          tomoni[SERVICE][RESOURCE].update(id, attributes)
            .then(({ data }) => {
              context.commit(PREFIX + '.set-updating', false);
              context.commit(PREFIX + '.merge', data)
              touches.forEach(touch => {
                if (context.getters[touch.STORE + '.detail.id'] == data[touch.KEY]) {
                  context.dispatch(touch.STORE + '.detail.refresh')
                }
              })
              context.commit("toasts.push", {
                message: "Updated",
                type: "success",
              });
              resolve(data)
            }).catch((error) => {
              context.dispatch('errors.push-http-error', error);
              reject(error);
            }).finally(() => {
              context.commit(PREFIX + '.set-updating', false);
            })
        });
      },
      [PREFIX + '.delete'](context, id) {
        return new Promise((resolve, reject) => {
          context.commit(PREFIX + '.set-deleting', true);
          tomoni[SERVICE][RESOURCE].delete(id)
            .then(({ data }) => {
              context.commit(PREFIX + '.set-deleting', false);
              context.commit(PREFIX + '.delete', { id, data });
              touches.forEach(touch => {
                if (context.getters[touch.STORE + '.detail.id'] == data[touch.KEY]) {
                  context.dispatch(touch.STORE + '.detail.refresh')
                }
              })
              context.commit("toasts.push", {
                message: "Deleted",
                type: "success",
              });
              resolve(data)
            }).catch((error) => {
              context.dispatch('errors.push-http-error', error);
              reject(error);
            }).finally(() => {
              context.commit(PREFIX + '.set-deleting', false);
            })
        });
      },
      [PREFIX + '.detail.fetch'](context, id) {
        return new Promise((resolve, reject) => {
          context.commit(PREFIX + '.detail.set-fetching', true);
          tomoni[SERVICE][RESOURCE].get(id, context.state[PREFIX_STATE + '_detail_query'])
            .then(({ data }) => {
              context.commit(PREFIX + '.detail.set-detail', data)
              context.commit(PREFIX + '.detail.set-fetching', false);
              context.commit(PREFIX + '.merge', data)
              resolve(data)
            }).catch((error) => {
              context.dispatch('errors.push-http-error', error);
              reject(error);
            }).finally(() => {
              context.commit(PREFIX + '.detail.set-fetching', false);
            })
        });
      },
      [PREFIX + '.detail.refresh'](context) {
        if (context.getters[PREFIX + '.detail.id']) {
          return context.dispatch(PREFIX + '.detail.fetch', context.getters[PREFIX + '.detail.id']);
        }
      },
      [PREFIX + '.detail.update'](context, attributes) {
        return new Promise((resolve, reject) => {
          context.commit(PREFIX + '.detail.set-updating', true);
          tomoni[SERVICE][RESOURCE].update(context.getters[PREFIX + '.detail.id'], attributes)
            .then(({ data }) => {
              context.commit(PREFIX + '.detail.set-updating', false);
              context.commit(PREFIX + '.detail.merge', data)
              touches.forEach(touch => {
                if (context.getters[touch.STORE + '.detail.id'] == data[touch.KEY]) {
                  context.dispatch(touch.STORE + '.detail.refresh')
                }
              })
              context.commit("toasts.push", {
                message: "Updated",
                type: "success",
              });
              resolve(data)
            }).catch((error) => {
              context.dispatch('errors.push-http-error', error);
              reject(error);
            }).finally(() => {
              context.commit(PREFIX + '.detail.set-updating', false);
            })
        });
      },
      [PREFIX + '.detail.delete'](context) {
        return new Promise((resolve, reject) => {
          context.commit(PREFIX + '.detail.set-deleting', true);
          tomoni[SERVICE][RESOURCE].delete(context.getters[PREFIX + '.detail.id'])
            .then(({ data }) => {
              context.commit(PREFIX + '.detail.set-deleting', false);
              context.commit(PREFIX + '.delete', { id: context.getters[PREFIX + '.detail.id'], data });
              touches.forEach(touch => {
                if (context.getters[touch.STORE + '.detail.id'] == data[touch.KEY]) {
                  context.dispatch(touch.STORE + '.detail.refresh')
                }
              })
              context.commit("toasts.push", {
                message: "Deleted",
                type: "success",
              });
              resolve(data)
            }).catch((error) => {
              context.dispatch('errors.push-http-error', error);
              reject(error);
            }).finally(() => {
              context.commit(PREFIX + '.detail.set-deleting', false);
            })
        });
      },
    }
  }
}
