<template>
  <TModal
    title="Create district"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
      />
    </template>
    <CRow>
      <CCol col="12">
        <TInputText
          class="mt-3"
          placeholder="Enter district name"
          label="District"
          :value.sync="data.name"
        >
          <template #prepend-content>
            <CIcon name="cil-view-list" />
          </template>
        </TInputText>

        <TInputText
          class="mt-3"
          placeholder="Enter district id"
          label="District id"
          :value.sync="data.id"
        >
          <template #prepend-content>
            <CIcon name="cil-view-list" />
          </template>
        </TInputText>
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      data: {
        name: null,
        province_id: null,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {
    value(){
      this.data.province_id = this.value;
    }
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("helper.districts.create", this.data)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>