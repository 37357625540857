import { OrderService } from '../services'

export const SERVICE = OrderService

export default {
  attachTracking(id, attributes) {
    return SERVICE.put(`api/purchases/${id}/attach-tracking`, attributes);
  },
  detachTracking(id, attributes) {
    return SERVICE.put(`api/purchases/${id}/detach-tracking`, attributes);
  },
};
