<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="list"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: true,
      preserveSearch: true,
      ...customOptionLabel,
      ...selectOptions,
    }"
    :normalizer="{ label: 'id', value: 'id' }"
    :placeholder="cPlaceholder"
    :loading="loading"
    @select="select"
    @search="search"
  />
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: [Number, String],
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    noCustomLabel: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Object,
      require: false,
    },
    outstanding: {
      type: Number,
    },
  },
  watch: {
    outstanding: {
      immediate: true,
      handler(v) {
        if (v) {
          this.$store.dispatch("order.purchases.apply-query", {
            "filter_between[outstanding]": v,
          });
        } else {
          this.$store.dispatch("order.purchases.fetch.if-first-time");
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "order.purchases.list",
      loading: "order.purchases.fetching",
    }),
    customOptionLabel() {
      return !this.noCustomLabel ? { "custom-label": this.customLabel } : {};
    },
    cPlaceholder() {
      return this.selectOptions?.disabled
        ? "Choose a supplier first"
        : "Select";
    },
    valueSelected() {
      return this.list.find((x) => x.id == this.value);
    }
  },
  methods: {
    select(value) {
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("order.purchases.apply-query", {
        "filter[id]": param,
        "filter_between[outstanding]": this.outstanding,
      });
    }, 300),
    customLabel({ id, supplier }) {
      return `${id} – ${supplier?.name ? supplier.name : ""}`;
    },
  },
};
</script>
