import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'accounting',
    resource: 'cards',
  },
  {
    default_query: {
      include: 'bank',
    },
    detail_query: {
      include: 'bank,currency',
    }
  }
).store()

export default resource
