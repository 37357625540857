<template>
  <CModal :show="show" @update:show="$emit('update:show', $event)">
    <template #header>
      <TMessage :content="title" bold capitalize class="my-auto" />
    </template>
    <slot name="content">
      <TMessage :content="content" />
    </slot>
    <template #footer>
      <slot name="actions">
        <TButton
          color="secondary"
          content="Cancel"
          @click="$emit('update:show', false)"
        />
        <TButton
          class="ml-2"
          color="primary"
          content="Confirm"
          @click="confirm"
        />
      </slot> </template
  ></CModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Confirmation",
    },
    content: {
      type: String,
      default: "Are you sure?",
    },
  },
  methods: {
    confirm() {
      this.$emit("click-confirm");
      this.$emit("update:show", false);
    },
  },
};
</script>
