const Retail = () => import('../views/retail/Index')
const RetailDetail = () => import('../views/retail/Detail')
const NewRetail = () => import('../views/retail/NewRetail')
const NewWholesale = () => import('../views/wholesale/New')
const Wholesale = () => import('../views/wholesale/Index')
const WholesaleDetail = () => import('../views/wholesale/Detail')
const Shipment = () => import('../views/shipment/Index')
const NewShipment = () => import('../views/shipment/New')
const ShipmentDetail = () => import('../views/shipment/Detail')
const Products = () => import('@/workspaces/admin/views/goods/Index')
const Product = () => import('@/workspaces/admin/views/goods/Detail')
const NewProduct = () => import('@/workspaces/admin/views/goods/New')
const Origins = () => import('@/workspaces/admin/views/goods/Origin')
const Payment = () => import('../views/payment/Index')
const NewPayment = () => import('../views/payment/New')
const PaymentDetail = () => import('../views/payment/Detail')
const Auction = () => import('../views/auction/Index')
const NewAuction = () => import('../views/auction/New')
const AuctionDetail = () => import('../views/auction/Detail')
const Contracts = () => import('../views/contract/Index')
const NewContract = () => import('../views/contract/New')
const ContractDetail = () => import('../views/contract/Detail')
const GoodsInventory = () => import('../views/inventory/Index')
const Orders = () => import('../views/Orders')
const Category = () => import('@/workspaces/admin/views/goods/Category')
const NewSupport = () => import('@/workspaces/admin/views/support/NewSupport')
const Supports = () => import('@/workspaces/admin/views/support/Index')
const SupportDetail = () => import('@/workspaces/admin/views/support/SupportDetail')
const CustomerDebt = () => import("@/workspaces/accounting/views/sale/CustomerDebt");
const Trackings = () => import('@/workspaces/warehouse/views/management/Tracking')
const AuctionLogs = () => import('../views/management/AuctionLogs')
export default [
  {
    path: 'dashboard',
    name: 'Orders',
    component: Orders,
  },
  {
    path: 'orders',
    meta: { label: 'Orders' },
    component:
    {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        meta: { label: 'Orders', title: 'Orders' },
        component: Orders
      },
      {
        path: 'retail',
        meta: { label: 'Retail' },
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            name: 'Retail',
            component: Retail
          },
          {
            path: 'new',
            meta: {
              label: 'New Retail'
            },
            name: 'New Retail',
            component: NewRetail
          },
          {
            path: ':id',
            meta: {
              label: 'Retail Detail'
            },
            name: 'Retail Detail',
            component: RetailDetail
          }
        ]
      },
      {
        path: 'wholesale',
        meta: { label: 'Wholesale' },
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            name: 'Wholesale',
            component: Wholesale
          },
          {
            path: 'new',
            meta: {
              label: 'New Wholesale'
            },
            name: 'New Wholesale',
            component: NewWholesale
          },
          {
            path: ':id',
            meta: {
              label: 'Wholesale Detail'
            },
            name: 'Wholesale Detail',
            component: WholesaleDetail
          }
        ]
      },
      {
        path: 'shipment',
        meta: { label: 'Shipment' },
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            name: 'Shipment',
            component: Shipment
          },
          {
            path: 'new',
            meta: {
              label: 'New Shipment'
            },
            name: 'New Shipment',
            component: NewShipment
          },
          {
            path: ':id',
            meta: {
              label: 'Shipment Detail'
            },
            name: 'Shipment Detail',
            component: ShipmentDetail
          }
        ]
      },
      {
        path: 'payment',
        meta: { label: 'Payment' },
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            name: 'Payment Order',
            component: Payment
          },
          {
            path: 'new',
            meta: {
              label: 'New Payment Order'
            },
            name: 'New Payment Order',
            component: NewPayment
          },
          {
            path: ':id',
            meta: {
              label: 'Payment Detail'
            },
            name: 'Payment Detail',
            component: PaymentDetail
          }
        ]
      },
      {
        path: 'auction',
        meta: { label: 'Auction' },
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            name: 'Auction Order',
            component: Auction
          },
          {
            path: 'new',
            meta: {
              label: 'New Auction'
            },
            name: 'New Auction Order',
            component: NewAuction
          },
          {
            path: ':id',
            meta: {
              label: 'Auction Detail'
            },
            name: 'Auction Detail',
            component: AuctionDetail
          }
        ]
      },
    ]
  },
  {
    path: 'statistics',
    meta: { label: 'Statistics' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'goods-inventory',
        meta: { label: 'Goods inventory', title: 'Goods inventory' },
        component: GoodsInventory,
      },
      {
        path: 'customer-debt',
        meta: { label: 'Customer debt', title: 'Customer debt' },
        component: CustomerDebt,
      },
    ]
  },
  {
    path: 'contracts',
    meta: { label: 'Contracts' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'contracts',
        meta: { label: 'Contracts' },
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            meta: {
              label: 'Contracts',
              title: 'Contracts'
            },
            component: Contracts
          },
          {
            path: 'new',
            meta: {
              label: 'New Contract',
              title: 'New contract'
            },
            component: NewContract
          },
          {
            path: ':id',
            meta: {
              label: 'Detail',
              title: 'Detail'
            },
            component: ContractDetail
          }
        ]
      },
    ]
  },
  {
    path: 'goods',
    meta: { label: 'Goods' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'products',
        meta: { label: 'Product' },
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            meta: {
              label: 'Product',
              title: 'Product'
            },
            component: Products
          },
          {
            path: 'new',
            meta: {
              label: 'New Product',
              title: 'New product'
            },
            component: NewProduct
          },
          {
            path: ':id',
            meta: {
              label: 'Detail',
              title: 'Detail'
            },
            component: Product
          }
        ]
      },
      {
        path: 'categories',
        meta: {
          label: 'Category',
          title: 'Category'
        },
        component: Category
      },
      {
        path: 'origins',
        meta: {
          label: 'Origin',
          title: 'Origin'
        },
        component: Origins
      },
    ]
  },
  {
    path: "supports",
    meta: { label: "Support" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        meta: {
          label: "Support",
          title: "Support"
        },
        component: Supports
      },
      {
        path: 'new',
        meta: {
          label: 'New support',
          title: 'New support'
        },
        component: NewSupport
      },
      {
        path: ":id",
        meta: {
          label: "Support",
          title: 'Support'
        },
        component: SupportDetail
      }
    ]
  },
  {
    path: "tracking",
    meta: { label: "Tracking", title: 'Tracking' },
    component: Trackings
  },
  {
    path: "auction-logs",
    meta: { label: "Auction logs", title: 'Auction logs' },
    component: AuctionLogs
  },
  {
    path: "/sale/*",
    meta: {
      title: "Not found",
      label: "Not found"
    },
    component: () => import("@/workspaces/sale/views/Page404"),
  }
]
