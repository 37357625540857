<template>
  <div class="w-100">
    <TMessage v-if="label" :content="label" bold :class="labelClasses" />
    <div :class="inputWrapClasses">
      <MaskedInput
        :class="inputClasses"
        :value="input"
        :mask="lodash.numberMask(maskOptions)"
        :guide="false"
        @input="setValue"
        :placeholder="placeholder"
        ref="maskedInput"
        @blur="$emit('blur', $event)"
        v-bind="inputOptions"
      />
      <TMessage
        v-if="append"
        class="input-group-text rounded-0 border-0"
        bold
        noTranslate
        :content="append"
      />
    </div>
  </div>
</template>

<script>
import MaskedInput from "vue-text-mask";
import mixin from "./mixin";
export default {
  mixins: [mixin],
  expose: ["focus"],
  components: {
    MaskedInput,
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    maskOptions: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
    append: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "0",
    },
  },
  data() {
    return {
      input: "",
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        return (this.input = value ? value.toString() : "");
      },
    },
  },
  computed: {
    inputWrapClasses() {
      return this.append
        ? "d-flex input-append-wrap rounded overflow-hidden"
        : "";
    },
    inputClasses() {
      const properties = [
        "form-control w-100",
        this.append ? "input-append" : null,
        this.size == "lg" ? "form-control-lg" : null,
      ];
      return this.lodash.arrayToString(properties);
    },
    labelClasses() {
      const properties = [
        "mb-2",
        this.size == "lg" ? "mb-0 col-form-label-lg" : null,
      ];
      return this.lodash.arrayToString(properties);
    },
  },
  methods: {
    setValue(value) {
      this.$emit(
        "update:value",
        Number.parseFloat(this.lodash.replace(value, /[^0-9.]/g, "")) || 0
      );
      this.input = value;
    },
    focus() {
      this.$refs.maskedInput.$el.focus();
    },
  },
};
</script>

<style scoped>
.input-append {
  border-top: 0px;
  border-bottom: 0px;
  border-left: 0px;
  border-radius: 0px;
}
.input-append-wrap {
  border: 1px solid #e4e7ea;
}
</style>
