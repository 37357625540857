<template>
  <div>
    <div class="d-flex align-items-center">
      <TMessage :content="owning_box.box_id" bold noTranslate class="h4 mb-0" />
      <CBadge
        v-if="owning_box.is_cancel_distributed"
        color="danger"
        class="ml-1"
      >
        <TMessage content="Distributed canceled" bold />
      </CBadge>
    </div>
    <TMessage :content="owning_box.order_id" bold noTranslate />
    <TMessage content="Customer" v-if="owning_box.user">
      <template #suffix
        >:
        {{ owning_box.user.name }}
      </template>
    </TMessage>
    <TMessage content="Quantity">
      <template #suffix
        >:
        <CBadge color="info"> {{ owning_box.quantity }} </CBadge>
      </template>
    </TMessage>
  </div>
</template>

<script>
export default {
  props: {
    owning_box: {
      type: Object,
      required: true,
    },
  },
};
</script>
