<template>
  <CBadge v-if="getContentTypeBox" :color="getColorTypeBox">
    <TMessage :content="getContentTypeBox" />
  </CBadge>
</template>

<script>
export default {
  props: {
    box: {
      type: Object,
      required: true,
    },
  },
  computed: {
    getColorTypeBox() {
      if (this.box.is_child_box) return "primary";
      if (this.box.is_parent_box) return "info";
      return "";
    },
    getContentTypeBox() {
      if (this.box.is_child_box) return "Child box";
      if (this.box.is_parent_box) return "Combine box";
      return "";
    },
  },
};
</script>
