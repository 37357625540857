import { ProductService } from "../services";

export const SERVICE = ProductService;

export default {
  attachImage(id, attributes) {
    return SERVICE.postFormData(`api/products/${id}/product-images`, attributes);
  },
  detachImage(id, attributes) {
    return SERVICE.postFormData(`api/products/${id}/product-images`, {
      ...attributes,
      _method: "DELETE"
    });
  },
  updateSupplier(id, attributes) {
    return SERVICE.put(`api/products/${id}/supplier`, attributes);
  },
  addSupplier(id, attributes) {
    return SERVICE.post(`api/products/${id}/supplier`, attributes);
  },
  deleteSupplier(id, supplierId) {
    return SERVICE.delete(
      `api/products/${id}`, `supplier?supplier_id=${supplierId}`
    );
  },
  attachCategory(id, attributes) {
    return SERVICE.postFormData(`api/products/${id}/category`, attributes);
  },
  detachCategory(id, attributes) {
    return SERVICE.postFormData(`api/products/${id}/category`, {
      ...attributes,
      _method: "DELETE"
    });
  },
  attachTag(id, attributes) {
    return SERVICE.postFormData(`api/products/${id}/product-tags`, attributes);
  },
  detachTag(id, attributes) {
    return SERVICE.postFormData(`api/products/${id}/product-tags`, {
      ...attributes,
      _method: "DELETE"
    });
  },
};
