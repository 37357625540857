import { AccountingService } from '../services'

export const SERVICE = AccountingService
export const RESOURCE = 'transactions/transfer'

export default {
  sameCurrencyTransfer(attributes) {
    return SERVICE.postFormData(`api/transactions/same-currency-transfer`, attributes);
  },
  otherCurrencyTransfer(attributes) {
    return SERVICE.postFormData('api/transactions/other-currency-transfer', attributes);
  },
};
