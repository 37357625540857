<template>
  <div class="form-group">
    <TMessage v-if="label" :content="label" bold class="mb-2" />
    <div :class="customClasses">
      <div class="prepend" v-if="checkPrepend">
        <CIcon v-if="icon" :name="icon" />
        <slot name="prepend-content"></slot>
      </div>
      <div v-if="$slots['prepend']">
        <slot name="prepend"></slot>
      </div>
      <Multiselect
        :value="value"
        :options="options"
        :label="normalizer.label"
        :trackBy="normalizer.value"
        :class="['tselect rounded']"
        :selectLabel="null"
        :deselectLabel="null"
        :selectedLabel="null"
        :placeholder="$t(placeholder)"
        @select="$emit('select', $event)"
        @search-change="$emit('search', $event)"
        :loading="loading"
        v-bind="{
          'block-keys': ['Delete'],
          clearOnSelect: false,
          ...selectOptions,
        }"
      >
        <template #singleLabel="{ option }">
          <slot name="singleLabel" :option="option">
            <TMessage
              :content="option[normalizer.label]"
              noTranslate
              :truncate="1"
              size="small"
              class="text-break"
            />
          </slot>
        </template>
        <template #option="{ option }">
          <slot name="option" :option="option"> </slot>
        </template>
        <template #placeholder>
          <TMessage :content="placeholder" class="pl-1 font-weight-normal" />
        </template>
        <template #noResult>
          <TMessage content="No data" />
        </template>
        <template #noOptions>
          <TMessage content="No data" />
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: {
    Multiselect,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    value: {
      type: Object,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    normalizer: {
      type: Object,
      default() {
        return {
          value: "id",
          label: "name",
        };
      },
    },
    options: {
      type: Array,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Object,
      required: false,
    },
    addLabelClasses: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
  },
  computed: {
    checkPrepend() {
      return this.$slots["prepend-content"] || this.icon;
    },
    customClasses() {
      if (this.$slots["prepend-content"] || this.icon) {
        return "d-flex border rounded";
      }
      if (this.$slots["prepend"]) {
        return "d-flex border rounded p-0";
      }
      return "border rounded";
    },
  },
};
</script>

<style scoped>
.tselect {
  min-height: auto;
}
.tselect >>> .multiselect__select,
.tselect >>> .multiselect__tags {
  min-height: auto;
  height: calc(1.5em + 0.75rem);
  border: 0;
}
.tselect >>> .multiselect__tags .multiselect__spinner {
  height: 30px;
}
.tselect >>> .multiselect__tags .multiselect__placeholder {
  transform: translate(1px, -3px);
}
.tselect >>> input.multiselect__input,
.tselect >>> .multiselect__option {
  font-size: 0.875rem;
}
.tselect.multiselect--disabled >>> .multiselect__select {
  background: transparent;
}
.prepend {
  padding: 0.345rem 0.75rem;
  background: #f0f3f5;
  border-right: 1px solid #e4e7ea;
}
.tselect.multiselect >>> .multiselect__option--highlight {
  background: var(--primary);
}
.c-dark-theme .multiselect >>> .multiselect__content-wrapper {
  background-color: var(--light);
}
.c-dark-theme .prepend {
  background-color: var(--secondary);
  border-color: var(--light);
}
.c-dark-theme .tselect >>> .multiselect__placeholder {
  color: #fff;
}
.c-dark-theme >>> .multiselect--disabled {
  background: var(--secondary);
}
</style>
