<template>
  <div class="w-100">
    <div class="d-flex flex-wrap mb-1">
      <CBadge :color="getTypeColor" class="d-flex my-auto p-1">
        <TMessage
          :content="log.creator ? log.creator.name : log.creator_id"
          noTranslate
          bold
          class="mr-1"
        >
          <template #suffix>:</template>
        </TMessage>
        <TMessage
          :content="log.type ? log.type.name : log.type_id"
          bold
          noTranslate
        />
      </CBadge>
      <TMessage
        v-if="showLogable"
        :content="log.logable_id"
        bold
        class="mx-1"
        color="primary"
        noTranslate
      />
      <span v-if="showLogable">-</span>
      <TMessage :content="getMessage.type" bold class="mx-1" />
      <TMessage
        v-if="getProductKey"
        :content="getProductKey"
        bold
        noTranslate
      />
      <SMessageOrderStatus
        v-if="getOrderStatus"
        :store="
          log.logable_type === 'purchase'
            ? 'order.purchase_statuses'
            : 'order.statuses'
        "
        :id="getOrderStatus"
        class="my-auto"
      />
      <SMessageContractStatus
        v-if="log.logable_type == 'contract' && getContractStatus"
        :status="getContractStatus"
        class="my-auto ml-1"
      />
      <SMessageTransactionType
        v-if="getTransactionType"
        :value="getTransactionType"
      />
      <TMessageDateTime :content="log.created_at" small class="ml-auto" />
    </div>

    <!-- Content -->
    <div v-for="(item, index) in getMessage.content" :key="index">
      <div class="d-flex flex-wrap mr-3" v-if="item.field">
        <CIcon name="cilNote" class="mr-2 my-auto" />
        <TMessage :content="item.field" class="mr-1" bold>
          <template #suffix>:</template>
        </TMessage>
        <!-- Value -->
        <SMessageShipmentMethod v-if="item.shipment" :id="item.shipment" />
        <TMessage
          v-if="item.value"
          :content="item.value"
          :noTranslate="item.translate ? false : true"
        />
        <TMessageNumber
          v-if="lodash.has(item, 'number')"
          :value="lodash.toNumber(item.number)"
        />
        <TMessagePercent
          v-if="lodash.has(item, 'percent')"
          :value="lodash.toNumber(item.percent)"
        />
        <TMessageDateTime
          v-if="item.date"
          :content="lodash.toNumber(item.date)"
          dateOnly
        />
        <CIcon
          v-if="item.icon"
          :name="item.icon.name"
          :class="['my-auto', `text-${item.icon.color}`]"
        />
        <TLink v-if="item.link" :content="item.link" :to="item.to" />
        <TMessageMoney
          v-if="
            lodash.isNumber(lodash.toNumber(item.amount)) &&
            lodash.has(item, 'amount')
          "
          :amount="lodash.toNumber(item.amount)"
          :currency="item.currency"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    log: {
      type: Object,
      required: true,
    },
    showLogable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      address: undefined,
    };
  },
  watch: {
    log: {
      immediate: true,
      handler(log) {
        if (log?.content?.shipment_info?.ward_id) {
          this.$store
            .dispatch(
              "helper.wards.detail.fetch",
              log.content.shipment_info.ward_id
            )
            .then((data) => (this.address = data.address));
        }
        if (log?.content?.shipment_info?.ward_id === null) {
          this.address = this.$t("No data");
        }
      },
    },
  },
  computed: {
    getShipmentInfo() {
      const shipmentInfo = [
        {
          field: "Consignee",
          value: this.log.content.shipment_info?.consignee,
        },
        {
          field: "Tel",
          value: this.log.content.shipment_info?.tel,
        },
        {
          field: "Sender name",
          value: this.log.content.shipment_info?.sender_name,
        },
        {
          field: "Sender tel",
          value: this.log.content.shipment_info?.sender_tel,
        },
        {
          field: "Address",
          value: this.log.content.shipment_info?.address,
        },
        {
          field: "Ward",
          value: this.address,
        },
        {
          field: "Note",
          value: this.log.content.shipment_info?.note,
        },
      ];
      return shipmentInfo.filter((x) => x.value !== undefined);
    },
    getTransaction() {
      const transacion = [
        {
          field: "Card Id",
          value: this.log.content.transaction?.card_id,
        },
        {
          field: "Ref code",
          value: this.log.content.transaction?.code_ref,
        },
        {
          field: "Account",
          link: this.log.content.transaction?.transactionable_id,
          to:
            this.log.content.transacion?.transactionable_type == "supplier"
              ? `/purchase/suppliers/${this.log?.content?.transaction?.transactionable_id}`
              : `/admin/users/${this.log?.content?.transaction?.transactionable_id}`,
        },
        {
          field: "Executor",
          link: this.log.content.transaction?.prepared_by_id,
          to: `/admin/users/${this.log?.content?.transaction?.prepared_by_id}`,
        },
        {
          field: "Amount",
          amount: this.log.content.transaction?.amount,
          currency: this.log.content.transaction?.currency_id,
        },
        {
          field: "Voucher date",
          date: this.log.content.transaction?.voucher_date,
        },
        {
          field: "Description",
          value: this.log.content.transaction?.description,
        },
      ];
      return transacion.filter((x) =>
        Object.values(x).every((y) => y !== undefined && y !== null)
      );
    },
    getOrderInfo() {
      const orderInfo = [
        {
          field: "Supplier",
          link: this.log.content?.supplier_id,
          to: `/purchase/suppliers/${this.log?.content?.supplier_id}`,
        },
        {
          field: "Buyer",
          link: this.log.content?.buyer_id,
          to: `/admin/users/${this.log?.content?.buyer_id}`,
        },
        {
          field: "Note",
          value: this.log.content?.note || undefined,
        },
        {
          field: "Purchase order",
          icon:
            this.log.content?.purchased == true
              ? { name: "cil-check-circle", color: "success" }
              : this.log.content?.purchased == false
                ? { name: "cil-x-circle", color: "danger" }
                : undefined,
        },
        {
          field: "Merge package",
          icon:
            this.log.content?.merge_package == true
              ? { name: "cil-check-circle", color: "success" }
              : this.log.content?.merge_package == false
                ? { name: "cil-x-circle", color: "danger" }
                : undefined,
        },
        {
          field: "Repackage",
          icon:
            this.log.content?.repackage == true
              ? { name: "cil-check-circle", color: "success" }
              : this.log.content?.repackage == false
                ? { name: "cil-x-circle", color: "danger" }
                : undefined,
        },
        {
          field: "Shipment method",
          shipment: this.log.content.shipment_method_id,
        },
        {
          field: "Special declaration",
          amount: this.log.content?.special_declaration,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Insurance declaration",
          amount: this.log.content?.insurance_declaration,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Insurance fee",
          amount: this.log.content?.insurance_fee,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Service fee",
          amount: this.log.content?.service_fee,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Service fee boxes",
          amount: this.log.content?.service_fee_boxes,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Service fee paid",
          amount: this.log.content?.service_fee_paid,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Undebited service fee",
          amount: this.log.content?.service_fee_unsolved,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Service fee debited",
          amount: this.log.content?.service_fee_debited,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Service fee outstanding",
          amount: this.log.content?.service_fee_outstanding,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Special goods fee",
          amount: this.log.content?.special_goods_fee,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Additional cost",
          amount: this.log.content?.additional_cost,
          currency: this.log.content?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Goods money",
          amount: this.log.content?.cost_of_goods,
          currency: this.log.content?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Purchase cost",
          amount: this.log.content?.purchase_cost,
          currency: this.log.content?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Paid goods",
          amount: this.log.content?.purchase_cost_paid,
          currency: this.log.content?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Purchase cost outstanding",
          amount: this.log.content?.purchase_cost_outstanding,
          currency: this.log.content?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Purchase cost unsolved",
          amount: this.log.content?.purchase_cost_unsolved,
          currency: this.log.content?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Goods debited",
          amount: this.log.content?.purchase_cost_debited,
          currency: this.log.content?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Compensation",
          amount: this.log.content?.compensation,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Compensation to be debited",
          amount: this.log.content?.compensation_unsolved,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Compensation debited",
          amount: this.log.content?.compensation_debited,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Shipping fee",
          amount: this.log.content?.shipping_fee,
        },
        {
          field: "Flight shipping fee",
          amount: this.log.content?.shipping_fee_air,
        },
        {
          field: "Sea shipping fee",
          amount: this.log.content?.shipping_fee_sea,
        },
        {
          field: "Shipping fee debt",
          amount: this.log.content?.shipping_fee_outstanding,
        },
        {
          field: "Shipping fee to be debited",
          amount: this.log.content?.shipping_fee_unsolved,
        },
        {
          field: "Payment due date",
          date: this.log.content?.payment_due_date,
        },
      ];
      return orderInfo.filter((x) =>
        Object.values(x).every(
          (y) => y !== undefined && !Number.isNaN(y) && y !== "0"
        )
      );
    },
    getPurchaseOrderInfo() {
      const purchaseOrderInfo = [
        {
          field: "Total amount",
          amount: this.log.content?.balance,
          currency: this.log.content?.currency_id || "JPY",
        },
        {
          field: "Goods money",
          amount: this.log.content?.sub_total,
          currency: this.log.content?.currency_id || "JPY",
        },
        {
          field: "Additional cost",
          amount: this.log.content?.additional_cost,
          currency: this.log.content?.currency_id || "JPY",
        },
        {
          field: "Paid",
          amount: this.log.content?.paid,
          currency: this.log.content?.currency_id || "VND",
        },
        {
          field: "Outstanding",
          amount: this.log.content?.outstanding,
          currency: this.log.content?.currency_id || "VND",
        },
        {
          field: "Debited",
          amount: this.log.content?.debited,
          currency: this.log.content?.currency_id || "JPY",
        },
        {
          field: "Unsolved",
          amount: this.log.content?.unsolved,
          currency: this.log.content?.currency_id || "VND",
        },
        {
          field: "Compensation",
          amount: this.log.content?.compensation,
          currency: this.log.content?.currency_id || "JPY",
        },
      ];
      return purchaseOrderInfo.filter((x) =>
        Object.values(x).every(
          (y) => y !== undefined && !Number.isNaN(y) && y !== "0"
        )
      );
    },
    getContractInfo() {
      const contactInfo = [
        {
          field: "Customer",
          value: this.log.content?.customer_id,
        },
        {
          field: "Contract Id",
          value:
            this.log.logable_type === "contract"
              ? this.log.content?.id
              : undefined,
        },
        {
          field: "Start date",
          date: this.log.content?.start_date,
        },
        {
          field: "End date",
          date: this.log.content?.end_date,
        },
        {
          field: "Purchase cost",
          amount: this.log.content?.contract?.purchase_cost,
          currency:
            this.log.content?.contract?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Paid goods",
          amount: this.log.content?.contract?.purchase_cost_paid,
          currency:
            this.log.content?.contract?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Purchase cost outstanding",
          amount: this.log.content?.purchase_cost_outstanding,
          currency: this.log.content?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Purchase cost unsolved",
          amount: this.log.content?.purchase_cost_unsolved,
          currency: this.log.content?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Goods debited",
          amount: this.log.content?.purchase_cost_debited,
          currency: this.log.content?.purchase_cost_currency_id || "JPY",
        },
        {
          field: "Service fee paid",
          amount: this.log.content?.service_fee_paid,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Service fee outstanding",
          amount: this.log.content?.service_fee_outstanding,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Undebited service fee",
          amount: this.log.content?.service_fee_unsolved,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Service fee debited",
          amount: this.log.content?.service_fee_debited,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Compensation to be debited",
          amount: this.log.content?.compensation_unsolved,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Compensation debited",
          amount: this.log.content?.compensation_debited,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
        {
          field: "Additional cost",
          amount: this.log.content?.additional_cost,
          currency: this.log.content?.service_fee_currency_id || "VND",
        },
      ];
      return contactInfo.filter((x) =>
        Object.values(x).every((y) => y !== undefined && !Number.isNaN(y))
      );
    },
    getProductInfo() {
      const info = [
        {
          field: "Quantity",
          number: this.log.content?.items?.quantity,
        },
        {
          field: "Received at the warehouse",
          number: this.log.content?.items?.received_quantity,
        },
        {
          field: "Distributed quantity",
          number: this.log.content?.items?.distributed_quantity,
        },
        {
          field: "Remaining quantity",
          number: this.log.content?.items?.remaining_distributed_quantity,
        },
        {
          field: "Price",
          amount: this.log.content?.items?.price,
          currency: this.log.content?.items?.currency_id || "JPY",
        },
        {
          field: "Amount",
          amount: this.log.content?.items?.amount,
          currency: this.log.content?.items?.currency_id || "JPY",
        },
        {
          field: "Total amount",
          amount: this.log.content?.items?.balance,
          currency: this.log.content?.items?.currency_id || "JPY",
        },
        {
          field: "Tax",
          amount: this.log.content?.items?.tax,
          currency: this.log.content?.items?.currency_id || "JPY",
        },
        {
          field: "Tax percent",
          percent: this.log.content?.items?.tax_percent,
        },
        {
          field: "Payment due date",
          date: this.log.content?.items?.payment_due_date,
        },
        {
          field: "Expected delivery",
          date: this.log.content?.items?.expected_delivery,
        },
        {
          field: "Note",
          value: this.log.content?.items?.note || undefined,
        },
      ];
      return info.filter((x) =>
        Object.values(x).every((y) => y !== undefined && !Number.isNaN(y))
      );
    },
    getBox() {
      const info = [
        {
          field: "Goods delivery",
          link: this.log.content?.goods_delivery_box?.goods_delivery_id?.toString(),
          to: `/warehouse/internal/goods-deliveries?goods_delivery_id=${this.log.content?.goods_delivery_box?.goods_delivery_id}`,
        },
        {
          field: "Goods delivery",
          link: this.log.content?.goods_delivery_box?.original_goods_delivery_id?.toString(),
          to: `/warehouse/internal/goods-deliveries?goods_delivery_id=${this.log.content?.goods_delivery_box?.original_goods_delivery_id}`,
        },
        {
          field: "Quantity",
          value: this.log.content?.goods_delivery_box?.quantity,
        },
        {
          field: "Shipping code",
          value: this.log.content?.goods_delivery_box?.shipping_code,
        },
        {
          field: "Pallet Id",
          link: this.log.content?.pallet?.original_pallet_id?.toString(),
          to: `/warehouse/internal/lading-bills?lading_bill_id=${this.log.content?.pallet?.original_pallet_id}`,
        },
        {
          field: "Pallet Id",
          link: this.log.content?.pallet?.pallet_id?.toString(),
          to: `/warehouse/internal/lading-bills?lading_bill_id=${this.log.content?.pallet?.pallet_id}`,
        },
        {
          field: "Quantity",
          value: this.log.content?.pallet?.quantity,
        },
        {
          field: "Lading bill",
          link: this.log.content?.box_lading_bill?.lading_bill_id?.toString(),
          to: `/warehouse/internal/lading-bills?lading_bill_id=${this.log.content?.box_lading_bill?.lading_bill_id}`,
        },
        {
          field: "Lading bill",
          link: this.log.content?.box_lading_bill?.original_lading_bill_id?.toString(),
          to: `/warehouse/internal/lading-bills?lading_bill_id=${this.log.content?.box_lading_bill?.original_lading_bill_id}`,
        },
        {
          field: "Quantity",
          value: this.log.content?.box_lading_bill?.quantity,
        },
        {
          field: "Sale order",
          value: this.log.content?.owner?.order_id,
        },
        {
          field: "Sale order",
          value: this.log.content?.owner?.original_order_id,
        },
        {
          field: "Quantity",
          value: this.log.content?.owner?.quantity,
        },
        {
          field: "Quantity",
          value: this.log.content?.box?.duplicate,
        },
        {
          field: "Height",
          value: this.log.content?.box?.height,
        },
        {
          field: "Length",
          value: this.log.content?.box?.length,
        },
        {
          field: "Weight",
          value: this.log.content?.box?.weight,
        },
        {
          field: "Width",
          value: this.log.content?.box?.width,
        },
        {
          field: "SFA Id",
          value: this.log.content?.box?.sfa_id,
        },
      ];
      return info.filter((x) =>
        Object.values(x).every((y) => y !== undefined && y !== null)
      );
    },
    getMessage() {
      const key = !this.lodash.isArray(this.log?.content?.items)
        ? Object.keys(this.log.content)
        : Object.keys(this.lodash.omit(this.log?.content, "items"));
      let data = {
        type: "Order",
        content: [],
      };
      key.forEach((x) => {
        switch (x) {
          case "product":
          case "items":
            // data[x].length > 0
            return (data = {
              type: "Product",
              content: [...this.getProductInfo],
            });
          case "shipment_info":
            return (data = {
              type: "Shipment info",
              content: [...this.getShipmentInfo],
            });
          case "status_id":
            return (data.type =
              this.log.logable_type == "contract"
                ? "Contract"
                : "Order status");
          case "write":
            return (data = {
              type: "Note",
              content: [
                {
                  field: "Content",
                  value: this.log.content[x],
                },
              ],
            });
          case "contract_id":
            return (data = {
              type: "Contract",
              content: [
                {
                  field: "Contract Id",
                  value:
                    this.log.content[x] !== null
                      ? this.log.content[x]
                      : this.$t("No"),
                },
              ],
            });
          case "updated_at":
            return null;
          // Box log
          case "pallet":
            return (data = {
              type: "Pallet",
              content: [...this.getBox],
            });
          case "owner":
            return (data = {
              type: "Distribution",
              content: [...this.getBox],
            });
          case "goods_delivery_box":
            return (data = {
              type: "Goods delivery",
              content: [...this.getBox],
            });
          case "box_lading_bill":
            return (data = {
              type: "Lading bill",
              content: [...this.getBox],
            });
          default:
            return (data = {
              ...data,
              type: this.getTypeLog,
              content: this.lodash.uniqBy(
                [
                  ...this.getBox,
                  ...this.getContractInfo,
                  ...this.getOrderInfo,
                  ...this.getPurchaseOrderInfo,
                  ...this.getTransaction,
                ],
                "field"
              ),
            });
        }
      });
      return data;
    },
    getTypeLog() {
      if (this.lodash.has(this.log?.content, "transaction"))
        return "Transaction";
      switch (this.log.logable_type) {
        case "contract":
          return "Contract";
        case "purchase":
          return "Purchase order";
        case "box":
          return "Box";
        default:
          return "Order";
      }
    },
    getTypeColor() {
      if (this.log.type_id === "deleted") return "danger";
      if (this.log.type_id === "created") return "info";
      if (this.log.type_id === "write") return "warning";
      return "success";
    },
    getProductKey() {
      return this.log?.content?.items?.product_id
        ? this.log?.content?.items?.product_id
        : this.log?.content?.items?.original_product_id;
    },
    getOrderStatus() {
      if (this.log.logable_type == "contract") return null;
      if (this.log.logable_type == "purchase")
        return this.log.content?.status_id;
      return this.log.content?.status_id;
    },
    getContractStatus() {
      if (this.log.logable_type == "contract")
        return this.log.content?.status_id;
      return null;
    },
    getTransactionType() {
      return this.log.content?.transaction?.type_id;
    },
  },
};
</script>
