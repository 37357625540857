<template>
  <CCard class="h-100 m-0">
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="Order credit" bold />
      <TSpinner :loading="creditLoading" />
      <div class="ml-auto">
        <TButton
          v-if="lodash.isEmpty(creditOrder)"
          :disabled="creditLoading || walletLoading"
          content="Create order credit"
          icon="cil-plus"
          variant="outline"
          @click="showCreateCredit = true"
        />
        <TButtonRefresh class="ml-1" @click="getCreditOrder" />
      </div>
    </CCardHeader>
    <CCardBody class="w-100 mx-auto" style="max-width: 800px">
      <TTableAsForm
        v-if="!lodash.isEmpty(creditOrder)"
        title=""
        :data="creditOrder"
        :fields="creditFields"
        :splitLeft="7"
        class="mb-3"
      >
        <TMessage
          v-if="!walletList.length"
          content="Add wallets"
          class="text-muted"
          italic
        />
        <template #amount="{ value }">
          <TMessageMoney
            :amount="value"
            :currency="creditOrder.wallet && creditOrder.wallet.currency_id"
            @change="updateCredit('amount', $event)"
          />
        </template>
        <template #outstanding_amount="{ value }">
          <TMessageMoney
            :amount="value"
            :currency="creditOrder.wallet && creditOrder.wallet.currency_id"
          />
        </template>
        <template #paid_amount="{ value }">
          <TMessageMoney
            :amount="value"
            :currency="creditOrder.wallet && creditOrder.wallet.currency_id"
          />
        </template>
        <template #used_amount="{ value }">
          <TMessageMoney
            :amount="value"
            :currency="creditOrder.wallet && creditOrder.wallet.currency_id"
          />
        </template>
        <template #payment_due_date="{ value }">
          <TMessageDateTime
            :content="value"
            dateOnly
            editable
            @change="updateCredit('payment_due_date', $event)"
          />
        </template>
      </TTableAsForm>
      <TMessageNoData v-else />
    </CCardBody>
    <SModalCreditOrder
      v-if="!lodash.isEmpty(order)"
      :walletList="walletList"
      :order="order"
      :show.sync="showCreateCredit"
      @change="fetchCreditDetail"
    />
  </CCard>
</template>
<script>
export default {
  props: {
    order: {
      type: Object,
      required: false,
    },
    orderStore: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      walletLoading: false,
      creditLoading: false,
      showCreateCredit: false,
      walletList: [],
      creditOrder: {},
    };
  },
  watch: {
    "order.customer_id": {
      immediate: true,
      handler(value) {
        if (value) {
          this.walletLoading = true;
          this.creditLoading = true;
          this.$tomoni.accounting.wallets_users
            .all({
              "filter[walletable_id]": value,
            })
            .then(({ data }) => {
              if (data.data.length) {
                this.walletList = data.data;
              }
            })
            .finally(() => {
              this.walletLoading = false;
            });
          this.getCreditOrder();
        }
      },
    },
  },
  computed: {
    creditFields() {
      if (!this.lodash.isEmpty(this.creditOrder))
        return [
          { key: "amount", label: "Amount" },
          // { key: "used_amount", label: "Used" },
          { key: "outstanding_amount", label: "Outstanding" },
          { key: "paid_amount", label: "Paid" },
          { key: "payment_due_date", label: "Payment due date" },
        ];
      else return [];
    },
  },
  methods: {
    getCreditOrder() {
      this.creditLoading = true;
      this.$tomoni.accounting.credit_orders
        .all({
          "filter[by_order]": this.order.id,
          ...this.$store.state.credit_orders[
            "accounting_credit_orders_default_query"
          ],
        })
        .then(({ data }) => {
          this.creditOrder = data.data?.[0];
        })
        .finally(() => {
          this.creditLoading = false;
        });
    },
    fetchCreditDetail(data) {
      this.creditLoading = true;
      this.$tomoni.accounting.credit_orders
        .get(
          data.id,
          this.$store.state.credit_orders[
            "accounting_credit_orders_detail_query"
          ]
        )
        .then(({ data }) => {
          this.creditOrder = data;
          setTimeout(() => {
            this.$store.dispatch(
              "order." + this.orderStore + ".detail.refresh"
            );
          }, 1500);
        })
        .finally(() => {
          this.creditLoading = false;
        });
    },
    updateCredit(field, value) {
      this.$tomoni.accounting.credit_orders
        .update(this.creditOrder.id, {
          [field]: value,
        })
        .then(({ data }) => {
          this.creditOrder = { ...this.creditOrder, ...data };
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        });
    },
  },
};
</script>
