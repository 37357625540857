<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <SSelectFreightRangeType
        v-if="editing"
        :value="id"
        @close="setEditing(false)"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        :editable="editable"
      />
    </slot>
    <TMessage
      :content="type"
      color="danger"
      bold
      :italic="editable"
      uppercase
      v-show="!editing || dontHideWhenEditing"
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      @click-edit="showEdit()"
      :addClasses="['d-flex align-items-center']"
      ><template #prefix><CIcon name="cis-braille" class="mr-1" /></template
    ></TMessage>
  </div>
</template>

<script>
import mixin from "@/core/components/T/Button/actions";

export default {
  mixins: [mixin],
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    type() {
      return this.id == "lading_bill"
        ? "Lading bill"
        : "Box";
    },
  },
};
</script>
