<template>
  <TButton
    :variant="variant"
    :size="size"
    @click="$emit('click')"
    tooltip="Distribute"
    icon="cil-timeline"
  />
</template>

<script>
import mixin from "@/core/components/T/Button/mixin";

export default {
  mixins: [mixin],
};
</script>
