<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex align-items-center">
        <TMessage content="Compensation" bold> </TMessage>
        <TSpinner :loading="detail_loading" />
        <div class="ml-auto">
          <TButton
            v-if="ladingBill.boxes && ladingBill.boxes.length"
            content="Create compensation"
            variant="outline"
            size="sm"
            color="danger"
            class="mr-1"
            icon="cil-plus"
            @click="showModal = true"
          />
          <TButtonRefresh
            @click="refresh"
            :options="{ disabled: detail_loading }"
          />
        </div>
      </CCardHeader>
      <CCardBody v-if="compensations.length">
        <div v-for="compensation in compensations" :key="compensation.id">
          <CCard>
            <CCardHeader class="d-flex flex-wrap" color="light">
              #<TMessage
                :content="compensation.id"
                bold
                noTranslate
                class="mr-1"
              />
              <SMessageCurrency :id="compensation.currency_id" class="mr-1" />
              <CBadge color="primary" class="d-flex">
                <SMessageOrganization
                  :id="compensation.organization_id"
                  class="my-auto"
                />
              </CBadge>
              <TMessageDateTime
                :content="compensation.created_at"
                class="ml-auto"
                dateOnly
              />
            </CCardHeader>
            <CCardBody class="py-2">
              <TTableAsForm
                :fields="compensationFields"
                :data="compensation"
                :splitLeft="5"
                style="font-size: 1rem"
                :addRowClasses="['pb-1']"
              >
                <template #amount>
                  <TMessageMoney
                    :amount="compensation.amount"
                    :currency="compensation.currency_id"
                    editable
                    @change="
                      updateCompensation(compensation.id, 'amount', $event)
                    "
                  />
                </template>
                <template #paid>
                  <TMessageMoney
                    :amount="compensation.paid"
                    :currency="compensation.currency_id"
                  />
                </template>
                <template #unsolved>
                  <TMessageMoney
                    :amount="compensation.unsolved"
                    :currency="compensation.currency_id"
                  />
                </template>
                <template #note>
                  <TMessageTextarea
                    @change="
                      updateCompensation(compensation.id, 'note', $event)
                    "
                    editable
                    :value="compensation.note"
                  />
                </template>
              </TTableAsForm>
            </CCardBody>
          </CCard>
        </div>
      </CCardBody>
      <TMessageNoData v-else
    /></CCard>
    <SModalCompensation
      :show.sync="showModal"
      @updated="refresh"
      :lading_bill_id="ladingBill.id"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    compensations: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      showModal: false,
      compensationFields: [
        { key: "amount", label: "Compensation amount" },
        { key: "paid", label: "Compensated" },
        { key: "unsolved", label: "Uncompensated" },
        { key: "note", label: "Note" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      detail_loading: "warehouse.lading_bills.detail.loading",
      ladingBill: "warehouse.lading_bills.detail",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("warehouse.lading_bills.detail.refresh");
    },
    updateCompensation(id, field, value) {
      this.$store
        .dispatch("warehouse.lading_bill_compensations.update", {
          id: id,
          attributes: {
            [field]: value,
          },
        })
        .then(() => {
          this.refresh();
        });
    },
  },
};
</script>
