import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'product',
    resource: 'products',
    prefix: 'product.product_affiliates_list',
    prefix_state: 'product_affiliates_list',
  },
  {
    default_query: {
      include: "suppliers,origin,categories,tags",
    },
    detail_query: {
      include: "suppliers,categories,tags,reviews,images,ecommerceChannel",
    },
    default_detail: {
      origin_id: "",
      suppliers: [],
      banned_air: false,
      banned_export: false,
      banned_sea: false,
      private_license: false,
      special: false,
    },
  }
).store()

export default resource
