<template>
  <TSelectEditable
    v-if="editable"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: true,
      preserveSearch: true,
    }"
    :loading="loading"
    @select="select"
    @search="search"
    :normalizer="{ label: 'name', value: 'id' }"
    @close="$emit('close')"
    @change="$emit('change', $event)"
  />
  <TSelect
    v-else
    :label="label"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: true,
      preserveSearch: true,
    }"
    :normalizer="{ label: 'name', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
  />
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: "",
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    prepend: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      firstValue: null,
      valueSelected: null,
    };
  },
  created() {
    this.$store.dispatch("warehouse.container_types.fetch.if-first-time");
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value == null) return;
      },
    },
    valueSelected: {
      immediate: false,
      handler(value) {
        if (value) {
          this.$emit("update:value", value.id);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.container_types.list",
      loading: "warehouse.container_types.fetching",
    }),
    listC() {
      const firstValue = this.firstValue ? this.firstValue : null;
      const list = this.lodash.uniqBy(
        [...this.prepend, firstValue, ...this.list],
        "id"
      );
      return this.lodash.filter(list, (x) => {
        return x != null;
      });
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
    checkValue(value) {
      const firstValue = this.listC.find((x) => x.id == value);
      // Nếu value rỗng
      if (!value) {
        this.valueSelected = null;
        return;
      }
      // Value k có trong list
      if (!firstValue) {
        this.$tomoni.warehouse.container_types
          .all({ "filter[id]": value })
          .then(({ data }) => {
            this.firstValue = data.data[0];
            this.valueSelected = data.data[0];
          });
      }
      // Value có trong list
      if (firstValue) {
        this.valueSelected = firstValue;
      }
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("warehouse.container_types.push-query", {
        "filter[name]": param,
      });
    }, 300),
  },
};
</script>
