const Dashboard = () => import("../views/Dashboard");
const Purchases = () => import("../views/purchases/Index");
const Purchase = () => import("../views/purchases/Detail");
const Suppliers = () => import("../views/suppliers/Index");
const Supplier = () => import("../views/suppliers/Detail");
const NewPurchase = () => import("../views/purchases/New");
const Products = () => import('@/workspaces/admin/views/goods/Index')
const Product = () => import('@/workspaces/admin/views/goods/Detail')
const NewProduct = () => import('@/workspaces/admin/views/goods/New')
const Origins = () => import('@/workspaces/admin/views/goods/Origin')
const Category = () => import('@/workspaces/admin/views/goods/Category')
const NewSupport = () => import('@/workspaces/admin/views/support/NewSupport')
const Supports = () => import('@/workspaces/admin/views/support/Index')
const SupportDetail = () => import('@/workspaces/admin/views/support/SupportDetail')
const ListToBuy = () => import('../views/listToBuy/Index')
const Inventory = () => import('../views/inventory/Index')
const GoodsInventory = () => import('@/workspaces/sale/views/inventory/Index')
const SupplierDebt = () => import("@/workspaces/accounting/views/purchase/SupplierDebt");
const AvailableForDistribution = () => import("../views/available-for-distribution/Index")
const Tracking = () => import('@/workspaces/warehouse/views/management/Tracking')

export default [
  {
    path: "dashboard",
    name: "Purchase Dashboard",
    component: Dashboard
  },
  {
    path: "purchases",
    meta: { label: "Purchases" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        meta: {
          label: "Purchases",
          title: "Purchases"
        },
        component: Purchases
      },
      {
        path: 'new',
        meta: {
          label: 'New Purchase',
          title: 'New order'
        },
        component: NewPurchase
      },
      {
        path: ":id",
        meta: {
          label: "Purchase Detail",
        },
        name: "Purchases Detail",
        component: Purchase
      }
    ]
  },
  {
    path: "list-to-buy",
    name: "List to buy",
    component: ListToBuy
  },
  {
    path: "available-for-distribution",
    name: "Available for distribution",
    component: AvailableForDistribution
  },
  {
    path: "purchase-inventory",
    name: "Purchase inventory",
    component: Inventory
  },
  {
    path: "goods-inventory",
    name: "Goods inventory",
    component: GoodsInventory
  },
  {
    path: 'supplier-debt',
    meta: { label: 'Supplier debt', title: 'Supplier debt' },
    component: SupplierDebt,
  },
  {
    path: "suppliers",
    meta: { label: "Suppliers" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        meta: {
          title: "Supplier",
          label: "Suppliers"
        },
        component: Suppliers
      },
      {
        path: ":id",
        meta: {
          label: "Supplier Detail",
        },
        name: "Supplier",
        component: Supplier
      }
    ]
  },
  {
    path: 'goods',
    meta: { label: 'Goods' },
    component: {
      render(c) { return c('router-view') }
    },
    children: [
      {
        path: 'products',
        meta: { label: 'Product' },
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            meta: {
              label: 'Product',
              title: 'Product'
            },
            component: Products
          },
          {
            path: 'new',
            meta: {
              label: 'New Product',
              title: 'New product'
            },
            component: NewProduct
          },
          {
            path: ':id',
            meta: {
              label: 'Detail',
              title: 'Detail'
            },
            component: Product
          }
        ]
      },
      {
        path: 'categories',
        meta: {
          label: 'Category',
          title: 'Category'
        },
        component: Category
      },
      {
        path: 'origins',
        meta: {
          label: 'Origin',
          title: 'Origin'
        },
        component: Origins
      },
    ]
  },
  {
    path: "supports",
    meta: { label: "Support" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        meta: {
          label: "Support",
          title: "Support"
        },
        component: Supports
      },
      {
        path: 'new',
        meta: {
          label: 'New support',
          title: 'New support'
        },
        component: NewSupport
      },
      {
        path: ":id",
        meta: {
          label: "Support",
          title: 'Support'
        },
        component: SupportDetail
      }
    ]
  },
  {
    path: "tracking",
    meta: { label: "Tracking", title: 'Tracking' },
    component: Tracking
  },
  {
    path: "/purchase/*",
    meta: {
      title: "Not found",
      label: "Not found"
    },
    component: () => import("@/workspaces/purchase/views/Page404"),
  }
];
