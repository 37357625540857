<template>
  <TModal
    title="Add tracking"
    :show="show"
    @update:show="$emit('update:show', $event)"
    :key="modalKey"
  >
    <template #actions>
      <TButton
        content="Add"
        icon="cil-plus"
        @click="
          isSelectAvailableTracking ? addTracking() : addWithNewTracking()
        "
        :options="{ disabled: creating || adding }"
      />
    </template>
    <TInputCheckbox
      label="Add created tracking"
      :checked="isSelectAvailableTracking"
      @change="isSelectAvailableTracking = !isSelectAvailableTracking"
      class="mb-3"
    />
    <div v-if="!isSelectAvailableTracking">
      <TInputText
        label="Tracking code"
        placeholder="Input tracking"
        :value.sync="tracking_code"
      />
    </div>
    <div v-else>
      <SSelectTracking
        label="Select tracking"
        @change="tracking_id = $event.id"
      />
    </div>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    store: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tracking_id: "",
      tracking_code: "",
      modalKey: "",
      isSelectAvailableTracking: false,
      creating: false,
      adding: false,
    };
  },
  watch: {
    isSelectAvailableTracking() {
      this.tracking_id = "";
    },
  },
  methods: {
    addTracking() {
      this.adding = true;
      this.$store
        .dispatch(this.store + ".detail.attach-tracking", {
          tracking_ids: [this.tracking_id],
        })
        .then(() => {
          this.tracking_id = "";
          this.$store.dispatch(this.store + ".detail.refresh");
          this.$emit("update:show", false);
          this.modalKey = this.lodash.resetKey("modal-key");
        })
        .finally(() => {
          this.adding = false;
        });
    },
    addWithNewTracking() {
      this.creating = true;
      this.$store
        .dispatch("order.trackings.create", {
          code: this.tracking_code,
        })
        .then((data) => {
          this.tracking_code = "";
          this.tracking_id = data.id;
          this.addTracking();
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
