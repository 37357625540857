import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'product',
    resource: 'category_affiliates',
    prefix: 'product.category_affiliate_list',
    prefix_state: 'product_category_affiliate_list',
  },
).store()

export default resource
