import { HelperService } from '../services'

export const SERVICE = HelperService
export const RESOURCE = 'posts'

export default {
  update(id, attributes) {
    return SERVICE.postFormData(`api/${RESOURCE}/${id}`, {
      ...attributes,
      _method: "PUT"
    });
  },
};
