import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'helper',
    resource: 'support_types',
  },
  {
    default_detail: {
      role_support_types: []
    }
  }
).store()

export default resource;
