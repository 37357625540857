<template>
  <CBadge color="primary" style="font-size: 85%" class="px-2">
    <TMessage
      :content="category.name"
      noTranslate
      :removable="removable"
      @click-remove="showModalConfirm = true"
    >
    </TMessage
    ><TModalConfirm
      class="text-dark"
      v-if="removable"
      :show.sync="showModalConfirm"
      @click-confirm="detachCategory"
    />
  </CBadge>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      required: false,
    },
    store: {
      type: String,
      required: false,
      default: "product.products"
    }
  },
  data() {
    return {
      showModalConfirm: false,
    };
  },
  methods: {
    detachCategory() {
      this.$store.dispatch(`${this.store}.detail.detach-category`, {
        "category_ids[]": this.category.id,
      });
    },
  },
};
</script>
