<template>
  <CSidebarNavTitle><TMessage :content="content" :noTranslate="noTranslate"/></CSidebarNavTitle>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true
    },
    noTranslate: {
      type: Boolean,
      default: false
    }
  }
}
</script>
