<template>
  <div>
    <CSidebar
      aside
      :show="$store.state.sidebar.sidebar_aside_show"
      @update:show="
        (val) => $store.commit('sidebar.set', ['sidebar_aside_show', val])
      "
      colorScheme="light"
      overlaid
      size="lg"
    >
      <div v-if="!firstOpen" class="scroll scroll-y scroll-tiny mb-3 h-100">
        <slot> </slot>
      </div>
    </CSidebar>
    <div class="d-md-down-none">
      <slot> </slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstOpen: true,
    };
  },
  created() {
    this.$store.commit("sidebar.set", ["sidebar_aside_exist", true]);
  },
  beforeDestroy() {
    this.$store.commit("sidebar.set", ["sidebar_aside_exist", false]);
  },
  watch: {
    show(value) {
      if (value && this.firstOpen) this.firstOpen = false;
    },
  },
  computed: {
    show() {
      return this.$store.state.sidebar.sidebar_aside_show;
    },
  },
};
</script>
