<template>
  <TModal
    title="Select order"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <div />
      <!-- <TButtonAdd
        variant="outline"
        size=""
        :options="{ content: 'Add', disabled: !select_order_id }"
        @click="select"
        :key="key"
      /> -->
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      :store="this.store"
      @click-clear-filter="clearFilter"
      resource=""
      reloadable
      @click-reload="fetch"
    >
      <template #_="{ item }">
        <td>
          <CCol col="12">
            <TButtonEnter @click="select(item)" />
          </CCol>
        </td>
      </template>
      <template #related_trackings="{ item }">
        <td class="text-truncate">
          <SMessageTracking
            v-if="!lodash.isEmpty(item.related_trackings)"
            :value="item.related_trackings[0].code"
            :checked="item.related_trackings[0].received"
          />
          <TMessageNotFound v-else />
          <TMessage
            v-if="item.related_trackings.length > 1"
            content="Tracking"
            lowercase
            size="small"
            color="muted"
            :truncate="1"
          >
            <template #prefix>
              +{{ item.related_trackings.length - 1 }}
            </template>
          </TMessage>
        </td>
      </template>
      <template #status="{ item }">
        <td>
          <SMessageOrderStatus :id="item.status_id" />
        </td>
      </template>
      <template #customer_id="{ item }">
        <td>
          <TLink
            v-if="item.customer"
            :to="lodash.getReferenceLink('user', item.customer_id)"
            :content="item.customer.name"
          />
        </td>
      </template>
      <template #type="{ item }">
        <td>
          <SMessageOrderType :id="item.type_id" />
        </td>
      </template>

      <template #purchase_cost="{ item }">
        <td>
          <TMessageMoney
            :amount="item.purchase_cost"
            :currency="item.purchase_cost_currency_id"
          />
        </td>
      </template>

      <template #purchase_cost_debited="{ item }">
        <td>
          <TMessageMoney
            :amount="item.purchase_cost_debited"
            :currency="item.purchase_cost_currency_id"
          />
        </td>
      </template>

      <template #purchase_cost_outstanding="{ item }">
        <td>
          <TMessageMoney
            :amount="item.purchase_cost_outstanding"
            :currency="item.purchase_cost_currency_id"
          />
        </td>
      </template>

      <template #service_fee="{ item }">
        <td>
          <div class="d-flex align-items-center">
          <TMessageMoney
            :amount="item.service_fee_paid"
            :currency="item.service_fee_currency_id"
          />
          <span class="mx-1">/</span>
          <TMessageMoney
            :amount="item.service_fee"
            :currency="item.service_fee_currency_id"
          />
          </div>
        </td>
      </template>

      <template #service_fee_debited="{ item }">
        <td>
          <TMessageMoney
            :amount="item.service_fee_debited"
            :currency="item.service_fee_currency_id"
          />
        </td>
      </template>

      <template #service_fee_outstanding="{ item }">
        <td>
          <TMessageMoney
            :amount="item.service_fee_outstanding"
            :currency="item.service_fee_currency_id"
          />
        </td>
      </template>

      <template #compensation="{ item }">
        <td>
          <TMessageMoney
            :amount="item.compensation"
            :currency="item.service_fee_currency_id"
          />
        </td>
      </template>

      <template #compensation_unsolved="{ item }">
        <td>
          <TMessageMoney
            :amount="item.compensation_unsolved"
            :currency="item.service_fee_currency_id"
          />
        </td>
      </template>

      <template #compensated="{ item }">
        <td>
          <TMessageMoney
            :amount="item.compensated"
            :currency="item.service_fee_currency_id"
          />
        </td>
      </template>

      <template #shipment_method_id="{ item }">
        <td>
          <SMessageShipmentMethod :id="item.shipment_method_id" />
        </td>
      </template>

      <template #updated_at="{ item }">
        <td>
          <TMessageDateTime :content="item.updated_at" small />
        </td>
      </template>

      <template #id-filter>
        <TInputText
          placeholder="Order Id"
          :value.sync="filter.id"
          @update:value="filterChange"
        />
      </template>

      <template #related_trackings-filter>
        <TInputText
          placeholder="Tracking Id"
          :value.sync="filter['by_tracking_code']"
          @update:value="filterChange"
        />
      </template>

      <template #purchase_cost-filter>
        <TInputNumberRange
          :value.sync="filter_between['purchase_cost']"
          @change="filterChange"
        />
      </template>
      <template #purchase_cost_debited-filter>
        <TInputNumberRange
          :value.sync="filter_between['purchase_cost_debited']"
          @change="filterChange"
        />
      </template>
      <template #purchase_cost_outstanding-filter>
        <TInputNumberRange
          :value.sync="filter_between['purchase_cost_outstanding']"
          @change="filterChange"
        />
      </template>

      <template #service_fee-filter>
        <TInputNumberRange
          :value.sync="filter_between['service_fee']"
          @change="filterChange"
        />
      </template>
      <template #service_fee_debited-filter>
        <TInputNumberRange
          :value.sync="filter_between['service_fee_debited']"
          @change="filterChange"
        />
      </template>
      <template #service_fee_outstanding-filter>
        <TInputNumberRange
          :value.sync="filter_between['service_fee_outstanding']"
          @change="filterChange"
        />
      </template>

      <template #shipment_method_id-filter>
        <SSelectShipmentMethod
          :value.sync="filter.shipment_method_id"
          @change="filterChange"
        />
      </template>
      <template #status-filter>
        <SSelectOrderStatus
          store="order.statuses"
          :value.sync="filter.status_id"
          @change="filterChange"
        />
      </template>
      <template #type-filter>
        <SSelectOrderType :value.sync="filter.type_id" @change="filterChange" />
      </template>
      <template #updated_at-filter>
        <TInputDateTimeRange
          :value.sync="filter_between['updated_at']"
          @update:value="filterChange"
        />
      </template>
      <template #customer_id-filter>
        <SSelectCustomer
          :value.sync="filter.customer_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    store: {
      type: String,
      default: "order.orders",
    },
    default_filter: {
      type: Object,
      default: () => ({}),
    },
    money_column_type: {
      type: String,
      default: "purchase_cost",
    },
  },
  data() {
    return {
      key: "btn-select",
      filter: {},
      filter_between: {},
    };
  },
  mounted() {
    this.$store.dispatch("warehouse.shipment_methods.fetch.if-first-time");
  },
  watch: {
    show(isShow) {
      if (isShow) {
        this.filterChange();
      }
    },
  },
  computed: {
    list() {
      return this.$store.getters[this.store + ".list"];
    },
    list_loading() {
      return this.$store.getters[this.store + ".fetching"];
    },
    include() {
      return "type,status,customer";
    },
    append() {
      return "related_trackings";
    },
    fields() {
      let append_columns = [
        {
          key: "purchase_cost",
          label: "Purchase cost",
          sorter: true,
        },
        {
          key: "purchase_cost_debited",
          label: "Debited",
          sorter: true,
        },
        {
          key: "purchase_cost_outstanding",
          label: "Outstanding",
          sorter: true,
        },
      ];

      if (this.money_column_type === "compensation") {
        append_columns = [
          {
            key: "compensation",
            label: "Commodity compensation",
            sorter: true,
          },
          {
            key: "compensation_unsolved",
            label: "Compensation unsolved",
            sorter: true,
          },
          {
            key: "compensated",
            label: "Compensated",
            sorter: true,
          },
        ];
      }

      if (this.money_column_type === "service_fee") {
        append_columns = [
          {
            key: "service_fee",
            label: "Paid/Service fee",
            sorter: true,
          },
          {
            key: "service_fee_debited",
            label: "Service fee debited",
            sorter: true,
          },
          {
            key: "service_fee_outstanding",
            label: "Service fee outstanding",
            sorter: true,
          },
        ];
      }

      return [
        { key: "_", _style: "width: 40px" },
        {
          key: "id",
          label: "Order Id",
          _style: "min-width: 100px",
          sorter: true,
        },
        {
          key: "related_trackings",
          label: "Tracking Id",
        },
        ...append_columns,
        {
          key: "type",
          label: "Order type",
          _classes: "text-nowrap",
          _style: "min-width: 150px",
        },
        {
          key: "status",
          label: "Status",
          _classes: "text-nowrap",
          _style: "min-width: 150px",
        },
        {
          key: "customer_id",
          label: "Customer",
          _classes: "text-nowrap",
          _style: "min-width: 170px",
        },
        {
          key: "shipment_method_id",
          label: "Shipment method",
          _classes: "text-nowrap",
          _style: "min-width: 150px",
        },
        {
          key: "updated_at",
          sorter: true,
          label: "Updated at",
          _style: "min-width: 220px",
        },
      ];
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch(this.store + ".fetch");
    },
    select(selected_order) {
      this.$emit("select", selected_order);
      this.$emit("update:show", false);
      this.key = this.lodash.resetKey("btn-select");
    },
    filterChange() {
      this.$store.dispatch(this.store + ".apply-query", {
        ...this.default_filter,
        ...this.lodash.mapKeys(this.filter, (_, k) => `filter[${k}]`),
        ...this.formatFilterBetween(this.filter_between),
        include: this.include,
        append: this.append,
      });
    },
    formatFilterBetween(obj) {
      return Object.entries(obj).reduce((p, c) => {
        p[`filter_between[${c[0]}]`] = [c[1].start || 0, c[1].end || 0].join(
          ","
        );

        return p;
      }, {});
    },
    clearFilter() {
      this.filter_between = {};
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>
