<template>
  <TMessage :content="type" :messageOptions="messageOptions" noTranslate bold color='muted' />
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    messageOptions: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      types: "accounting.transaction_types.list",
    }),
    type() {
      const type = this.lodash.find(this.types, { id: this.value });
      if (!type) {
        return this.value;
      }
      return type.name;
    },
  },
};
</script>
