<template>
  <CButton
    v-if="status"
    :color="getColor"
    variant="outline"
    size="sm"
    class="ml-1"
    @click="$emit('click', status.id)"
  >
    <TMessage :content="status" noTranslate />
  </CButton>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    statuses() {
      return this.$store.getters["warehouse.goods_delivery_statuses.list"];
    },
    status() {
      const status = this.lodash.find(this.statuses, {
        id: this.lodash.lowerCase(this.id),
      });
      if (!status) {
        return this.id;
      }
      return status.name;
    },
    getColor() {
      switch (this.lodash.lowerCase(this.id)) {
        case "waiting_shipment":
          return "info";
        case "shipping":
          return "primary";
        case "received":
          return "success";
        case "cancelled":
          return "danger";
        default:
          return "warning";
      }
    },
  },
};
</script>
