<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      'custom-label': customLabel,
      preserveSearch: true,
      ...selectOptions,
    }"
    :normalizer="{ label: 'name', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
  >
    <template #singleLabel="{ option }">
      <TMessage
        :content="option.name ? option.name : option.id"
        noTranslate
        :truncate="1"
        size="small"
      />
    </template>
  </TSelect>
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    prepend: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    value: {
      type: [String, Number],
      required: false,
      default: function () {
        return "";
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    selectOptions: {
      type: Object,
      require: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  created() {
    this.$store.dispatch("warehouse.goods_deliveries.fetch.if-first-time");
  },
  watch: {
    valueMatch: {
      immediate: true,
      handler(status) {
        this.valueSelected = status;
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.goods_deliveries.list",
      loading: "warehouse.goods_deliveries.fetching",
    }),
    valueMatch() {
      return this.listC.find((x) => x.id === this.value);
    },
    listC() {
      return [...this.prepend, ...this.list];
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change");
    },
    customLabel({ id, customer, name }) {
      let display = id;
      if (customer) {
        display += " (" + customer.name + ")";
      }
      if (!id) display = name;
      return display;
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("warehouse.goods_deliveries.apply-query", {
        "filter[id]": param,
      });
    }, 300),
  },
};
</script>
