<template>
  <CPagination
    align="center"
    :pages="paginate.last"
    :active-page="paginate.current"
    @update:activePage="pageChange"
  />
</template>

<script>
export default {
  props: {
    store: {
      type: String,
      required: true,
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query[this.store + "-page"]) {
          const page = Number(route.query[this.store + "-page"]);
          if (this.paginate.current !== page) {
            this.$store.dispatch(`${this.store}.change-page`, page);
          }
        }
      },
    },
    paginate(value) {
      if (this.$route.query && this.$route.query[this.store + "-page"]) {
        const page = Number(this.$route.query[this.store + "-page"]);
        if (page !== value.current) {
          this.pageChange(value.current);
        }
      }
    },
  },
  computed: {
    paginate() {
      return this.$store.getters[this.store + ".paginate"];
    },
  },
  methods: {
    pageChange(val) {
      this.$router.push({
        query: { ...this.$route.query, [this.store + "-page"]: val },
      });
    },
  },
};
</script>
