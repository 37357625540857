<template>
  <CCard class="h-100 m-0">
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="List of bills of lading" bold />
      <TSpinner :loading="loading" />
      <TButtonRefresh
        class="ml-auto"
        @click="$store.dispatch('warehouse.lading_bills_of_orders.fetch')"
      />
    </CCardHeader>
    <CCardBody v-if="list" class="w-100 mx-auto" style="max-width: 800px">
      <div class="w-100">
        <CCol v-for="ladingBill in list" :key="ladingBill.id" class="p-0 w-100">
          <CCard class="mb-2 w-100" borderColor="primary">
            <CCardHeader class="p-1" color="primary">
              <TMessage content="Bill of lading code" color="white" bold>
                <template #suffix>
                  <span>:</span> {{ ladingBill.id }}
                </template>
              </TMessage>
            </CCardHeader>
            <CCardBody class="p-2">
              <TTableAsForm
                :data="ladingBill"
                :fields="pivotLadingBillFields"
                :splitLeft="6"
                :addRowClasses="[]"
                class="mb-0"
              >
                <template #created_at="{ value }">
                  <TMessageDateTime :content="value" dateOnly />
                </template>
                <template #freight_charge="{ value }">
                  <TMessageMoney
                    :amount="value"
                    :currency="ladingBill.currency_id"
                  />
                </template>
                <template #container="{ data }">
                  <SMessageContainer
                    noBadge
                    v-if="data.container"
                    :container="data.container"
                  />
                  <TMessageNotFound v-else />
                </template>
                <template #status="{ data }">
                  <div v-if="data.container" class="d-flex">
                    <SMessageContainerStatus
                      :status="data.container.had_cut_off"
                      class="my-1"
                    />
                    <TMessageDateTime
                      class="ml-2 my-auto"
                      v-if="data.container.cut_off_date"
                      :content="data.container.cut_off_date"
                      dateOnly
                    />
                  </div>
                </template>
                <template #from-to="{ data }">
                  <div v-if="data.container">
                    <CBadge color="primary" v-if="data.container.from_area_id">
                      <TMessage
                        :content="data.container.from_area_id"
                        uppercase
                        noTranslate
                      />
                    </CBadge>
                    <CIcon class="mx-1" name="cil-arrow-circle-right" />
                    <CBadge color="info" v-if="data">
                      <TMessage
                        :content="data.container.to_area_id"
                        uppercase
                        noTranslate
                      />
                    </CBadge>
                  </div>
                </template>
              </TTableAsForm>
            </CCardBody>
          </CCard>
        </CCol>
      </div>
      <TMessageNoData v-if="!list.length" />
    </CCardBody>
  </CCard>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    order: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      pivotLadingBillFields: [
        { key: "created_at", label: "Created at" },
        { key: "freight_charge", label: "Freight charge" },
        { key: "container", label: "Container" },
        { key: "from-to", label: "From" },
        { key: "status", label: "Status" },
      ],
    };
  },
  watch: {
    "order.id": {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("warehouse.lading_bills_of_orders.apply-query", {
            "order_ids[0]": id,
            "filter[customer_id]": this.order.customer_id,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.lading_bills_of_orders.list",
      loading: "warehouse.lading_bills_of_orders.loading",
    }),
  },
};
</script>
