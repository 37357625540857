<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <SSelectCard
        v-if="editing"
        :value="value ? value.id : null"
        @close="setEditing(false)"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        :editable="editable"
      />
    </slot>
    <TMessage
      v-show="!editing || dontHideWhenEditing"
      :content="value ? value.name : null"
      :editable="editable"
      noTranslate
      @click-edit="showEdit()"
    />
  </div>
</template>

<script>
import mixin from "@/core/components/T/Button/actions";

export default {
  mixins: [mixin],
  props: {
    value: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
