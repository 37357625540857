<template>
  <TButton
    v-bind="options"
    :size="size"
    :variant="variant"
    @click="$emit('click')"
    tooltip="Refresh"
    icon="cil-reload"
  />
</template>

<script>
import mixin from "./mixin";
export default {
  mixins: [mixin],
  props: {
    variant: {
      type: String,
      default: "outline",
    },
  },
};
</script>
