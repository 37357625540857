<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <TInputEditable
        v-if="editing"
        :value="value / 1000000"
        @change="
          $emit('change', $event * 1000000);
          setEditing(false);
        "
        @close="setEditing(false)"
      >
        <template #input="{ setInput, input }">
          <TInputNumber
            :value="input"
            @update:value="setInput"
            @keydown.enter.native="
              $emit('change', input * 1000000);
              setEditing(false);
            "
            :maskOptions="{ decimalLimit, integerLimit: 3 }"
          />
        </template>
      </TInputEditable>
    </slot>
    <TMessage
      v-show="!editing || dontHideWhenEditing"
      :content="content"
      noTranslate
      :italic="editable"
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      @click-edit="showEdit()"
      v-bind="messageOptions"
    >
      <template #suffix>
        <slot name="suffix"></slot>
      </template>
    </TMessage>
  </div>
</template>

<script>
import actions from "../Button/actions";
import TMessage from "../Message.vue";
import TInputNumber from "../Input/Number.vue";

export default {
  mixins: [actions],
  components: {
    TMessage,
    TInputNumber,
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    suffix: {
      type: String,
      required: false,
      default: "m3",
    },
    messageOptions: {
      type: Object,
      required: false,
    },
    decimalLimit: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    content() {
      if (this.suffix) return this.numberMasked() + " " + this.suffix;
      return this.numberMasked();
    },
  },
  methods: {
    numberMasked() {
      return this.lodash.toNumberMask(this.value / 1000000, {
        decimalLimit: this.decimalLimit,
      });
    },
  },
};
</script>
