<template>
  <TSelect
    class="m-0"
    :options="statuses"
    :value="valueSelected"
    @select="select"
    :selectOptions="selectOptions"
  />
</template>

<script>
export default {
  props: {
    prepend: {
      type: Array,
      required: false,
      default: function () {
        return [{ id: "", name: this.$t("All") }];
      },
    },
    value: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
    selectOptions: {
      type: Object,
      required: false,
    },
  },
  created() {
    this.$store.dispatch("order.order_types.fetch.if-first-time");
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.valueSelected = this.status;
      },
    },
  },
  computed: {
    statuses() {
      return [
        ...this.prepend,
        ...this.$store.getters["order.order_types.list"],
      ];
    },
    status() {
      return this.statuses.find((x) => x.id === this.value);
    },
  },
  methods: {
    select(status) {
      this.valueSelected = status;
      this.$emit("update:value", status.id);
      this.$emit("change");
    },
  },
};
</script>
