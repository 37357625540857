import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'product',
    resource: 'categories',
    paginate: false
  },
  {
    default_query: {
      include: "parent",
    },
    default_detail: {
      parent: {
        name: "",
      },
    },
  }
).store()

export default resource
