<template>
  <div>
    <CCard v-if="contract">
      <CCardHeader class="d-flex">
        <TMessage :content="contract.id" bold noTranslate class="my-auto" />
        <TSpinner :loading="loading" />
        <SMessageContractStatus
          :status="contract.status_id"
          class="my-auto ml-1"
        />
        <TButtonEnter
          :to="lodash.getReferenceLink('contract', contract.id)"
          class="ml-auto"
          variant="outline"
        />
      </CCardHeader>
      <CCardBody class="w-100 mx-auto">
        <div>
          <TTableAsForm
            :data="contract"
            :fields="contractFields"
            :splitLeft="6"
            :addRowClasses="[]"
          >
            <template #start_date="{ value }">
              <TMessageDateTime :content="value" dateOnly />
            </template>
            <template #end_date="{ value }">
              <TMessageDateTime :content="value" dateOnly />
            </template>
            <template #service_fee="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="contract.service_fee_currency_id"
              />
            </template>
            <template #purchase_cost="{ value }">
              <TMessageMoney
                :amount="value"
                :currency="contract.purchase_cost_currency_id"
              />
            </template>
          </TTableAsForm>
        </div>
      </CCardBody>
    </CCard>
    <CCard v-if="checkEmpty">
      <CCardHeader>
        <TMessage content="Contract" bold />
      </CCardHeader>
      <CCardBody> <TMessageNoData /></CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    orderId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      contractFields: [
        { key: "start_date", label: "Start date" },
        { key: "end_date", label: "End date" },
        { key: "service_fee", label: "Service fee" },
        { key: "purchase_cost", label: "Purchase cost" },
      ],
    };
  },
  destroyed() {
    this.$store.commit("order.contracts.reset-query");
  },
  watch: {
    orderId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("order.contracts.apply-query", {
            "filter[orders.id]": id,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      loading: "order.contracts.loading",
    }),
    checkEmpty() {
      return this.lodash.isEmpty(this.contract);
    },
    contract() {
      return this.$store.getters[`order.contracts.list`]?.[0];
    },
  },
};
</script>
