<template>
  <div>
    <CButton variant="outline" color="primary" @click="checSFA(sfa)" size="">
      <strong>{{ $t("Assemble to pallets") }}</strong>
      <CIcon class="ml-2" name="cil-arrow-right" />
    </CButton>
    <TModalConfirm
      :show.sync="showModalWarning"
      @click-confirm="moveToPallet(sfa)"
      title="Tally"
    >
      <template #content>
        <div class="d-flex h5">
          <TMessage content="There are" />
          <CBadge color="danger" class="h6 mx-1">
            {{ remaining_quantity }}
          </CBadge>
          <TMessage content="packages left untested" />
        </div>
        <TMessage content="Do you want to assemble on pallets?" size="h5" />
      </template>
    </TModalConfirm>
  </div>
</template>

<script>
export default {
  props: {
    sfa: {
      type: Object,
      required: true,
    },
    remaining_quantity: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showModalWarning: false,
    };
  },
  methods: {
    checSFA(sfa) {
      if (this.remaining_quantity) {
        this.showModalWarning = true;
      } else {
        this.moveToPallet(sfa);
      }
    },
    moveToPallet(sfa) {
      this.$router.push(
        `/warehouse/internal/assemble-to-stock?sfa_id=${sfa.id}`
      );
    },
  },
};
</script>
