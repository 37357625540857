import tomoni from "../services/tomoni";
import firebaseAuth from "../services/firebase/auth";
import messaging from "../services/firebase/messaging";
import lodash from "@/core/plugins/lodash";
import organizationStore from "@/core/services/tomoni/organization.local";
import warehouseScope from "@/core/services/tomoni/warehouse_scope.local";

const state = {
  auth_me: {}
};

const getters = {
  ["auth.me"](state) {
    return state.auth_me;
  },
  ["auth.me.id"](state) {
    return state.auth_me.id;
  },
  ["auth.me.permissions"](state) {
    let permissionList = [];
    state.auth_me.permissions.forEach(per => {
      ["read", "update", "delete", "create"].forEach(x => {
        const result = per?.[x]
          ? `${per?.resource_id}.${per?.scope}.${x}`
          : undefined;
        permissionList = [...permissionList, result];
      });
    });
    return lodash.uniq(lodash.compact(permissionList))
  },
  ["auth.me.manage_user_ids"](state) {
    return state.auth_me.manage_user_ids;
  },
  ["auth.authenticated"](state) {
    return state.auth_me.id;
  }
};

const actions = {
  ["auth.login.password"](context, { email, password }) {
    return new Promise(resolve => {
      // - set loading true
      firebaseAuth.password
        .login(email, password)
        .then(() => {
          context.dispatch("auth.me.fetch").then(me => resolve(me));
        })
        .catch(error => {
          context.dispatch("errors.push", {
            error,
            notify: true
          });
        })
        .finally(() => {
          // - set loading false
        });
    });
  },
  ["auth.login.social"](context, provider) {
    return new Promise(resolve => {
      // - set loading true
      firebaseAuth[provider]
        .login()
        .then(() => {
          context.dispatch("auth.me.fetch").then(me => resolve(me));
        })
        .catch(error => {
          context.dispatch("errors.push", {
            error,
            notify: true
          });
        })
        .finally(() => {
          // - set loading false
        });
    });
  },
  ["auth.me.fetch"](context) {
    return new Promise((resolve, reject) => {
      tomoni.auth.auth
        .me({
          "filter[own]": "1",
          append: "permissions",
          include: "organization",
        })
        .then(({ data }) => {
          const user_logged_in = firebaseAuth.currentUser();
          if (data.data.length) {
            const me = {
              id: data.data[0].id,
              name: data.data[0].name,
              email: data.data[0].email,
              tel: data.data[0].tel,
              role_id: data.data[0].role_id,
              email_verified: user_logged_in.emailVerified,
              permissions: data.data[0].permissions,
              organization: data.data[0].organization,
            };
            organizationStore.set(data.data[0].organization_id ? data.data[0].organization_id : organizationStore.get() || "");
            context.commit("auth.me.set", me);
            resolve(me);
          }
          else {
            context.dispatch("auth.logout").then(() => {
              warehouseScope.set("");
              this.$redirect.toLogin();
            });
          }
        })
        .catch(error => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  ["auth.me.update"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.auth.me
        .update(attributes)
        .then(({ data }) => {
          resolve(data);
          context.commit("toasts.push", {
            message: "Updated",
            type: "success"
          });
        })
        .catch(error => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  ["auth.logout"](context) {
    return firebaseAuth.signOut().then(() => {
      context.commit("auth.me.purge");
    });
  },
  ["auth.me.send_mail.verification"](context) {
    return new Promise((resolve, reject) => {
      firebaseAuth
        .currentUser()
        .sendEmailVerification()
        .then(() => {
          context.commit("toasts.push", {
            title: "Verification email sent",
            message: "Check your mail",
            type: "success"
          });
          resolve();
        })
        .catch(error => {
          context.dispatch("errors.push", {
            error,
            notify: true
          });
          reject(error);
        });
    });
  },
  ["auth.me.update_email"](context, email) {
    return new Promise((resolve, reject) => {
      firebaseAuth
        .currentUser()
        .updateEmail(email)
        .then(() => {
          context.dispatch("auth.me.send_mail.verification");
          context.dispatch("auth.me.fetch");
          resolve();
        })
        .catch(error => {
          context.dispatch("errors.push", {
            error,
            notify: true
          });
          reject(error);
        });
    });
  },
  ["auth.me.send_mail.reset_password"](context) {
    return new Promise((resolve, reject) => {
      const email = context.getters["auth.me"].email;
      if (!email) {
        const error = {
          message: "Email empty"
        };
        context.dispatch("errors.push", {
          error: error,
          notify: true
        });
        reject(error);
      }
      firebaseAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          context.commit("toasts.push", {
            title: "Password reset email was sent",
            message: "Check your mail",
            type: "success"
          });
          resolve();
        })
        .catch(error => {
          context.dispatch("errors.push", {
            error,
            notify: true
          });
          reject(error);
        });
    });
  },
  ["auth.send_mail.reset_password"](context, email) {
    return new Promise((resolve, reject) => {
      if (!email) {
        const error = {
          message: "Email empty"
        };
        context.dispatch("errors.push", {
          error: error,
          notify: true
        });
        reject(error);
      }
      firebaseAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          context.commit("toasts.push", {
            title: "Password reset email sent",
            message: "Check your mail",
            type: "success"
          });
          resolve();
        })
        .catch(error => {
          context.dispatch("errors.push", {
            error,
            notify: true
          });
          reject(error);
        });
    });
  },
  ["auth.verify"](context, requireVerification = false) {
    return new Promise((resolve, reject) => {
      context
        .dispatch("auth.authenticate")
        .then(me => {
          if (requireVerification) {
            if (me.email_verified) {
              resolve(me);
            }
            reject({
              code: 403,
              type: "email_not_verified",
              message: "Email not verified"
            });
          }
          resolve(1);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  ["auth.authenticate"](context) {
    if (context.getters["auth.authenticated"]) {
      return context.getters["auth.me"];
    }
    var unsubscribe;
    return new Promise((resolve, reject) => {
      unsubscribe = firebaseAuth.onAuthStateChanged(user => {
        if (!user) {
          reject({
            code: 401,
            type: "logged_out",
            message: "Logged out"
          });
          return;
        }
        context.dispatch("auth.me.fetch").then(me => {
          resolve(me);
        });
      });
    }).finally(() => {
      unsubscribe();
    });
  },
  ["auth.me.idtoken.toast"](context) {
    toastIDToken(context);
  },
  ["auth.me.deviceToken.toast"](context) {
    toastDeviceToken(context)
  }
};

const mutations = {
  ["auth.me.set"](state, user) {
    state.auth_me = user;
  },
  ["auth.me.purge"](state) {
    state.auth_me = {
      email_verified: false,
      permissions: [],
      manage_user_ids: []
    };
  }
};

async function toastIDToken(context) {
  context.commit("toasts.push", {
    title: "ID Token",
    message: await firebaseAuth.getIdToken(),
    type: "success"
  });
}

async function toastDeviceToken(context) {
  context.commit("toasts.push", {
    title: "Device Token",
    message: await messaging.getToken(),
    type: "success"
  });
}

export default {
  state,
  actions,
  mutations,
  getters
};
