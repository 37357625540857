<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <SSelectProductEc
        v-if="editing"
        :value="id"
        @close="setEditing(false)"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        :editable="editable"
      />
    </slot>
    <TMessage
      :content="content ? content : type"
      noTranslate
      color="info"
      bold
      :italic="editable"
      :uppercase="uppercase"
      v-show="!editing || dontHideWhenEditing"
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      :truncate="1"
      @click-edit="showEdit()"
    ></TMessage>
  </div>
</template>

<script>
import mixin from "@/core/components/T/Button/actions";
import { mapGetters } from "vuex";

export default {
  mixins: [mixin],
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: "",
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    uppercase: {
      type: Boolean,
      required: false,
      default: true,
    },
    content: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapGetters({
      product_ec: "product.ecommerce_channels.list",
    }),
    type() {
      return this.product_ec.find((i) => i.id === this.id)?.name;
    },
  },
};
</script>
