<template>
  <TModal
    title="Add to goods delivery"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButton
        content="Add"
        :options="{ disabled: loading }"
        icon="cil-plus"
        @click="submit"
      />
    </template>
    <SSelectGoodsDelivery
      :prepend="[]"
      label="Goods delivery"
      :value.sync="input.goods_delivery_id"
      @update:value="input.goods_delivery_id = $event"
    />
    <TInputNumber label="Quantity" :value.sync="input.quantity" class="mt-3" />
    <TInputText
      label="Shipping code"
      :value.sync="input.shipping_code"
      class="mt-3"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    boxId: {
      type: String,
      default: "",
    },
    orderId: {
      type: String,
      default: "",
    },
    customerId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      input: {
        quantity: 1,
      },
    };
  },
  methods: {
    submit() {
      this.loading = true;
      return this.$store
        .dispatch("warehouse.goods_delivery_boxes.create", {
          ...this.input,
          order_id: this.orderId,
          box_id: this.boxId,
          customer_id: this.customerId,
        })
        .then(() => {
          this.input = {
            quantity: 1,
          };
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
