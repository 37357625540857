<template>
  <div>
    <CToaster :autohide="timeout" position="bottom-right">
      <template v-for="(toast, i) in toasts">
        <CToast :key="i" :show="true">
          <template #header>
            <p v-if="!!toast.title" :class="getStyle(toast.type)">
              {{ toast.title }}
            </p>
          </template>
          <template class="p-0">
            <div v-if="!!toast.title">
              <TMessage
                v-if="lodash.isString(toast.message)"
                :content="toast.message"
                style="overflow-x: auto"
              />
              <div
                v-else
                class="d-flex flex-wrap"
                v-for="(message, index) in toast.message"
                :key="index"
              >
                <TMessage
                  :content="`[${lodash.toString(message.key)}]`"
                  noTranslate
                />:
                <span
                  class="ml-1"
                  v-for="(mes, index) in message.message"
                  :key="index"
                  >{{ mes }}</span
                >
              </div>
            </div>
            <TMessage
              v-if="!toast.title"
              :content="toast.message"
              :color="toast.type"
              style="overflow-x: auto"
            />
          </template>
        </CToast>
      </template>
    </CToaster>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Toast",
  computed: {
    ...mapGetters({ toasts: "toasts.list", timeout: "toasts.timeout" }),
  },
  methods: {
    getStyle(color) {
      return "w-100 m-0 text-" + color;
    },
  },
};
</script>
