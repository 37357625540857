import Resource from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const resource = new Resource(
  {
    service: 'helper',
    resource: 'exports',
  },
  {
  }
).store()

const PREFIX = "helper.exports";

const actions = {
  [PREFIX + ".export-invoice"](context, attributes) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-updating', true);
      tomoni.helper.exports.exportInvoice(attributes)
        .then(({ data }) => {
          context.commit("toasts.push", {
            message: "Export success",
            type: "success",
          });
          resolve(data)
        }).catch(async (error) => {
          let responseObj = JSON.parse(await error.response.data.text())
          context.dispatch('errors.push-http-error', {
            response: {
              ...error.response,
              data: responseObj
            }
          });
          reject(error);
        }).finally(() => {
          context.commit(PREFIX + '.set-updating', false);
        })
    });
  },
  [PREFIX + ".export-distribution"](context, attributes) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-updating', true);
      tomoni.helper.exports.exportDistribution(attributes)
        .then(({ data }) => {
          context.commit("toasts.push", {
            message: "Export success",
            type: "success",
          });
          resolve(data)
        }).catch(async (error) => {
          let responseObj = JSON.parse(await error.response.data.text())
          context.dispatch('errors.push-http-error', {
            response: {
              ...error.response,
              data: responseObj
            }
          });
          reject(error);
        }).finally(() => {
          context.commit(PREFIX + '.set-updating', false);
        })
    });
  },
  [PREFIX + ".export-purchase"](context, attributes) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-updating', true);
      tomoni.helper.exports.exportPurchase(attributes)
        .then((response) => {
          context.commit("toasts.push", {
            message: "Export success",
            type: "success",
          });
          const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
          resolve({ data: response.data, filename })
        }).catch(async (error) => {
          let responseObj = JSON.parse(await error.response.data.text())
          context.dispatch('errors.push-http-error', {
            response: {
              ...error.response,
              data: responseObj
            }
          });
          reject(error);
        }).finally(() => {
          context.commit(PREFIX + '.set-updating', false);
        })
    });
  },
  [PREFIX + ".export-purchase-distribution"](context, attributes) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-updating', true);
      tomoni.helper.exports.exportPurchaseDistribution(attributes)
        .then((response) => {
          context.commit("toasts.push", {
            message: "Export success",
            type: "success",
          });
          const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0].replaceAll('"', '')
          resolve({ data: response.data, filename })
        }).catch(async (error) => {
          let responseObj = JSON.parse(await error.response.data.text())
          context.dispatch('errors.push-http-error', {
            response: {
              ...error.response,
              data: responseObj
            }
          });
          reject(error);
        }).finally(() => {
          context.commit(PREFIX + '.set-updating', false);
        })
    });
  },
  [PREFIX + ".export-goods-delivery"](context, attributes) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-updating', true);
      tomoni.helper.exports.exportGoodsDelivery(attributes)
        .then(({ data }) => {
          context.commit("toasts.push", {
            message: "Export success",
            type: "success",
          });
          resolve(data)
        }).catch(async (error) => {
          let responseObj = JSON.parse(await error.response.data.text())
          context.dispatch('errors.push-http-error', {
            response: {
              ...error.response,
              data: responseObj
            }
          });
          reject(error);
        }).finally(() => {
          context.commit(PREFIX + '.set-updating', false);
        })
    });
  },
  [PREFIX + ".export-container-picker"](context, attributes) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-fetching', true);
      tomoni.helper.exports.exportContainerPicker(attributes)
        .then((response) => {
          context.commit("toasts.push", {
            message: "Export success",
            type: "success",
          });
          const filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
          resolve({ data: response.data, filename })
        }).catch(async (error) => {
          let responseObj = JSON.parse(await error.response.data.text())
          context.dispatch('errors.push-http-error', {
            response: {
              ...error.response,
              data: responseObj
            }
          });
          reject(error);
        }).finally(() => {
          context.commit(PREFIX + '.set-fetching', false);
        })
    });
  },
  [PREFIX + ".export-transactions"](context, attributes) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-updating', true);
      tomoni.helper.exports.exportTransaction(attributes)
        .then(({ data }) => {
          context.commit("toasts.push", {
            message: "Export success",
            type: "success",
          });
          resolve(data)
        }).catch(async (error) => {
          let responseObj = JSON.parse(await error.response.data.text())
          context.dispatch('errors.push-http-error', {
            response: {
              ...error.response,
              data: responseObj
            }
          });
          reject(error);
        }).finally(() => {
          context.commit(PREFIX + '.set-updating', false);
        })
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};
