<template>
  <div>
    <CLink v-if="order.status" class="float-right">
      <SMessageOrderStatus :id="order.status.id" />
    </CLink>
    <TMessage :content="order.id" bold noTranslate size="h4" />
    <TMessage content="Customer" v-if="order.customer">
      <template #suffix
        >:
        {{ order.customer.name }}
      </template>
    </TMessage>
    <TMessage content="Owned boxes">
      <template #suffix
        >:
        <CBadge color="info"> {{ owned }} </CBadge>
      </template>
    </TMessage>
    <div v-for="tracking in order.related_trackings" :key="tracking.id">
      <TMessage content="Tracking">
        <template #suffix
          >:
          {{ tracking.code }}
        </template>
      </TMessage>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    owned() {
      return this.lodash.sumBy(this.order.owning_boxes, "quantity");
    },
  },
};
</script>
