<template>
  <div class="w-100">
    <TMessage
      v-if="label"
      :content="label"
      bold
      :class="['mb-2', { 'mb-0 col-form-label-lg': size == 'lg' }]"
    />
    <div
      :class="{ 'd-flex border rounded overflow-hidden': append }"
      style="height: 35px"
    >
      <MaskedInput
        :class="[
          'form-control w-100',
          { 'border-top-0 border-bottom-0 border-left-0 rounded-0': append },
          { 'form-control-lg': size == 'lg' },
        ]"
        :value="input"
        :mask="
          lodash.moneyMask(
            currency ? { currency: currency } : { noSymbol: true }
          )
        "
        :guide="false"
        @input="setValue"
        :placeholder="`0${
          !!currency ? ' ' + lodash.symbolCurrency(currency) : ''
        }`"
        v-bind="inputOptions"
        :disabled="disabled"
      />
      <TMessage
        v-if="append"
        class="input-group-text rounded-0 border-0"
        bold
        noTranslate
        :content="append"
      />
    </div>
  </div>
</template>

<script>
import MaskedInput from "vue-text-mask";
import mixin from "./mixin";

export default {
  mixins: [mixin],
  components: {
    MaskedInput,
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    append: {
      type: String,
      required: false,
    },
    currency: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: "",
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        return (this.input = value ? value.toString() : "");
      },
    },
  },
  methods: {
    setValue(value) {
      this.$emit(
        "update:value",
        Number.parseFloat(this.lodash.replace(value, /[^0-9.]/g, "")) || 0
      );
      this.input = value;
    },
  },
};
</script>
