import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'change-sku-position'

export default {
  changePosition(attributes) {
    return SERVICE.put(`api/${RESOURCE}`, attributes);
  },
};
