<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="list"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      'custom-label': customLabel,
      preserveSearch: true,
    }"
    :normalizer="{ label: 'name', value: 'id' }"
    placeholder="Select"
    :loading="loading"
    @select="select"
    @search="search"
  >
    <template #singleLabel="{ option }">
      <TMessage :content="option.id" noTranslate :truncate="1" size="small" />
    </template>
  </TSelect>
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: function () {
        return "";
      },
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  created() {
    this.$store.dispatch("warehouse.boxes.fetch.if-first-time");
  },
  watch: {
    valueMatch: {
      immediate: true,
      handler(status) {
        this.valueSelected = status;
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.boxes.list",
      loading: "warehouse.boxes.fetching",
    }),
    valueMatch() {
      return this.list.find((x) => x.id === this.value);
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
    customLabel({ id }) {
      return id;
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("warehouse.boxes.apply-query", {
        "filter[id]": param,
      });
    }, 300),
  },
};
</script>
