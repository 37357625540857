<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      preserveSearch: true,
      customLabel,
    }"
    :normalizer="{ label: value == '' ? 'name' : 'id', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
  />
</template>
<script>
import mixins from "./mixins";
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";

export default {
  mixins: [mixins],
  props: {
    value: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
  },
  created() {
    if (!this.noFetchStore)
      this.$store.dispatch("warehouse.sfas.fetch.if-first-time");
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value == null) {
          this.checkValue("");
          return;
        }
        this.checkValue(value);
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.sfas.list",
      loading: "warehouse.sfas.fetching",
    }),
  },
  methods: {
    checkValue(value) {
      const firstValue = this.listC.find((x) => x.id === value);
      // Nếu value rỗng
      if (!value && !this.prependAll) {
        this.valueSelected = null;
        return;
      }
      // Value k có trong list
      if (!firstValue) {
        this.$tomoni.warehouse.sfas
          .all({ "filter[id]": value })
          .then(({ data }) => {
            this.firstValue = data.data[0];
            this.valueSelected = data.data[0];
          });
      }
      // Value có trong list
      if (firstValue) {
        this.valueSelected = firstValue;
      }
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("warehouse.sfas.push-query", {
        "filter[id]": param,
      });
      if (this.firstValue) this.firstValue = null;
    }, 300),
    customLabel({ id, name }) {
      return `${name ? name : id}`;
    },
  },
};
</script>
