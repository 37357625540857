<template>
  <CButton
    :color="color"
    variant="outline"
    size="sm"
    class="ml-1"
    @click="$emit('click')"
    :disabled="disabled"
  >
    <TMessage :content="name" noTranslate />
  </CButton>
</template>

<script>
import TMessage from "@/core/components/T/Message.vue";

export default {
  components: {
    TMessage,
  },
  props: {
    id: {
      type: String,
      required: false,
    },
    noTranslate: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    store: {
      type: String,
      required: false,
      default: 'order.statuses'
    }
  },
  computed: {
    statuses() {
      return this.$store.getters[`${this.store}.list`];
    },
    name() {
      const status = this.lodash.find(this.statuses, { id: this.id });
      if (!status) {
        return this.id;
      }
      return status.name;
    },
    color() {
      switch (this.id) {
        case "Newish":
          return "dark";
        case "Paid":
        case "Finish":
          return "success";
        case "OutOfStock":
        case "Cancelled":
          return "danger";
        default:
          return "warning";
      }
    },
  },
};
</script>
