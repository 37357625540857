<template>
  <div class="d-flex align-items-center">
    <CDropdown
      color="primary"
      class="mr-1 w-auto"
      addMenuClasses="p-0 mt-1 overflow-hidden"
      :show.sync="show"
    >
      <template #toggler>
        <CButton variant="outline" color="primary" size="sm">
          <CIcon
            :name="optionSelected == 'user' ? 'cil-user' : 'cil-store'"
            style="width: 15px; height: 15px"
            size="custom"
          />
        </CButton>
      </template>
      <CDropdownItem
        v-for="option in options"
        :key="option.value"
        @click="optionSelected = option.value"
        :active="optionSelected == option.value"
        >{{ $t(option.label) }}</CDropdownItem
      >
    </CDropdown>
    <SSelectSupplier
      v-if="optionSelected == 'supplier'"
      :value="value"
      @update:value="$emit('update:value', $event)"
      @change="$emit('change', $event)"
      noCustomLabel
      prependAll
      class="w-100"
      @mousedown.native="show = false"
    />
    <SSelectCustomer
      v-else
      :value="value"
      @update:value="$emit('update:value', $event)"
      @change="$emit('change', $event)"
      noCustomLabel
      prependAll
      store="auth.user_recipient"
      class="w-100"
      @mousedown.native="show = false"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      options: [
        { value: "user", label: "User" },
        { value: "supplier", label: "Supplier" },
      ],
      optionSelected: "user",
      show: false,
    };
  },
  watch: {
    optionSelected() {
      this.$emit("update:value", "");
    },
  },
};
</script>
