<template>
  <div>
    <TMessage v-if="label" :content="label" bold class="mb-2" />

    <div class="d-inline-flex w-100" style="min-width: 200px">
      <TInputNumber
        :value="value.start"
        @keydown.enter.native="focusNext"
        @update:value="$emit('update:value', {...value, start: $event})"
        :maskOptions="maskOptions"
      />
      <TInputNumber
        :value="value.end"
        @keydown.enter.native="change"
        @update:value="$emit('update:value', {...value, end: $event})"
        ref="inputEnd"
        :maskOptions="maskOptions"
      />
    </div>
  </div>
</template>

<script>
// import MaskedInput from "vue-text-mask";
import mixin from "./mixin";

export default {
  mixins: [mixin],
  components: {
    // MaskedInput,
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({ start: 0, end: 0 }),
    },
    label: {
      type: String,
    },
    maskOptions: {
      type: Object,
      required: false,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    change() {
      this.$emit("change", this.value);
    },
    focusNext() {
      this.$refs.inputEnd.focus();
    },
  },
};
</script>
