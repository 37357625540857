import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'purchases',
  },
  {
    default_query: {
      include: "supplier,buyer",
    },
  },
).store()

export default resource
