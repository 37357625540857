import Resource from "@/core/store/Resource";

const resource = new Resource(
  {
    service: "auth",
    resource: "organizations",
    paginate: false
  },
).store();

export default resource;
