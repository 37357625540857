<template>
  <TSelectEditable
    v-if="editable"
    class="m-0"
    :options="list"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      'custom-label': customLabel,
      preserveSearch: true,
      ...selectOptions,
    }"
    :normalizer="{ label: 'name', value: 'name' }"
    :placeholder="placeholder"
    :loading="loading"
    @search="search"
    @close="$emit('close')"
    @change="$emit('change', $event)"
  />
  <TSelect
    v-else
    :label="label"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      'custom-label': customLabel,
      preserveSearch: true,
      ...selectOptions,
    }"
    :normalizer="{ label: 'name', value: 'name' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
    @close="$emit('close')"
    @change="$emit('change', $event)"
  />
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    prependAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
    prepend: {
      type: Object,
      required: false,
      default: function () {
        return { id: "", name: this.$t("All") };
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    selectOptions: {
      type: Object,
      require: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  created() {
    this.$store.dispatch("accounting.cards.fetch.if-first-time");
  },
  watch: {
    cardSelected: {
      immediate: true,
      handler(status) {
        this.valueSelected = status;
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "accounting.cards.list",
      loading: "accounting.cards.fetching",
    }),
    cardSelected() {
      return this.listC.find((x) => x.id === this.value);
    },
    listC() {
      const prepend = this.prependAll ? this.prepend : null;
      const list = this.lodash.uniqBy([prepend, ...this.list], "id");
      return this.lodash.filter(list, (x) => {
        return x != null;
      });
    },
    customOptionLabel() {
      return !this.noCustomLabel ? { "custom-label": this.customLabel } : {};
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
    customLabel({ name, bank }) {
      return bank ? `${name} – ${bank.name}` : `${name}`
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("accounting.cards.apply-query", {
        "filter[name]": param,
      });
    }, 300),
  },
};
</script>
