<template>
  <TModal
    title="Info"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="lg"
  >
    <template #actions> <span></span></template>
    <template #header-actions>
      <TButtonRefresh
        variant="ghost"
        @click="fetchBoxDetail"
        :options="{ disabled: box_fetching }"
      />
    </template>
    <template #title>
      <div class="d-flex">
        <TMessage content="Info" bold>
          <template #suffix>
            {{ box.id }}
          </template></TMessage
        >
        <SMessageBoxStatus :box="box" class="ml-1 my-auto" />
      </div>
    </template>
    <div v-if="box">
      <div v-if="box.tags && box.tags.length" class="d-flex flex-wrap mb-3">
        <TMessage content="Tags" bold class="mr-2 h5">
          <template #suffix>:</template>
        </TMessage>
        <div v-for="tag in box.tags" :key="tag.id" class="mr-1">
          <SMessageBoxTag :tag="tag" removable />
        </div>
      </div>

      <CRow>
        <CCol col="12" md="6" class="mb-3">
          <TTableAsForm
            :fields="boxFields"
            :data="box"
            :splitLeft="7"
            style="font-size: 16px"
            :addRowClasses="['pb-2']"
          >
            <template #updated_at="{ value }">
              <TMessageDateTime dateOnly :content="value" />
            </template>
            <template #closed_at="{ value }">
              <TMessageDateTime dateOnly :content="value" />
            </template>
            <template #number_days_storage="{ value }">
              <TMessageNumber :value="value" />
            </template>
            <template #length="{ value }">
              <TMessageNumber :value="value" suffix="cm" />
            </template>
            <template #width="{ value }">
              <TMessageNumber :value="value" suffix="cm" />
            </template>
            <template #height="{ value }">
              <TMessageNumber :value="value" suffix="cm" />
            </template>
            <template #volume="{ value }">
              <TMessageVolume :value="value" />
            </template>
            <template #weight="{ value }">
              <TMessageNumber :value="value" suffix="kg" />
            </template>
            <template #duplicate="{ value }">
              <TMessageNumber :value="value" />
            </template>
            <template #sfa_id="{ value }">
              <TMessage v-if="value" :content="value" bold noTranslate />
            </template>
            <template #tracking>
              <TMessage
                v-if="box.sfa"
                :content="box.sfa.tracking_code"
                bold
                noTranslate
              />
            </template>
          </TTableAsForm>
        </CCol>
        <CCol col="12" md="6" class="mb-3">
          <TTableAsForm
            :fields="quantityFields"
            :splitLeft="7"
            :data="box"
            style="font-size: 16px"
            :addRowClasses="['pb-2']"
          >
          </TTableAsForm>
        </CCol>
        <CCol col="12" md="6" class="mb-3" v-if="refFields.length">
          <TTableAsForm
            title="Reference"
            :fields="refFields"
            :splitLeft="7"
            :data="box"
          >
            <template #box_parent_id="{ value }">
              <TLink
                v-if="value"
                :content="value"
                bold
                :to="lodash.getReferenceLink('box', value)"
              />
            </template>
            <template #box_ref_id="{ value }">
              <TLink
                v-if="value"
                :content="value"
                bold
                :to="lodash.getReferenceLink('box', value)"
              />
            </template>
          </TTableAsForm>
        </CCol>
      </CRow>
      <div v-if="box.items && box.items.length">
        <hr />
        <TMessage content="Goods" bold uppercase>
          <template #suffix>:</template>
        </TMessage>
        <div class="mt-2" v-for="item in box.items" :key="item.product_id">
          <CRow style="w-100">
            <CCol col="5">
              <SCardProductItems widthAuto :item="item.product" />
            </CCol>
            <CCol col="3">
              <TMessage content="Quantity" />
              <TMessageNumber :value="item.quantity" bold />
            </CCol>
            <CCol col="3">
              <TMessage content="Expiration date" />
              <TMessageDateTime :content="item.expiry_date" dateOnly />
            </CCol>
          </CRow>
        </div>
      </div>
      <div v-if="box.owners && box.owners.length">
        <hr />
        <TMessage content="Distribute" bold uppercase class="mb-2">
          <template #suffix>:</template>
        </TMessage>
        <CRow v-if="box.owners && box.owners.length">
          <CCol
            col="4"
            v-for="order in box.owners"
            :key="order.id"
            class="mb-2"
          >
            <CCard accentColor="info" class="p-3 mb-0">
              <TLink
                v-if="order.order_id"
                :content="order.order_id"
                :messageOptions="{ bold: true }"
                :to="
                  lodash.getReferenceLink('assemble-to-order', order.order_id)
                "
                class="h4 m-0"
              />
              <TMessage
                :content="order.user ? order.user.name : order.user_id"
                bold
                noTranslate
              />
              <TMessage content="Distribution quantity">
                <template #suffix
                  >:
                  <CBadge color="info" style="font-size: 13px">
                    {{ order.quantity }}
                  </CBadge>
                </template>
              </TMessage>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </div>
    <TMessageNoData v-else />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    box: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      data: {},
      box_fetching: false,
      boxFields: [
        { key: "length", label: "Length" },
        { key: "width", label: "Width" },
        { key: "height", label: "Height" },
        { key: "volume", label: "Volume" },
        { key: "weight", label: "Weight" },
        { key: "duplicate", label: "Quantity" },
        { key: "sfa_id", label: "SFA" },
        { key: "tracking", label: "Tracking Id" },
        { key: "updated_at", label: "Updated at" },
        { key: "closed_at", label: "Packed date" },
        { key: "number_days_storage", label: "Total days of storage" },
      ],
      quantityFields: [
        {
          key: "quantity_in_inventory",
          label: "Quantity in stock",
        },
        {
          key: "quantity_inventory",
          label: "Inventory number",
        },
        { key: "quantity_in_pallets", label: "Quantity in pallets" },
        { key: "quantity_of_owners", label: "Distributed quantity" },
        {
          key: "quantity_in_goods_delivery",
          label: "Quantity in goods delivery",
        },
        { key: "quantity_in_picker", label: "Quantity in cont" },
        { key: "quantity_out_picker", label: "Quantity out cont" },
        { key: "quantity_outbound", label: "Quantity outbound" },
      ],
      refFields: [],
    };
  },
  watch: {
    box: {
      immediate: true,
      handler(box) {
        this.refFields = [];
        if (box?.box_ref_id) {
          this.refFields.push({ key: "box_ref_id", label: "Reference" });
        }
        if (box?.box_parent_id) {
          this.refFields.push({ key: "box_parent_id", label: "Combine box" });
        }
      },
    },
  },
  methods: {
    fetchBoxDetail() {
      this.box_fetching = true;
      this.$tomoni.warehouse.boxes
        .get(this.box.id, {
          include: "owners,sfa,children,tags,items,owners.user,items.product",
        })
        .then((data) => {
          this.$emit("update:data", data.data);
        })
        .finally(() => {
          this.box_fetching = false;
        });
    },
  },
};
</script>
