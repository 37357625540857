<template>
  <TModal
    title="Add tags"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="add"
    :creating="tag ? false : true"
  >
    <template #actions>
      <TButton
        content="Create"
        :options="{ disabled: loading || !tag }"
        icon="cil-plus"
        @click="add"
      />
    </template>
    <SSelectBoxTags
      :prepend="[]"
      :value.sync="tag"
      @update:value="tag = $event"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    box_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tag: "",
      loading: false,
    };
  },
  methods: {
    add() {
      this.loading = true;
      const suffix = this.box_id ? "attach-tags" : "detail.attach-tags";
      const tag = { "tag_ids[0]": this.tag };
      const attributes = this.box_id
        ? { id: this.box_id, attributes: tag }
        : tag;
      this.$store
        .dispatch("warehouse.boxes_management." + suffix, attributes)
        .then((data) => {
          this.$emit("created", data);
        })
        .finally(() => {
          this.tag = "";
          this.loading = false;
          this.$emit("update:show", false);
        });
    },
  },
};
</script>
