<template>
  <div>
    <SModalPriceCondition
      ref="modalPriceCondition"
      @changed="$emit('changed')"
    />
    <TMessage
      v-bind="messageOptions"
      :content="getConditionTitleByKey(condition.key)"
      :italic="editable"
      :editable="editable"
      :creatable="creatable"
      :removable="conditionRemovable"
      @click-edit="showModalPriceCondition"
      @click-remove="showModalConfirm = true"
      :addClasses="['d-flex', 'justify-content-between']"
    >
      <template #suffix>
        <b class="text-uppercase ml-3 text-right">{{
          translateConditionValue(condition)
        }}</b>
      </template>
    </TMessage>
    <TModalConfirm
      class="text-dark"
      v-if="conditionRemovable"
      :show.sync="showModalConfirm"
      @click-confirm="$emit('click-remove')"
    />
  </div>
</template>

<script>
import mixin from "@/core/components/T/Button/actions";
import { mapGetters } from "vuex";

export default {
  mixins: [mixin],
  props: {
    condition: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    messageOptions: {
      type: Object,
      required: false,
    },
    update: {
      type: Boolean,
      required: false,
      default: false,
    },
    groupConditionType: {
      type: String,
      default: "shipping-fee",
    },
  },
  data() {
    return {
      showModalConfirm: false,
    };
  },
  computed: {
    conditionRemovable() {
      return this.condition?.key !== "unit";
    },
    ...mapGetters({
      shipment_methods: "warehouse.shipment_methods.list",
      areas: "warehouse.areas.list",
      freight_charge_types: "warehouse.freight_charge_types.list",
    }),
  },
  methods: {
    showModalPriceCondition() {
      this.$refs.modalPriceCondition.open(
        { ...this.condition },
        this.groupConditionType
      );
    },
    getConditionTitleByKey(key) {
      let title = key.replace(/[^a-zA-Z0-9]/g, " ");

      return title.charAt(0).toUpperCase() + title.slice(1);
    },
    translateConditionValue(condition) {

      const { key, value } = condition;

      if (["shipment-method"].includes(key))
        return this.shipment_methods.find((i) => i.id === value)?.name || value;
      if (["from", "to"].includes(key))
        return this.areas.find((i) => i.id === value)?.name || value;
      if (["unit"].includes(key)) {
        let matched_charge_type = this.freight_charge_types.find(
          (i) => i.id === value
        );

        return matched_charge_type?.name || value;
      }

      return value;
    },
  },
};
</script>
