import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'purchase_statuses',
    paginate: false
  },
  {
    default_detail: {
      id: null,
      name: null,
    }
  }
).store()

export default resource
