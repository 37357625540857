<template>
  <div class="d-flex mb-2">
    <TInputText
      :placeholder="placeholder"
      :inputOptions="{ size: 'lg' }"
      :disabled="disabled"
      class="w-100"
      @keyup.enter.native="pressEnter"
      :value.sync="input"
    />
    <TInputNumber
      v-if="showQuantity"
      :value.sync="quantity"
      size="lg"
      :maskOptions="{ integerLimit: 4 }"
      style="width: 100px !important"
      class="ml-1"
      @keyup.enter.native="pressEnter"
    />
  </div>
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Add SKU",
    },
    showQuantity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: null,
      quantity: 1,
    };
  },
  methods: {
    pressEnter() {
      this.$emit("enter", this.input, this.quantity);
      this.input = null;
      this.quantity = 1;
    },
  },
};
</script>
