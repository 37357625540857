<template>
  <CInputRadioGroup
    :options="list"
    :checked="warehouse_scope"
    @update:checked="selectWarehouse"
    custom
  />
</template>

<script>
import warehouseScope from "@/core/services/tomoni/warehouse_scope.local";

export default {
  computed: {
    list() {
      return [
        {
          value: "",
          label: this.$t("All"),
        },
        ...this.$store.getters["warehouse.areas.list"]?.map((area) => {
          return {
            value: area.id,
            label: area.name,
          };
        }),
      ];
    },
    warehouse_scope() {
      return warehouseScope.get();
    },
  },
  methods: {
    selectWarehouse(area_id) {
      warehouseScope.set(area_id);
      window.location.reload();
    },
  },
};
</script>
