<template>
  <TSelectEditable
    v-if="editable"
    class="m-0 account-select"
    :options="list"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: true,
      preserveSearch: true,
      ...customOptionLabel,
      ...selectOptions,
    }"
    :normalizer="{ label: 'id', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @close="$emit('close')"
    @change="$emit('change', $event)"
  />
  <TSelect
    v-else
    :label="label"
    class="m-0 account-select"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: true,
      preserveSearch: true,
      ...customOptionLabel,
      ...selectOptions,
    }"
    :normalizer="{ label: 'id', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
  >
    <template #singleLabel="{ option }">
      <TMessage
        :content="option.id ? option.id : option.name"
        noTranslate
        :truncate="1"
        size="small"
      />
    </template>
  </TSelect>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
    prepend: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    noCustomLabel: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Object,
      require: false,
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  watch: {
    valueObject: {
      immediate: true,
      handler(value) {
        this.valueSelected = value;
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "accounting.accounts.list",
      loading: "accounting.accounts.fetching",
    }),
    customOptionLabel() {
      return !this.noCustomLabel ? { "custom-label": this.customLabel } : {};
    },
    listC() {
      return [...this.prepend, ...this.list];
    },
    valueObject() {
      return this.listC.find((x) => x.id === this.value);
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
    customLabel({ id, name }) {
      return id ? `${id} – ${name}` : `${name}`;
    },
  },
};
</script>
