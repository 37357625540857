<template>
  <CRow v-if="tiny" class="mb-0">
    <CCol col="12">
      <CRow class="flex-wrap">
        <CCol col="6" class="my-auto">
          <div class="d-flex">
            <SMessageShipmentMethod
              v-if="showShipmentMethod"
              :id="lading_bill.shipment_method_id"
              iconOnly
            />
            <TMessage
              :content="lading_bill.id"
              bold
              noTranslate
              size="h5"
              :truncate="1"
            />
          </div>
        </CCol>
        <CCol sm="12" md="6">
          <SMessageContainer
            v-if="lading_bill.container"
            :container="lading_bill.container"
            class="float-right ml-auto"
          />
        </CCol>
        <CCol col="12" class="my-auto">
          <TMessage
            v-if="lading_bill.customer"
            :content="lading_bill.customer.name || lading_bill.customer.email"
            bold
            noTranslate
            :truncate="1"
          />
          <TMessage v-else content="..." noTranslate />
        </CCol>
      </CRow>
    </CCol>
    <CCol>
      <div class="d-flex flex-wrap">
        <div>
          <TMessage content="Volume" :addClasses="['d-flex flex-wrap']">
            <template #suffix>
              (m3):
              <TMessageVolume
                class="ml-1"
                :value="lading_bill.volume"
                suffix=""
              />
            </template>
          </TMessage>
          <TMessage content="Weight" :addClasses="['d-flex flex-wrap']">
            <template #suffix>
              (kg):
              <TMessageNumber class="ml-1" :value="lading_bill.weight" />
            </template>
          </TMessage>
        </div>
        <TMessageDateTime
          small
          title="Created at"
          :content="lading_bill.created_at"
          class="ml-auto"
        />
      </div>
    </CCol>
  </CRow>
  <CCard v-else>
    <CCardHeader
      class="d-flex align-items-center"
      :class="checkHasShipmentMethodDiff && 'list-group-item-danger'"
    >
      <SMessageShipmentMethod
        v-if="showShipmentMethod"
        :id="lading_bill.shipment_method_id"
        iconOnly
      />
      <TMessage :content="lading_bill.id" bold noTranslate size="h5 my-auto" />
      <span class="mx-1">-</span>
      <TMessage
        :content="
          lading_bill.customer
            ? lading_bill.customer.name
            : lading_bill.customer_id
        "
        bold
        noTranslate
        class="h5 my-auto"
      />
      <TMessage
        class="mx-3"
        content="Volume"
        :addClasses="['d-flex flex-wrap']"
      >
        <template #suffix>
          (m3):
          <TMessageVolume class="ml-1" :value="lading_bill.volume" suffix="" />
        </template>
      </TMessage>
      <TMessage
        class="mx-3"
        content="Weight"
        :addClasses="['d-flex flex-wrap']"
      >
        <template #suffix>
          (kg):
          <TMessageNumber class="ml-1" :value="lading_bill.weight" />
        </template>
      </TMessage>
      <div class="ml-auto d-flex">
        <TButtonEnter
          :to="lodash.getReferenceLink('lading_bill', lading_bill.id)"
          class="mx-1"
        />
        <TButtonRemove v-if="removable" @click="$emit('remove', lading_bill)" />
        <TMessageDateTime
          v-else
          small
          title="Created at"
          :content="lading_bill.created_at"
        />
      </div>
    </CCardHeader>
    <CCardBody class="scroll-x">
      <TMessageNotFound v-if="!lading_bill.pivot_boxes.length" />
      <div class="d-inline-flex" v-if="quantity_focus === 'distribution'">
        <SCardSku
          v-for="box in lading_bill.pivot_boxes"
          :key="box.id"
          :box="{
            ...box,
            id: box.box_id,
            box_lading_bill_id: box.id,
            ...getBox(box.box_id),
          }"
          class="mx-2"
          refresh
          :focusQuantity="box.quantity"
          focusQuantityDescription="Quantity in lading bill"
          mainQuantityDescription="Total number of boxes"
          :showWeight="false"
          :showSfa="false"
          :ownerId="lading_bill.customer_id"
          :orderId="box.order_id"
          :ladingBillId="lading_bill.id"
          @decoupe-box-ldb="$emit('updated')"
        >
          <template #append-content>
            <div v-if="box.order_id" class="d-flex justify-content-center">
              <SMessageShipmentMethod
                v-if="box.order"
                :id="box.order.shipment_method_id"
                iconOnly
              />
              <CBadge color="primary" class="h6">
                {{ box.order_id }}
              </CBadge>
            </div>
          </template>
          <template #main-quantity>
            <TMessageNumber :value="getQuantityBox(box.box_id)" />
          </template>
          <template #append-extend-actions>
            <CDropdownItem @click="onClickRemoveLdb(box.id)">
              <TMessage content="Remove from lading bill" />
            </CDropdownItem>
          </template>
        </SCardSku>
        <TModalConfirm
          v-if="showModalRemoveBoxFromLdb"
          :show.sync="showModalRemoveBoxFromLdb"
          @click-confirm="confirmRemoveLadingBill"
        />
      </div>
      <div class="d-inline-flex" v-if="quantity_focus === 'in_picker'">
        <SCardSku
          v-for="box in lading_bill.boxes"
          :key="box.id"
          :box="box"
          class="mx-2"
          refresh
          :focusQuantity="box.quantity_in_picker"
          focusQuantityDescription="Picked"
          focusQuantityColor="success"
          :showWeight="false"
          :showSfa="false"
        >
          <template #append-content>
            <div class="d-flex justify-content-center">
              <SMessageShipmentMethod
                v-if="box.order"
                :id="box.order.shipment_method_id"
                iconOnly
              />
              <CBadge color="primary" class="h6">
                {{ box.order_id }}
              </CBadge>
            </div>
          </template>
        </SCardSku>
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    lading_bill: {
      type: Object,
      required: true,
    },
    tiny: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    quantity_focus: {
      type: String,
      default: "distribution",
      validator: (value) => {
        return ["distribution", "in_picker"].includes(value);
      },
    },
    showShipmentMethod: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showModalRemoveBoxFromLdb: false,
      box_ldb_id_selected: "",
    };
  },
  computed: {
    checkHasShipmentMethodDiff() {
      return this.lading_bill?.pivot_boxes
        .filter((x) => x.order_id)
        .some((x) => {
          return (
            x?.order?.shipment_method_id != this.lading_bill.shipment_method_id
          );
        });
    },
  },
  methods: {
    getQuantityBox(sku) {
      return this.lading_bill.boxes.find((pb) => pb.id === sku).duplicate;
    },
    getBox(box_id) {
      const box = this.lading_bill.boxes.find((x) => x.id == box_id);
      return box ? box : {};
    },
    onClickRemoveLdb(box_lading_bill_id) {
      this.showModalRemoveBoxFromLdb = true;
      this.box_ldb_id_selected = box_lading_bill_id;
    },
    confirmRemoveLadingBill() {
      this.removeBoxFromLdb();
      this.showModalRemoveBoxFromLdb = false;
    },
    removeBoxFromLdb() {
      this.$store
        .dispatch("warehouse.box_lading_bills.delete", this.box_ldb_id_selected)
        .then(() => {
          this.$emit("updated");
          this.box_ldb_id_selected = "";
        });
    },
  },
};
</script>
