import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'boxes'

export default {
  attachTags(id, attributes) {
    return SERVICE.postFormData(`api/boxes/${id}/attach-tags`, attributes);
  },
  detachTags(id, attributes) {
    return SERVICE.delete('api/boxes', `${id}/detach-tags`, attributes);
  },
  open(id) {
    return SERVICE.put(`api/${RESOURCE}/${id}/open`);
  },
  close(id) {
    return SERVICE.put(`api/${RESOURCE}/${id}/close`);
  },
};