import printer_url from '../services/tomoni/printer_url.local'
const state = {
  isConnected: false,
  socket: {},
  url: ""
};

const getters = {
  ["printer.url"](state) {
    return state.url
  },
  ["printer.value"](state) {
    return state.socket
  },
  ["printer.isConnected"](state) {
    return state.isConnected
  }
};

const mutations = {
  ["printer.set-state"](state, value) {
    state.isConnected = value || false;
  },
  ["printer.set-socket"](state, value) {
    state.socket = new WebSocket(value)
  },
  ["printer.set-url"](state, value) {
    state.url = value
  },
};

const actions = {
  ["printer.connect"](context, url) {
    context
      .dispatch("printer.change-url", url).then(() => {
        return new Promise((resolve, reject) => {
          context.getters['printer.value'].onopen = () => {
            context.commit("printer.set-state", true);
            context.commit("toasts.push", {
              message: "Successful connection",
              type: "success",
            })
            resolve()
          }
          setTimeout(() => {
            const readyState = context.getters['printer.value'].readyState
            const OPEN = context.getters['printer.value'].OPEN
            if (readyState !== OPEN) {
              context.commit("printer.set-state", false);
              context.commit("toasts.push", {
                message: "Check the connection again",
                type: "danger",
              })
              reject()
            }
          }, 1000);
        })
      })
  },
  ["printer.first-connect"](context) {
    if (!printer_url?.get()) {
      return
    }
    if (context.getters['printer.isConnected']) {
      return
    }
    context.commit("printer.set-url", printer_url?.get())
    context
      .dispatch("printer.change-url", context.getters['printer.url']).then((value) => {
        context.commit("printer.set-socket", value)
        return new Promise((resolve,) => {
          context.getters['printer.value'].onopen = () => {
            context.commit("printer.set-state", true);
            context.commit("toasts.push", {
              message: "Successful connection",
              type: "success",
            })
            resolve()
          }
        })
      })
  },
  ["printer.send-message"](context, message) {
    if (context.getters['printer.value'].readyState !== context.getters['printer.value'].OPEN) {
      context.commit("toasts.push", {
        message: "The printer does not work, check the connection again",
        type: "warning",
      })
    } else {
      context.getters['printer.value'].send(message);
    }
  },
  ["printer.change-url"](context, value) {
    return new Promise((resolve, reject) => {
      var regex = /(^http|^https|^ws|^wss):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\\/]))?/;
      if (!regex.test(value)) {
        context.commit("toasts.push", {
          message: "Invalid IP address",
          type: "warning",
        });
        reject()
      } else {
        if (context.getters['printer.url'] !== value) {
          context.commit("printer.set-url", value)
          context.commit("printer.set-socket", value)
          printer_url.set(value)
        }
        resolve(value)
      }
    })
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
