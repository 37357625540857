<template>
  <TButton
    icon="cil-clear-all"
    tooltip="Clear"
    @click="$emit('click')"
    v-bind="options"
    :color="color"
    :variant="variant"
    :size="size"
  />
</template>

<script>
import mixin from "./mixin";

export default {
  mixins: [mixin],
};
</script>
