<template>
  <TMessage :content="user.name" noTranslate />
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>