<template>
  <div>
    <TMessage content="Select country" class="mb-2" bold />
    <CInputRadioGroup
      class="pt-1"
      :options="options"
      @update:checked="$emit('update:value', $event)"
      inline
      custom
    />
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("helper.countries.fetch.if-first-time");
  },

  computed: {
    list() {
      return this.$store.getters["helper.countries.list"];
    },

    options() {
      return this.list.map((x) => {
        let newObj = this.lodash.mapKeys(x, (value, key) => {
          switch (key) {
            case "id":
              return "value";
            case "name":
              return "label";
          }
        });
        return newObj;
      });
    },
  },
};
</script>