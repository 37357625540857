<template>
  <TSelect
    :label="label"
    class="m-0"
    placeholder="Select"
    :options="options"
    :value="valueSelected"
    :selectOptions="selectOptions"
    @select="onSelect"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    selectOptions: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      require: false,
    },
    options: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.checkValue(value);
      },
    },
    valueSelected: {
      immediate: false,
      handler(value) {
        if (value) {
          this.$emit("update:value", value.id);
          this.$emit("change", value);
        }
      },
    },
  },
  methods: {
    onSelect(value) {
      this.valueSelected = value;
    },
    checkValue(value) {
      const valueSelected = this.options.find((x) => x.id === value);
      this.valueSelected = valueSelected;
    },
  },
};
</script>
