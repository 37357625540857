<template>
  <TModal
    title="Create SFA"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
      />
    </template>

    <CRow>
      <CCol col="12" lg="7">
        <SInputTracking @update:value="data.tracking_code = $event" />
      </CCol>
      <CCol col="12" lg="5" class="mb-3">
        <TInputNumber
          label="Total quantity"
          @update:value="data.quantity = $event"
        />
        <TInputNumber
          label="Shipping fee"
          class="my-3"
          @update:value="data.shipping_fee = $event"
        />
        <TInputText
          label="Discount code"
          :inputOptions="{
            addLabelClasses: 'font-weight-bold',
          }"
          class="my-3"
          @update:value="data.coupon = $event"
        />
        <TInputDateTime
          label="Arrival date"
          class="my-3"
          :value="data.arrival_date"
          @update:value="data.arrival_date = $event"
        />
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("warehouse.sfas.create", this.data)
        .then((data) => {
          this.$emit("created", data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
