import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'goods-deliveries'

export default {
  attachAttachment(id, params) {
    return SERVICE.postFormData(`api/${RESOURCE}/${id}/attachment`, params);
  },
  detachAttachment(id, params) {
    return SERVICE.delete(`api/${RESOURCE}/${id}`, 'attachment', params);
  },
}
