<template>
  <div>
    <TButton
      icon="cil-trash"
      :tooltip="tooltip"
      @click="remove"
      v-bind="options"
      color="danger"
      :variant="variant"
      :size="size"
    />
    <TModalConfirm :show.sync="showModalConfirm" @click-confirm="confirm" />
  </div>
</template>

<script>
import mixin from "./mixin";

export default {
  mixins: [mixin],
  props: {
    noConfirm: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "Delete",
    },
  },
  data() {
    return {
      showModalConfirm: false,
    };
  },
  methods: {
    remove() {
      if (!this.noConfirm) this.showModalConfirm = true;
      else this.$emit("click");
    },
    confirm() {
      this.$emit("click");
      this.showModalConfirm = false;
    },
  },
};
</script>
