import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'helper',
    resource: 'price_timelines',
  },
).store()

export default resource;
