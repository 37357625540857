<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="list"
    :value="valueSelected"
    @select="select"
    :selectOptions="selectOptions"
  />
</template>

<script>
export default {
  props: {
    prepend: {
      type: Array,
      required: false,
      default: function () {
        return [{ id: "", name: this.$t("All") }];
      },
    },
    value: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
    label: {
      type: String,
      required: false,
    },
    selectOptions: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.valueSelected = this.type;
      },
    },
    list: {
      immediate: true,
      handler() {
        this.valueSelected = this.type;
      },
    },
  },
  computed: {
    list() {
      return [
        ...this.prepend,
        ...this.$store.getters["accounting.transaction_types.list"],
      ];
    },
    type() {
      return this.list.find((x) => x.id === this.value);
    },
  },
  methods: {
    select(type) {
      this.valueSelected = type;
      this.$emit("update:value", type.id);
      this.$emit("change");
    },
  },
};
</script>
