<template>
  <TButton
    :disabled="sending"
    color="primary"
    @click="resetPassword()"
    :variant="variant"
    icon="cilReload"
    content="Reset password"
  />
</template>
<script>
export default {
  props: {
    email: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      sending: false,
    };
  },
  methods: {
    resetPassword() {
      this.sending = true;
      if (this.email) {
        this.$store
          .dispatch("auth.send_mail.reset_password", this.email)
          .finally(() => {
            this.sending = false;
          });
      } else {
        this.$store.dispatch("auth.me.send_mail.reset_password").finally(() => {
          this.sending = false;
        });
      }
    },
  },
};
</script>
