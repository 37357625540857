import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'functions/cancel-distributed'

export default {
  cancel(owning_box_id) {
    return SERVICE.post(`api/${RESOURCE}/${owning_box_id}`);
  },
};
