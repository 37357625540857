<template>
  <CCard
    :style="'width:' + (itemsVisible && showPallet ? '210px' : '180px')"
    :class="border ? `border-${border}` : ''"
    v-if="box"
  >
    <CCardHeader class="d-flex">
      <div>
        <slot name="prepend-quantity"> </slot>
        <CBadge
          style="font-size: 85%"
          v-if="focusQuantity >= 0"
          :color="editing ? '' : focusQuantityColor"
          :title="$t(focusQuantityDescription)"
        >
          <TMessageNumber
            :value="focusQuantity"
            :editable="focusQuantityEdiable"
            @change="$emit('focus-quantity:change', $event)"
            @update:editing="editing = $event"
          />
        </CBadge>
        <strong v-if="focusQuantity >= 0"> / </strong>
        <CBadge
          style="font-size: 85%"
          :color="mainEditing ? '' : 'info'"
          :title="$t(mainQuantityDescription)"
        >
          <slot name="main-quantity">
            <TMessageNumber
              :value="data.duplicate"
              :editable="editable"
              @update:editing="mainEditing = $event"
              @change="updateDuplicate"
            />
          </slot>
        </CBadge>
        <CBadge
          v-if="showStatusBox && data.items && !editing && !mainEditing"
          :color="data.closed_at ? 'success' : 'danger'"
          class="my-auto ml-2"
        >
          <TMessage :content="data.closed_at ? 'Closed box' : 'Open box'" />
        </CBadge>
      </div>
      <div v-if="!editing && !mainEditing" class="ml-auto d-flex">
        <div v-if="refresh" class="ml-2 text-info">
          <TSpinner v-if="box_fetching" :loading="box_fetching" />
          <CLink v-else :title="$t('Refresh')" @click="fetchSku()">
            <CIcon name="cil-reload" width="16" />
          </CLink>
        </div>

        <div v-if="removable" class="ml-2">
          <TSpinner v-if="removing" :loading="removing" />
          <CLink
            v-else
            :title="$t('Remove')"
            class="text-danger"
            @click="removeHandle"
          >
            <CIcon name="cil-x-circle" width="16" />
          </CLink>
        </div>

        <CLink
          v-if="editable"
          class="ml-2 text-info"
          :title="$t('Edit')"
          @click="showModalEdit = true"
        >
          <CIcon name="cil-pencil" width="16" />
        </CLink>
        <CLink
          v-if="deletable"
          class="ml-2 text-danger"
          :title="$t('Delete')"
          @click="showModalDelete = true"
          :disabled="box_fetching"
        >
          <CIcon name="cil-trash" width="16" />
        </CLink>
        <TModalConfirm
          v-if="showModalDelete"
          :show.sync="showModalDelete"
          @click-confirm="confirm"
        />
        <SModalCreateSku
          v-if="showModalEdit"
          :sku="box"
          :show.sync="showModalEdit"
        />
        <slot name="append-actions"> </slot>
      </div>
      <CLink
        v-if="enterable"
        :title="$t('Enter')"
        class="ml-2 text-primary"
        :to="lodash.getReferenceLink('box', data.id)"
      >
        <CIcon name="cil-arrow-right" width="16" />
      </CLink>
    </CCardHeader>
    <CCardBody class="p-2">
      <div v-if="showSfa" class="d-flex justify-content-center">
        <TMessage
          v-if="sfa"
          :content="data.sfa_id"
          title="SFA"
          color="muted"
          bold
          noTranslate
        />
      </div>
      <div class="d-flex justify-content-center">
        <TMessage
          :content="data.id"
          bold
          size="h4"
          noTranslate
          :addClasses="['mb-0']"
          title="SKU"
        />
        <CDropdown
          :inNav="false"
          class="c-header-nav-items"
          placement="right-end"
          add-menu-classes="p-0"
          v-if="showExtendFunc"
        >
          <template #toggler>
            <CHeaderNavLink class="ml-1">
              <CIcon name="cis-info-circle" width="15" />
            </CHeaderNavLink>
          </template>
          <CDropdownItem @click.stop="showModalInfo = !showModalInfo">
            <TMessage content="Info" />
          </CDropdownItem>
          <CDropdownItem @click.stop="showModalAddTags = !showModalAddTags">
            <TMessage content="Add tags" />
          </CDropdownItem>
          <CDropdownItem
            v-if="orderId && ownerId"
            @click.stop="showModalAddSkuToGd = !showModalAddSkuToGd"
          >
            <TMessage content="Add to goods delivery" />
          </CDropdownItem>
          <CDropdownItem
            v-if="ladingBillId"
            @click.stop="showModalTransferLdb = !showModalTransferLdb"
          >
            <TMessage content="Transfer the bill of lading" />
          </CDropdownItem>
          <slot name="append-extend-actions"></slot>
        </CDropdown>
      </div>
      <div
        v-if="showWeight"
        class="d-flex justify-content-center"
        :title="`${$t('Length')}(cm) x ${$t('Width')}(cm) x ${$t(
          'Height'
        )}(cm) | ${$t('Weight')}(kg)`"
      >
        {{ data.length }} x {{ data.width }} x {{ data.height }} |
        {{ data.weight }}
      </div>
      <TMessageDateTime
        v-if="showDateTime"
        :content="data.created_at"
        class="d-flex justify-content-center"
      />
      <div v-if="showOwner && owners && owners.length">
        <div
          v-for="owner in owners"
          :key="owner.id"
          class="d-flex justify-content-center"
        >
          <SMessageShipmentMethod
            v-if="owner.order"
            :id="owner.order.shipment_method_id"
            iconOnly
          />
          <CBadge color="primary" class="h6 mb-1">
            {{
              owner.order_id
                ? owner.order_id
                : owner.user
                ? owner.user.name
                : owner.user_id
            }}
          </CBadge>
        </div>
      </div>
      <slot name="append-content"> </slot>
      <TMessage
        v-if="showStorageTime && lodash.has(data, 'number_days_storage')"
        :content="data.number_days_storage"
        bold
        class="d-flex justify-content-center"
      >
        <template #prefix> {{ $t("Total days of storage") }}: </template>
      </TMessage>
      <div class="d-flex scroll-x scroll-hidden" v-if="data.tags">
        <SMessageBoxTag
          v-for="tag in tags"
          :key="tag.id"
          :tag="tag"
          class="mr-1"
        />
      </div>
    </CCardBody>
    <CCardFooter v-if="!noItems && !data.is_parent_box && data.items">
      <div class="d-flex">
        <TMessage content="items" color="muted" class="font-xs my-auto" bold>
          <template #prefix>
            {{ data.items.length }}
          </template>
        </TMessage>
      </div>
    </CCardFooter>
    <CCardFooter v-if="$slots['footer']">
      <slot name="footer"></slot>
    </CCardFooter>
    <CCardFooter v-if="showPallet && data.pallets">
      <CLink
        v-if="data.pallets.length"
        class="float-right ml-3 my-auto"
        @click="showSelectPallet = !showSelectPallet"
      >
        <CIcon name="cis-chart-table" width="15" :title="$t('Select pallet')" />
      </CLink>
      <span v-if="data.pallet_id">
        <CBadge color="success" class="my-auto">
          {{ data.pallet_id }}
        </CBadge>
      </span>
      <TMessage
        v-if="data.pallets.length && !data.pallet_id"
        content="pallets"
        color="muted"
        class="font-xs"
        bold
        noTranslate
      >
        <template #prefix>
          {{ data.pallets.length }}
        </template>
      </TMessage>
      <span v-if="!data.pallets.length && !data.pallet_id" class="d-flex">
        <TMessage content="In stock" bold />
        <span class="mx-1">-</span>
        <CBadge color="info" class="my-auto">
          {{ data.quantity_in_inventory }}
        </CBadge>
      </span>
      <TModal
        title="Select pallet"
        v-if="showSelectPallet"
        :show.sync="showSelectPallet"
        @update:show="$emit('update:show', $event)"
      >
        <template #actions> <span></span></template>
        <CRow>
          <CCol
            col="12"
            md="6"
            v-for="pallet in data.pallets"
            :key="pallet.id"
            @click="selectPallet(pallet.id)"
            style="cursor: pointer"
          >
            <div
              class="card p-3"
              :class="{
                'border-success': data.pallet_id == pallet.id,
              }"
            >
              <TMessage
                :content="pallet.id"
                size="h4"
                color="primary"
                noTranslate
              />
              <SMessagePalletLocation
                lass="mr-auto"
                v-if="pallet.location"
                :location="pallet.location"
              />
              <CBadge color="warning" class="mr-auto" v-else>
                {{ $t("Pallets have not been stored") }}
              </CBadge>
            </div>
          </CCol>
        </CRow>
      </TModal>
    </CCardFooter>
    <CCardFooter v-if="showChildren && data.children && data.children.length">
      <div class="d-flex">
        <TMessage
          content="child boxes"
          color="muted"
          class="font-xs my-auto"
          bold
        >
          <template #prefix>
            {{ data.children.length }}
          </template>
        </TMessage>
        <div class="ml-auto ml-3">
          <CLink
            v-if="data.children.length"
            class="ml-1"
            @click="showModalViewChildren = true"
          >
            <CIcon name="cil-eye" width="16" :title="$t('View')" />
          </CLink>
        </div>
      </div>
      <TModal
        title="Child box"
        v-if="showModalViewChildren"
        :show.sync="showModalViewChildren"
      >
        <template #actions> <span></span></template>
        <CRow class="mx-3">
          <SCardSku
            v-for="box in data.children"
            :key="box.id"
            :box="box"
            enterable
            refresh
            class="mx-3"
          />
        </CRow>
      </TModal>
    </CCardFooter>
    <SModalInfoSku
      v-if="showModalInfo"
      :show.sync="showModalInfo"
      :box="data"
      @update:data="data = { ...data, ...$event }"
    />
    <SModalAddTagSku
      v-if="showModalAddTags"
      :show.sync="showModalAddTags"
      :box_id="box.id"
      @created="data = { ...data, ...$event }"
    />
    <SModalAddSkuToGoodsDelivery
      v-if="showModalAddSkuToGd"
      :show.sync="showModalAddSkuToGd"
      :boxId="box.id"
      :orderId="orderId"
      :customerId="ownerId"
      @created="data = { ...data, ...$event }"
    />
    <SModalTransferBoxLadingBill
      v-if="showModalTransferLdb"
      :show.sync="showModalTransferLdb"
      :boxId="box.id"
      :orderId="orderId"
      :ladingBillId="ladingBillId"
      @decoupe-box-ldb="$emit('decoupe-box-ldb')"
    />
  </CCard>
</template>

<script>
export default {
  props: {
    box: {
      type: Object,
      required: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    removing: {
      type: Boolean,
      default: false,
    },
    sfa: {
      type: Boolean,
      default: true,
    },
    focusQuantity: {
      type: Number,
      required: false,
      default: -1,
    },
    focusQuantityDescription: {
      type: String,
      required: false,
      default: "Quantity",
    },
    mainQuantityDescription: {
      type: String,
      required: false,
      default: "Quantity",
    },
    focusQuantityColor: {
      type: String,
      required: false,
      default: "primary",
    },
    focusQuantityEdiable: {
      type: Boolean,
      default: false,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    border: {
      type: String,
      required: false,
    },
    noItems: {
      type: Boolean,
      default: false,
    },
    showPallet: {
      type: Boolean,
      default: false,
    },
    showStatusBox: {
      type: Boolean,
      default: false,
    },
    showDateTime: {
      type: Boolean,
      default: false,
    },
    noConfirmOnRemove: {
      type: Boolean,
      default: false,
    },
    enterable: {
      type: Boolean,
      default: false,
    },
    showSfa: {
      type: Boolean,
      default: true,
    },
    showWeight: {
      type: Boolean,
      default: true,
    },
    showOwner: {
      type: Boolean,
      default: false,
    },
    showChildren: {
      type: Boolean,
      default: true,
    },
    showStorageTime: {
      type: Boolean,
      default: true,
    },
    ownerId: {
      type: String,
      required: false,
      default: "",
    },
    orderId: {
      type: String,
      required: false,
      default: "",
    },
    ladingBillId: {
      type: [String, Number],
      required: false,
      default: "",
    },
    showExtendFunc: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    box: {
      immediate: true,
      handler(box) {
        this.data = box;
      },
    },
    "data.items"(data) {
      if (data && !data.length) this.itemsVisible = false;
    },
  },
  data() {
    return {
      editing: false,
      mainEditing: false,
      skuFields: [
        { key: "size", label: "Size" },
        { key: "weight", label: "Weight" },
      ],
      itemsVisible: false,
      box_fetching: false,
      data: this.box,
      showSelectPallet: false,
      showModalEdit: false,
      showModalDelete: false,
      showModalViewItem: false,
      showModalViewChildren: false,
      showModalInfo: false,
      showModalAddTags: false,
      showModalAddSkuToGd: false,
      showModalTransferLdb: false,
    };
  },
  computed: {
    tags() {
      return (
        this.lodash.orderBy(this.data?.tags, ["name.length"], ["desc"]) || []
      );
    },
    owners() {
      let listOwners = this.box?.owners ? this.box?.owners : [];
      this.box?.children?.forEach((x) => {
        if (x?.owners) listOwners = [...listOwners, ...x?.owners] || [];
      });
      if (this.ownerId && listOwners.length)
        return listOwners.filter((x) => x.user_id == this.ownerId) || [];

      return listOwners || [];
    },
  },
  methods: {
    deleteSku() {
      this.$store.dispatch("warehouse.boxes.delete", this.data.id).then(() => {
        this.$emit("deleted", this.data);
      });
    },
    removeSku() {
      this.$emit("remove", this.data);
    },
    removeHandle() {
      this.noConfirmOnRemove ? this.removeSku() : (this.showModalDelete = true);
    },
    updateDuplicate(quantity) {
      this.$store.commit("warehouse.boxes.select", this.data.id);
      this.$store
        .dispatch("warehouse.boxes.update", {
          id: this.data.id,
          attributes: { duplicate: quantity },
        })
        .then((data) => {
          this.$emit("updated", data);
        });
    },
    fetchSku() {
      this.box_fetching = true;
      this.$tomoni.warehouse.boxes
        .get(
          this.data.id,
          this.$store.state.boxes["warehouse_boxes_detail_query"]
        )
        .then((data) => {
          this.data = data.data;
        })
        .finally(() => {
          this.box_fetching = false;
        });
    },
    selectPallet(id) {
      this.box.pallet_id = id;
      this.$emit("updated", this.data);
    },
    removeItem(id) {
      this.$store.dispatch("warehouse.box_items.delete", id).then(() => {
        this.fetchSku();
      });
    },
    itemCreated() {
      this.fetchSku();
    },
    confirm() {
      this.removable ? this.removeSku() : this.deleteSku();
      this.showModalDelete = false;
    },
  },
};
</script>

<style scoped>
.c-header-nav-items >>> .dropdown-item {
  padding: 0.4em 0.5em;
}
</style>
