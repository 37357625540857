import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'helper',
    resource: 'notification_subscribers',
  },
  {
    default_query: {
      include: "supportType",
    },
  },
).store()

export default resource
