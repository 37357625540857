<template>
  <CCard class="mb-0">
    <CCardHeader class="p-2">
      <div class="d-flex">
        <TMessage :content="order.id" noTranslate bold />
        <div class="ml-1">
          <SMessageOrderStatus :id="order.status_id" />
        </div>
      </div>
    </CCardHeader>
    <CCardBody class="p-2">
      <TTableAsForm
        v-if="display.includes('service_fee')"
        :data="order"
        :fields="serviceChargeFields"
        :splitLeft="7"
      >
        <template #service_fee="{ value }">
          <TMessageMoney :amount="value" :currency="order.service_fee_currency_id" />
        </template>
        <template #service_fee_paid="{ value }">
          <TMessageMoney :amount="value" :currency="order.service_fee_currency_id" />
        </template>
        <template #service_fee_unsolved="{ value }">
          <TMessageMoney :amount="value" :currency="order.service_fee_currency_id" />
        </template>
        <template #service_fee_boxes="{ value }">
          <TMessageMoney :amount="value" :currency="order.service_fee_currency_id" />
        </template>
      </TTableAsForm>
      <TTableAsForm
        v-if="display.includes('purchase_cost')"
        :data="order"
        :fields="goodsMoneyFields"
        :splitLeft="7"
      >
        <template #purchase_cost="{ value }">
          <TMessageMoney :amount="value" :currency="order.purchase_cost_currency_id" />
        </template>
        <template #purchase_cost_paid="{ value }">
          <TMessageMoney :amount="value" :currency="order.purchase_cost_currency_id" />
        </template>
        <template #purchase_cost_unsolved="{ value }">
          <TMessageMoney :amount="value" :currency="order.purchase_cost_currency_id" />
        </template>
      </TTableAsForm>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    display: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      serviceChargeFields: [
        { key: "service_fee", label: "Service fee" },
        { key: "service_fee_paid", label: "Paid" },
        { key: "service_fee_unsolved", label: "Outstanding" },
      ],
      goodsMoneyFields: [
        { key: "purchase_cost", label: "Purchase cost" },
        { key: "purchase_cost_paid", label: "Paid" },
        { key: "purchase_cost_unsolved", label: "Outstanding" },
      ],
    };
  },
};
</script>
