var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('label',[(_vm.label)?_c('TMessage',{attrs:{"bold":"","content":_vm.label}}):_vm._e()],1),_c('CLink',{staticClass:"float-right",on:{"click":function($event){_vm.showModal = true}}},[_c('TMessage',{attrs:{"content":"Add new"}})],1)],1),(!_vm.editable)?_c('TSelect',{staticClass:"m-0",attrs:{"options":_vm.list,"value":_vm.valueSelected,"selectOptions":{
      internalSearch: true,
      'custom-label': _vm.customLabel,
      preserveSearch: true,
      ..._vm.selectOptions,
    },"normalizer":{ label: 'full_address', value: 'id' },"placeholder":"Select","loading":_vm.loading},on:{"select":_vm.select,"search":_vm.search},scopedSlots:_vm._u([{key:"singleLabel",fn:function({ option }){return [_c('div',{staticClass:"d-flex"},[_c('TMessage',{staticClass:"text-break",attrs:{"content":option.consignee,"noTranslate":"","truncate":1,"size":"small"},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(" - "+_vm._s(option.full_address)+" ")]},proxy:true}],null,true)})],1)]}}],null,false,3701577214)}):_c('TSelectEditable',{staticClass:"m-0",attrs:{"options":_vm.list,"value":_vm.valueSelected,"selectOptions":{
      internalSearch: false,
      preserveSearch: true,
      'custom-label': _vm.customLabel,
      ..._vm.selectOptions,
    },"placeholder":"Select","loading":_vm.loading,"normalizer":{ label: 'full_address', value: 'id' }},on:{"search":_vm.search,"select":_vm.select,"close":function($event){return _vm.$emit('close')},"change":function($event){return _vm.$emit('change', $event)}},scopedSlots:_vm._u([{key:"singleLabel",fn:function({ option }){return [_c('div',{staticClass:"d-flex"},[_c('TMessage',{staticClass:"text-break",attrs:{"content":option.consignee,"noTranslate":"","truncate":1,"size":"small"},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(" - "+_vm._s(option.full_address)+" ")]},proxy:true}],null,true)})],1)]}}])}),_c('SModalAddress',{attrs:{"show":_vm.showModal,"type":"place_of_delivery"},on:{"update:show":function($event){_vm.showModal=$event},"created":_vm.created}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }