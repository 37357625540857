import { HelperService } from '../services'

export const SERVICE = HelperService
export const RESOURCE = 'user-notifications'

export default {
  markAsRead(id) {
    return SERVICE.post(`api/${RESOURCE}/${id}/markAsRead`);
  },
  markAllAsRead() {
    return SERVICE.post(`api/${RESOURCE}/markAllAsRead`);
  },
};
