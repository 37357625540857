export default class RESTApi {
  constructor(client) {
    this.client = client
  }

  query(resource, params) {
    return this.client.get(resource, { params });
  }

  queryBlob(resource, params) {
    this.client.defaults.responseType = 'blob'
    return this.client.get(resource, { params }).finally(() => {
      this.client.defaults.responseType = ''
    })
  }

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "", params) {
    return this.client.get(`${resource}/${slug}`, { params });
  }

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return this.client.post(`${resource}`, params);
  }
  postFormData(resource, params) {
    let formData = new FormData();
    Object.entries(params).forEach(param => {
      formData.append(param[0], param[1])
    })
    return this.client.post(`${resource}`, formData);
  }

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return this.client.put(`${resource}/${slug}`, params);
  }

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return this.client.put(`${resource}`, params);
  }

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, slug, params) {
    if (slug)
      return this.client.delete(`${resource}/${slug}`,
        {
          data: params
        });
    else return this.client.delete(resource)
  }
}
