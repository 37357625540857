<template>
  <div class="w-100 d-flex">
    <div class="input-group">
      <slot name="input" :setInput="setInput" :input="input">
        <CTextarea
          v-if="textarea"
          :value="input"
          v-bind="inputOptions"
          :lazy="false"
          @keydown.enter.native="onChange"
          @input="setInput"
          class="mb-0 w-100"
        />
        <CInput
          v-else
          :value="input"
          :lazy="false"
          @keydown.enter.native="onChange"
          v-bind="inputOptions"
          @input="setInput"
          class="mb-0 w-100"
        />
      </slot>
    </div>
    <div class="input-group-append">
      <slot name="action" :input="input">
        <TButtonSave @click="onChange" />
        <TButtonClose @click="$emit('close')" />
      </slot>
    </div>
  </div>
</template>

<script>
import actions from "../Button/actions";

export default {
  mixins: [actions],
  props: {
    value: {
      required: false,
    },
    inputOptions: {
      type: Object,
      required: false,
    },
    textarea: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      input: this.value,
    };
  },
  methods: {
    setInput(input) {
      this.input = input;
    },
    onChange() {
      this.$emit("change", this.input);
    },
  },
};
</script>
