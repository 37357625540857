<template>
  <TModal
    title="Select lading bill"
    :show="show"
    size="lg"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButtonAdd
        variant="outline"
        size=""
        :options="{ content: 'Add', disabled: !selected_lading_bill_id }"
        @click="select"
        :key="key"
      />
    </template>
    <CListGroup>
      <CListGroupItem>
        <CRow>
          <CCol col="12" lg="6" v-if="!noCustomerFilter">
            <SSelectCustomer
              label="Customer"
              noCustomLabel
              :value.sync="filter.customer_id"
              @update:value="filterChange"
              prependAll
            />
          </CCol>
          <CCol col="12" lg="6">
            <TInputText
              label="Bill of lading code"
              placeholder="Bill of lading code"
              :value.sync="filter.id"
              @update:value="filterChange"
            />
          </CCol>
          <CCol col="12">
            <SButtonClearFilter
              class="ml-auto mt-1 d-flex"
              variant="outline"
              @click="clearFilter"
            />
          </CCol>
        </CRow>
      </CListGroupItem>
      <CListGroupItem>
        <slot name="prepend-content"> </slot>
      </CListGroupItem>
      <CListGroupItem
        v-for="lading_bill in lading_bills"
        :key="lading_bill.id"
        :color="
          selected_lading_bill_id == lading_bill.id ? 'primary' : 'secondary'
        "
        :to="
          _.getAppendRouteQuery($route, {
            select_lading_bill_id: lading_bill.id,
          })
        "
      >
        <SCardLadingBill :lading_bill="lading_bill" tiny showShipmentMethod />
      </CListGroupItem>
      <CListGroupItem v-if="!lading_bills.length">
        <TMessageNoData />
      </CListGroupItem>
      <CListGroupItem v-if="lading_bills_loading">
        <TSpinner :loading="lading_bills_loading" />
      </CListGroupItem>
      <SButtonLoadmore :store="store" />
    </CListGroup>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    store: {
      type: String,
      default: "warehouse.lading_bills",
    },
    noCustomerFilter: {
      type: Boolean,
      default: false,
    },
    default_filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      key: "btn-select",
      filter: {},
    };
  },
  watch: {
    show(isShow) {
      if (isShow) {
        this.$store.dispatch(this.store + ".apply-query", this.default_filter);
      } else {
        this.$router
          .push({
            query: { ...this.$route.query, select_lading_bill_id: "" },
          })
          .catch(() => {});
      }
    },
  },
  computed: {
    selected_lading_bill_id() {
      return this.$route.query.select_lading_bill_id;
    },
    lading_bills() {
      return this.$store.getters[this.store + ".list"];
    },
    lading_bills_loading() {
      return this.$store.getters[this.store + ".fetching"];
    },
  },
  methods: {
    select() {
      this.$emit("select", this.selected_lading_bill_id);
      this.$emit("update:show", false);
      this.key = this.lodash.resetKey("btn-select");
    },
    selectCustomer(id) {
      this.$store.dispatch(this.store + ".apply-query", {
        "filter[customer_id]": id,
      });
    },
    filterChange() {
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filterList,
        ...this.default_filter,
      });
      this.$store.dispatch(this.store + ".apply-query", filterFields);
    },
    clearFilter() {
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>
