<template>
  <CButton
    variant="outline"
    color="primary"
    class="float-right"
    :to="lodash.getReferenceLink(
      'invoice',
      lading_bill.container_id
    )"
  >
    <div class="d-flex">
      <TMessage content="Go to invoice">
        <template #suffix>
          <strong>
            {{ lading_bill.container.name }}
          </strong>
        </template>
      </TMessage>
      <CIcon class="ml-2" name="cil-arrow-right" />
    </div>
  </CButton>
</template>

<script>
export default {
  props: {
    lading_bill: {
      type: Object,
      required: true,
    },
  },
};
</script>
