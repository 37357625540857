<template>
  <CTabs
    variant="pills"
    :active-tab="lodash.toNumber(activeTab)"
    addNavClasses="mb-3"
    class="tabs-custom"
    addTabClasses="mb-3"
    @update:activeTab="$emit('change-tab', $event)"
  >
    <template>
      <CTab v-for="(tab, index) in tabs" :key="index" :disabled="tab.disabled">
        <template #title>
          <div class="d-flex align-items-center">
            <CIcon v-if="tab.icon" :name="tab.icon" class="mr-2 my-auto" />
            <TMessage
              :content="tab.name"
              :class="{ 'mr-2': $slots[`${tab.key}-badge`] }"
              :noTranslate="tab.noTranslate ? tab.noTranslate : false"
            />
            <slot :name="`${tab.key}-badge`"> </slot>
          </div>
        </template>
        <slot
          :name="tab.key"
          v-if="mountOnEnter ? (activeTab == index ? true : false) : true"
        >
        </slot>
      </CTab>
    </template>
  </CTabs>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    activeTab: {
      type: [String, Number],
      default: 0,
    },
    mountOnEnter: { // Tab nào active thì mới render tab ấy ra, nếu không thì không render
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    const scrollContainer = document.querySelector(".tabs-custom ul");
    scrollContainer.addEventListener("wheel", (evt) => {
      evt.preventDefault();
      scrollContainer.scrollLeft += evt.deltaY;
    });

    let mouseDown = false;
    let startX, scrollLeft;

    let startDragging = function (e) {
      mouseDown = true;
      startX = e.pageX - scrollContainer.offsetLeft;
      scrollLeft = scrollContainer.scrollLeft;
    };
    let stopDragging = function () {
      mouseDown = false;
    };

    scrollContainer.addEventListener("mousemove", (e) => {
      e.preventDefault();
      if (!mouseDown) {
        return;
      }
      const x = e.pageX - scrollContainer.offsetLeft;
      const scroll = (x - startX) * 1; //scroll-fast
      scrollContainer.scrollLeft = scrollLeft - scroll;
    });

    scrollContainer.addEventListener("mousedown", startDragging, false);
    scrollContainer.addEventListener("mouseup", stopDragging, false);
    scrollContainer.addEventListener("mouseleave", stopDragging, false);
  },
};
</script>
<style scoped>
.tabs-custom >>> .nav-link.active {
  border-bottom: 2px solid var(--primary);
  background: #fff;
  color: var(--primary);
  font-weight: bold;
  border-radius: 0;
}
.tabs-custom >>> ul.nav {
  background: #fff;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  flex-wrap: nowrap;
}
.tabs-custom >>> ul::-webkit-scrollbar {
  height: 0;
}
.tabs-custom >>> .nav-item {
  white-space: nowrap;
}
.c-dark-theme .tabs-custom >>> ul {
  background-color: #24252f;
}
.c-dark-theme .tabs-custom >>> .nav-link.active {
  background-color: var(--dark);
}
</style>
