<template>
  <CBadge v-if="location" :color="color">
    {{ getShelveName }}{{ location.row }}-{{ location.floor }}
  </CBadge>
</template>

<script>
export default {
  props: {
    location: {
      type: Object,
      required: false,
    },
    color: {
      type: String,
      default: "info",
    },
  },
  computed: {
    getShelveName() {
      return this.location ? this.location?.name?.charAt(0) : "";
    },
  },
};
</script>
