import { HelperService } from '../services'

export const SERVICE = HelperService
export const RESOURCE = 'converts'

export default {
  importGoodsDelivery(attributes) {
    return SERVICE.postFormData(`api/converts/goods-delivery-boxs`, attributes);
  },
  importShippingCode(attributes) {
    return SERVICE.postFormData(`api/converts/goods-delivery-boxs-shipping-code`, attributes);
  },
  importOutcontContainerPickers(attributes) {
    return SERVICE.postFormData(`api/converts/out-container-pickers`, attributes);
  },
}
