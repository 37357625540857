import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'lading-bills-of-orders'

export default {
  all(attributes) {
    return SERVICE.postFormData(`api/lading-bills-of-orders`, attributes);
  },
};
