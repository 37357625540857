<template>
  <div class="p-2">
    <div class="d-flex flex-wrap">
      <SMessageShipmentMethod
        :id="invoice.shipment_method_id"
        class="ml-1"
        iconOnly
      />
      <TMessage
        v-if="invoice.name"
        :content="invoice.name"
        bold
        noTranslate
        size="h5"
        :truncate="1"
        :color="invoice.had_cut_off ? 'success' : ''"
        class="mr-2"
      />
      <div class="ml-auto">
        <CBadge
          :color="getAreaColor(invoice.from_area.name)"
          v-if="invoice.from_area"
        >
          {{ invoice.from_area.name }}
        </CBadge>
        <CIcon class="mx-1" name="cil-arrow-circle-right" />
        <CBadge
          :color="getAreaColor(invoice.to_area.name)"
          v-if="invoice.to_area"
        >
          {{ invoice.to_area.name }}
        </CBadge>
      </div>
    </div>
    <div class="my-1">
      <TMessage content="Volume" :addClasses="['d-flex flex-wrap']">
        <template #suffix>
          (m3):
          <TMessageVolume class="mx-1" :value="invoice.volume" suffix="" /> /
          <TMessageVolume
            class="mx-1"
            :value="invoice.total_max_volume"
            suffix=""
          />
        </template>
      </TMessage>
      <CProgress
        :value="invoice.volume"
        :max="invoice.total_max_volume"
        style="height: 5px"
      />
    </div>
    <div class="mt-1 mb-2">
      <TMessage content="Weight" :addClasses="['d-flex flex-wrap']">
        <template #suffix>
          (kg):
          <TMessageNumber class="mx-1" :value="invoice.weight" /> /
          <TMessageNumber class="mx-1" :value="invoice.total_max_weight" />
        </template>
      </TMessage>
      <CProgress
        :value="invoice.weight"
        :max="invoice.total_max_weight"
        style="height: 5px"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getAreaColor(name) {
      switch (name) {
        case "Narita Center":
          return "primary";
        case "Matsudo Japan":
          return "success";
        case "VN Ha Noi":
          return "info";
        default:
          return "warning";
      }
    },
  },
};
</script>
