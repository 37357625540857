import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'orders',
  },
  {
    default_query: {
      include: 'type,status,customer',
      append: 'first_item',
      sort: '-updated_at'
    }
  }
).store()

export default resource
