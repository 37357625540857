<template>
  <div>
    <label class="font-weight-bold" v-if="label">{{ $t(label) }}</label>
    <TSelectEditable
      v-if="editable"
      class="m-0"
      :options="categories"
      :value="valueSelected"
      :selectOptions="selectOptions"
      @close="$emit('close')"
      @change="$emit('change', $event)"
    />
    <TSelect
      v-else
      class="m-0"
      placeholder="Select"
      :options="categories"
      :value="valueSelected"
      :selectOptions="selectOptions"
      @select="select"
    />
  </div>
</template>
<script>
export default {
  props: {
    prepend: {
      type: Array,
      required: false,
      default: function () {
        return [{ id: "", name: this.$t("All") }];
      },
    },
    value: {
      type: String,
      required: false,
      default: "",
    },
    selectOptions: {
      type: Object,
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  created() {
    this.$store.dispatch("helper.post_categories.fetch.if-first-time");
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.valueSelected = this.option;
      },
    },
  },
  computed: {
    categories() {
      if (this.prepend)
        return [
          ...this.prepend,
          ...this.$store.getters["helper.post_categories.list"],
        ];
      return this.$store.getters["helper.post_categories.list"];
    },
    option() {
      return this.categories.find((x) => x.id === this.value);
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
  },
};
</script>
