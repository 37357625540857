<template>
  <div class="btn-new-pallet my-3 mx-3">
    <CButton class="w-100 h-100" @click="create()">
      <TMessage :content="`New pallet`" alignment="center" color="primary" bold>
        <template #prefix>
          <CIcon name="cis-plus" class="mr-1" />
        </template>
        <template #over-suffix>
          {{ $t("Size") }} (cm): {{ type.max_length }} x {{ type.max_width }} x
          {{ type.max_height }} | {{ $t("Weight") }} (kg): {{ type.max_weight }}
        </template>
      </TMessage>
    </CButton>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: Object,
      required: true,
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch("warehouse.pallets.create", {
          type_id: this.type.id,
        })
        .then((pallet) => {
          this.$emit("created", pallet);
        });
    },
  },
};
</script>
