import Resource from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const resource = new Resource(
  {
    service: 'helper',
    resource: 'supports',
  },
  {
    default_query: {
      append: "logs",
      include: "supportType,creator",
      sort: "-created_at",
    },
    detail_query: {
      append: "logs",
      include: "supportType,supportContentsCount,creator",
    },
  },
).store()


const PREFIX = "helper.supports";

const actions = {
  [PREFIX + ".detail.mark-as-read"](context) {
    return new Promise((resolve, reject) => {
      tomoni.helper.supports.markAsRead(context.getters[PREFIX + '.detail.id'])
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          context.dispatch('errors.push-http-error', error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.close"](context) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-updating', true);
      tomoni.helper.supports.close(context.getters[PREFIX + '.detail.id'])
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.set-updating', false);
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Closed",
            type: "danger",
          });
          resolve(data)
        }).catch((error) => {
          context.commit(PREFIX + '.set-updating', false);
          context.dispatch('errors.push-http-error', error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.open"](context) {
    return new Promise((resolve, reject) => {
      context.commit(PREFIX + '.set-updating', true);
      tomoni.helper.supports.open(context.getters[PREFIX + '.detail.id'])
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.set-updating', false);
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Open",
            type: "success",
          });
          resolve(data)
        }).catch((error) => {
          context.commit(PREFIX + '.set-updating', false);
          context.dispatch('errors.push-http-error', error);
          reject(error);
        });
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};


