import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'lading_bills_of_orders',
    paginate: false,
  },
  {
    default_query: {
      include: "container,compensations,pivotBoxes,boxes,customer",
      sort: "-created_at",
    },
    default_detail: {
      customer: {},
    }
  }
).store()

export default resource
