<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex align-items-center">
        <SMessageShipmentMethod :id="ladingBill.shipment_method_id" iconOnly />
        <TMessage content="Lading bill" bold>
          <template #suffix>
            <span class="ml-1">{{ ladingBill.id }}</span>
          </template>
        </TMessage>
        <TSpinner :loading="detail_loading" />
        <div class="ml-auto d-flex">
          <SButtonDebit
            @click="debit"
            class="mr-1"
            :options="{ disabled: detail_loading }"
            v-if="ladingBill.freight_charge_unsolved"
          />
          <TButtonDelete
            v-if="removable"
            @click="removeLadingBill"
            class="mr-1"
            variant=""
            :options="{ disabled: detail_loading }"
          />
          <TButtonRefresh
            @click="refresh"
            :options="{ disabled: detail_loading }"
          />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="12" lg="12" xl="6">
            <TTableAsForm
              :fields="parameterFields"
              :data="ladingBill"
              :splitLeft="7"
              title="Detail"
              class="mb-3"
            >
              <template #shipment_method_id="{ value }">
                <SMessageShipmentMethod
                  :id="value"
                  :editable="!detail_loading"
                  @change="updateLadingBill('shipment_method_id', $event)"
                />
              </template>
              <template #volume="{ value }">
                <TMessageVolume :value="value" class="font-weight-bold" />
              </template>
              <template #weight="{ value }">
                <TMessageNumber
                  :value="value"
                  class="font-weight-bold"
                  suffix="kg"
                />
              </template>
              <template #volumetric_weight="{ value }">
                <TMessageNumber
                  :value="value"
                  class="font-weight-bold"
                  suffix="kg"
                />
              </template>
              <template #customer="{ data }">
                <TLink
                  :content="
                    data.customer ? data.customer.name : data.customer_id
                  "
                  :to="lodash.getReferenceLink('user', data.customer_id)"
                />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol md="12" lg="12" xl="6">
            <TTableAsForm
              :fields="detailFields"
              :data="ladingBill"
              :splitLeft="7"
              title="Container"
              class="mb-3"
            >
              <template #container="{ value }">
                <TLink
                  v-if="value"
                  :content="value.name"
                  :to="lodash.getReferenceLink('invoice', value.id)"
                />
                <TMessageNotFound v-else />
              </template>
              <template #shipment_method="{ data }">
                <SMessageShipmentMethod
                  v-if="data.container"
                  :id="data.container.shipment_method_id"
                />
              </template>
              <template #status="{ data }">
                <div v-if="data.container">
                  <SMessageContainerStatus
                    :status="data.container.had_cut_off"
                  />
                  <TMessageDateTime
                    v-if="data.container.cut_off_date"
                    :content="data.container.cut_off_date"
                    dateOnly
                  />
                </div>
              </template>
              <template #from_area_id="{ data }">
                <SMessageArea
                  v-if="data.container"
                  :id="data.container.from_area_id"
                />
              </template>
              <template #to_area_id="{ data }">
                <SMessageArea
                  v-if="data.container"
                  :id="data.container.to_area_id"
                />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol md="12" lg="12" xl="6">
            <TTableAsForm
              :fields="methodFields"
              :data="ladingBill"
              :splitLeft="7"
              title="Charging method"
              class="mb-3"
              :addRowClasses="['py-1']"
            >
              <template #type_freight_charge="{ value }">
                <SMessageFreightChargeType
                  :id="value"
                  :editable="!detail_loading"
                  @change="updateLadingBill('type_freight_charge', $event)"
                />
              </template>
              <template #type_freight_range="{ value }">
                <SMessageFreightRangeType
                  :id="value"
                  :editable="!detail_loading"
                  @change="updateLadingBill('type_freight_range', $event)"
                />
              </template>
              <template #automatic_freight_rate_update="{ value }">
                <TSwitch
                  :checked="value"
                  :disabled="detail_loading"
                  @change="
                    updateLadingBill('automatic_freight_rate_update', $event)
                  "
                />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol md="12" lg="12" xl="6">
            <TTableAsForm
              :fields="additionalFields"
              :data="ladingBill"
              :splitLeft="7"
              title="Other"
              class="mb-3"
              :addRowClasses="['py-1']"
            >
              <template #freight_charge_pay_for_order="{ value }">
                <TSwitch
                  :checked="value"
                  :disabled="detail_loading"
                  @change="
                    updateLadingBill('freight_charge_pay_for_order', $event)
                  "
                />
              </template>
              <template #freight_charge_pay_for_order-header>
                <TMessage content="Freight charge pay for order" bold>
                  <template #suffix>
                    ({{ $t("Not in the contract") }})
                  </template>
                </TMessage>
              </template>
            </TTableAsForm>
          </CCol>
        </CRow>
      </CCardBody></CCard
    >
    <CCard>
      <CCardHeader class="d-flex align-items-center">
        <div class="d-flex">
          <TMessage content="Value of Lading bill" bold />
          <TSpinner :loading="detail_loading" />
        </div>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="12" lg="12" xl="6">
            <TTableAsForm
              :fields="freightChargeFields"
              :data="ladingBill"
              :splitLeft="7"
              title="Freight charge"
              class="mb-3"
            >
              <template #freight_charge="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="ladingBill.currency_id"
                />
              </template>
              <template #freight_charge_debited="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="ladingBill.currency_id"
                />
              </template>
              <template #freight_charge_outstanding="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="ladingBill.currency_id"
                />
              </template>
              <template #freight_charge_paid="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="ladingBill.currency_id"
                />
              </template>
              <template #freight_charge_unsolved="{ value }">
                <TMessageMoney
                  :amount="value"
                  :currency="ladingBill.currency_id"
                />
              </template>
            </TTableAsForm>
          </CCol>
          <CCol md="12" lg="12" xl="6">
            <TTableAsForm
              :fields="chargeFields"
              :data="ladingBill"
              :splitLeft="7"
              title="Calculated value"
              class="mb-3"
            >
              <template #freight_range="{ value }">
                <TMessageNumber :value="value" :decimalLimit="4" />
              </template>
              <template #discount="{ value }">
                <TMessageMoney
                  :currency="ladingBill.currency_id"
                  :amount="value"
                  :editable="!detail_loading"
                  @change="updateLadingBill('discount', $event)"
                />
              </template>
              <template #freight_rate="{ value }">
                <TMessageMoney
                  :currency="ladingBill.currency_id"
                  :amount="value"
                  :editable="
                    !detail_loading && !ladingBill.automatic_freight_rate_update
                  "
                  @change="updateLadingBill('freight_rate', $event)"
                />
              </template>
            </TTableAsForm>
          </CCol>
        </CRow> </CCardBody
    ></CCard>
  </div>
</template>

<script>
export default {
  props: {
    ladingBill: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    store: {
      type: String,
      default: "warehouse.lading_bills",
    },
  },
  data() {
    return {
      detailFields: [
        { key: "container", label: "Container" },
        { key: "shipment_method", label: "Shipment method" },
        { key: "status", label: "Status" },
        { key: "from_area_id", label: "From" },
        { key: "to_area_id", label: "To" },
      ],
      methodFields: [
        { key: "type_freight_charge", label: "Freight charge type" },
        { key: "type_freight_range", label: "Freight range type" },
        {
          key: "automatic_freight_rate_update",
          label: "Get unit price from price list",
        },
      ],
      parameterFields: [
        { key: "shipment_method_id", label: "Shipment method" },
        { key: "customer", label: "Customer" },
        { key: "volume", label: "Volume" },
        { key: "weight", label: "Weight" },
        { key: "volumetric_weight", label: "Volumetric weight" },
      ],
      freightChargeFields: [
        { key: "freight_charge", label: "Total freight" },
        { key: "freight_charge_paid", label: "Paid" },
        {
          key: "freight_charge_outstanding",
          label: "Outstanding",
        },
        { key: "freight_charge_debited", label: "Debited" },
        { key: "freight_charge_unsolved", label: "Unsolved" },
      ],
      chargeFields: [
        { key: "freight_rate", label: "Freight rate" },
        { key: "freight_range", label: "Freight range" },
        { key: "discount", label: "Freight charge discount" },
      ],
      additionalFields: [
        {
          key: "freight_charge_pay_for_order",
          label: "Freight charge pay for order",
        },
      ],
    };
  },
  computed: {
    detail_loading() {
      return this.$store.getters[this.store + ".detail.loading"];
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch(this.store + ".detail.refresh");
    },
    updateLadingBill(field, value) {
      return this.$store.dispatch(this.store + ".detail.update", {
        [field]: value,
      });
    },
    removeLadingBill() {
      this.$store.dispatch(this.store + ".detail.delete").then(() => {
        this.$router.push(`/warehouse/internal/lading-bills`);
      });
    },
    debit() {
      this.$store
        .dispatch(this.store + ".detail.update", {
          action_debit: true,
        })
        .then(() => {
          this.refresh();
        });
    },
  },
};
</script>
