import { HelperService } from '../services'

export const SERVICE = HelperService
export const RESOURCE = 'supports'

export default {
  markAsRead(id) {
    return SERVICE.put(`api/${RESOURCE}/${id}/markAsRead`);
  },
  close(id) {
    return SERVICE.put(`api/${RESOURCE}/${id}/close`);
  },
  open(id) {
    return SERVICE.put(`api/${RESOURCE}/${id}/open`);
  },
};
