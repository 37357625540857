import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'house-airway-bills'

export default {
  update(id, attributes) {
    return SERVICE.postFormData(`api/${RESOURCE}/${id}`, {
      ...attributes,
      _method: "PUT"
    });
  },
};
