<template>
  <TModal
    title="Update suppliers"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <div></div>
    </template>
    <SSelectSupplier
      :value.sync="item.supplier_id"
      @update:value="item.supplier_id = $event"
      label="Supplier"
      class="mb-3"
    />
    <CRow>
      <CCol col="6">
        <TInputMoney
          :value="item.price"
          @update:value="item.price = $event"
          label="Price"
          class="mb-3"
          :currency="item.currency_id"
        />
      </CCol>
      <CCol col="6">
        <TInputNumber
          :value="item.quantity_items"
          @update:value="item.quantity_items = $event"
          label="Items in box"
          class="mb-3"
        />
      </CCol>
    </CRow>
    <div class="d-flex">
      <TButton icon="cil-plus" content="Add" @click="create" class="ml-auto" />
    </div>
    <TTableAdvance
      class="mt-3"
      :items="product.suppliers"
      :fields="itemFields"
      noPaginate
      store="product.products.detail"
      resource=""
      noFilter
      noClickRow
      title="Supplier list"
    >
      <template #append-actions="{ item }">
        <CCol class="px-1 text-center" col="12">
          <TButtonRemove @click="removeSupplier(item.id)" />
        </CCol>
      </template>
      <template #id="{ item }">
        <td>
          <TMessage :content="item.id" noTranslate />
        </td>
      </template>
      <template #price="{ item }">
        <td>
          <TMessageMoney
            :amount="lodash.toNumber(item.pivot.price)"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #note="{ item }">
        <td>
          <TMessage :content="item.note" noTranslate />
        </td>
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    product_id: {
      type: String,
      required: true,
    },
  },
  defaultItem: {
    supplier_id: "",
    price: 0,
    quantity_items: 1,
  },
  data() {
    return {
      item: this.defaultItem(),
      itemFields: [
        { key: "_", _style: "width: 40px; min-width: 40px" },
        { key: "id", label: "Id", _style: "width: 80px; min-width: 80px" },
        {
          key: "name",
          label: "Supplier",
          _style: "width: 450px; min-width: 450px",
        },
        { key: "price", label: "Price" },
        {
          key: "note",
          label: "Note",
          _style: "width: 350px; min-width: 350px",
        },
      ],
    };
  },
  watch: {
    product_id: {
      immediate: true,
      handler(value) {
        if (value && this.show) {
          this.item = this.defaultItem();
          this.$store.dispatch("product.products.detail.fetch", value);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      product: "product.products.detail",
    }),
  },
  methods: {
    create() {
      this.$store
        .dispatch("product.products.detail.add-supplier", this.item)
        .then((data) => {
          this.item = this.defaultItem();
          this.$emit("updated", data);
        });
    },
    removeSupplier(id) {
      this.$store
        .dispatch("product.products.detail.delete-supplier", id)
        .then((data) => {
          this.$emit("updated", data);
        });
    },
    defaultItem() {
      return { ...this.$options.defaultItem };
    },
  },
};
</script>
