<template>
  <TModal
    title=""
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="lg"
  >
    <template #title>
      <div class="d-flex align-items-center">
        <TMessage content="SKUs in pallet" bold capitalize class="my-auto" />
        <TSpinner :loading="loading" />
      </div>
    </template>
    <template #actions>
      <span></span>
    </template>

    <TMessageNoData content="No SKU" v-if="!boxes_in_pallet.length" />
    <CRow v-else class="d-flex align-items-stretch">
      <SCardSku
        v-for="box in boxes_in_pallet"
        :key="box.id"
        :box="box.box"
        class="my-3 mx-3"
        :focus-quantity="box.quantity"
        refresh
      />
    </CRow>
    <SButtonLoadmore store="warehouse.pallet_boxes" />
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    palletId: {
      type: String,
      required: true,
      default: "",
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(show) {
        if (show) this.fetchBoxesInPallet();
      },
    },
  },
  computed: {
    ...mapGetters({
      loading: "warehouse.pallet_boxes.loading",
    }),
    boxes_in_pallet() {
      return this.$store.getters["warehouse.pallet_boxes.list"].filter(
        (p) => p.box
      );
    },
  },
  methods: {
    fetchBoxesInPallet() {
      this.$store.dispatch("warehouse.pallet_boxes.apply-query", {
        "filter[pallet_id]": this.palletId,
      });
    },
  },
};
</script>
