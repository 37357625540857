import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'count-product-inventory'

export default {
  count(id) {
    return SERVICE.get(`api/count-product-inventory`, id);
  },
};
