<template>
  <CCard
    class="p-3"
    :style="[clickable && { cursor: 'pointer' }]"
    @click="$emit('click')"
    :textColor="selected ? 'white' : ''"
    align="center"
    :accentColor="color"
    :color="selected ? color : ''"
  >
    <div class="d-flex" v-if="$slots['title-right']">
      <TMessage
        :content="title"
        :alignment="alignment"
        bold
        class="h5"
        :truncate="1"
      />
      <slot name="title-right"> </slot>
    </div>
    <TMessage
      v-else
      :content="title"
      :alignment="alignment"
      bold
      class="h5"
      :truncate="1"
    />
    <TSpinner v-if="loading" :loading="loading" class="my-3" />
    <div v-else>
      <slot name="content">
        <TMessageNumber
          :value="number"
          :messageOptions="{
            color: !selected ? color : '',
            alignment: alignment,
            size: 'h2',
          }"
        />
      </slot>
      <slot name="bottom-content"> </slot>
    </div>
  </CCard>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },
    number: {
      type: Number,
      required: false,
      default: 0,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "",
    },
    alignment: {
      type: String,
      default: "center",
    },
  },
};
</script>
