<template>
  <TMessageStatus :id="id" color="priamry" :statuses="statuses" noBadge />
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    statuses() {
      return this.$store.getters["warehouse.areas.list"];
    },
  },
};
</script>
