<template>
  <div>
    <CRow v-if="lading_bill_id">
      <CCol col="12">
        <CCard v-if="showSuggest">
          <CCardHeader class="d-flex flex-wrap align-items-center">
            <div class="d-flex align-items-center">
              <TMessage content="Suggestion SKUs for lading bill" bold>
                <template #suffix>
                  <span class="ml-1 text-primary">{{
                    lading_bill_selected.id
                  }}</span>
                  <span class="ml-1">-</span>
                  <span class="ml-1" v-if="lading_bill_selected.customer">{{
                    lading_bill_selected.customer.name ||
                    lading_bill_selected.customer.email
                  }}</span>
                  <span v-else class="ml-1">...</span>
                </template>
              </TMessage>
              <TSpinner :loading="boxes_available_in_lading_bill_loading" />
            </div>
            <TButtonRefresh
              class="ml-auto"
              @click="fetchBoxesAvailableInLadingBill"
              :options="{ disabled: boxes_available_in_lading_bill_loading }"
            />
          </CCardHeader>
          <CCardBody>
            <div class="scroll-x">
              <TMessageNoData
                content="No SKU"
                v-if="!boxes_available_in_lading_bill.length"
              />
              <div class="d-inline-flex">
                <SCardSku
                  v-for="box in boxes_available_in_lading_bill"
                  :key="box.id"
                  :box="box.box"
                  class="my-3 mx-2"
                  :focusQuantity="box.quantity"
                  focusQuantityColor="secondary"
                  focusQuantityDescription="Available quantity"
                  mainQuantityDescription="Total number of boxes"
                  :showWeight="false"
                  :showSfa="false"
                  showOwner
                  style="width: 170px"
                  :ownerId="lading_bill_selected.customer_id"
                  :class="
                    boxListSelected.some((x) => x.id == box.id)
                      ? 'border-success'
                      : 'border-light'
                  "
                >
                  <template #append-content>
                    <div
                      class="d-flex justify-content-center"
                      v-if="box.order_id"
                    >
                      <SMessageShipmentMethod
                        v-if="box.order"
                        :id="box.order.shipment_method_id"
                        iconOnly
                      />
                      <CBadge color="primary" class="h6">
                        {{ box.order_id }}
                      </CBadge>
                    </div>
                  </template>
                  <template #main-quantity>
                    <TMessage v-if="box.quantity < 0" content="Combine box" />
                    <TMessageNumber
                      v-else
                      :value="box.quantity_of_owner_in_area"
                    />
                  </template>
                  <template #append-actions>
                    <TInputCheckbox
                      :checked="boxListSelected.some((x) => x.id == box.id)"
                      @change="getBoxToList(box)"
                    />
                  </template>
                  <template #append-extend-actions>
                    <CDropdownItem @click.stop="createBoxLadingbill(box)">
                      <TMessage content="Add to lading bill" />
                    </CDropdownItem>
                  </template>
                </SCardSku>
              </div>
            </div>
          </CCardBody>
          <CCardFooter class="d-flex flex-wrap">
            <CLink
              class="ml-2 d-flex align-items-center"
              @click="onSelectAll"
              v-if="
                boxListSelected.length < boxes_available_in_lading_bill.length
              "
              :disabled="submitLoading"
            >
              <CIcon name="cil-check" class="mr-1" />
              <TMessage content="Select all" bold />
            </CLink>
            <CLink
              class="ml-2 d-flex text-danger align-items-center"
              @click="boxListSelected = []"
              v-if="boxListSelected.length"
              :disabled="submitLoading"
            >
              <CIcon name="cil-x-circle" class="mr-1" />
              <TMessage content="Deselect all" bold color="text-danger" />
            </CLink>
            <TButton
              class="ml-auto"
              color="success"
              :messageOptions="{ bold: true, noTranslate: true }"
              icon="cil-plus"
              :options="{
                disabled: !boxListSelected.length || submitLoading,
              }"
              @click="submit"
            >
              <template #content>
                <TMessage content="Add" bold class="ml-2">
                  <template #suffix>
                    <span v-if="boxListSelected.length">
                      {{
                        `(${_.sumBy(boxListSelected, "quantity")} ${$t(
                          "boxes"
                        )})`
                      }}
                    </span>
                  </template>
                </TMessage>
              </template>
            </TButton>
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol col="12">
        <CCard>
          <CCardHeader class="d-flex align-items-center flex-wrap">
            <TMessage content="SKUs in lading bill" bold>
              <template #suffix>
                <span class="ml-1 text-primary">{{ lading_bill_id }}</span>
                <span
                  class="ml-1"
                  v-if="!lodash.isEmpty(lading_bill_selected.customer)"
                  >-</span
                >
                <span class="ml-1" v-if="lading_bill_selected.customer">{{
                  lading_bill_selected.customer.name ||
                  lading_bill_selected.customer.email
                }}</span>
                <span v-else class="ml-1">...</span>
              </template>
            </TMessage>
            <TSpinner :loading="detail_loading" />
            <div class="ml-auto">
              <SButtonGoToInvoice
                v-if="
                  lading_bill_id &&
                  lading_bill_selected.container_id &&
                  showSuggest
                "
                :lading_bill="lading_bill_selected"
              />
            </div>
          </CCardHeader>
          <CCardBody>
            <TMessageNoData content="No SKU" v-if="!box_lading_bills.length" />
            <CRow v-else class="d-flex align-items-stretch">
              <CCol v-if="!showSuggest" class="w-100">
                <div
                  v-for="box_lading_bill in box_lading_bills"
                  :key="box_lading_bill.id"
                  class="w-100"
                >
                  <CCard>
                    <CCardHeader class="d-flex flex-wrap py-2" color="light">
                      <TMessage
                        :content="box_lading_bill.box_id"
                        bold
                        noTranslate
                        class="mr-1"
                      />
                    </CCardHeader>
                    <CCardBody class="py-1">
                      <TTableAsForm
                        :fields="boxLbFields"
                        :data="box_lading_bill"
                        :splitLeft="5"
                        style="font-size: 0.9rem"
                        :addRowClasses="['pb-1']"
                      >
                        <template #volume>
                          <TMessageVolume :value="box_lading_bill.volume" />
                        </template>
                        <template #weight>
                          <TMessageNumber
                            :value="box_lading_bill.weight"
                            suffix="kg"
                          />
                        </template>
                        <template #type_freight_charge>
                          <SMessageFreightChargeType
                            :id="box_lading_bill.type_freight_charge"
                            :editable="!detail_loading && typeFreightRangeByBox"
                            @change="
                              updateFreightChargeBox(box_lading_bill, $event)
                            "
                          />
                        </template>
                      </TTableAsForm>
                    </CCardBody>
                  </CCard>
                </div>
              </CCol>
              <CCol v-else class="w-100 d-flex flex-wrap">
                <div
                  v-for="box_lading_bill in box_lading_bills"
                  :key="box_lading_bill.id"
                  :class="['m-2', { 'd-flex': !typeFreightRangeByBox }]"
                >
                  <SMessageFreightChargeType
                    v-if="typeFreightRangeByBox"
                    class="mb-1 border-light rounded p-1 shadow-sm"
                    style="width: 185px"
                    :uppercase="false"
                    :id="box_lading_bill.type_freight_charge"
                    editable
                    @change="updateFreightChargeBox(box_lading_bill, $event)"
                  />
                  <SCardSku
                    style="min-width: 185px"
                    :class="typeFreightRangeByBox ? 'h-75' : ''"
                    :box="box_lading_bill.box"
                    removable
                    @remove="removeBox(box_lading_bill)"
                    :focusQuantity="
                      box_lading_bill.box && box_lading_bill.box.is_parent_box
                        ? -1
                        : box_lading_bill.quantity
                    "
                    focusQuantityDescription="Quantity in lading bill"
                    :focusQuantityEdiable="true"
                    @focus-quantity:change="
                      updateQuantity(box_lading_bill, $event)
                    "
                    :showWeight="false"
                    :showSfa="false"
                    :showChildren="false"
                    showOwner
                    :ownerId="lading_bill_selected.customer_id"
                    :orderId="box_lading_bill.order_id"
                    :ladingBillId="lading_bill_selected.id"
                    @decoupe-box-ldb="
                      fetchLadingBillBoxes();
                      fetchLadingBill();
                    "
                    mainQuantityDescription="Total number of boxes"
                  >
                    <template #append-content>
                      <div
                        class="d-flex justify-content-center"
                        v-if="box_lading_bill.order_id"
                      >
                        <SMessageShipmentMethod
                          v-if="box_lading_bill.order"
                          :id="box_lading_bill.order.shipment_method_id"
                          iconOnly
                        />
                        <CBadge color="primary" class="h6">
                          {{ box_lading_bill.order_id }}
                        </CBadge>
                      </div>
                    </template>
                    <template #main-quantity>
                      <TMessage
                        v-if="box_lading_bill.box.is_parent_box"
                        content="Combine box"
                      />
                      <TMessageNumber
                        v-else
                        :value="box_lading_bill.quantity_of_owner"
                      />
                    </template>
                  </SCardSku>
                </div>
              </CCol>
            </CRow>
            <SButtonLoadmore store="warehouse.box_lading_bills" />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  props: {
    lading_bill_id: {
      type: [String, Number],
      required: false,
    },
    showSuggest: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      customer_id_selected: null,
      boxLbFields: [
        { key: "quantity", label: "Quantity" },
        { key: "type_freight_charge", label: "Freight charge type" },
        { key: "volume", label: "Volume" },
        { key: "weight", label: "Weight" },
      ],
      boxListSelected: [],
      submitLoading: false,
    };
  },
  watch: {
    lading_bill_id: {
      immediate: true,
      handler(id) {
        this.boxListSelected = [];
        if (id) {
          if (this.showSuggest) {
            this.$store.commit("warehouse.owning_boxes_available.purge");
            this.$store
              .dispatch("warehouse.lading_bills.detail.fetch", id)
              .then(() => {
                this.fetchBoxesAvailableInLadingBill();
              });
          }
          this.fetchLadingBillBoxes();
        }
      },
    },
  },
  computed: {
    lading_bill_selected() {
      return this.$store.getters["warehouse.lading_bills.detail"];
    },
    detail_loading() {
      return this.$store.getters["warehouse.box_lading_bills.loading"];
    },
    box_lading_bills() {
      return this.$store.getters["warehouse.box_lading_bills.list"] || [];
    },
    boxes_available_in_lading_bill() {
      const list = this.$store.getters["warehouse.owning_boxes_available.list"];
      let boxes = [];
      list?.boxes?.forEach((b) => {
        b.owners.forEach((owner) => {
          boxes = [
            ...boxes,
            {
              ...owner,
              quantity:
                owner.quantity_of_owner_in_area -
                owner.quantity_in_lading_bills -
                owner.quantity_in_goods_deliveries,
            },
          ];
        });
      });
      list?.boxes_parent?.forEach((b) => {
        const box_parent = {
          ...b,
          box_id: b.id,
          box: { ...b, duplicate: 0 },
          quantity: -1,
        };
        boxes = [...boxes, box_parent];
      });
      return boxes || [];
    },
    boxes_available_in_lading_bill_loading() {
      return this.$store.getters["warehouse.owning_boxes_available.fetching"];
    },
    typeFreightRangeByBox() {
      return this.lading_bill_selected.type_freight_range == "box";
    },
  },
  methods: {
    fetchLadingBillBoxes() {
      this.$store.dispatch("warehouse.box_lading_bills.apply-query", {
        "filter[lading_bill_id]": this.lading_bill_id,
        include: "box.children.owners,box.tags,box.children.owners.order",
      });
    },
    removeBox(box_lading_bill) {
      this.$store
        .dispatch("warehouse.box_lading_bills.delete", box_lading_bill.id)
        .then(() => {
          this.fetchBoxesAvailableInLadingBill();
        });
    },
    updateQuantity(box_lading_bill, quantity) {
      this.$store
        .dispatch("warehouse.box_lading_bills.update", {
          id: box_lading_bill.id,
          attributes: { quantity },
        })
        .then(() => {
          this.fetchBoxesAvailableInLadingBill();
        });
    },
    fetchBoxesAvailableInLadingBill() {
      if (this.lading_bill_selected.customer_id) {
        this.$store.dispatch("warehouse.owning_boxes_available.apply-query", {
          customer_id: this.lading_bill_selected.customer_id,
          type: "lading_bill",
          include: "box.tags,order",
        });
      }
    },
    updateFreightChargeBox(box_lading_bill, value) {
      this.$store.dispatch("warehouse.box_lading_bills.update", {
        id: box_lading_bill.id,
        attributes: {
          type_freight_charge: value,
          quantity: box_lading_bill.quantity,
        },
      });
    },
    getBoxToList(box) {
      const existIndex = this.boxListSelected.findIndex((x) => x.id == box.id);
      if (existIndex == -1)
        this.boxListSelected.push({
          id: box.id,
          box_id: box.box_id,
          order_id: box.order_id,
          quantity: Math.abs(box?.quantity) || 1,
        });
      else this.boxListSelected.splice(existIndex, 1);
    },
    onSelectAll() {
      this.boxes_available_in_lading_bill.forEach((box) => {
        const check = this.boxListSelected.find((x) => x.id == box.id);
        if (!check) this.getBoxToList(box);
      });
    },
    createBoxLadingbill(box) {
      this.$store
        .dispatch("warehouse.box_lading_bills.create", {
          quantity: box.quantity,
          order_id: box.order_id,
          box_id: box.box_id,
          lading_bill_id: this.lading_bill_id,
        })
        .then(() => {
          this.fetchLadingBillBoxes();
          this.fetchBoxesAvailableInLadingBill();
          const existIndex = this.boxListSelected.findIndex(
            (x) => x.id == box.id
          );
          if (existIndex >= 0) this.boxListSelected.splice(existIndex, 1);
        });
    },
    submit() {
      this.submitLoading = true;
      let data = { lading_bill_id: this.lading_bill_id };
      let listConvert = [];
      this.boxListSelected.forEach((box) => {
        const indexE = listConvert.findIndex((x) => x.box_id == box.box_id);
        if (indexE >= 0)
          listConvert[indexE]?.list.push({
            order_id: box?.order_id,
            quantity: box?.quantity,
          });
        else {
          const item = this.lodash.pickBy({
            box_id: box.box_id,
            list: [{ order_id: box.order_id, quantity: box.quantity }],
          });
          listConvert = [...listConvert, item];
        }
      });
      listConvert.forEach((box, index) => {
        const boxC = { box_id: box.box_id };
        const boxCArray = this.lodash.mapKeys(boxC, (value, key) => {
          return `boxes[${index}][${key}]`;
        });
        box.list.forEach((item, itemIndex) => {
          const itemCArray = this.lodash.mapKeys(item, (value, key) => {
            return `boxes[${index}][orders][${itemIndex}][${key}]`;
          });
          data = { ...data, ...itemCArray };
        });
        data = { ...data, ...boxCArray };
      });

      this.$tomoni.warehouse.create_many_box_lading_bills
        .create(this.lodash.pickBy(data))
        .then(() => {
          this.fetchLadingBillBoxes();
          this.fetchBoxesAvailableInLadingBill();
          this.boxListSelected = [];
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    fetchLadingBill() {
      this.$store.dispatch("warehouse.lading_bills.fetch");
    },
  },
};
</script>
