<template>
  <CSidebarNavItem
    :name="$t(name)"
    :color="color"
    :icon="icon"
    :to="to"
    :badge="getBadge"
    :exact="exact"
    :disabled="disabled"
    :addLinkClasses="disabled ? 'text-dark' : ''"
  />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    badge: {
      type: Object,
      required: false,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    store() {
      if (this.badge?.store) return this.$store.getters[`${this.badge?.store}`];
      return null;
    },
    getBadge() {
      const badge = {
        text: this.badge?.text,
        color: this.badge?.color,
      };
      this.store
        ? (badge.text = this.store)
        : (badge.text = this.$t(this.badge?.text));
      return badge;
    },
  },
};
</script>
