import Resource from '@/core/store/Resource';
import tomoni from "@/core/services/tomoni";

const resource = new Resource(
  {
    service: 'helper',
    resource: 'user_notifications',
  },
  {
    default_query: {
      sort: '-created_at',
    },
  },
).store()


const PREFIX = "helper.user_notifications";

const actions = {
  [PREFIX + ".detail.mark-as-read"](context) {
    return new Promise((resolve, reject) => {
      tomoni.helper.user_notifications.markAsRead(context.getters[PREFIX + '.detail.id'])
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', {
            ...context.getters[PREFIX + '.detail'],
            read_at: 1
          })
          resolve(data)
        }).catch((error) => {
          context.dispatch('errors.push-http-error', error);
          reject(error);
        });
    });
  },
  [PREFIX + ".mark-all-as-read"](context) {
    return new Promise((resolve, reject) => {
      tomoni.helper.user_notifications.markAllAsRead()
        .then(({ data }) => {
          resolve(data)
        }).catch((error) => {
          context.dispatch('errors.push-http-error', error);
          reject(error);
        });
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};
