<template>
  <div>
    <TMessage :content="sfa.id" noTranslate />
    <TMessage :content="sfa.tracking_code" size="h4" noTranslate />
    <div>
      <TMessage content="Quantity">
        <template #suffix
          >:
          <CBadge color="success"> {{ sfa.received_quantity }} </CBadge>
          /
          <CBadge color="info"> {{ sfa.quantity }} </CBadge>
        </template>
      </TMessage>
    </div>
    <TMessageDateTime
      small
      title="Arrival date"
      :content="sfa.arrival_date"
      class="float-right"
    />
  </div>
</template>

<script>
export default {
  props: {
    sfa: {
      type: Object,
      required: true,
    },
  },
};
</script>
