<template>
  <div>
    <label
      v-if="label"
      for="inputdatepicker"
      :class="['font-weight-bold', { 'mb-0 col-form-label-lg': size == 'lg' }]"
    >
      {{ $t(label) }}
    </label>
    <DatePicker
      id="inputdatepicker"
      :locale="{ id: locale }"
      :mode="mode"
      :attributes="[
        {
          key: 'today',
          highlight: 'red',
          dates: new Date(),
        },
      ]"
      :value="value * 1000"
      :model-config="modelConfig"
      :masks="masksConfig"
      @input="setValue"
      class="w-100"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <input
          :class="['form-control', { 'form-control-lg': size == 'lg' }]"
          :value="inputValue"
          v-on="inputEvents"
          :placeholder="$t(placeholder)"
        />
      </template>
    </DatePicker>
  </div>
</template>

<script>
import { DatePicker } from "v-calendar";
import i18n from "@/core/plugins/i18n";
import mixin from "./mixin";

export default {
  mixins: [mixin],
  components: {
    DatePicker,
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: Math.floor(Date.now() / 1000),
    },
    formatDate: {
      type: String,
      default: "DD-MM-YYYY",
    },
    formatTime: {
      type: String,
      default: "HH:mm:ss",
    },
    dateOnly: {
      type: Boolean,
      default: true,
    },
    timeline: {
      type: String,
      default: "",
      validator: function (value) {
        return ["", "end", "start"].indexOf(value) !== -1;
      },
    },
  },
  data() {
    return {
      modelConfig: {
        type: "number",
        mask: this.mask(),
      },
      masksConfig: {
        input: this.mask(),
      },
      mode: this.dateOnly ? "date" : "dateTime",
    };
  },
  methods: {
    mask() {
      return this.dateOnly
        ? this.formatDate
        : this.formatDate + " " + this.formatTime;
    },
    setValue(value) {
      this.$emit("update:value", this.lodash.toTimeStamp(value, this.timeline));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (!value) return;
        if (value.toString().length <= 10) {
          return;
        }
        this.setValue(value);
      },
    },
  },
  computed: {
    locale() {
      return i18n._vm._data.locale;
    },
  },
};
</script>
