import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'product',
    resource: 'product_tags',
  }
).store()

export default resource
