<template>
  <CButton
    :color="colorBtn"
    @click="invoice.had_cut_off ? unlock() : lock()"
    :disabled="updating"
  >
    <div class="d-flex">
      <TMessage :content="titleBtn" class="mr-1" />
      <strong>
        {{ invoice.name }}
      </strong>
      <CIcon :name="iconBtn" class="ml-1" />
    </div>
  </CButton>
</template>

<script>
export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    store: {
      type: String,
      required: true,
      default: "warehouse.invoices",
    },
  },
  computed: {
    updating() {
      return this.$store.getters[this.store + ".detail.updating"];
    },
    titleBtn() {
      return this.invoice.had_cut_off ? "Unlock invoice" : "Lock invoice";
    },
    colorBtn() {
      return this.invoice.had_cut_off ? "warning" : "success";
    },
    iconBtn() {
      return this.invoice.had_cut_off ? "cil-lock-unlocked" : "cil-lock-locked";
    },
  },
  methods: {
    lock() {
      this.$store.dispatch(this.store + ".detail.lock").then(() => {
        this.$store.dispatch(this.store + ".detail.refresh");
      });
    },
    unlock() {
      this.$store.dispatch(this.store + ".detail.unlock").then(() => {
        this.$store.dispatch(this.store + ".detail.refresh");
      });
    },
  },
};
</script>
