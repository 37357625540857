<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex justify-content-between align-items-center">
        <TMessage content="Shipment info" bold />
        <TSpinner :loading="fetching" />
        <TButton
          color="success"
          variant="outline"
          @click="showModalEdit = true"
          tooltip="Change"
          icon="cil-pencil"
          size="sm"
          class="ml-auto"
        />
      </CCardHeader>
      <CCardBody>
        <TMessageNoData v-if="shipmentInfoEmpty" />
        <TTableAsForm
          v-else
          :data="shipment_infos"
          :fields="shipmentFields"
          :splitLeft="5"
        >
          <template #consignee="{ value }">
            <TMessageText
              :value="value"
              editable
              @change="updateShipmentInfo('consignee', $event)"
            />
          </template>
          <template #sender_name="{ value }">
            <TMessageText
              :value="value"
              editable
              @change="updateShipmentInfo('sender_name', $event)"
            />
          </template>
          <template #sender_full_address="{ data }">
            <SMessageAddress
              :wardId="data.sender_ward_id"
              :address="data.sender_address || ''"
              :content="data.sender_full_address"
              editable
              @change="
                $store.dispatch('order.shipment_infos.update', {
                  id: orderId,
                  attributes: {
                    sender_address: $event.address,
                    sender_ward_id: $event.ward_id,
                  },
                })
              "
            />
          </template>
          <template #full_address="{ data }">
            <SMessageAddress
              :wardId="data.ward_id"
              :address="data.address"
              :content="data.full_address"
              editable
              @change="
                $store.dispatch('order.shipment_infos.update', {
                  id: orderId,
                  attributes: $event,
                })
              "
            />
          </template>
          <template #tel="{ value }">
            <TMessageText
              :value="value"
              editable
              @change="updateShipmentInfo('tel', $event)"
            />
          </template>
          <template #sender_tel="{ value }">
            <TMessageText
              :value="value"
              editable
              @change="updateShipmentInfo('sender_tel', $event)"
            />
          </template>
          <template #note="{ value }">
            <TMessageText
              :value="value"
              editable
              @change="updateShipmentInfo('note', $event)"
            />
          </template>
        </TTableAsForm>
      </CCardBody>
    </CCard>
    <SModalSelectAddress
      :disabled="disabled"
      :show.sync="showModalEdit"
      :userId="userId"
      @click-save="save"
      :key="keyModal"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    userId: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      shipmentFields: [
        { key: "sender_name", label: "Sender name" },
        { key: "sender_full_address", label: "Address" },
        { key: "sender_tel", label: "Tel" },
        { key: "consignee", label: "Consignee" },
        { key: "full_address", label: "Address" },
        { key: "tel", label: "Tel" },
        { key: "note", label: "Note" },
      ],
      showModalEdit: false,
      disabled: false,
      keyModal: "select-address",
    };
  },
  created() {
    this.$store.commit("order.shipment_infos.purge");
    if (this.orderId) {
      this.$store.dispatch("order.shipment_infos.detail.fetch", this.orderId);
    }
  },
  computed: {
    ...mapGetters({
      shipment_infos: "order.shipment_infos.detail",
      fetching: "order.shipment_infos.detail.fetching",
    }),
    shipmentInfoEmpty() {
      return this.lodash.isEmpty(this.shipment_infos);
    },
  },
  methods: {
    updateShipmentInfo(field, value) {
      this.$store.dispatch("order.shipment_infos.update", {
        id: this.orderId,
        attributes: {
          [field]: value,
        },
      });
    },
    save(data) {
      const dataUpdate = {
        address: data.address_detail,
        consignee: data.consignee,
        note: data.note,
        sender_name: data.sender_name,
        sender_tel: data.sender_tel,
        tel: data.tel,
        ward_id: data.ward_id,
        sender_ward_id: data.sender_ward_id,
        sender_address: data.sender_address,
      };
      const dataUpdateClean = this.lodash.pickBy(
        dataUpdate,
        this.lodash.identity
      );
      if (!this.lodash.isEmpty(dataUpdateClean) && !this.shipmentInfoEmpty) {
        this.disabled = true;
        this.$store
          .dispatch(`order.shipment_infos.update`, {
            id: this.orderId,
            attributes: dataUpdateClean,
          })
          .then((data) => {
            this.showModalEdit = false;
            this.$emit("updated", data);
            this.reset();
          })
          .finally(() => {
            this.disabled = false;
          });
      } else {
        this.disabled = true;
        this.$store
          .dispatch(`order.shipment_infos.create`, {
            id_order: this.orderId,
            ...dataUpdateClean,
          })
          .then((data) => {
            this.showModalEdit = false;
            this.$store.commit("order.shipment_infos.detail.merge", data);
            this.reset();
          })
          .finally(() => {
            this.disabled = false;
          });
      }
    },
    reset() {
      this.key = this.lodash.resetKey("select-address");
    },
  },
};
</script>
