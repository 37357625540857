import { ProductService } from "../services";

export const SERVICE = ProductService;
export const RESOURCE = "product-affiliates";

export default {
  attachCategory(id, attributes) {
    return SERVICE.postFormData(`api/${RESOURCE}/${id}/category`, attributes);
  },
  detachCategory(id, attributes) {
    return SERVICE.postFormData(`api/${RESOURCE}/${id}/category`, {
      ...attributes,
      _method: "DELETE"
    });
  },
  attachTag(id, attributes) {
    return SERVICE.postFormData(`api/${RESOURCE}/${id}/product-tags`, attributes);
  },
  detachTag(id, attributes) {
    return SERVICE.postFormData(`api/${RESOURCE}/${id}/product-tags`, {
      ...attributes,
      _method: "DELETE"
    });
  },
};
