<template>
  <div>
    <CCard>
      <CCardHeader class="d-flex align-items-center">
        <TMessage content="Compensation" bold> </TMessage>
        <TSpinner :loading="detail_loading" />
        <div class="ml-auto">
          <TButton
            content="Create compensation"
            variant="outline"
            size="sm"
            color="danger"
            class="mr-1"
            icon="cil-plus"
            @click="showModal = true"
          />
          <TButtonRefresh
            @click="refresh"
            :options="{ disabled: detail_loading }"
          />
        </div>
      </CCardHeader>
      <CCardBody
        v-if="compensations.length"
        class="w-100 mx-auto"
        style="max-width: 800px"
      >
        <div v-for="compensation in compensations" :key="compensation.id">
          <CCard>
            <CCardHeader
              class="d-flex flex-wrap align-items-center py-1"
              color="light"
            >
              #<TMessage
                :content="compensation.id"
                bold
                noTranslate
                class="mr-1"
              />
              <CBadge color="primary" class="d-flex">
                <SMessageOrganization
                  :id="compensation.organization_id"
                  class="my-auto p-1"
                />
              </CBadge>
              <SMessageCurrency
                :id="compensation.currency_id"
                class="ml-1 my-auto"
              />
              <TMessageDateTime
                :content="compensation.created_at"
                class="ml-1"
                dateOnly
              />
              <TButtonDelete
                @click="deleteCompensation(compensation.id)"
                class="ml-auto"
              />
            </CCardHeader>
            <CCardBody class="py-2">
              <TTableAsForm
                :fields="compensationFields"
                :data="compensation"
                :splitLeft="5"
                style="font-size: 1rem"
                :addRowClasses="['pb-1']"
              >
                <template #amount>
                  <TMessageMoney
                    :amount="compensation.amount"
                    :currency="compensation.currency_id"
                    editable
                    @change="
                      updateCompensation(compensation.id, 'amount', $event)
                    "
                  />
                </template>
                <template #paid>
                  <TMessageMoney
                    :amount="compensation.paid"
                    :currency="compensation.currency_id"
                  />
                </template>
                <template #unsolved>
                  <TMessageMoney
                    :amount="compensation.unsolved"
                    :currency="compensation.currency_id"
                  />
                </template>
                <template #note>
                  <TMessageTextarea
                    @change="
                      updateCompensation(compensation.id, 'note', $event)
                    "
                    editable
                    :value="compensation.note"
                  />
                </template>
              </TTableAsForm>
            </CCardBody>
          </CCard>
        </div>
      </CCardBody>
      <TMessageNoData v-else
    /></CCard>
    <SModalCompensation
      :show.sync="showModal"
      @updated="refresh"
      :order_id="orderId"
      type="order"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      compensationFields: [
        { key: "amount", label: "Compensation amount" },
        { key: "paid", label: "Compensated" },
        { key: "unsolved", label: "Uncompensated" },
        { key: "note", label: "Note" },
      ],
    };
  },
  watch: {
    orderId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("order.order_compensations.apply-query", {
            "filter[order_id]": id,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      detail_loading: "order.order_compensations.loading",
      compensations: "order.order_compensations.list",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("order.order_compensations.fetch");
    },
    updateCompensation(id, field, value) {
      this.$store
        .dispatch("order.order_compensations.update", {
          id: id,
          attributes: {
            [field]: value,
          },
        })
        .then(() => {
          this.refresh();
        });
    },
    deleteCompensation(id) {
      this.$store.dispatch("order.order_compensations.delete", id);
    },
  },
};
</script>
