<template>
  <TModal
    title="Create User"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
      />
    </template>
    <CRow>
      <CCol col="12">
        <TInputText
          class="mt-3"
          placeholder="Enter username"
          label="Name"
          :value.sync="data.name"
        >
          <template #prepend-content>
            <CIcon name="cilUser" />
          </template>
        </TInputText>
        <TInputEmail class="mt-3" label="Email" :value.sync="data.email" />
        <TSelect
          label="Role"
          class="mt-3"
          :options="role"
          :loading="role_fetching"
          :value="roleSelected"
          placeholder="Select role"
          @select="selectRole"
          icon="cilPeople"
        />
        <TInputText
          class="mt-3"
          placeholder="Enter tel"
          label="Tel"
          :value.sync="data.tel"
        >
          <template #prepend-content>
            <CIcon name="cilPhone" />
          </template>
        </TInputText>
        <TInputPassword
          class="mt-3"
          label="Password"
          :value.sync="data.password"
          viewable
          autocomplete="new-password"
        />
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      data: {
        name: null,
        tel: null,
        email: null,
        role_id: null,
        password: null,
      },
      roleSelected: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth.users.detail",
      role: "auth.roles.list",
      role_fetching: "auth.roles.fetching",
    }),
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("auth.users.create", this.data)
        .then((data) => {
          this.$redirect.toUserDetail(data.id);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectRole(role) {
      this.data.role_id = role.id;
      this.roleSelected = role;
    },
  },
};
</script>
