import { AccountingService } from '../services'

const prefix = 'api/statistics/'

export default {
  getOutstandingObjects(query) {
    return AccountingService.query(prefix + 'get-outstanding-objects', query);
  },
  getOutstandingReferenceObjects(query) {
    return AccountingService.query(prefix + 'get-outstanding-reference-objects', query);
  },
  getAccountingPeriod(query) {
    return AccountingService.query(prefix + 'accounting-period/objects', query);
  },
  getAccountPeriod(query) {
    return AccountingService.query(prefix + 'account-period', query);
  },
}
