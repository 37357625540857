<template>
  <TModal
    title="Add product"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="add"
    :creating="item.product_id ? false : true"
    :key="key"
  >
    <SSelectProduct :id="item.product_id" @update:id="updateItem">
      <template #append-product-selected="{ product }">
        <TTableAsForm :data="product" :fields="itemFields" :splitLeft="6">
          <template #is_price_with_tax>
            <TSwitch
              :checked="item.is_price_with_tax"
              @change="item.is_price_with_tax = $event"
            />
          </template>
          <template #price="{ value }">
            <TInputMoney
              :value="value"
              @update:value="item.price = $event"
              :currency="currency_id"
            />
          </template>
          <template #quantity>
            <TInputNumber
              :value="item.quantity"
              @update:value="item.quantity = $event"
            />
          </template>
          <template #tax_percent>
            <TInputNumber
              :value="item.tax_percent"
              :maskOptions="{ integerLimit: 2 }"
              @update:value="item.tax_percent = $event"
            />
          </template>
          <template #expected_delivery>
            <TInputDateTime
              :value.sync="item.expected_delivery"
              @update:value="item.expected_delivery = $event"
            />
          </template>
          <template #discount_tax_per_tax_percent>
            <TInputNumber
              :value="item.discount_tax_per_tax_percent"
              :maskOptions="{ integerLimit: 2 }"
              @update:value="item.discount_tax_per_tax_percent = $event"
            />
          </template>
          <template #note="{ value }">
            <TInputTextarea :value="value" @update:value="item.note = $event" />
          </template>
        </TTableAsForm>
        <div class="border-info p-3 rounded h-100">
          <div class="d-flex mb-2">
            <TMessage content="Price" bold size="h6">
              <template #suffix v-if="item.is_price_with_tax">
                ({{ $t("Tax included") }})
              </template>
            </TMessage>
            <TMessageMoney
              :amount="item.price * item.quantity"
              class="ml-auto"
              :currency="currency_id"
            />
          </div>
          <div class="d-flex mb-2">
            <TMessage content="Tax" bold size="h6">
              <template #suffix v-if="item.tax_percent">
                ({{ item.tax_percent }}%)
              </template>
            </TMessage>
            <TMessageMoney
              :amount="tax"
              class="ml-auto"
              :currency="currency_id"
            />
          </div>
          <div class="d-flex mb-2">
            <TMessage content="Discount tax" bold size="h6">
              <template #suffix v-if="item.discount_tax_per_tax_percent">
                ({{ item.discount_tax_per_tax_percent }}%)
              </template>
            </TMessage>
            <TMessageMoney
              :amount="-discount_tax"
              class="ml-auto"
              :currency="currency_id"
            />
          </div>
          <hr />
          <div class="d-flex mb-2">
            <TMessage content="Total amount" bold size="h5" />
            <TMessageMoney
              :amount="totalAmount"
              :currency="currency_id"
              class="ml-auto h4"
            />
          </div>
        </div>
      </template>
    </SSelectProduct>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currency_id: {
      type: String,
      required: false,
    },
  },
  defaultItem: {
    product_id: null,
    price: 0,
    quantity: 1,
    tax_percent: 0,
    discount_tax_per_tax_percent: 0,
    is_price_with_tax: true,
    expected_delivery: null,
    note: null,
  },
  data() {
    return {
      item: this.defaultItem(),
      itemFields: [
        { key: "is_price_with_tax", label: "Use tax-inclusive price" },
        { key: "price", label: "Price" },
        { key: "quantity", label: "Quantity" },
        { key: "tax_percent", label: "Tax percent" },
        { key: "discount_tax_per_tax_percent", label: "Discount tax" },
        { key: "expected_delivery", label: "Desired delivery" },
        { key: "note", label: "Note" },
      ],
      key: "add-item",
    };
  },
  computed: {
    totalPrice() {
      return this.item.quantity * this.item.price;
    },
    totalAmount() {
      return this.totalPrice + this.tax - this.discount_tax || 1;
    },
    tax() {
      if (this.item.is_price_with_tax) return 0;
      return (this.item.tax_percent * this.totalPrice) / 100;
    },
    taxInclusivePricePerPiece() {
      if (this.item.is_price_with_tax)
        return (this.item.price / (100 + this.item.tax_percent)) * 100;
      return 0;
    },
    discount_tax() {
      if (this.item.is_price_with_tax)
        return (
          (this.totalPrice * this.item.discount_tax_per_tax_percent) /
          (100 + this.item.tax_percent)
        );
      return (this.item.discount_tax_per_tax_percent * this.totalPrice) / 100;
    },
  },
  methods: {
    updateItem(id, product) {
      this.item.product = product;
      this.item.product_id = id;
      this.item.price = product.price;
      this.item.tax_percent = product.tax_percent;
    },
    add() {
      this.$emit("click-add", {
        ...this.lodash.omit(this.item, ["is_price_with_tax"]),
        price: this.item.is_price_with_tax
          ? this.taxInclusivePricePerPiece
          : this.item.price,
      });
      this.item = this.defaultItem();
      this.key = this.lodash.resetKey("add-item");
    },
    defaultItem() {
      return { ...this.$options.defaultItem };
    },
  },
};
</script>
