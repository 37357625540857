<template>
  <div>
    <SMessageBoxStatus :box="box" class="float-right ml-2" />
    <TMessage :content="box.id" noTranslate bold size="h4" />
    <div
      v-if="box.tags && box.tags.length"
      class="d-flex scroll-x scroll-hidden"
    >
      <SMessageBoxTag
        v-for="tag in tags"
        :key="tag.id"
        class="mr-1 mb-1"
        :tag="tag"
      />
    </div>
    <div
      v-if="showVolume"
      class="d-flex"
      :title="`${$t('Length')}(cm) x ${$t('Width')}(cm) x ${$t(
        'Height'
      )}(cm) | ${$t('Weight')}(kg)`"
    >
      {{ box.length }} x {{ box.width }} x {{ box.height }} |
      {{ box.weight }}
    </div>
    <TMessage v-if="!parent" content="SFA" noTranslate bold>
      <template #suffix
        >:
        {{ box.sfa_id || "..." }}
      </template>
    </TMessage>
    <TMessage v-if="!parent" content="Quantity" bold>
      <template #suffix
        >:
        <CBadge style="font-size: 85%" color="info">
          {{ box.duplicate }}</CBadge
        >
      </template>
    </TMessage>
    <TMessage v-else content="Child box" bold>
      <template #suffix
        >:
        <CBadge color="primary"> {{ box.children.length }}</CBadge>
      </template>
    </TMessage>
  </div>
</template>

<script>
export default {
  props: {
    box: {
      type: Object,
      required: true,
    },
    parent: {
      type: Boolean,
      required: false,
      default: false,
    },
    showVolume: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    tags() {
      return this.lodash.orderBy(this.box?.tags, ["name.length"], ["desc"]);
    },
  },
};
</script>
