<template>
  <div>
    <TMessage v-if="label" :content="label" bold class="mb-2" />
    <VueFileAgent
      ref="fileAgent"
      :uploadHeaders="{}"
      :multiple="multiple"
      :deletable="true"
      :meta="meta"
      :accept="accept"
      :maxSize="'15MB'"
      :maxFiles="maxFiles"
      :helpText="$t('Select files')"
      :errorText="{
        type: 'Please select images or pdf files',
        size: 'You selected a larger file!',
      }"
      :thumbnailSize="120"
      :theme="theme"
      :value="fileRecords"
      v-bind="options"
      @select="filesSelected($event)"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      v-model="fileRecords"
    ></VueFileAgent>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
    },
    theme: {
      type: String,
      required: false,
      default: "list",
    },
    accept: {
      type: String,
      required: false,
      default: "image/*,.pdf",
    },
    meta: {
      type: Boolean,
      required: false,
      default: true,
    },
    maxFiles: {
      type: Number,
      required: false,
      default: 3,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    options: {
      type: Object,
      required: false,
    },
    compressor: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      fileRecords: [],
      fileRecordsForUpload: [],
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.fileRecords = value;
        this.fileRecordsForUpload = value;
      },
    },
  },
  methods: {
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      this.fileRecordsForUpload =
        this.fileRecordsForUpload.concat(validFileRecords);

      this.$emit("selected", validFileRecords);

      this.emitNewValue();
    },
    onBeforeDelete: function (fileRecord) {
      this.$refs.fileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
    },
    fileDeleted: function (fileRecord) {
      this.lodash.remove(
        this.fileRecordsForUpload,
        (f) => f.url == fileRecord.url
      );

      this.$emit("deleted", fileRecord);
      this.emitNewValue();
    },
    emitNewValue() {
      this.fileRecordsForUpload.forEach((x, i) => {
        const checkMymeImg = [
          "image/png",
          "image/jpeg",
          "image/webp",
          "image/tiff",
        ].includes(x.type);
        if (checkMymeImg && x?.file) {
          this.lodash.compressor(x.file, this.compressor).then((result) => {
            this.fileRecordsForUpload[i].file = result;
          });
        }
      });
      this.$emit(
        "update:value",
        this.fileRecordsForUpload.length ? this.fileRecordsForUpload : []
      );
    },
  },
};
</script>
