import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'functions/decoup-lading-bill'

export default {
  attachLadingbill(attributes) {
    return SERVICE.post(`api/${RESOURCE}`, attributes);
  },
};
