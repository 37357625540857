<template>
  <TModal title="Binding conditions" :show.sync="show" @click-create="save">
    <div class="d-flex align-items-center flex-wrap">
      <TMessage content="Suggest for you" class="font-weight-bold" />

      <TButton
        v-for="suggestItem in suggestConditionList"
        :content="suggestItem.title"
        @click="setTempKey(suggestItem.value)"
        class="m-1 p-1"
        :key="suggestItem.value"
      />
    </div>

    <CRow class="mt-3">
      <CCol>
        <TInputText
          label="Key"
          placeholder="Key"
          :value.sync="temp.key"
          disabled
        />
      </CCol>

      <CCol>
        <SSelectCurrency
          :value.sync="temp.value"
          label="Currency"
          v-if="
            temp.key === 'currency' ||
            (['from', 'to'].includes(temp.key) &&
              groupConditionType === 'exchange-rates')
          "
          :prepend="[]"
        />
        <SSelectShipmentMethod
          :value.sync="temp.value"
          label="Shipment method"
          v-else-if="temp.key === 'shipment-method'"
          :prepend="[]"
        />
        <SSelectArea
          label="Area"
          v-else-if="
            ['from', 'to'].includes(temp.key) &&
            groupConditionType === 'shipping-fee'
          "
          :prepend="[]"
          :value.sync="temp.value"
        />
        <SSelectFreightChargeType
          label="Freight charge type"
          v-else-if="
            ['unit'].includes(temp.key) && groupConditionType === 'shipping-fee'
          "
          :prepend="[]"
          :value.sync="temp.value"
        />
        <TInputText
          v-else
          label="Value"
          placeholder="Value"
          :value.sync="temp.value"
        />
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
export default {
  expose: ["open"],
  data() {
    return {
      loading: false,
      temp: {},
      show: false,
      groupConditionType: "shipping-fee",
    };
  },
  computed: {
    suggestConditionList() {
      if (this.groupConditionType === "shipping-fee") {
        return [
          { title: "Currency", value: "currency" },
          { title: "Shipment method", value: "shipment-method" },
          { title: "From", value: "from" },
          { title: "To", value: "to" },
          // { title: "Freight charge type", value: "unit" },
        ];
      }

      if (this.groupConditionType === "exchange-rates") {
        return [
          { title: "From", value: "from" },
          { title: "To", value: "to" },
        ];
      }

      return [{ title: "Currency", value: "currency" }];
    },
  },
  methods: {
    open(condition, conditionType) {
      this.temp = condition;
      this.show = true;
      this.groupConditionType = conditionType;
    },
    save() {
      if (!this.temp.key || !this.temp.value) return;

      this.loading = true;

      let api = () =>
        this.$tomoni.helper.conditions.update(
          this.temp.id,
          this.lodash.pick(this.temp, ["key", "value"])
        );

      if (!this.temp.id) {
        api = () =>
          this.$tomoni.helper.conditions.create(
            this.lodash.pick(this.temp, ["key", "value", "price_id"])
          );
      }

      api()
        .then(() => {
          this.$emit("changed");
          this.show = false;
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setTempKey(k) {
      this.$set(this.temp, "key", k);
    },
  },
};
</script>
