<template>
  <CCard class="h-100 m-0">
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="Logs" bold />
      <TSpinner :loading="loading" />
      <TButtonRefresh
        class="ml-auto"
        @click="$store.dispatch('helper.logs.change-page', 1)"
      />
    </CCardHeader>
    <CCardBody class="w-100 mx-auto" style="max-width: 800px">
      <div>
        <CInput
          v-if="writable"
          :value.sync="write"
          class="mb-2"
          @input="write = $event"
          @keydown.enter.native="addWrite"
        >
          <template #prepend-content>
            <TMessage content="Note" />
          </template>
          <template #append>
            <TButton
              class="border rounded-right"
              @click="addWrite"
              tooltip="Write"
              icon="cil-pencil"
              variant="ghost"
              :options="{ disabled: fetching }"
            />
          </template>
        </CInput>
        <div v-for="log in logs" :key="log.id" class="d-flex my-1">
          <SMessageLog :log="log" />
        </div>
        <SButtonLoadmore store="helper.logs" />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    logableId: {
      type: String,
      required: true,
    },
    writable: {
      type: Boolean,
      required: false,
      default: true,
    },
    logableType: {
      type: String,
      default: "order",
    },
  },
  data() {
    return {
      write: "",
    };
  },
  watch: {
    logableId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("helper.logs.apply-query", {
            "filter[logable_id]": id,
            page: 1,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      logs: "helper.logs.list",
      fetching: "helper.logs.fetching",
      creating: "helper.logs.creating",
    }),
    loading() {
      return this.fetching || this.creating;
    },
  },
  methods: {
    addWrite() {
      if (this.write !== "") {
        this.$store
          .dispatch("helper.logs.create", {
            logable_id: this.logableId,
            "content[write]": this.write,
            logable_type: this.logableType,
            type_id: "write",
          })
          .then(() => {
            this.write = "";
          });
      }
    },
  },
};
</script>
