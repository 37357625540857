<template>
  <div class="d-flex">
    <CIcon class="mr-1 my-auto" name="cid-file" />
    <TMessage
      class="my-auto"
      :content="receipt.receiptable_id"
      noTranslate
      :truncate="1"
    />
    <div class="ml-auto">
      <TButton
        color="primary"
        size="sm"
        variant="ghost"
        @click="download"
        tooltip="Download"
        icon="cid-cloud-download"
      />
      <TButtonDelete
        @click="$store.dispatch('accounting.transaction_receipts.delete', receipt.id)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    receipt: {
      type: Object,
      required: false,
    },
  },
  methods: {
    getUrlFile() {
      return (
        process.env.VUE_APP_ACCOUNTING_SERVICE_HOST + "/files/" + this.receipt.receiptable_id
      );
    },
    download() {
      window.open(this.getUrlFile());
    },
  },
};
</script>
