<template>
  <TButton
    size="sm"
    content="Debit"
    :color="color"
    @click="$emit('click')"
    :options="options"
  />
</template>

<script>
import mixin from "@/core/components/T/Button/mixin";

export default {
  mixins: [mixin],
  props: {
    to: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
      default: "info",
    },
  },
};
</script>
