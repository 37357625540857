<template>
  <CInput
    :value="input"
    @change="$emit('update:value', $event)"
    class="mb-0"
    :size="size"
    @input="$emit('input', $event)"
  >
    <template #label>
      <div class="d-flex align-items-center">
        <label
          :class="[
            'font-weight-bold',
            { 'mb-0 col-form-label-lg': size == 'lg' },
          ]"
        >
          <TMessage content="Input tracking" />
        </label>
        <CLink class="ml-auto my-auto" @click="createTracking">
          <TMessage content="Create new" />
        </CLink>
      </div>
    </template>
  </CInput>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      input: null,
    };
  },
  watch: {
    value(v) {
      this.input = v;
    },
  },
  methods: {
    createTracking() {
      this.$store.dispatch("order.trackings.create", {}).then((data) => {
        this.input = data.id;
        this.$emit("update:value", data.id);
        this.$emit("created", data);
      });
    },
  },
};
</script>
