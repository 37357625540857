<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <TInputEditable
        v-if="editing"
        :value="amount"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        @close="setEditing(false)"
      >
        <template #input="{ setInput, input }">
          <TInputMoney
            :value="input"
            @keydown.enter.native="
              $emit('change', input);
              setEditing(false);
            "
            @update:value="setInput"
            :currency="currency"
          />
        </template>
      </TInputEditable>
    </slot>
    <TMessage
      v-show="!editing || dontHideWhenEditing"
      :content="moneyConvert.int"
      :bold="!noBold"
      :italic="editable"
      noTranslate
      noWrap
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      @click-edit="showEdit()"
      :color="color"
    >
      <template #suffix>
        <span v-if="moneyConvert.dec" class="small"
          >.{{ moneyConvert.dec }}</span
        >
        <span class="ml-1">{{ lodash.symbolCurrency(currency) }}</span>
      </template>
    </TMessage>
  </div>
</template>

<script>
import actions from "../Button/actions";
import TMessage from "../Message.vue";
import TInputMoney from "../Input/Money.vue";

export default {
  mixins: [actions],
  components: {
    TMessage,
    TInputMoney,
  },
  props: {
    amount: {
      type: Number,
      required: false,
      default: 0,
    },
    currency: {
      type: String,
      required: false,
    },
    decimalLimit: {
      type: Number,
      required: false,
      default: 2,
    },
    prefix: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    noBold: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: this.amount,
    };
  },
  computed: {
    moneyConvert() {
      const part = this.amountMasked().split(".");
      return {
        int: part[0],
        dec: part[1],
      };
    },
  },
  methods: {
    amountMasked() {
      return this.lodash.toMoney(this.amount, {
        currency: this.currency,
        decimalLimit: this.decimalLimit,
        prefix: this.prefix,
        noSymbol: true,
      });
    },
  },
};
</script>
