<template>
  <div>
    <div>
      <label>
        <TMessage bold :content="label" />
      </label>
      <CLink class="float-right" @click="showModal = true">
        <TMessage content="Add new" />
      </CLink>
    </div>
    <TSelect
      class="m-0"
      :options="listC"
      :value="valueSelected"
      :selectOptions="{
        internalSearch: false,
        preserveSearch: true,
      }"
      :normalizer="{ label: 'id', value: 'id' }"
      :placeholder="placeholder"
      :loading="loading"
      @select="select"
      @search="search"
    />
    <TModal
      title="New pallet"
      v-if="showModal"
      :show.sync="showModal"
      @update:show="showModal = $event"
    >
      <template #actions> <span></span></template>
      <CRow class="d-flex">
        <SButtonNewPallet
          v-for="type in pallet_types"
          :key="type.id"
          :type="type"
          class="w-100"
          @created="created"
        />
      </CRow>
    </TModal>
  </div>
</template>
<script>
import mixins from "./mixins";
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";

export default {
  mixins: [mixins],
  props: {
    value: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  created() {
    if (!this.noFetchStore)
      this.$store.dispatch("warehouse.pallets_list.fetch.if-first-time");
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value == null) {
          this.checkValue("");
          return;
        }
        this.checkValue(value);
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.pallets_list.list",
      loading: "warehouse.pallets_list.fetching",
    }),
    pallet_types() {
      return this.$store.getters["warehouse.pallet_types.list"];
    },
  },
  methods: {
    checkValue(value) {
      const firstValue = this.listC.find((x) => x.id === value);
      // Nếu value rỗng
      if (!value && !this.prependAll) {
        this.valueSelected = null;
        return;
      }
      // Value k có trong list
      if (!firstValue) {
        this.$tomoni.warehouse.pallets
          .all({ "filter[id]": value })
          .then(({ data }) => {
            this.firstValue = data.data[0];
            this.valueSelected = data.data[0];
          });
      }
      // Value có trong list
      if (firstValue) {
        this.valueSelected = firstValue;
      }
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("warehouse.pallets_list.push-query", {
        "filter[id]": param,
      });
      if (this.firstValue) this.firstValue = null;
    }, 300),
    created(pallet) {
      this.showModal = false;
      this.$store.commit("warehouse.pallets_list.unshift-list", pallet);
      this.valueSelected = pallet;
    },
  },
};
</script>
