<template>
  <TButton
    :content="content"
    variant="outline"
    icon="cil-arrow-right"
    :to="`/warehouse/internal/owning-boxes/assemble-to-order?${query}`"
  />
</template>

<script>
export default {
  props: {
    query: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      default: "Next to distribute",
    },
  },
};
</script>
