var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100"},[(_vm.label)?_c('TMessage',{class:['mb-2', { 'mb-0 col-form-label-lg': _vm.size == 'lg' }],attrs:{"content":_vm.label,"bold":""}}):_vm._e(),_c('div',{class:{ 'd-flex border rounded overflow-hidden': _vm.append },staticStyle:{"height":"35px"}},[_c('MaskedInput',_vm._b({class:[
        'form-control w-100',
        { 'border-top-0 border-bottom-0 border-left-0 rounded-0': _vm.append },
        { 'form-control-lg': _vm.size == 'lg' },
      ],attrs:{"value":_vm.input,"mask":_vm.lodash.moneyMask(
          _vm.currency ? { currency: _vm.currency } : { noSymbol: true }
        ),"guide":false,"placeholder":`0${
        !!_vm.currency ? ' ' + _vm.lodash.symbolCurrency(_vm.currency) : ''
      }`,"disabled":_vm.disabled},on:{"input":_vm.setValue}},'MaskedInput',_vm.inputOptions,false)),(_vm.append)?_c('TMessage',{staticClass:"input-group-text rounded-0 border-0",attrs:{"bold":"","noTranslate":"","content":_vm.append}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }