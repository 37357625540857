import { AccountingService } from '../services'

export const SERVICE = AccountingService
export const RESOURCE = 'transactions/receive-transfer'

export default {
  receivedFromLocalCurrency(attributes) {
    return SERVICE.postFormData(`api/transactions/received-from-local-currency`, attributes);
  },
  receivedFromForeignCurrency(attributes) {
    return SERVICE.postFormData('api/transactions/received-from-foreign-currency', attributes);
  },
};