<template>
  <CInput
    type="text"
    autocomplete="name"
    class="mb-0"
    :value="input"
    :label="getLabel()"
    :placeholder="$t(placeholder)"
    addLabelClasses="font-weight-bold"
    v-bind="inputOptions"
    @change="setInput"
  >
    <template #prepend-content><CIcon name="cil-user" /></template>
    <template #append>
      <TButtonSave
        @click="$emit('click-save', input)"
        v-if="savable"
        variant="outline"
        :options="{ iconSize: '16'}"
      />
    </template>
  </CInput>
</template>

<script>
import mixins from "./mixins";
export default {
  mixins: [mixins],
  props: {
    savable: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Enter full name",
    },
  },
};
</script>
