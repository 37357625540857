<template>
  <CRow>
    <CCol>
      <TInputRadio
        label="Reference"
        :list="types"
        :value.sync="temp.receiptable_type"
      />
      <div>
        <SSelectOrder
          v-if="temp.receiptable_type === 'order'"
          :value.sync="temp.receiptable_id"
        />
        <SSelectPurchaseOrder
          v-if="temp.receiptable_type === 'purchase'"
          :value.sync="temp.receiptable_id"
        />
        <SSelectContract
          v-if="temp.receiptable_type === 'contract'"
          :value.sync="temp.receiptable_id"
        />
        <SSelectSfa
          v-if="temp.receiptable_type === 'sfa'"
          :value.sync="temp.receiptable_id"
        />
        <SSelectLadingBill
          v-if="temp.receiptable_type === 'lading_bill'"
          :value.sync="temp.receiptable_id"
        />
        <SSelectGoodsDelivery
          v-if="temp.receiptable_type === 'goods_delivery'"
          :value.sync="temp.receiptable_id"
        />
        <SSelectInvoice
          v-if="temp.receiptable_type === 'container'"
          :value.sync="temp.receiptable_id"
        />
        <!-- <SSelectSupplier
          v-if="temp.receiptable_type === 'supplier'"
          :value.sync="temp.receiptable_id"
        /> -->
      </div>
    </CCol>
  </CRow>
</template>

<script>
export default {
  props: {
    receipt: {
      type: Object,
      default: () => ({}),
    },
    typesPick: {
      type: Array,
      default: () => [],
    },
    user_id: {
      type: String,
      default: "",
    },
    all: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      refTypes: [
        { value: "order", label: this.$t("Sale order") },
        { value: "purchase", label: this.$t("Purchase order") },
        { value: "contract", label: this.$t("Contract") },
        { value: "sfa", label: this.$t("SFA") },
        { value: "lading_bill", label: this.$t("Lading bill") },
        { value: "goods_delivery", label: this.$t("Goods delivery") },
        { value: "container", label: this.$t("Container") },
      ],
      temp: this.receipt,
    };
  },
  watch: {
    temp: {
      immediate: true,
      deep: true,
      handler(new_temp) {
        if (!this.lodash.isEqual(this.receipt, new_temp)) {
          this.$emit("update:receipt", new_temp);
        }
      },
    },
    receipt(new_receipt) {
      if (!this.lodash.isEqual(this.temp, new_receipt)) this.temp = new_receipt;
    },
  },
  computed: {
    types() {
      if (this.all && !this.typesPick.length) return this.refTypes;
      return this.refTypes.filter((x) => this.typesPick.includes(x.value));
    },
  },
};
</script>
