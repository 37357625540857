import firebase from "@/core/plugins/firebase";

const messaging = firebase.messaging();

export default {
  onMessage(callback) {
    messaging.onMessage(callback);
    // messaging.getToken({
    //   vapidKey: process.env.VUE_APP_VAPID,
    // })
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       // Send the token to your server and update the UI if necessary
    //       // ...
    //     } else {
    //       // Show permission request UI
    //       console.log(
    //         "No registration token available. Request permission to generate one."
    //       );
    //       // ...
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("An error occurred while retrieving token. ", err);
    //   })
  },
  getToken() {
    return messaging.getToken()
  }
}
