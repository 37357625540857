import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'functions/attach-orders-in-lading-bill-to-contract'

export default {
  attachLadingbill(attributes) {
    return SERVICE.post(`api/${RESOURCE}`, attributes);
  },
};
