const STORE_KEY = "printer_url";

export const get = () => {
  return localStorage.getItem(STORE_KEY)
};

export const set = (url) => {
  return localStorage.setItem(STORE_KEY, url);
};

export default { get, set, }
