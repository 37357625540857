<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <TInputEditable
        v-if="editing"
        :value="range[0]"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        @close="setEditing(false)"
      >
      </TInputEditable>
    </slot>

    <div class="d-flex align-items-center">
      <TMessage
        :content="range_for_render.join(' - ')"
        noTranslate
        :italic="editable"
        v-show="!editing || dontHideWhenEditing"
        :editable="editable"
        :creatable="creatable"
        :removable="removable"
        @click-edit="showEdit()"
        @click-create="showEdit()"
        @click-remove="showModalConfirm = true"
      />

      <span v-if="is_top_range && !editing" class="mx-2">-</span>

      <div v-if="is_top_range && !editing" style="max-width: 100px">
        <TInputNumber
          :value="input.range"
          :append="this.unit"
          placeholder="..."
          @update:value="input.range = $event"
          @keydown.enter.native="$emit('submit-new-range', input.range)"
        />
      </div>
    </div>

    <TModalConfirm
      class="text-dark"
      v-if="removable"
      :show.sync="showModalConfirm"
      @click-confirm="$emit('click-remove')"
    />
  </div>
</template>

<script>
import mixin from "@/core/components/T/Button/actions";

export default {
  mixins: [mixin],
  props: {
    range: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showModalConfirm: false,
      input: {
        range: 0,
      },
    };
  },
  watch: {
    range() {
      this.input.range = 0;
    },
  },
  computed: {
    range_for_render() {
      let r = this.range.map((i) =>
        typeof i === "number"
          ? this.lodash.toNumberMask(i) + (this.unit ? " " + this.unit : "")
          : i
      );

      if (r.length < 2) return r;

      if (r[1] === "∞") return [r[0]];
      else r[1] = `${this.$t("Under").toLowerCase()} ${r[1]}`;

      return r;
    },
    is_top_range() {
      if (this.range[1] === "∞") return true;

      return false;
    },
  },
};
</script>
