<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <TInputEditable
        v-if="editing && !showEditModal"
        :value="content"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        @close="setEditing(false)"
      >
        <template #input="{ input, setInput }">
          <TInputDateTime
            :value="input"
            @update:value="setInput"
            :dateOnly="dateOnly"
          />
        </template>
      </TInputEditable>
      <TModal
        title="Dates"
        v-if="editing && showEditModal"
        :show.sync="editing"
      >
        <template #actions> <span></span></template>
        <TInputEditable
          v-if="editing"
          :value="content"
          class="w-100"
          @change="
            $emit('change', $event);
            setEditing(false);
          "
          @close="setEditing(false)"
        >
          <template #input="{ input, setInput }">
            <TInputDateTime
              :value="input"
              @update:value="setInput"
              :dateOnly="dateOnly"
              class="w-100"
            />
          </template>
        </TInputEditable>
      </TModal>
    </slot>
    <TMessage
      v-show="!editing || dontHideWhenEditing"
      :content="formatTime"
      :size="small ? 'small' : null"
      noTranslate
      :italic="editable"
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      @click-edit="showEdit()"
      @click-create="showEdit()"
      @click-remove="showModalConfirm = true"
    >
    </TMessage>
    <TModalConfirm
      class="text-dark"
      v-if="removable"
      :show.sync="showModalConfirm"
      @click-confirm="$emit('click-remove')"
    />
  </div>
</template>

<script>
import actions from "../Button/actions";
import TMessage from "../Message.vue";
import TInputDateTime from "../Input/DateTime.vue";
export default {
  mixins: [actions],
  components: {
    TMessage,
    TInputDateTime,
  },
  props: {
    content: {
      type: Number,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    dateOnly: {
      type: Boolean,
      default: false,
    },
    showEditModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModalConfirm: false,
    };
  },
  computed: {
    formatTime() {
      if (this.content) {
        const dateOptions = {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        };
        const dateTimeOptions = {
          ...dateOptions,
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
        const intlDate = new Intl.DateTimeFormat(
          "en-GB",
          this.dateOnly ? dateOptions : dateTimeOptions
        );
        // Timestamp format
        return intlDate.format(new Date(1000 * this.content));
      }
      return this.content;
    },
  },
};
</script>
