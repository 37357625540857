import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'product',
    resource: 'categories',
    paginate: false,
    prefix: 'product.product_categories',
    prefix_state: 'product_product_categories',
  },
  {
    default_query: {
      include: "parent",
    },
    default_detail: {
      parent: {
        name: "",
      },
    },
  }
).store()

export default resource
