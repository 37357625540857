import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'goods-delivery-boxes'

export default {
  createMultiple(attributes) {
    return SERVICE.post(`api/goods-delivery-box/create-multiple`, attributes);
  },
  updateShippingCode(attributes) {
    return SERVICE.post(`api/goods-delivery-box/update-shipping-code`, attributes);
  },
};
