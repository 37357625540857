<template>
  <div style="min-height: 64px">
    <TMessage :content="label" class="mb-2" bold />
    <CInputRadioGroup
      :options="options"
      :value="value"
      :checked="value"
      @update:checked="$emit('update:value', $event)"
      inline
      custom
    />
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      require: false,
    },
    value: {
      type: String,
      required: false,
    },
    noTranslate: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    options() {
      return this.lodash.map(this.list, (field) => {
        return {
          value: field.id,
          label: !this.noTranslate ? this.$t(field.name) : field.name,
          ...field,
          props: { addLabelClasses: "mb-2"}
        };
      });
    }
  }
};
</script>
