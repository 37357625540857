import { HelperService } from '../services'

export const SERVICE = HelperService
export const RESOURCE = 'exports'

export default {
  exportInvoice(attributes) {
    return SERVICE.queryBlob(`api/${RESOURCE}/invoice`, attributes);
  },
  exportDistribution(attributes) {
    return SERVICE.queryBlob(`api/${RESOURCE}/product-sorting`, attributes);
  },
  exportPurchase(attributes) {
    return SERVICE.queryBlob(`api/${RESOURCE}/purchase`, attributes);
  },
  exportPurchaseDistribution(attributes) {
    return SERVICE.queryBlob(`api/${RESOURCE}/purchase-distribution`, attributes);
  },
  exportGoodsDelivery(attributes) {
    return SERVICE.queryBlob(`api/${RESOURCE}/goods-delivery`, attributes);
  },
  exportContainerPicker(attributes) {
    return SERVICE.queryBlob(`api/${RESOURCE}/container-picker`, attributes);
  },
  exportTransaction(attributes) {
    return SERVICE.queryBlob(`api/${RESOURCE}/transactions`, attributes);
  },
}
