<template>
  <div>
    <slot name="edit" :editing="editing">
      <TInputEditable v-if="editing" :value="value">
        <template #action="{ input }">
          <TButtonSave @click="save(input)" />
          <TButtonCreate @click="create(input)" />
          <TButtonClose @click="close" />
        </template>
      </TInputEditable>
    </slot>
    <TMessage
      v-show="showTracking && !editing || dontHideWhenEditing"
      :content="value"
      :size="small ? 'small' : null"
      noTranslate
      :italic="editable"
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      @click-edit="showEdit()"
      @click-remove="showConfirmDetachTracking = true"
    >
    </TMessage>
    <CBadge :color="getBadge">
      <TMessage :content="status" />
    </CBadge>
    <TModalConfirm
      :show.sync="showConfirmDetachTracking"
      @click-confirm="$emit('click-remove')"
    />
  </div>
</template>

<script>
import actions from "@/core/components/T/Button/actions";
import TMessage from "@/core/components/T/Message.vue";

export default {
  mixins: [actions],
  components: {
    TMessage,
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTracking: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      showConfirmDetachTracking: false,
    };
  },
  computed: {
    status() {
      return this.checked ? "Received at warehouse" : "Waiting to receive";
    },
    getBadge() {
      switch (this.status) {
        case "Received at warehouse":
          return "primary";
        default:
          return "info";
      }
    },
  },
  methods: {
    save(value) {
      this.setEditing(false);
      this.$emit("change", value);
    },
    create(value) {
      this.$tomoni.order.trackings
        .create(value ? { id: value } : {})
        .then(({ data }) => {
          this.setEditing(false);
          this.$emit("change", data.id);
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        });
    },
    close() {
      this.setEditing(false);
      this.$emit("close");
    },
  },
};
</script>
