import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'lading_bill_id', store: 'warehouse.lading_bills' })
]

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'box_lading_bills',
  },
  {
    default_query: {
      include: 'box.tags,order',
      append: "quantity_of_owner",
      sort: "-id"
    },

  },
  touchs
).store()

export default resource
