<template>
  <CSidebarNavDropdown :items="item" :name="$t(name)" :icon="icon" :route="route" />
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    route: {
      type: String,
      required: false,
    },
  },
  computed: {
    item() {
      return this.lodash.map(this.items, (field) => {
        return {
          exact: false,
          ...field,
          name: this.$t(field.name),
        };
      });
    },
  },
};
</script>
