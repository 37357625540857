<template>
  <TMessageStatus :id="id" :statuses="statuses" :color="getBadge" />
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false,
    },
    store: {
      type: String,
      required: false,
      default: "order.statuses",
    },
  },
  computed: {
    statuses() {
      return this.$store.getters[`${this.store}.list`];
    },
    getBadge() {
      switch (this.id) {
        case "ReceivedAtWarehouse":
          return "primary";
        case "WaitingToReceive":
          return "info";
        case "Newish":
          return "dark";
        case "Paid":
        case "Finish":
          return "success";
        case "OutOfStock":
        case "Cancelled":
          return "danger";
        default:
          return "warning";
      }
    },
  },
};
</script>
