<template>
  <TMessageStatus :id="id" :color="getBadge" :statuses="statuses" />
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    statuses() {
      return this.$store.getters["warehouse.goods_delivery_statuses.list"];
    },
    getBadge() {
      switch (this.id) {
        case "waiting_shipment":
          return "info";
        case "shipping":
          return "primary";
        case "received":
          return "success";
        case "cancelled":
          return "danger";
        default:
          return "warning";
      }
    },
  },
};
</script>
