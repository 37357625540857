import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'product',
    resource: 'ecommerce_channels',
    paginate: false
  }
).store()

export default resource
