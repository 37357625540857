<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      preserveSearch: true,
      ...customOptionLabel,
    }"
    :normalizer="{ label: 'code_ref', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
  />
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    prependAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      type: [String, Number],
      required: false,
      default: function () {
        return "";
      },
    },
    prepend: {
      type: Object,
      required: false,
      default: function () {
        return { id: "", name: this.$t("All") };
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Code ref",
    },
    noCustomLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstValue: null,
      valueSelected: null,
    };
  },
  created() {
    this.$store.dispatch("accounting.transactions.fetch.if-first-time");
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value == null) {
          this.checkValue("");
          return;
        }
        this.checkValue(value);
      },
    },
    valueSelected: {
      immediate: false,
      handler(value) {
        if (value) {
          this.$emit("update:value", value.id);
          this.$emit("change", value);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "accounting.transactions.list",
      loading: "accounting.transactions.fetching",
    }),
    listC() {
      const prepend = this.prependAll ? this.prepend : null;
      const firstValue = this.firstValue ? this.firstValue : null;
      const list = this.lodash.uniqBy(
        [prepend, firstValue, ...this.list],
        "id"
      );
      return this.lodash.filter(list, (x) => {
        return x != null;
      });
    },
    customOptionLabel() {
      return !this.noCustomLabel ? { "custom-label": this.customLabel } : {};
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
    },
    checkValue(value) {
      const firstValue = this.listC.find((x) => x.id === value);
      // Nếu value rỗng
      if (!value && !this.prependAll) {
        this.valueSelected = null;
        return;
      }
      // Value k có trong list
      if (!firstValue) {
        this.$tomoni.accounting.transactions
          .all({ "filter[id]": value })
          .then(({ data }) => {
            this.firstValue = data.data[0];
            this.valueSelected = data.data[0];
          });
      }
      // Value có trong list
      if (firstValue) {
        this.valueSelected = firstValue;
      }
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("accounting.transactions.apply-query", {
        "filter[code_ref]": param,
      });
      if (this.firstValue) this.firstValue = null;
    }, 300),
    customLabel({ code_ref, amount, currency_id, description }) {
      return `${code_ref} - ${this.lodash.toMoney(amount, {
        currency: currency_id,
      })} – ${description}`;
    },
  },
};
</script>
