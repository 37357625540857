<template>
  <CDataTable
    border
    fixed
    :column-filter="columnFilter"
    :pagination="pagination"
    :size="size"
    :items="items"
    :fields="advanceFields"
    addTableClasses="text-nowrap"
    @filtered-items-change="itemsChanged"
    :loading="loading"
    v-bind="options"
  >
    <template #no-items-view>
      <TMessage content="Empty" italic color="muted" class="py-2" />
    </template>
    <template #footer>
      <tr v-if="!noResult" class="bg-gray-100 text-dark">
        <slot
          v-for="column in columnResults"
          :name="`result-${column.key}`"
          v-bind="{
            field: column,
            total: calTotal(column.key, items),
          }"
        >
          <th :key="`result-${column.key}`">
            {{ column.result }}
          </th>
        </slot>
      </tr>
      <tr v-if="columnInput">
        <slot
          v-for="(field, index) in fields"
          :name="`input-${field.key}`"
          v-bind="{
            selected: selected,
            dataInput: dataInput,
            field: field,
            eventUpdate: onInput,
          }"
        >
          <th :key="index">
            <div>
              <CInput
                v-if="isShowInput(field)"
                :placeholder="field.placeholder"
                :type="field.inputType || 'text'"
                class="mb-0"
                :value="
                  selected[field.key] ||
                  dataInput[field.key] ||
                  field.inputDefault
                "
                @update:value="onInput(field.key, $event, field)"
              />
            </div>
          </th>
        </slot>
      </tr>
    </template>
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
      ><slot :name="name" v-bind="slotData"
    /></template>
    <template #details v-if="$slots['details']">
      <slot name="details" v-bind="slotData"> </slot>
    </template>
  </CDataTable>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    columnFilter: {
      type: Boolean,
      default: false,
    },
    columnInput: {
      type: Boolean,
      default: false,
    },
    noResult: {
      type: Boolean,
      default: false,
    },
    defaultSelected: {
      type: Object,
      required: false,
      default: null,
    },
    size: {
      type: String,
      default: "sm",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columnResults: [],
      selected: this.defaultSelected ?? {},
      dataInput: {},
    };
  },
  watch: {
    items(newItems) {
      this.itemsChanged(newItems);
    },
  },
  computed: {
    advanceFields() {
      return this.lodash.map(this.fields, (field) => {
        return {
          sorter: false,
          filter: false,
          _classes: "text-truncate",
          ...field,
          label: field.label ? this.$t(field.label) : undefined,
        };
      });
    },
  },
  methods: {
    calTotal(field, items) {
      return this.lodash.sumBy(items, field);
    },
    itemsChanged(items) {
      this.columnResults = this.fields.map((f) => {
        if (f.showCount) {
          return {
            ...f,
            result:
              (f.countLabel ? this.$t(f.countLabel) + ": " : "") +
                items.length ?? 0,
          };
        }
        if (!f.showTotal) {
          return f;
        }

        return {
          ...f,
          result:
            (f.totalLabel ? this.$t(f.totalLabel) + ": " : "") +
            (f.totalValue ?? this.calTotal(f.key, items)),
        };
      });
    },
    onInput(key, value, field) {
      this.dataInput = { ...this.dataInput, [key]: value };
      if (field && field.bindInput) {
        const mergeDefault = field.bindInput(value);
        this.dataInput = {
          ...this.dataInput,
          ...mergeDefault,
        };
        this.selected = {
          ...this.selected,
          ...mergeDefault,
        };
      }
      this.$emit("update:create", this.dataInput);
    },
    isShowInput(field) {
      return field.showInput ?? !field.showTotal;
    },
  },
};
</script>
