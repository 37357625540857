<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <SSelectAccount
        v-if="editing"
        :value="value"
        @close="setEditing(false)"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        :editable="editable"
      />
    </slot>
    <TMessage
      v-show="!editing || dontHideWhenEditing"
      :content="getAccount"
      :editable="editable"
      noTranslate
      @click-edit="showEdit()"
    />
  </div>
</template>

<script>
import mixin from "@/core/components/T/Button/actions";
import { mapGetters } from 'vuex';

export default {
  mixins: [mixin],
  props: {
    value: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      accounts: "accounting.accounts.list",
    }),
    getAccount() {
      const account = this.lodash.find(this.accounts, { id: this.value });
      if (!account) {
        return this.value;
      }
      if (account) return account.id + '-' + account.name;
      return this.$t("Empty");
    },
  },
};
</script>
