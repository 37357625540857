<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <SSelectShipmentMethod
        v-if="editing"
        :prepend="[]"
        :value="id"
        @close="setEditing(false)"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        :editable="editable"
      />
    </slot>
    <div v-if="iconOnly" :class="'text-' + getColor">
      <CIcon
        :name="getIcon"
        class="mr-1"
        size="custom"
        style="height: 20px; width: 20px"
      />
    </div>
    <TMessage
      v-else
      :content="getStatus"
      :color="getColor"
      :italic="editable"
      noTranslate
      v-show="!editing || dontHideWhenEditing"
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      @click-edit="showEdit()"
      ><template #prefix><CIcon :name="getIcon" class="mr-1" /></template
    ></TMessage>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mixin from "@/core/components/T/Button/actions";

export default {
  mixins: [mixin],
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      statuses: "warehouse.shipment_methods.list",
    }),
    IdConvert() {
      return this.lodash.lowerCase(this.id);
    },
    getIcon() {
      switch (this.IdConvert) {
        case "air":
          return "cis-flight-takeoff";
        case "sea":
          return "cisBoat";
        default:
          return "cil-ban";
      }
    },
    getColor() {
      switch (this.IdConvert) {
        case "air":
          return "primary";
        case "sea":
          return "danger";
        default:
          return "warning";
      }
    },
    getStatus() {
      const status = this.statuses?.find((x) => x.id === this.IdConvert);
      if (!status && this.IdConvert) {
        return this.IdConvert;
      }
      if (status) return status.name;
      return this.$t("Empty");
    },
  },
};
</script>
