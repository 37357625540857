<template>
  <CCard class="h-100 m-0">
    <CCardHeader class="d-flex align-items-center">
      <TMessage content="SKU list" bold />
    </CCardHeader>
    <CCardBody
      v-if="owningBoxes"
      class="w-100 mx-auto"
      style="max-width: 800px"
    >
      <div class="w-100">
        <CCol v-for="box in owningBoxes" :key="box.id" class="p-0 w-100">
          <CCard class="mb-2 w-100" borderColor="primary">
            <CCardHeader class="p-1" color="primary">
              <TMessage content="SKU Id" color="white" bold>
                <template #suffix> <span>:</span> {{ box.box_id }} </template>
              </TMessage>
            </CCardHeader>
            <CCardBody class="p-2">
              <TTableAsForm
                :data="box"
                :fields="pivotboxFields"
                :splitLeft="6"
                :addRowClasses="[]"
                class="mb-0"
              >
                <template #created_at="{ value }">
                  <TMessageDateTime :content="value" dateOnly />
                </template>
                <template #quantity>
                  <div class="d-flex align-items-center">
                    <TMessageNumber class="h6 mb-0" :value="box.quantity" />
                    <CBadge
                      v-if="box.quantity == 0"
                      color="danger"
                      class="ml-1"
                    >
                      <TMessage content="Distributed canceled" bold />
                    </CBadge>
                  </div>
                </template>
              </TTableAsForm>
              <CRow class="mt-3">
                <CCol
                  col="12"
                  md="12"
                  v-for="item in box.box.items"
                  :key="item.id"
                  class="mb-3"
                >
                  <SCardProductItems :item="item.product" widthAuto tiny>
                    <template #append-content>
                      <CRow>
                        <CBadge
                          color="info"
                          class="d-flex mt-1 mr-1"
                          style="font-size: 90%"
                        >
                          <TMessageNumber :value="item.quantity" />
                          <TMessage class="ml-1" content="Piece" />
                          <span class="mx-1">/</span> <TMessage content="Box" />
                        </CBadge>
                        <CBadge
                          color="primary"
                          class="d-flex mt-1 mr-1"
                          style="font-size: 90%"
                          v-if="box.box"
                        >
                          <TMessage class="mr-1" content="Total:" />
                          {{ getTotalDistributed(item.quantity, box.quantity) }}
                          <TMessage class="ml-1" content="Piece" />
                        </CBadge>
                        <CBadge
                          v-if="checkMiss(item.product_id)"
                          color="danger"
                          class="d-flex flex-wrap mt-1"
                          style="font-size: 90%"
                        >
                          {{ $t("Missing") }}
                          {{ getRemaining(item.product_id) }}
                          <TMessage class="mx-1" content="Piece" />
                          ({{
                            getRemainingBox(item.product_id, item.quantity)
                          }}
                          {{ $t("boxes") }})
                        </CBadge>
                        <CBadge
                          v-if="checkEnough(item.product_id)"
                          color="success"
                          class="d-flex mt-1 mr-1"
                          style="font-size: 90%"
                        >
                          <TMessage content="Enough quantity" />
                        </CBadge>
                        <CBadge
                          v-if="checkExceed(item.product_id)"
                          color="warning"
                          class="d-flex mt-1 mr-1"
                          style="font-size: 90%"
                        >
                          <TMessage content="exceeds" class="mr-1" />
                          {{ getAbsRemaining(item.product_id) }}
                          <TMessage class="mx-1" content="Piece" />
                        </CBadge>
                      </CRow>
                    </template>
                  </SCardProductItems>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </div>
      <TMessageNoData v-if="!owningBoxes.length" />
    </CCardBody>
  </CCard>
</template>
<script>
export default {
  props: {
    orderItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    owningBoxes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      pivotboxFields: [
        { key: "created_at", label: "Created at" },
        { key: "quantity", label: "Number of boxes" },
      ],
    };
  },
  methods: {
    getTotalDistributed(quantity_in_box, boxes_quantity) {
      return quantity_in_box * boxes_quantity;
    },
    getItemQuantity(product_id) {
      return (
        this.orderItems?.find((x) => x.product_id == product_id)?.quantity || 0
      );
    },
    getTotalSKUQuantity(product_id) {
      const listSKU = [];
      this.owningBoxes.forEach((x) => {
        const item = x?.box?.items.find((x) => x.product_id == product_id);
        if (item)
          listSKU.push({
            ...item,
            total_quantity: x.quantity * item.quantity,
          });
      });
      return this.lodash.sumBy(listSKU, "total_quantity");
    },
    getRemaining(product_id) {
      return (
        this.getItemQuantity(product_id) - this.getTotalSKUQuantity(product_id)
      );
    },
    getAbsRemaining(product_id) {
      return Math.abs(this.getRemaining(product_id));
    },
    getRemainingBox(product_id, quantity) {
      return Math.round((this.getRemaining(product_id) * 100) / quantity) / 100;
    },
    checkExceed(product_id) {
      return this.orderItems.length && this.getRemaining(product_id) < 0;
    },
    checkEnough(product_id) {
      return this.orderItems.length && !this.getRemaining(product_id);
    },
    checkMiss(product_id) {
      return this.orderItems.length && this.getRemaining(product_id) > 0;
    },
  },
};
</script>
