import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'order',
    resource: 'order_types',
    paginate: false,
  },
).store()

export default resource
