<template>
  <CTextarea
    :value="value"
    @change="$emit('update:value', $event)"
    class="mb-0 font-weight-bold"
    :label="getLabel()"
    :placeholder="$t(placeholder)"
    v-bind="inputOptions"
  >
    <template #prepend-content>
      <slot name="prepend-content"> </slot>
    </template>
    <template #append>
      <slot name="append"> </slot>
    </template>
  </CTextarea>
</template>

<script>
import mixins from "./mixins";

export default {
  mixins: [mixins],
};
</script>
