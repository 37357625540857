const Dashboard = () => import('../views/Dashboard')
const Settings = () => import('../views/settings/Index')
const InboundPurchaseOrder = () => import('../views/inbound/PurchaseOrder')
const AssembleToStock = () => import('../views/internal/AssembleToStock')
const AssembleToOrder = () => import('../views/internal/AssembleToOrder')
const LadingBills = () => import('../views/internal/LadingBills')
const Invoices = () => import('../views/internal/Invoices')
const GoodsDeliveries = () => import('../views/internal/GoodsDeliveries')
const OutboundTransfer = () => import('../views/outbound/Transfer')
const OutboundSaleOrder = () => import('../views/outbound/SaleOrder')
const Storage = () => import('../views/internal/Storage')
const Agency = () => import('../views/management/Agency')
const TransferOrder = () => import('../views/inbound/TransferOrder')
const Decouple = () => import('../views/internal/Decouple')
const Combine = () => import('../views/internal/Combine')
const BoxManagement = () => import('../views/internal/BoxManagement')
const OwningBoxes = () => import('../views/internal/OwningBoxes')
const ContainerInspection = () => import('../views/internal/ContainerInspection.vue')
const NotDistributed = () => import('../views/internal/NotDistributed')
const GoodsStatistics = () => import('../views/internal/GoodsStatistics')
const NewSupport = () => import('@/workspaces/admin/views/support/NewSupport')
const Supports = () => import('@/workspaces/admin/views/support/Index')
const SupportDetail = () => import('@/workspaces/admin/views/support/SupportDetail')
const Products = () => import('@/workspaces/admin/views/goods/Index')
const Product = () => import('@/workspaces/admin/views/goods/Detail')
const NewProduct = () => import('@/workspaces/admin/views/goods/New')
const DeliveryPartners = () => import('../views/management/DeliveryPartners')
const TrackingImages = () => import('../views/inbound/TrackingImages')
const DeliveryAddress = () => import('../views/management/DeliveryAddress')
const Tracking = () => import('../views/management/Tracking')
const DecoupleBoxItem = () => import('../views/internal/DecoupleBoxItem')
const Inventory = () => import('../views/internal/Inventory')
const FreightForwarder = () => import('../views/management/FreightForwarder')
const ContainerType = () => import('../views/management/ContainerType')
const InventoryByCustomer = () => import('../views/internal/InventoryByCustomer')
const CleanUpWarehouse = () => import('../views/internal/CleanUpWarehouse')

export default [
  {
    path: 'dashboard',
    name: 'Warehouse Dashboard',
    component: Dashboard,
  },
  {
    path: 'inbound',
    meta: {
      label: "Inbound"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "tracking-images",
        name: "Tracking images",
        component: TrackingImages,
      },
      {
        path: "purchase-order",
        name: "Purchase order",
        component: InboundPurchaseOrder,
      },
      {
        path: "transfer-order",
        name: "Inbound transfer order",
        component: TransferOrder,
      },
    ]
  },
  {
    path: 'internal',
    meta: {
      label: "Internal"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "assemble-to-stock",
        name: "Assemble to stock",
        component: AssembleToStock,
      },
      {
        path: "owning-boxes",
        name: "Owning box",
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "assemble-to-order",
            name: "Assemble to order",
            component: AssembleToOrder,
          },
          {
            path: "distributed",
            name: "Distributed goods",
            component: OwningBoxes,
          },
          {
            path: "not-distributed-yet",
            name: "Goods not yet distributed",
            component: NotDistributed,
          },
        ]
      },
      {
        path: "lading-bills",
        name: "Lading bills",
        component: LadingBills,
      },
      {
        path: "invoices",
        name: "Invoices",
        component: Invoices,
      },
      {
        path: "goods-deliveries",
        name: "Goods deliveries",
        component: GoodsDeliveries,
      },
      {
        path: "boxes",
        name: "Box",
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "decouple",
            name: "Decouple",
            component: Decouple,
          },
          {
            path: "combine",
            name: "Combine",
            component: Combine,
          },
          {
            path: "management",
            name: "Box management",
            component: BoxManagement,
          },
          {
            path: "decouple-box-item",
            name: "Decouple box item",
            component: DecoupleBoxItem,
          },
        ]
      },
      {
        path: "storage",
        name: "Store",
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "model",
            name: "Warehouse model",
            component: Storage,
          },
          {
            path: "statistics",
            name: "Goods statistics",
            component: GoodsStatistics,
          },
          {
            path: "inventory",
            name: "Inventory",
            component: Inventory,
          },
          {
            path: "inventory-by-customer",
            name: "Inventory by customer",
            component: InventoryByCustomer,
          },
          {
            path: "clean-up",
            name: "Clean up warhouse",
            component: CleanUpWarehouse,
          },
        ]
      },
      {
        path: "container-inspection",
        name: "Container inspection",
        component: ContainerInspection,
      },
    ]
  },
  {
    path: 'outbound',
    meta: {
      label: "Outbound"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "transfer-order",
        name: "Outbound transfer order",
        component: OutboundTransfer,
      },
      {
        path: "sales-order",
        name: "Outbound sale order",
        component: OutboundSaleOrder,
      },
    ]
  },
  {
    path: 'management',
    meta: {
      label: "Management"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "agency",
        name: "Agency",
        component: Agency,
      },
      {
        path: "freight-forwarder",
        name: "Freight forwarder",
        component: FreightForwarder,
      },
      {
        path: "container-type",
        name: "Container type",
        component: ContainerType,
      },
      {
        path: "tracking",
        name: "Tracking",
        component: Tracking,
      },
      {
        path: "delivery-partner",
        name: "Delivery partner",
        component: DeliveryPartners,
      },
      {
        path: "delivery-address",
        name: "Delivery address",
        component: DeliveryAddress,
      },
      {
        path: 'products',
        meta: { label: 'Product' },
        component: {
          render(c) { return c('router-view') }
        },
        children: [
          {
            path: '',
            meta: {
              label: 'Product',
              title: 'Product'
            },
            component: Products
          },
          {
            path: 'new',
            meta: {
              label: 'New Product',
              title: 'New product'
            },
            component: NewProduct
          },
          {
            path: ':id',
            meta: {
              label: 'Detail',
              title: 'Detail'
            },
            component: Product
          }
        ]
      },
    ]
  },
  {
    path: 'settings',
    meta: {
      label: "Settings"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "general",
        name: "General",
        component: Settings,
      },
    ]
  },
  {
    path: "supports",
    meta: { label: "Support" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        meta: {
          label: "Support",
          title: "Support"
        },
        component: Supports
      },
      {
        path: 'new',
        meta: {
          label: 'New support',
          title: 'New support'
        },
        component: NewSupport
      },
      {
        path: ":id",
        meta: {
          label: "Support",
          title: 'Support'
        },
        component: SupportDetail
      }
    ]
  },
  {
    path: "/warehouse/*",
    meta: {
      title: "Not found",
      label: "Not found"
    },
    component: () => import("@/workspaces/sale/views/Page404"),
  }
]
