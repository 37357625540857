<template>
  <div>
    <TInputRadio
      label="Currency"
      :value="value"
      :list="list"
      @update:value="$emit('update:value', $event)"
      class="mb-3"
      noTranslate
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
  },
  computed: {
    list() {
      return this.$store.getters["accounting.currencies.list"];
    },
  },
};
</script>
