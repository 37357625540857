import Resource, { Touch } from '@/core/store/Resource';

const touchs = [
  new Touch({ key: 'purchase_item', store: 'order.purchase_items' })
]

const resource = new Resource(
  {
    service: 'order',
    resource: 'distributions'
  },
  touchs,
).store()

export default resource
