<template>
  <div v-if="!lodash.isEmpty(product)">
    <CMedia
      :style="[
        { 'min-width': tiny ? 'auto' : widthAuto ? 'auto' : '400px' },
        { 'min-height': tiny ? '50px' : '70px' },
      ]"
    >
      <template #aside>
        <CImg
          :src="product.image_url"
          thumbnail
          :width="tiny ? '50px' : '70px'"
          style="min-width: 70px; height: 70px; object-fit: contain"
          :style="[
            { 'object-fit': 'contain' },
            tiny
              ? { 'min-width': '50px', height: '50px' }
              : { 'min-width': '70px', height: '70px' },
          ]"
          class="p-0"
        />
      </template>
      <CCol col="12">
        <CRow v-if="showName">
          <TLink
            v-if="resource"
            :to="`${resource}/${product.id}`"
            :content="product.name"
            :size="tiny ? 'small' : ''"
            :messageOptions="{ truncate: 2 }"
          />
          <TMessage
            v-if="!resource"
            noTranslate
            :content="product.name"
            :truncate="2"
            :size="tiny ? 'small' : ''"
          />
        </CRow>
        <CRow v-if="product.brand">
          <TMessage
            v-show="!editing || dontHideWhenEditing"
            :content="product.brand"
            noTranslate
            class="small"
          >
          </TMessage>
        </CRow>
        <CRow v-if="product.id">
          <TMessage
            v-show="!editing || dontHideWhenEditing"
            :content="product.id"
            noTranslate
            :editable="editable"
            :removable="removable"
            @click-edit="showEdit()"
            @click-remove="$emit('click-remove', product.id)"
            class="small"
          >
          </TMessage>
        </CRow>
        <CRow v-if="itemsCount > 1">
          <TMessage
            content="other items"
            size="small"
            color="muted"
            :truncate="1"
          >
            <template #prefix> +{{ itemsCount - 1 }} </template>
          </TMessage>
        </CRow>
        <CRow v-if="showTag" class="align-items-baseline">
          <CBadge color="danger" class="mr-1 mb-1">
            <TMessage v-if="product.banned_sea" content="Banned sea" />
          </CBadge>
          <CBadge color="danger" class="mr-1 mb-1">
            <TMessage v-if="product.banned_air" content="Banned air" />
          </CBadge>
          <CBadge color="danger" class="mr-1 mb-1">
            <TMessage v-if="product.banned_export" content="Banned export" />
          </CBadge>
          <CBadge color="danger" class="mr-1 mb-1">
            <TMessage v-if="product.special" content="Special goods" />
          </CBadge>
          <CBadge color="danger" class="mr-1 mb-1">
            <TMessage
              v-if="product.private_license"
              content="Private license"
            />
          </CBadge>
        </CRow>
        <slot name="append-content"></slot>
      </CCol>
    </CMedia>
    <slot name="append" :product="product"></slot>
    <div>
      <slot name="edit" :editing="editing">
        <TInputEditable v-if="editing" :value="product.id">
          <template #action="{ input }">
            <TButtonSave @click="save(input)" />
            <TButtonCreate @click="create(input)" />
            <TButtonClose @click="close" />
          </template>
        </TInputEditable>
      </slot>
    </div>
  </div>
  <div v-else>
    <CMedia
      :style="[
        { 'min-width': tiny ? 'auto' : widthAuto ? 'auto' : '400px' },
        { 'min-height': tiny ? '50px' : '70px' },
      ]"
    >
      <template #aside>
        <CImg
          src=""
          thumbnail
          :width="tiny ? '50px' : '70px'"
          style="min-width: 70px; height: 70px; object-fit: contain"
          :style="[
            { 'object-fit': 'contain' },
            tiny
              ? { 'min-width': '50px', height: '50px' }
              : { 'min-width': '70px', height: '70px' },
          ]"
          class="p-0"
        />
      </template>
      <TMessageNotFound />
      <CCol col="12">
        <slot name="append-content"></slot>
      </CCol>
    </CMedia>
  </div>
</template>

<script>
import actions from "@/core/components/T/Button/actions";
export default {
  mixins: [actions],
  props: {
    item: {
      type: Object,
      required: false,
    },
    itemsCount: {
      type: Number,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    resource: {
      type: String,
      required: false,
    },
    showName: {
      type: Boolean,
      required: false,
      default: true,
    },
    showTag: {
      type: Boolean,
      required: false,
      default: true,
    },
    tiny: {
      type: Boolean,
      default: false,
    },
    widthAuto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      product: {},
    };
  },
  watch: {
    item: {
      immediate: true,
      handler(item) {
        this.applyItems(item);
      },
    },
  },
  methods: {
    applyItems(item) {
      this.product = item;
    },
    save(value) {
      this.setEditing(false);
      this.$emit("change", value);
    },
    create(value) {
      this.$tomoni.order.trackings
        .create(value ? { id: value } : {})
        .then(({ data }) => {
          this.setEditing(false);
          this.$emit("change", data.id);
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        });
    },
    close() {
      this.setEditing(false);
      this.$emit("close");
    },
  },
};
</script>
