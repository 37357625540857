<template>
  <div>
    <CLink
      v-if="goods_delivery.status && goods_delivery.status.name"
      class="float-right"
    >
      <SMessageDeliveryStatus :id="goods_delivery.status.id" />
    </CLink>
    <TMessage
      :content="goods_delivery.id"
      bold
      noTranslate
      size="h5"
      :truncate="1"
    />
    <TMessage
      v-if="goods_delivery.customer"
      :content="goods_delivery.customer.name"
      bold
      noTranslate
      class="h6 mb-0"
      :truncate="1"
    />
    <TMessage content="To">
      <template #suffix
        >:
        {{
          goods_delivery.place_of_delivery
            ? goods_delivery.place_of_delivery.consignee +
              " - " +
              goods_delivery.place_of_delivery.full_address
            : "???"
        }}
      </template>
    </TMessage>
    <TMessage content="Partner">
      <template #suffix
        >:
        {{
          goods_delivery.delivery_partner
            ? goods_delivery.delivery_partner.name
            : "..."
        }}
      </template>
    </TMessage>

    <TMessage content="Picked">
      <template #suffix
        >:
        <CBadge color="success"> {{ count_picked }} </CBadge>
        /
        <CBadge color="info"> {{ count_items }} </CBadge>
      </template>
    </TMessage>
  </div>
</template>

<script>
export default {
  props: {
    goods_delivery: {
      type: Object,
      required: true,
    },
  },
  computed: {
    count_items() {
      return this.lodash.sumBy(this.goods_delivery.pivot_boxes, "quantity");
    },
    count_picked() {
      return this.lodash.sumBy(
        this.goods_delivery.outbound_pickers,
        "quantity"
      );
    },
  },
};
</script>
