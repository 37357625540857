<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="list"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: false,
      preserveSearch: true,
      ...customOptionLabel,
      ...selectOptions,
    }"
    :normalizer="{ label: 'id', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
  />
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: [Number, String],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    selectOptions: {
      type: Object,
      require: false,
    },
    user_id: {
      type: String,
      required: false,
    },
    purchase_cost_outstanding: {
      type: [Number, String],
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    this.$watch(
      (vm) => ({ user_id: vm.user_id, purchase_cost_outstanding: vm.purchase_cost_outstanding }),
      (val) => {
        let params = Object.entries(val).reduce((p, c) => {
          // Custom cho trường hợp user_id
          let k = c[0] === "user_id" ? "customer_id" : c[0];

          if (c[1]) p[`${k === 'purchase_cost_outstanding' ? 'filter_between' : 'filter'}[${k}]`] = c[1];

          return p;
        }, {});

        if (Object.values(params).length) {
          this.$store.dispatch("order.orders.apply-query", params);
        } else {
          this.$store.dispatch("order.orders.fetch.if-first-time");
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    ...mapGetters({
      list: "order.orders.list",
      loading: "order.orders.fetching",
    }),
    customOptionLabel() {
      return !this.noCustomLabel ? { "custom-label": this.customLabel } : {};
    },
    valueSelected() {
      return this.list.find((x) => x.id == this.value);
    }
  },
  methods: {
    select(value) {
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("order.orders.apply-query", {
        "filter[customer_id]": this.user_id,
        "filter[id]": param,
        "filter_between[purchase_cost_outstanding]": this.purchase_cost_outstanding,
      });
    }, 300),
    customLabel({ id, customer, customer_id }) {
      return `${id} – ${customer ? customer.name : customer_id}`;
    },
  },
};
</script>
