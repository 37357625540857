import { ProductService } from '../services'

export const SERVICE = ProductService
export const RESOURCE = 'product-categories'

export default {
  create(attributes) {
    return SERVICE.postFormData(`api/${RESOURCE}`, attributes);
  },
  update(id, attributes) {
    return SERVICE.postFormData(`api/${RESOURCE}/${id}`, {
      ...attributes,
      _method: "PUT"
    });
  },
};
