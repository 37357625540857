import axios from "axios";
import { getActiveLocale } from '@/core/plugins/i18n'
import firebaseAuth from "../firebase/auth";
import RESTApi from "../rest.api";
import lodash from 'lodash'
import warehouseScope from './warehouse_scope.local'
import organization from './organization.local'
import router from '../../router/index'

async function defaultHeaders() {
  const defaultHeaders = {
    "X-Firebase-IDToken": await firebaseAuth.getIdToken(),
    "Accept-Language": getActiveLocale(),
    Accept: "application/json",
    "X-Organization-Scope": organization.get(),
  };
  return lodash.pickBy(defaultHeaders, lodash.identity)
}

// Auth service
const axiosAuth = axios.create({
  baseURL: process.env.VUE_APP_AUTH_SERVICE_HOST
});

axiosAuth.interceptors.request.use(async function (config) {
  config.headers = await defaultHeaders();
  return config;
});

// Order service
const axiosOrder = axios.create({
  baseURL: process.env.VUE_APP_ORDER_SERVICE_HOST
});

axiosOrder.interceptors.request.use(async function (config) {
  config.headers = await defaultHeaders();
  return config;
});

// Pruduct service
const axiosProduct = axios.create({
  baseURL: process.env.VUE_APP_PRODUCT_SERVICE_HOST
});

axiosProduct.interceptors.request.use(async function (config) {
  config.headers = await defaultHeaders();
  return config;
});
// Accounting service
const axiosAccounting = axios.create({
  baseURL: process.env.VUE_APP_ACCOUNTING_SERVICE_HOST
});

axiosAccounting.interceptors.request.use(async function (config) {
  config.headers = await defaultHeaders();
  return config;
});

// Helper service
const axiosHelper = axios.create({
  baseURL: process.env.VUE_APP_HELPER_SERVICE_HOST
});

axiosHelper.interceptors.request.use(async function (config) {
  config.headers = await defaultHeaders();
  return config;
});

// Warehouse service
const axiosWarehouse = axios.create({
  baseURL: process.env.VUE_APP_WAREHOUSE_SERVICE_HOST
});

axiosWarehouse.interceptors.request.use(async function (config) {
  const checkRoute = router.history.current.matched.some(x => x.path == '/warehouse')
  if (!warehouseScope.get()) {
    config.headers = await defaultHeaders();
    return config;
  }
  const warehouse_scope = checkRoute ? { 'X-Warehouse-Scope': warehouseScope.get() } : {}
  config.headers = {
    ...await defaultHeaders(),
    ...warehouse_scope
  };
  return config;
});

const AuthService = new RESTApi(axiosAuth);
const OrderService = new RESTApi(axiosOrder);
const ProductService = new RESTApi(axiosProduct);
const WarehouseService = new RESTApi(axiosWarehouse);
const AccountingService = new RESTApi(axiosAccounting);
const HelperService = new RESTApi(axiosHelper);

// ... service

export {
  AuthService,
  OrderService,
  ProductService,
  WarehouseService,
  AccountingService,
  HelperService,
}
