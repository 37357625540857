<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <SSelectRole
        v-if="editing"
        :value="id"
        @close="setEditing(false)"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        :editable="editable"
      />
    </slot>
    <TMessage
      :content="name"
      noTranslate
      :italic="editable"
      v-show="!editing || dontHideWhenEditing"
      :editable="editable"
      @click-edit="showEdit()"
      :truncate="1"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import mixin from "@/core/components/T/Button/actions";
export default {
  mixins: [mixin],
  props: {
    id: {
      type: String,
      required: true,
      default: ''
    },
  },
  computed: {
    ...mapGetters({
      roles: "auth.roles.list",
    }),
    name() {
      const status = this.lodash.find(this.roles, { id: this.id });
      if (!status) {
        return this.content ? this.content : this.id;
      }
      return status.name;
    },
  },
};
</script>
