<template>
  <CInput
    :value="value"
    @change="$emit('update:value', $event)"
    @input="$emit('input', $event)"
    class="mb-0"
    :addInputClasses="{ 'form-control-lg': size == 'lg' }"
    :addLabelClasses="[
      'font-weight-bold',
      { 'mb-0 col-form-label-lg': size == 'lg' },
    ]"
    :label="getLabel()"
    :placeholder="$t(placeholder)"
    v-bind="inputOptions"
    :disabled="disabled"
  >
    <template #prepend-content>
      <slot name="prepend-content"> </slot>
    </template>
    <template #append>
      <slot name="append"> </slot>
    </template>
  </CInput>
</template>

<script>
import mixins from "./mixins";

export default {
  mixins: [mixins],
  props: {
    size: {
      type: String,
      default: "",
      validator(value) {
        return ["", "sm", "lg"].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
};
</script>
