<template>
  <TModal
    title="Transfer the bill of lading"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButton
        content="Move to a new bill of lading"
        :options="{ disabled: loading }"
        icon="cil-arrow-right"
        @click="submit"
      />
    </template>
    <TInputNumber label="Quantity" :value.sync="quantity" />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    boxId: {
      type: String,
      default: "",
    },
    orderId: {
      type: String,
      default: "",
    },
    ladingBillId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      quantity: 1,
    };
  },
  methods: {
    submit() {
      this.loading = true;
      let data = {
        lading_bill_id: this.ladingBillId,
        "boxes[0][orders][0][order_id]": this.orderId || "",
        "boxes[0][orders][0][quantity]": this.quantity,
        "boxes[0][box_id]": this.boxId,
      };
      this.$tomoni.warehouse.decoup_lading_bill
        .create(this.lodash.pickBy(data))
        .then(() => {
          this.$emit("decoupe-box-ldb");
          this.$store.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          this.$emit("update:show", false);
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
