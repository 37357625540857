import { WarehouseService } from '../services'

export const SERVICE = WarehouseService
export const RESOURCE = 'containers'

export default {
  lock(id) {
    return SERVICE.put(`api/${RESOURCE}/${id}/cut-off`);
  },
  unlock(id) {
    return SERVICE.put(`api/${RESOURCE}/${id}/open`);
  },
  attachLadingBill(id, attributes) {
    return SERVICE.postFormData(`api/containers/${id}/attach-lading-bills`, attributes);
  },
  detachLadingBill(id, attributes) {
    return SERVICE.delete('api/containers', `${id}/detach-lading-bills`, attributes);
  },
  attachCustomer(id, attributes) {
    return SERVICE.postFormData(`api/containers/${id}/attach-customers`, attributes);
  },
  detachCustomer(id, attributes) {
    return SERVICE.delete('api/containers', `${id}/detach-customers`, attributes);
  },
  getUndesiredBoxContainer(id) {
    return SERVICE.get('api/containers', `${id}/get-undesired-box-containers`);
  },
  removeUndesiredBoxContainer(id, params) {
    return SERVICE.delete('api/containers', `${id}/remove-undesired-box-containers?${new URLSearchParams(params)}`);
  },
  removePickInContainers(id, box_ids_obj) {
    return SERVICE.delete('api/containers', `${id}/remove-pick-in-containers?${new URLSearchParams(box_ids_obj)}`);
  },
  getContainerAcounting(id, attributes) {
    return SERVICE.get('api/containers', `${id}/accounting`, attributes);
  },
}
