<template>
  <TButton
    :options="{ disabled: disabled }"
    icon="cil-search"
    :messageOptions="{ bold: true }"
    content="Search"
    @click="$emit('click')"
  />
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
