<template>
  <TSelect
    :label="label"
    class="m-0"
    placeholder="Select"
    :options="list"
    :value="selected"
    :selectOptions="selectOptions"
    @select="onSelect"
  />
</template>
<script>
export default {
  props: {
    prepend: {
      type: Array,
      required: false,
      default: function () {
        return [{ id: "", name: this.$t("All") }];
      },
    },
    value: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
    selectOptions: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      require: false,
    },
  },
  computed: {
    list() {
      return [
        ...this.prepend,
        ...this.$store.getters["accounting.currencies.list"],
      ];
    },
    selected() {
      return this.list.find((x) => x.id == this.value);
    },
  },
  methods: {
    onSelect(selected) {
      this.$emit("update:value", selected.id);
      this.$emit("change", selected);
    },
  },
};
</script>
