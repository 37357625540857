export default {
  props: {
    inputOptions: {
      type: Object,
      required: false,
    },
    placeholder: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    size: {
      type: String,
      default: "",
      validator(value) {
        return ["", "sm", "lg"].includes(value);
      },
    }
  },
};
