import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'helper',
    resource: 'wards',
    paginate: false,
    prefix: 'helper.wards_sender',
    prefix_state: 'helper_wards_sender',
  },
  {
    default_detail: {
      id: null,
      name: null,
      district_id: null,
      address: null,
      province: {
        id: null,
        name: null,
        country_id: null,
      },
    },
    detail_query: {
      include: 'province',
    },
  },
).store()

const PREFIX = 'helper.wards_sender'

const actions = {
  [PREFIX + '.detail.fetch-missing'](context, id) {
    return context.dispatch(PREFIX + '.detail.fetch', id).then((ward) => {
      context.dispatch(PREFIX + '.push-query', {
        'filter[district_id]': ward.district_id,
      });
      context.dispatch('helper.districts_sender.push-query', {
        'filter[province_id]': ward.province.id
      }).then(() => {
        context.commit('helper.districts_sender.select', ward.district_id)
        context.commit('helper.provinces_sender.select', ward.province.id)
      })
    })
  },
}

export default {
  ...resource,
  actions: { ...resource.actions, ...actions }
}
