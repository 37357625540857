<template>
  <CButton
    v-bind="options"
    @click="click"
    :color="color"
    :variant="variant"
    :size="size"
    v-c-tooltip="tooltipConvert"
    :to="to"
    :target="target"
    :style="styleBtn"
  >
    <div class="d-flex justify-content-center">
      <CIcon
        :name="icon"
        v-if="icon"
        :style="iconStyle"
        size="custom"
        :customClasses="addIconClasses"
      />
      <slot name="content">
        <TMessage
          v-bind="messageOptions"
          v-if="content"
          :content="content"
          :class="['my-auto', { 'ml-2': icon }]"
        />
      </slot>
    </div>
  </CButton>
</template>

<script>
import mixin from "./Button/mixin";
export default {
  mixins: [mixin],
  props: {
    content: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: false,
    },
    iconSize: {
      type: [String, Number],
      required: false,
    },
    addIconClasses: {
      type: String,
      required: false,
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    tooltip: {
      type: String,
      required: false,
    },
    messageOptions: {
      type: Object,
      required: false,
    },
    tooltipOptions: {
      type: Object,
      required: false,
    },
    variant: {
      type: String,
      required: false,
      default: "",
    },
    size: {
      type: String,
      required: false,
    },
    options: {
      type: Object,
      required: false,
    },
    to: {
      type: String,
      required: false,
      default: "",
    },
    target: {
      type: String,
      default: "_self",
    },
  },
  computed: {
    tooltipConvert() {
      return this.tooltip
        ? {
          content: this.$t(this.tooltip),
          appendToBody: true,
          ...this.tooltipOptions,
        }
        : "";
    },
    iconStyle() {
      let size;
      if (this.size === "sm") size = 10;
      switch (this.size) {
        case "sm":
          size = 12;
          break;
        case "lg":
          size = 16;
          break;
        default:
          size = 14;
      }
      if (this.iconSize)
        return `height: ${this.iconSize}px; width: ${this.iconSize}px`;
      if (size) return `height: ${size}px; width: ${size}px`;
      return "";
    },
    styleBtn() {
      return this.variant ? "" : { border: "transparent 1px solid" };
    },
  },
  destroyed() {
    var elem = document.querySelectorAll(".tooltip-old[aria-hidden='false']");
    if (!this.lodash.isEmpty(elem))
      elem.forEach((e) => {
        e.style.visibility = "hidden";
        e.ariaHidden = "true";
      });
  },
  methods: {
    click(e) {
      e.stopPropagation();
      this.$emit('click');
    }
  }
};
</script>
