<template>
  <CCard class="h-100 m-0">
    <CCardHeader class="d-flex justify-content-between align-items-center">
      <TMessage content="Transactions arise" bold />
      <TSpinner :loading="fetching" />
      <TButtonRefresh
        class="ml-auto"
        @click="$store.dispatch('accounting.transactions.change-page', 1)"
      />
    </CCardHeader>
    <CCardBody class="w-100 mx-auto" style="max-width: 800px">
      <div>
        <CCol
          v-for="transaction in transactions"
          :key="transaction.id"
          class="p-0"
          ><CLink  :to="lodash.getReferenceLink('transaction', transaction.id)">
            <SCardTransactionItem :transaction="transaction" />
          </CLink>
        </CCol>
      </div>
      <TMessageNoData v-if="transactionEmpty" />
      <SButtonLoadmore store="accounting.transactions" />
    </CCardBody>
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    objectableId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.$store.dispatch("accounting.transaction_types.fetch.if-first-time");
  },
  watch: {
    objectableId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("accounting.transactions.apply-query", {
            "filter[receipts.receiptable_id]": id,
            page: 1,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      transactions: "accounting.transactions.list",
      fetching: "accounting.transactions.fetching",
    }),
    transactionEmpty() {
      return this.transactions.length === 0;
    },
  },
};
</script>
