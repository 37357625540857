<template>
  <div></div>
</template>

<script>
export default {};
</script>

<style scoped>
div {
  height: 120px;
  width: 100%;
  background-color: darkgray;
  opacity: 30%;
}
</style>
