<template>
  <TModal
    title="Select purchases"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="xl"
  >
    <template #actions>
      <div />
      <!-- <TButtonAdd
        variant="outline"
        size=""
        :options="{ content: 'Add' }"
        @click="select"
        :key="key"
      /> -->
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      :store="this.store"
      @click-clear-filter="clearFilter"
      reloadable
      @click-reload="fetch"
      resource="purchases"
    >
      <template #_="{ item }">
        <td>
          <CCol col="12">
            <TButtonEnter @click="select(item)" />
          </CCol>
        </td>
      </template>
      <template #status="{ item }">
        <td>
          <SMessageOrderStatus
            store="order.purchase_statuses"
            :id="item.status_id"
          />
        </td>
      </template>
      <template #supplier="{ item }">
        <td class="text-truncate">
          <TLink
            v-if="item.supplier"
            :content="item.supplier.name"
            :to="lodash.getReferenceLink('supplier', item.supplier_id)"
          />
          <TMessageNotFound v-else :slug="item.supplier_id" />
        </td>
      </template>
      <template #trackings="{ item }">
        <td class="text-truncate">
          <SMessageTracking
            v-if="!lodash.isEmpty(item.trackings)"
            :value="item.trackings[0].code"
            :checked="item.trackings[0].received"
          />
          <TMessageNotFound v-else />
          <TMessage
            v-if="item.trackings.length > 1"
            content="Tracking"
            lowercase
            size="small"
            color="muted"
            :truncate="1"
          >
            <template #prefix> +{{ item.trackings.length - 1 }} </template>
          </TMessage>
        </td>
      </template>
      <template #buyer_id="{ item }">
        <td>
          <TLink
            v-if="item.buyer"
            :content="item.buyer.name"
            :to="lodash.getReferenceLink('user', item.buyer_id)"
          />
        </td>
      </template>
      <template #balance="{ item }">
        <td>
          <TMessageMoney :amount="item.balance" :currency="item.currency_id" />
        </td>
      </template>
      <template #outstanding="{ item }">
        <td>
          <TMessageMoney
            :amount="item.outstanding"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #compensation="{ item }">
        <td>
          <TMessageMoney
            :amount="item.compensation"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #debited="{ item }">
        <td>
          <TMessageMoney :amount="item.debited" :currency="item.currency_id" />
        </td>
      </template>
      <template #updated_at="{ item }">
        <td>
          <TMessageDateTime :content="item.updated_at" small />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          placeholder="Order Id"
          :value.sync="filter.id"
          @update:value="filterChange"
        />
      </template>
      <template #trackings-filter>
        <TInputText
          :value.sync="filter['trackings.code']"
          @update:value="filterChange"
          placeholder="Tracking Id"
        />
      </template>
      <template #supplier-filter>
        <SSelectSupplier
          :value.sync="filter.supplier_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #buyer_id-filter>
        <SSelectCustomer
          :value.sync="filter.buyer_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #status-filter>
        <SSelectOrderStatus
          store="order.purchase_statuses"
          :value.sync="filter.status_id"
          @change="filterChange"
        />
      </template>
      <template #updated_at-filter>
        <TInputDateTimeRange
          :value.sync="filter_between['updated_at']"
          @update:value="filterChange"
        />
      </template>
      <template #outstanding-filter>
        <TInputNumberRange
          :value.sync="filter_between['outstanding']"
          @change="filterChange"
        />
      </template>
      <template #balance-filter>
        <TInputNumberRange
          :value.sync="filter_between['balance']"
          @change="filterChange"
        />
      </template>
      <template #debited-filter>
        <TInputNumberRange
          :value.sync="filter_between['debited']"
          @change="filterChange"
        />
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    store: {
      type: String,
      default: "order.purchases",
    },
    default_filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      key: "btn-select",
      filter: {},
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Order Id", sorter: true },
        {
          key: "trackings",
          label: "Tracking",
          _style: "width: 130px; min-width: 130px",
        },
        { key: "balance", label: "Goods money", sorter: true },
        { key: "debited", label: "Debited", sorter: true },
        { key: "outstanding", label: "Outstanding", sorter: true },
        { key: "compensation", label: "Compensation amount", sorter: true },
        {
          key: "supplier",
          label: "Supplier",
          _classes: "text-nowrap",
          _style: "width: 270px; min-width: 270px",
        },
        {
          key: "buyer_id",
          label: "Buyer",
          _classes: "text-nowrap",
          _style: "width: 270px; min-width: 270px",
        },
        {
          key: "status",
          label: "Status",
          _classes: "text-nowrap",
          _style: "width: 270px; min-width: 270px",
        },
        {
          key: "updated_at",
          label: "Updated at",
          sorter: true,
          _style: "width: 220px",
        },
      ],
      filter_between: {},
    };
  },
  watch: {
    show(isShow) {
      if (isShow) {
        this.filterChange();
      }
    },
  },
  computed: {
    list() {
      return this.$store.getters[this.store + ".list"];
    },
    list_loading() {
      return this.$store.getters[this.store + ".fetching"];
    },
    include() {
      return "trackings,supplier";
    },
  },
  methods: {
    fetch() {
      this.$store.dispatch(this.store + ".fetch");
    },
    select(selected_purchase) {
      this.$emit("select", selected_purchase);
      this.$emit("update:show", false);
      this.key = this.lodash.resetKey("btn-select");
    },
    filterChange() {
      this.$store.dispatch(this.store + ".apply-query", {
        ...this.default_filter,
        ...this.lodash.mapKeys(this.filter, (_, k) => `filter[${k}]`),
        ...this.formatFilterBetween(this.filter_between),
        include: this.include,
      });
    },
    formatFilterBetween(obj) {
      return Object.entries(obj).reduce((p, c) => {
        p[`filter_between[${c[0]}]`] = [c[1].start || 0, c[1].end || 0].join(
          ","
        );

        return p;
      }, {});
    },
    clearFilter() {
      this.filter_between = {};
      this.filter = {};
      this.filterChange();
    },
  },
};
</script>
