<template>
  <TMessageStatus
    :id="status"
    :statuses="statuses"
    :color="getBadge"
    :noTranslate="true"
  />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      statuses: "order.contract_statuses.list",
    }),
    getBadge() {
      switch (this.status) {
        case "Open":
          return "success";
        default:
          return "danger";
      }
    },
  },
};
</script>
