<template>
  <CLink v-if="!href" :target="target" :to="to">
    <TButton
      icon="cil-arrow-right"
      tooltip="Enter"
      @click="$emit('click')"
      :options="options"
      color="primary"
      :variant="variant"
      :size="size"
    />
  </CLink>
  <CLink v-else :href="href">
    <TButton
      icon="cil-arrow-right"
      tooltip="Enter"
      @click="$emit('click')"
      :options="options"
      color="primary"
      :variant="variant"
      :size="size"
    />
  </CLink>
</template>

<script>
import mixin from "./mixin";

export default {
  mixins: [mixin],
  props: {
    href: {
      type: String,
      required: false,
    },
    to: {
      type: String,
      required: false,
    },
    target: {
      type: String,
      default: "_self",
    },
  },
};
</script>
