<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <SSelectSupplier
        v-if="editing"
        :value="id"
        @close="setEditing(false)"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        :editable="editable"
      />
    </slot>
    <TLink
      :content="content"
      noTranslate
      v-show="!editing || dontHideWhenEditing"
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      @click-edit="showEdit()"
      :to="to"
      ><template #prefix></template
    ></TLink>
  </div>
</template>

<script>
import mixin from "@/core/components/T/Button/actions";
export default {
  mixins: [mixin],
  props: {
    id: {
      type: [String, Number],
      required: false,
    },
    content: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
    },
  },
};
</script>
