var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('label',[_c('TMessage',{attrs:{"bold":"","content":_vm.label}})],1),(_vm.userId)?_c('CLink',{staticClass:"float-right",on:{"click":function($event){_vm.showModal = true}}},[_c('TMessage',{attrs:{"content":"Add new"}})],1):_vm._e()],1),_c('TSelect',{staticClass:"m-0",attrs:{"options":_vm.list,"value":_vm.valueSelected,"selectOptions":{
      internalSearch: true,
      'custom-label': _vm.customLabel,
      preserveSearch: true,
      disabled: _vm.userId ? false : true,
      ..._vm.selectOptions,
    },"normalizer":{ label: 'full_address', value: 'id' },"placeholder":"Select","loading":_vm.loading},on:{"select":_vm.select}}),_c('SModalAddress',{attrs:{"show":_vm.showModal,"userId":_vm.userId},on:{"update:show":function($event){_vm.showModal=$event},"created":function($event){_vm.showModal = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }