var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.editable)?_c('TSelectEditable',{staticClass:"m-0",attrs:{"options":_vm.listC,"value":_vm.valueSelected,"selectOptions":{
    internalSearch: false,
    preserveSearch: true,
  },"loading":_vm.loading,"normalizer":{ label: 'name', value: 'id' }},on:{"select":_vm.select,"search":_vm.search,"close":function($event){return _vm.$emit('close')},"change":function($event){return _vm.$emit('change', $event)}}}):_c('TSelect',{staticClass:"m-0",attrs:{"label":_vm.label,"addLabelClasses":"font-weight-bold","options":_vm.list,"value":_vm.valueSelected,"selectOptions":{
    internalSearch: false,
    preserveSearch: true,
  },"normalizer":{ label: 'name', value: 'id' },"placeholder":_vm.placeholder,"loading":_vm.loading},on:{"select":_vm.select,"search":_vm.search}})
}
var staticRenderFns = []

export { render, staticRenderFns }