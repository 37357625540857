<template>
  <CCard
    :class="`w-100 mb-2${hasClickEvent ? ' cursor-pointer' : ''}`"
    accentColor="primary"
    @click="onClick"
  >
    <CCardBody class="p-2 transaction-card">
      <div class="d-flex is-credit-order">
        <CBadge v-if="transaction.credit_order_id" color="primary">
          <TMessage content="Credit card" />
        </CBadge>
      </div>
      <div class="d-flex flex-wrap">
        <div v-if="hasRemoveEvent" class="mr-2">
          <TButtonRemove @click="onRemove()" />
        </div>
        <CCol
          col="12"
          sm="4"
          md="4"
          class="p-0"
          v-if="transaction.transactionable_id !== null"
        >
          <SMessageWallet :data="transaction" />
        </CCol>
        <CCol col="12" sm="3" md="4" class="p-0 p-sm-0">
          <SMessageTransactionType
            :value="transaction.type_id"
            class="my-auto"
          />
        </CCol>
        <CCol col="12" sm="auto" md="auto" class="p-0 ml-sm-1">
          <TMessageMoney
            :currency="transaction.currency_id"
            :amount="transaction.amount"
            :decimalLimit="2"
          />
        </CCol>
        <CCol col="12" sm="auto" class="ml-auto p-0">
          <TMessageDateTime
            :content="transaction.voucher_date"
            small
            dateOnly
          />
        </CCol>
        <CCol
          v-if="transaction.transactionable_id == null"
          col="12"
          sm="4"
          class="d-flex my-auto ml-2"
        >
          <CIcon name="cil-note" class="mr-1" />
          <TMessage
            :content="transaction.description"
            noTranslate
            size="small"
          />
        </CCol>
      </div>
      <div
        v-if="transaction.description && transaction.transactionable_id"
        class="d-flex mt-2"
      >
        <CIcon name="cil-note" class="mr-1" />
        <TMessage :content="transaction.description" noTranslate size="small" />
      </div>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasRemoveEvent() {
      return this.$listeners && this.$listeners.remove;
    },
    hasClickEvent() {
      return this.$listeners && this.$listeners.click;
    },
  },
  methods: {
    onRemove() {
      this.$emit("remove");
    },
    onClick() {
      if (this.hasClickEvent) this.$emit("click", this.transaction);
    },
  },
};
</script>

<style scoped>
.transaction-card >>> .is-credit-order {
  position: absolute;
  top: -10px;
}
.transaction-card {
  position: relative;
}
</style>
