import { ProductService } from "../services";

export const SERVICE = ProductService;
export const RESOURCE = "affiliate-stores";

export default {
  createCategory(id, attributes) {
    return SERVICE.post(`api/${RESOURCE}/${id}/category`, attributes);
  },
  deleteCategory(id, category_id) {
    return SERVICE.delete(
      `api/${RESOURCE}/${id}/category?category_ids=${category_id}`
    );
  },
  attachImage(id, attributes) {
    return SERVICE.postFormData(
      `api/${RESOURCE}/${id}/affiliate-store-images`,
      attributes
    );
  },
  detachImage(id, attributes) {
    return SERVICE.postFormData(
      `api/${RESOURCE}/${id}/affiliate-store-images`,
      {
        ...attributes,
        _method: "DELETE",
      }
    );
  },
};
