<template>
  <TButton
    icon="cil-arrow-right"
    tooltip="Detail"
    @click="$emit('click')"
    v-bind="options"
    color="primary"
    :variant="variant"
    :size="size"
  />
</template>

<script>
import mixin from "./mixin";

export default {
  mixins: [mixin],
};
</script>