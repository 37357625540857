<template>
  <CModal :show="show" :size="size" @update:show="$emit('update:show', $event)">
    <template #header>
      <slot name="title">
        <div class="d-flex align-items-center">
          <TMessage :content="title" bold capitalize class="my-auto" />
          <TSpinner :loading="fetching" />
        </div>
      </slot>
      <div>
        <slot name="header-actions"></slot>
        <TButtonClose
          v-if="exitable"
          class="ml-1"
          @click="$emit('update:show', false)"
        />
      </div>
    </template>
    <slot></slot>
    <template #footer>
      <slot name="actions" :creating="creating">
        <TButtonCreate
          :options="{ disabled: creating }"
          variant="outline"
          @click="$emit('click-create')"
        />
      </slot>
    </template>
  </CModal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: false,
    },
    creating: {
      type: Boolean,
      default: false,
    },
    exitable: {
      type: Boolean,
      default: true,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
