<template>
  <CBadge color="info" style="font-size: 85%" class="px-2">
    <TMessage
      :content="tag.name"
      noTranslate
      :removable="removable"
      @click-remove="showModalConfirm = true"
    >
    </TMessage
    ><TModalConfirm
      class="text-dark"
      v-if="removable"
      :show.sync="showModalConfirm"
      @click-confirm="detachTag"
    />
  </CBadge>
</template>

<script>
export default {
  props: {
    tag: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      required: false,
    },
    store: {
      type: String,
      required: false,
      default: "product.products",
    },
  },
  data() {
    return {
      showModalConfirm: false,
    };
  },
  methods: {
    detachTag() {
      this.$store.dispatch(`${this.store}.detail.detach-tag`, {
        "tag_ids[]": this.tag.id,
      });
    },
  },
};
</script>
