<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <TInputEditable
        v-if="editing"
        :value="value"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        @close="setEditing(false)"
      >
      </TInputEditable>
    </slot>
    <TMessage
      v-show="!editing || dontHideWhenEditing"
      v-bind="messageOptions"
      :content="value"
      noTranslate
      :italic="editable"
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      @click-edit="showEdit()"
      @click-remove="showModalConfirm = true"
    >
    </TMessage>
    <TModalConfirm
      class="text-dark"
      v-if="removable"
      :show.sync="showModalConfirm"
      @click-confirm="$emit('click-remove')"
    />
  </div>
</template>

<script>
import actions from "../Button/actions";
import TMessage from "../Message.vue";

export default {
  mixins: [actions],
  components: {
    TMessage,
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: "",
    },
    messageOptions: {
      type: Object,
      required: false,
    },
    update: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      showModalConfirm: false
    }
  }
};
</script>
