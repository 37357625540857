<template>
  <CRow>
    <CCol v-if="title" col="12" class="mb-3">
      <TMessage :content="title" uppercase>
        <template #suffix>:</template>
      </TMessage>
    </CCol>
    <CCol col="12">
      <slot name="table">
        <CDataTable
          class="shadow"
          :class="{ 'table-custom': !noFilter }"
          :key="tableKey"
          :items="[...items]"
          :fields="advanceFields"
          :loading="fetching"
          :column-filter="!noFilter"
          :sorter="{ external: true, resetable: false }"
          @row-clicked="selectItem"
          @update:sorter-value="setSorter"
          :noItemsView="{ noItems: $t('No data') }"
          v-bind="{ border: true, ...options }"
        >
          <template #_-header>
            <CRow class="px-1">
              <CCol v-if="creatable" class="px-1 text-center" col="12">
                <TButtonCreate @click="$emit('click-create')" />
              </CCol>
              <CCol v-if="reloadable" class="px-1 text-center" col="12">
                <TButton
                  size="sm"
                  variant="ghost"
                  icon="cil-reload"
                  tooltip="Refresh"
                  @click="
                    clearQuery();
                    $emit('click-reload');
                  "
                />
              </CCol>
              <slot name="actions-header"></slot>
            </CRow>
          </template>
          <template #_="{ item }">
            <td>
              <CRow>
                <slot name="actions">
                  <slot name="prepend-actions"></slot>
                  <CCol v-if="enterable" class="px-1 text-center" col="12">
                    <TButtonEnter :to="`${resource}/${item[slugKey]}`" />
                  </CCol>
                  <CCol v-if="quickViewable" class="px-1 text-center" col="12">
                    <TButtonQuickView @click="$emit('click-quick-view')" />
                  </CCol>
                  <CCol v-if="removable" class="px-1 text-center" col="12">
                    <TButtonRemove @click="remove(item)" />
                  </CCol>
                  <CCol v-if="deletable" class="px-1 text-center" col="12">
                    <TButtonDelete @click="remove(item)" />
                  </CCol>
                  <CCol v-if="addable" class="px-1 text-center" col="12">
                    <TButtonAdd @click="$emit('click-add', item)" />
                  </CCol>
                  <slot name="append-actions" :item="item"></slot>
                </slot>
              </CRow>
            </td>
          </template>
          <template #_-filter>
            <CCol class="px-1 text-center" col="12">
              <TButtonClear @click="clearFilter" />
            </CCol>
          </template>
          <template
            v-for="(_, name) in $scopedSlots"
            :slot="name"
            slot-scope="slotData"
            ><slot :name="name" v-bind="slotData"
          /></template>
        </CDataTable>
      </slot>
    </CCol>
    <CCol v-if="!noPaginate" v-show="paginate.last > 1" col="12" class="mt-2">
      <slot name="pagination">
        <TPagination :store="store" />
      </slot>
    </CCol>
  </CRow>
</template>

<script>
import TPagination from "../Pagination.vue";
import actions from "../Button/actions";
export default {
  mixins: [actions],
  components: {
    TPagination,
  },
  props: {
    slugKey: {
      type: String,
      required: false,
      default: "id",
    },
    store: {
      type: String,
      required: true,
    },
    resource: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    noFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    noPaginate: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoFetching: {
      type: Boolean,
      required: false,
      default: true,
    },
    noClickRow: {
      type: Boolean,
      required: false,
      default: false,
    },
    reloadable: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Object,
      required: false,
    },
    deletable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tableKey: "thisistablekey",
    };
  },
  computed: {
    paginate() {
      return this.$store.getters[this.store + ".paginate"];
    },
    fetching() {
      return this.$store.getters[this.store + ".fetching"];
    },
    advanceFields() {
      return this.lodash.map(this.fields, (field) => {
        return {
          sorter: false,
          filter: false,
          _classes: "text-truncate",
          ...field,
          label: field.label ? this.$t(field.label) : undefined,
        };
      });
    },
  },
  watch: {
    // fix not rerender when init no items
    ["items.length"](newLength, oldLength) {
      if (newLength === 1 && oldLength <= 0) {
        this.refreshTable();
      }
    },
  },
  methods: {
    setSorter(sorter) {
      let prefix = sorter.asc ? "" : "-";
      let column = sorter.column;
      this.$store.dispatch(`${this.store}.push-query`, {
        sort: prefix.concat(column),
      });
    },
    selectItem(row) {
      if (!this.noClickRow) {
        this.$store.commit(`${this.store}.select`, row[this.slugKey]);
        this.$emit("select-item", row);
      }
    },
    remove(row) {
      this.selectItem(row);
      this.$store
        .dispatch(`${this.store}.detail.delete`, row[this.slugKey])
        .then(() => {
          this.$emit("row-removed", row);
        });
    },
    refreshTable() {
      this.tableKey = "" + this.lodash.random(10000, 100000);
    },
    clearQuery() {
      const newQuery = this.lodash.omit(this.$route.query, [
        `${this.store}-page`,
      ]);
      this.lodash.appendRouteQuery(this.$router, { query: newQuery });
    },
    clearFilter() {
      this.clearQuery();
      this.$emit("click-clear-filter");
    },
  },
};
</script>

<style scoped>
* >>> tr:nth-child(1) th {
  padding-right: 12px !important;
}
* >>> .table-custom .table-responsive {
  min-height: 400px;
}
</style>
