var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TModal',{attrs:{"title":"Binding conditions","show":_vm.show},on:{"update:show":function($event){_vm.show=$event},"click-create":_vm.save}},[_c('div',{staticClass:"d-flex align-items-center flex-wrap"},[_c('TMessage',{staticClass:"font-weight-bold",attrs:{"content":"Suggest for you"}}),_vm._l((_vm.suggestConditionList),function(suggestItem){return _c('TButton',{key:suggestItem.value,staticClass:"m-1 p-1",attrs:{"content":suggestItem.title},on:{"click":function($event){return _vm.setTempKey(suggestItem.value)}}})})],2),_c('CRow',{staticClass:"mt-3"},[_c('CCol',[_c('TInputText',{attrs:{"label":"Key","placeholder":"Key","value":_vm.temp.key,"disabled":""},on:{"update:value":function($event){return _vm.$set(_vm.temp, "key", $event)}}})],1),_c('CCol',[(
          _vm.temp.key === 'currency' ||
          (['from', 'to'].includes(_vm.temp.key) &&
            _vm.groupConditionType === 'exchange-rates')
        )?_c('SSelectCurrency',{attrs:{"value":_vm.temp.value,"label":"Currency","prepend":[]},on:{"update:value":function($event){return _vm.$set(_vm.temp, "value", $event)}}}):(_vm.temp.key === 'shipment-method')?_c('SSelectShipmentMethod',{attrs:{"value":_vm.temp.value,"label":"Shipment method","prepend":[]},on:{"update:value":function($event){return _vm.$set(_vm.temp, "value", $event)}}}):(
          ['from', 'to'].includes(_vm.temp.key) &&
          _vm.groupConditionType === 'shipping-fee'
        )?_c('SSelectArea',{attrs:{"label":"Area","prepend":[],"value":_vm.temp.value},on:{"update:value":function($event){return _vm.$set(_vm.temp, "value", $event)}}}):(
          ['unit'].includes(_vm.temp.key) && _vm.groupConditionType === 'shipping-fee'
        )?_c('SSelectFreightChargeType',{attrs:{"label":"Freight charge type","prepend":[],"value":_vm.temp.value},on:{"update:value":function($event){return _vm.$set(_vm.temp, "value", $event)}}}):_c('TInputText',{attrs:{"label":"Value","placeholder":"Value","value":_vm.temp.value},on:{"update:value":function($event){return _vm.$set(_vm.temp, "value", $event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }