<template>
  <div>
    <router-view></router-view>
    <Toast />
  </div>
</template>

<script>
import messaging from "@/core/services/firebase/messaging";

export default {
  name: "App",
  created() {
    messaging.onMessage(({ notification }) => {
      console.log("Messaging received => ", notification);
      this.$store.commit("toasts.push", {
        title: notification.title,
        message: notification.body,
      });
    });
  },
  watch: {
    "$route.path"() {
      setTimeout(() => {
        var elem = document.querySelectorAll(".tooltip-old");
        const length = elem.length;
        for (let i = 0; i < length; i++) {
          elem[i].remove();
        }
      });
    },
  },
};
</script>

<style lang="scss">
// Import core styles for this application
@import "@/core/assets/scss/style";
// Import main styles for this application
@import "@/assets/scss/style";
</style>
