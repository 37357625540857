import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'product',
    resource: 'product_statistics',
  },
  {
    default_query: {
      include: "product,suppliers",
    }
  },
).store()

export default resource
