import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'warehouse',
    resource: 'owning_boxes_available',
    paginate: false
  },
  {
    default_query: {
      include: "box.tags,user",
      sort: "-updated_at",
    },
  },
).store()

export default resource
