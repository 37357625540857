<template>
  <TModal
    title="Address"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="lg"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
        v-if="!is_edit && show"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit && show"
        :options="{ disabled: loading }"
      />
    </template>
    <CRow>
      <CCol col="6">
        <TInputText label="Consignee" :value.sync="input_data.consignee" />
      </CCol>
      <CCol col="6">
        <TInputText label="Tel" :value.sync="input_data.tel" />
      </CCol>
    </CRow>
    <hr />
    <SSelectAddress
      v-if="show"
      :ward_id.sync="input_data.ward_id"
      :address_detail.sync="input_data.address_detail"
    />
    <hr />

    <CRow>
      <CCol col="6">
        <TInputText label="Sender name" :value.sync="input_data.sender_name" />
      </CCol>
      <CCol col="6">
        <TInputText label="Tel" :value.sync="input_data.sender_tel" />
      </CCol>
    </CRow>
    <hr />
    <SSelectAddress
      v-if="show"
      :ward_id.sync="input_data.sender_ward_id"
      :address_detail.sync="input_data.sender_address"
      provinces_store="provinces_sender"
      districts_store="districts_sender"
      wards_store="wards_sender"
      country_default="jp"
    />
    <hr />
    <CRow>
      <CCol>
        <TInputText label="Note" :value.sync="input_data.note" />
      </CCol>
    </CRow>
    <CRow class="mt-2" v-if="type == 'address'">
      <CCol>
        <CInputCheckbox
          custom
          :label="$t('Set as default')"
          :checked.sync="input_data.default"
        />
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    address: {
      type: Object,
      required: false,
    },
    userId: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "address",
    },
  },
  inputDefault: {
    consignee: null,
    tel: null,
    note: null,
    default: false,
    ward_id: null,
    address_detail: null,
    sender_name: null,
    sender_tel: null,
    sender_address: null,
    sender_ward_id: null,
  },
  data() {
    return {
      input_data: this.getInput(),
      loading: false,
    };
  },
  watch: {
    show: {
      handler() {
        if (this.show & this.is_edit) {
          this.input_data = this.getInput();
        }
      },
    },
  },
  computed: {
    is_edit() {
      if (!this.address) {
        return false;
      }
      return !!this.address.id;
    },
    input: {
      get: function () {
        return this.getInput();
      },
      set: function (newValue) {
        this.input_data = newValue;
      },
    },
    data_submit: {
      cache: false,
      get() {
        return {
          ...this.input_data,
          default: +this.input_data.default,
        };
      },
    },
  },
  methods: {
    create() {
      this.loading = true;
      let store;
      const checkAddressType = this.type == "address";
      if (!checkAddressType) store = "warehouse.place_of_deliveries";
      else store = "auth.addresses";
      const dataAdresses = {
        ...this.data_submit,
        user_id: this.userId === "" ? this.meID : this.userId,
      };
      const dataPlaceOfDelivery = {
        ...this.lodash.omit(this.data_submit, "address_detail"),
        address: this.data_submit.address_detail,
      };
      const data = checkAddressType ? dataAdresses : dataPlaceOfDelivery;
      this.$store
        .dispatch(
          store + ".create",
          this.lodash.pickBy(data, this.lodash.identity)
        )
        .then((data) => {
          if (data?.default && checkAddressType) {
            this.$store.dispatch("auth.addresses.fetch");
          }
          this.clearForm();
          this.$emit("created", data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.loading = true;
      if (this.type == "address") {
        this.$store
          .dispatch("auth.addresses.update", {
            id: this.address.id,
            attributes: this.lodash.omit(this.data_submit, "user_id"),
          })
          .then((data) => {
            if (data.default) {
              this.$store.dispatch("auth.addresses.fetch");
            }
            this.$emit("updated", data);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store
          .dispatch("warehouse.place_of_deliveries.detail.update", {
            ...this.lodash.omit(this.data_submit, "address_detail"),
            address: this.data_submit.address_detail,
          })
          .then((data) => {
            this.$emit("update:show", false);
            this.$emit("updated", data);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      if (this.type == "address")
        return {
          ...this.address,
          set_as_default: this.address.default,
          address_detail: this.address.address_detail,
        };
      else
        return {
          ...this.address,
          address_detail: this.address.address,
        };
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    clearForm() {
      this.input = this.inputDefault();
    },
  },
};
</script>
