<template>
  <CBadge class="d-inline-flex border-primary" v-if="!lodash.isEmpty(data)">
    <CIcon name="cis-wallet" class="mr-1 my-auto" size="sm" />
    <TMessage
      :content="data.object ? data.object.name : '???'"
      noTranslate
      bold
      class="my-auto"
      noWrap
      style="max-width: 120px"
    />
    <SMessageCurrency :id="data.currency_id" class="my-auto ml-2" />
  </CBadge>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
};
</script>
