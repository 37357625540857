<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: true,
      preserveSearch: true,
      ...customOptionLabel,
      ...selectOptions,
    }"
    :normalizer="{ label: 'name', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
  >
    <template #singleLabel="{ option }">
      <TMessage
        :content="option.name ? option.name : option.id"
        noTranslate
        :truncate="1"
        size="small"
      />
    </template>
  </TSelect>
</template>
<script>
import mixins from "./mixins";
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  mixins: [mixins],
  props: {
    prepend: {
      type: Object,
      required: false,
      default: function () {
        return { id: "", name: this.$t("All") };
      },
    },
    value: {
      type: [Number, String],
      required: false,
      default: "",
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    noCustomLabel: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Object,
      require: false,
    },
    searchKey: {
      type: String,
      default: "name",
    },
  },
  created() {
    if (!this.noFetchStore)
      this.$store.dispatch("warehouse.containers.fetch.if-first-time");
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value == null) return;
        this.checkValue(value);
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.containers.list",
      loading: "warehouse.containers.fetching",
    }),
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
    checkValue(value) {
      const firstValue = this.listC.find((x) => x.id == value);
      // Nếu value rỗng
      if (!value && !this.prependAll) {
        this.valueSelected = null;
        return;
      }
      // Value k có trong list
      if (!firstValue) {
        this.$tomoni.warehouse.containers
          .all({ "filter[id]": value })
          .then(({ data }) => {
            this.firstValue = data.data[0];
            this.valueSelected = data.data[0];
          });
      }
      // Value có trong list
      if (firstValue) {
        // this.select(firstValue);
        this.valueSelected = firstValue
      }
    },
    search: lodash.debounce(function (param) {
      let filter = {};
      filter[`filter[${this.searchKey}]`] = param;
      this.$store.dispatch("warehouse.containers.push-query", filter);
    }, 500),
    customLabel({ id, name }) {
      return name ? `${id} ${id && "-"} ${name}` : `${id}`;
    },
  },
};
</script>
