import { OrderService } from '../services'

export const SERVICE = OrderService
export const RESOURCE = 'contracts'

export default {
  attachOrder(id, attributes) {
    return SERVICE.put(`api/contracts/${id}/attach-order`, attributes);
  },
  detachOrder(id, attributes) {
    return SERVICE.put(`api/contracts/${id}/detach-order`, attributes);
  },
};
