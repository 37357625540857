export default {
  'Dashboard': 'Bảng điều khiển',
  'Home': 'Trang chủ',
  'User info': 'Thông tin tài khoản',
  'Password': 'Mật khẩu',
  'User id': 'Mã tài khoản',
  'Email address': 'Địa chỉ email',
  "Enter email": "Nhập email",
  "Enter password": "Nhập mật khẩu",
  "Resend verification email": 'Gửi lại mail xác thực',
  'Unverified': "Chưa xác thực",
  'Verified': "Đã xác thực",
  'Disabled': "Đã khóa",
  "Reset password": "Đặt lại mật khẩu",
  'Password confirmation': 'Yêu cầu nhập mật khẩu',
  'Confirm': 'Xác nhận',
  'Email empty': 'Email trống',
  'Customer': 'Khách hàng',
  'Admin': 'Quản trị',
  'Sale': 'Bán hàng',
  'Purchase': 'Mua hàng',
  'Warehouse': 'Kho',
  'Accounting': 'Kế toán',
  'Account': 'Tài khoản',
  'Services': 'Dịch vụ',
  'Pricing': 'Bảng giá',
  'Policies': 'Chính sách',
  'New address': 'Thêm mới',
  'Default': 'Mặc định',
  'Address': 'Địa chỉ',
  'Province': 'Tỉnh thành',
  'District': 'Quận huyện',
  'Ward': 'Phường xã',
  'Consignee': 'Người nhận',
  'Tel': 'Số điện thoại',
  'Address detail': 'Địa chỉ chi tiết',
  'Note': 'Ghi chú',
  'Set as default': 'Đặt làm mặc định',
  'View detail': 'Xem chi tiết',
  'Name or jancode of product you want to search': 'Tên hoặc jancode sản phẩm muốn tìm',
  'Link or name of product you want to search': 'Link hoặc tên sản phẩm muốn tìm',
  'Suggest for you': 'Gợi ý cho bạn',
  'Top products': 'Sản phẩm hàng đầu',
  'Cost of goods': 'Tiền hàng',
  'Shipment method': 'Phương thức vận chuyển',
  'Status': 'Trạng thái',
  'Updated at': 'Ngày cập nhật',
  'Product': 'Sản phẩm',
  'Empty': 'Trống',
  'User ID': 'Mã tài khoản',
  'Email': 'Email',
  'Name': 'Tên',
  'Role': "Vai trò",
  'Created at': 'Ngày tạo',
  'Latest update': 'Ngày cập nhật',
  'User information': 'Thông tin người dùng',
  'Authorization': 'Phân quyền',
  'User detail': 'Chi tiết người dùng',
  'Create User': 'Tạo tài khoản',
  'Not found': 'Không tìm thấy',
  'other items': 'sản phẩm khác',
  'All': 'Tất cả',
  'Start': 'Bắt đầu',
  'End': 'Kết thúc',
  'Retail': 'Đơn lẻ',
  'Wholesale': 'Đơn sỉ',
  'Shipment': 'Đơn vận chuyển hộ',
  'Orders': 'Đơn hàng',
  'Order': 'Đơn hàng',
  'Order Id': 'Mã đơn',
  'Tracking Id': 'Mã tracking',
  'Jancode': 'Mã Jancode',
  'No data': 'Không có dữ liệu',
  'Goods money': 'Tiền hàng',
  'Paid goods': 'Tiền hàng đã thanh toán',
  'Goods debited': 'Tiền hàng đã ghi nợ',
  'Debt money': 'Tiền nợ',
  'Unpaid goods': 'Tiền hàng chưa thanh toán',
  'Details': 'Chi tiết',
  'Service charge': 'Phí dịch vụ',
  'Service fee paid': 'Phí dịch vụ đã thanh toán',
  'Undebited service fee': 'Phí dịch vụ chưa ghi nợ',
  'Service fee debited': 'Phí dịch vụ đã ghi nợ',
  'Service fee to be debited': 'Phí dịch vụ chưa ghi nợ',
  'Service fee boxes': 'Phí dịch vụ hàng hóa',
  'Compensation': 'Đền bù',
  'Commodity compensation': 'Tiền đền bù',
  'Compensation debited': 'Tiền đền bù đã ghi nợ',
  'Compensation to be debited': 'Tiền đền bù chưa ghi nợ',
  'Additional cost': 'Phụ phí',
  'Price': 'Giá',
  'Quantity': 'Số lượng',
  'Amount': 'Số tiền',
  'Total amount': 'Tổng tiền',
  'Total payment': 'Tổng tiền thanh toán',
  'Tax': 'Thuế',
  'Tax percent': 'Phần trăm thuế',
  'Discount tax': 'Chiết khấu thuế',
  'Special declaration': 'Khai báo hàng hóa đặc biệt',
  'Special goods fee': 'Phí hàng hóa đặc biệt',
  'Insurance declaration': 'Khai báo bảo hiểm',
  'Insurance fee': 'Phí bảo hiểm',
  'Requests': 'Yêu cầu',
  'Repackage': 'Đóng gói',
  'Merge package': 'Gộp thùng',
  'Retail Detail': 'Chi tiết đơn lẻ',
  'Wholesale Detail': 'Chi tiết đơn sỉ',
  'Items': 'Sản phẩm',
  'Add product': 'Thêm sản phẩm',
  'No products found': 'Không tìm thấy sản phẩm',
  'Search product': 'Tìm kiếm sản phẩm',
  'Shipment info': 'Thông tin giao hàng',
  'Write': 'Ghi',
  'Content': 'Nội dung',
  'Order status': 'Trạng thái đơn hàng',
  'Order info': 'Thông tin đơn hàng',
  'Deposits': 'Nộp tiền',
  'Payments': 'Thanh toán',
  'Info': 'Thông tin',
  'Prepared by': 'Thực hiện',
  'Transactions arise': 'Giao dịch phát sinh',
  'Logs': 'Nhật ký',
  'Load more': 'Xem thêm',
  'SKU Id': 'Mã SKU',
  'Service fee': 'Phí dịch vụ',
  'Storage cost': 'Phí lưu kho',
  'Quantity assigned bill of lading': 'Số lượng phân cho vận đơn',
  'Quantity assigned to order': 'Số lượng được phân cho đơn',
  'Bill of lading code': 'Mã vận đơn',
  'List of bills of lading': 'Danh sách vận đơn',
  'Lading bill': 'Vận đơn',
  'Yes': 'Có',
  'No': 'Không',
  'Ward Id': 'Mã phường, xã',
  'Order detail': 'Chi tiết đơn hàng',
  'Refresh': 'Làm mới',
  'Change': 'Thay đổi',
  'Select': 'Chọn',
  'What are you looking for': 'Bạn đang tìm kiếm gì',
  'Search': 'Tìm kiếm',
  'Login': 'Đăng nhập',
  'Sign in to your account': 'Đăng nhập tài khoản của bạn',
  'Forgot password': 'Quên mật khẩu',
  'Reset your password': 'Đặt lại mật khẩu của bạn',
  'Back to login': 'Quay lại đăng nhập',
  'Money account': 'Tài khoản tiền',
  'Wallet': 'Ví',
  'Wallet type': 'Loại ví',
  'Balance': 'Số dư',
  'Beginning balance': 'Số dư đầu kì',
  'Deposit': 'Tiền nộp',
  'Total purchase amount': 'Tiền chi',
  'Withdrawal': 'Tiền rút',
  'Payment for order': 'Thanh toán đơn hàng',
  'Service fee payment': 'Thanh toán phí dịch vụ',
  'Currency': 'Tiền tệ',
  'Customer wallet': 'Ví người dùng',
  'Card': 'Thẻ',
  'Bank & Currency': 'Ngân hàng & Tiền tệ',
  'Payment order': 'Thanh toán đơn hàng',
  'Payment service': 'Thanh toán dịch vụ',
  'Card Id': 'Mã thẻ',
  'Card name': 'Tên thẻ',
  'Bank Id': 'Mã ngân hàng',
  'Bank': 'Ngân hàng',
  'Bank name': 'Tên ngân hàng',
  'Currency Id': 'Mã tiền tệ',
  'Symbol': 'Ký hiệu',
  'Create Wallet': 'Tạo ví',
  'Create Bank': 'Tạo ngân hàng',
  'Transaction type': 'Loại giao dịch',
  'Credit': 'Ghi có',
  'Debit': 'Ghi nợ',
  'Description': 'Mô tả',
  'Voucher date': 'Ngày chứng từ',
  'Ref code': 'Mã giao dịch',
  'Recipient': 'Đối tượng nhận',
  'Collect money': 'Thu tiền',
  'Deposit collection': 'Thu tiền',
  'Collect deposit according to invoice': 'Thu tiền theo hoá đơn',
  'Attachments': 'Đính kèm',
  'Detail': 'Chi tiết',
  'Transaction': 'Giao dịch',
  'Executor': 'Người thực hiện',
  'Credit account': 'Tài khoản ghi có',
  'Debit account': 'Tài khoản ghi nợ',
  'Create transaction': 'Tạo giao dịch',
  'Select customer': 'Chọn khách hàng',
  'Add new': 'Thêm mới',
  'New Retail': 'Đơn lẻ mới',
  'Create order': 'Tạo đơn hàng',
  'New order': 'Đơn hàng mới',
  'Enter username': 'Nhập tên người dùng',
  'Enter tel': 'Nhập số điện thoại',
  'Sort by role': 'Phân theo vai trò',
  'Select role': 'Chọn vai trò',
  'Users': 'Người dùng',
  "NEW": 'Mới',
  'Permission': 'Quyền',
  'Permissions': 'Quyền',
  'Read': 'Đọc',
  'Create': 'Tạo',
  'Delete': 'Xóa',
  'Remove': 'Gỡ',
  'Update': 'Cập nhật',
  'Updated': 'Đã cập nhật',
  'Created': 'Đã tạo mới',
  'Deleted': 'Đã xóa',
  'Group management': 'Quản lý nhóm',
  'User management': 'Quản lý người dùng',
  'Role management': 'Quản lý vai trò',
  'Function': 'Chức năng',
  'Error link': 'Liên kết không hợp lệ',
  'Purchases': 'Đơn mua hàng',
  'Auth': 'Xác thực',
  'Cart': 'Giỏ hàng',
  'Box': 'Thùng',
  'Piece': 'Cái',
  'Unit': 'Đơn vị',
  'Contract': 'Hợp đồng',
  'Contract Id': 'Mã hợp đồng',
  'Major': 'Nghiệp vụ',
  'Start date': 'Ngày bắt đầu',
  'End date': 'Ngày kết thúc',
  'Flight shipping fee': 'Phí vận chuyển đường bay',
  'Sea shipping fee': 'Phí vận chuyển đường biển',
  'Sea freight rate': 'Giá cước vận chuyển đường biển',
  'Air freight rate': 'Giá cước vận chuyển đường bay',
  'Air shipping weight type': 'Đơn vị vận chuyển đường bay',
  'Sea shipping weight type': 'Đơn vị vận chuyển đường biển',
  'Purchase order': 'Đơn mua hàng',
  'Tracking list': 'Danh sách tracking',
  'Tracking': 'Tracking',
  'Shipping fee': 'Phí vận chuyển',
  'Shipping fee to be debited': 'Phí vận chuyển chưa ghi nợ',
  'Shipping fee debt': 'Phí vận chuyển nợ',
  'Weight': 'Khối lượng',
  'Expected delivery': 'Giao hàng dự kiến',
  'Payment due date': 'Hạn thanh toán',
  'Shipment Detail': 'Chi tiết đơn vận chuyển hộ',
  'Sender name': 'Người gửi',
  'Sender tel': 'Số điện thoại người gửi',
  'Enter tracking': 'Nhập mã tracking',
  'New Shipment': 'Đơn vận chuyển hộ mới',
  'Create new tracking': 'Tạo mới tracking',
  'Role id': 'Mã vai trò',
  'Role detail': 'Chi tiết vai trò',
  'Roles': 'Vai trò',
  'Scope': 'Phạm vi',
  'New Wholesale': 'Đơn sỉ mới',
  'Customer Id': 'Mã khách hàng',
  'Id': 'Mã',
  'Max width': 'Chiều rộng tối đa',
  'Max length': 'Chiều dài tối đa',
  'Max height': 'Chiều cao tối đa',
  'Max weight': 'Khối lượng tối đa',
  'Shelves': 'Kệ hàng',
  'Area': 'Kho',
  'Floor': 'Tầng',
  'Row': 'Hàng',
  'Column': 'Cột',
  'Pallet type': 'Loại pallet',
  'Warehouse settings': 'Cài đặt kho',
  'General settings': 'Cài đặt chung',
  'Domestic delivery fee': 'Phí giao hàng nội địa',
  'Date of receipt of goods': 'Ngày nhập kho',
  'Discount code': 'Mã giảm giá',
  'Goods receipt': 'Nhập kho',
  'Supplier': 'Nhà cung cấp',
  'Buyer': 'Người mua',
  'Sub total': 'Tổng cộng',
  'Tax balance': 'Số dư thuế',
  'Attachment': 'Đính kèm',
  'Due payment': 'Hạn thanh toán',
  'Costs': 'Chi phí',
  'Dates': 'Thời gian',
  'Distributed': 'Đã phân hàng',
  'Remaining': 'Còn lại',
  'File': 'Tệp',
  'Distributed quantity': 'Số lượng đã phân hàng',
  'Remaining quantity': 'Số lượng còn lại',
  'Distribution quantity': 'Số lượng phân hàng',
  'Supply sale price': 'Giá bán cho sale',
  'Voucher code': 'Mã chứng từ',
  'Remaining orders': 'Các đơn hàng còn lại',
  'Distributed orders': 'Các đơn hàng đã phân hàng',
  'Distribution': 'Phân hàng',
  'Order quantity': 'Số lượng đơn hàng',
  'Purchase Detail': 'Chi tiết đơn mua hàng',
  'Select order': 'Chọn đơn hàng',
  'Paid': 'Đã thanh toán',
  'Debited': 'Đã ghi nợ',
  'Outstanding': 'Chưa thanh toán',
  'Purchases Detail': 'Chi tiết đơn mua hàng',
  'Unsolved': 'Chưa ghi nợ',
  'Supplier Id': 'Mã nhà cung cấp',
  'Link': 'Liên kết',
  'Create Supplier': 'Tạo nhà cung cấp',
  'Enter link': 'Nhập liên kết',
  'Enter address': 'Nhập địa chỉ',
  'Enter name': 'Nhập tên',
  'Enter note': 'Nhập ghi chú',
  'Buyer Id': 'Mã người mua',
  'Upload files': 'Tải tệp lên',
  'Money Accounting': 'Kế toán tiền',
  'Refund': 'Hoàn trả',
  'Spend money from card': 'Chi tiền từ thẻ',
  'Spend money from wallet': 'Chi tiền từ TKT',
  'Purchases Accounting': 'Kế toán mua hàng',
  'Sales Accounting': 'Kế toán bán hàng',
  'Collection of deposits according to multi-customer invoices': 'Thu tiền gửi theo hoá đơn nhiều khách hàng',
  'Vendor returns money': 'Nhà cung cấp trả lại tiền',
  'Tax is deducted': 'Tiền thuế được khấu trừ',
  'Capital contribution': 'Tiền góp vốn',
  'Collection: Intra-card money transfer': 'Thu: Chuyển tiền nội thẻ',
  'Financial income': 'Doanh thu hoạt động tài chính',
  '[Contract | Bill of Lading] Payment of service fee of contract & bill of lading': '[Hợp đồng | Vận đơn] Thanh toán phí dịch vụ HĐ & VĐ',
  'Enter beginning balance': 'Nhập số dư đầu kì',
  'Pay in': 'Nộp tiền',
  'Supplier wallet': 'Ví nhà cung cấp',
  'Cards': 'Thẻ',
  'Banks': 'Ngân hàng',
  'Wallets': 'Ví',
  'Accounting Dashboard': 'Bảng điều khiển Kế toán',
  'Receipts': 'Chứng từ',
  'Code': 'Mã giao dịch',
  'Invoice': 'Invoice',
  'Total amount refunded': 'Tổng tiền hoàn trả',
  'Choose a supplier first': 'Chọn nhà cung cấp trước',
  'Close': 'Đóng',
  'Enter': 'Truy cập',
  'Save': 'Lưu',
  'Add': 'Thêm',
  'Clear filter': 'Bỏ lọc',
  'Edit': 'Sửa',
  'Quick view': 'Xem nhanh',
  'Origin': 'Xuất xứ',
  'Goods': 'Hàng hoá',
  'Ingredients': 'Thành phần',
  'Product Id': 'Mã sản phẩm',
  'Product name': 'Tên sản phẩm',
  'Product image': 'Hình ảnh sản phẩm',
  'other suppliers': 'nhà cung cấp khác',
  'Origin Id': 'Mã xuất xứ',
  'Create origin': 'Tạo xuất xứ',
  'Create product': 'Tạo sản phẩm',
  'New product': 'Sản phẩm mới',
  'Private license': 'Giấy phép riêng',
  'Banned air': 'Cấm bay',
  'Banned sea': 'Cấm biển',
  'Banned export': 'Cấm xuất khẩu',
  'Embargo & more': 'Cấm vận và khác',
  'Embargo': 'Cấm vận',
  'Special goods': 'Hàng hoá đặc biệt',
  'Height': 'Chiều cao',
  'Width': 'Chiều rộng',
  'Length': 'Chiều dài',
  'Enter image url': 'Nhập liên kết URL hình ảnh',
  'Product box specifications': 'Quy cách thùng sản phẩm',
  'Change product image': 'Thay đổi hình ảnh sản phẩm',
  'Customs clearance code': 'Mã thông quan',
  'Supplier list': 'Danh sách nhà cung cấp',
  'Items in box': 'Số lượng trong thùng',
  'Add supplier': 'Thêm nhà cung cấp',
  'Payment for purchases': 'Thanh toán mua hàng',
  'Supplier returns money': 'Nhà cung cấp trả lại tiền',
  'View receipts': 'Xem chứng từ',
  'Download': 'Tải về',
  'Opening balance': 'Số dư đầu kỳ',
  'Purpose': 'Mục đích',
  'Collect by': 'Thu theo',
  'Withdraw money from wallet': 'Rút tiền từ ví',
  'Withdraw money from card': 'Rút tiền từ thẻ',
  'Refund for payment': 'Trả lại tiền thanh toán',
  'Refund for service': 'Trả lại tiền dịch vụ',
  'Return for compensation': 'Trả lại tiền đền bù',
  'Pay into': 'Nộp vào',
  'Unpaid order list': 'Danh sách đơn hàng chưa thanh toán',
  'Entries': 'Hạch toán',
  'View list': 'Xem danh sách',
  'The reason for collecting money': 'Lý do thu tiền',
  'Compensated': 'Đã đền bù',
  'Loading': 'Đang tải',
  'Distribute': 'Phân hàng',
  'Input tracking': 'Nhập tracking',
  'Create new': 'Tạo mới',
  'View order': 'Xem đơn hàng',
  'Selected order list': 'Danh sách đơn hàng đang chọn',
  'Refund type': 'Loại hoàn trả',
  'Refund amount': 'Số tiền trả lại',
  'Transfer fee': 'Phí chuyển khoản',
  'Payment for goods': 'Thanh toán tiền hàng',
  'Payment to supplier': 'Thanh toán cho nhà cung cấp',
  'Refund form': 'Hình thức đền bù',
  'Clearing debt': 'Bù trừ công nợ',
  'Clearing according to order': 'Đối trừ theo đơn hàng',
  'Cash compensation': 'Rút tiền mặt',
  'Refund according to order & invoice': 'Hoàn trả theo đơn hàng/ hoá đơn',
  'Payment according to order & invoice': 'Thanh toán theo đơn hàng/ hoá đơn',
  'Payment by': 'Thanh toán theo',
  'Available payment': 'Khả dụng thanh toán',
  'Auction Order': 'Đơn đấu giá',
  'Payment Order': 'Đơn thanh toán hộ',
  'New Payment Order': 'Đơn thanh toán hộ mới',
  'New Auction Order': 'Đơn đấu giá mới',
  'Payment Detail': 'Chi tiết đơn thanh toán hộ',
  'Auction Detail': 'Chi tiết đơn đấu giá',
  'Order file': 'File đơn hàng',
  'Create purchase': 'Tạo đơn mua hàng',
  'SFA': 'SFA',
  'Weight (kg)': 'Khối lượng (kg)',
  'Width (cm)': 'Rộng (cm)',
  'Height (cm)': 'Cao (cm)',
  'Length (cm)': 'Dài (cm)',
  'Create SKU': 'Tạo mới SKU',
  'Inbound': 'Nhập kho',
  'Sales return order': 'Trả hàng bán',
  'Inbound transfer order': 'Nhập đơn chuyển kho',
  'Internal': 'Trong kho',
  'Assemble to stock': 'Đặt hàng trong kho',
  'Assemble to order': 'Phân đơn hàng',
  'Lading bills': 'Vận đơn',
  'Invoices': 'Đơn chuyển kho',
  'Outbound': 'Xuất kho',
  'Sales order': 'Xuất đơn bán',
  'Outbound transfer order': 'Xuất đơn chuyển kho',
  'Purchase return order': 'Trả hàng mua',
  'Setting': 'Cài đặt',
  'General': 'Chung',
  'Total quantity': 'Tổng số lượng',
  'Coupon': 'Mã giảm giá',
  'Arrival date': 'Ngày nhận hàng',
  'New SKU': 'Tạo mới SKU',
  'Assemble to pallets': 'Đặt hàng vào pallet',
  'New pallet': 'Tạo mới pallet',
  'Search pallet': 'Tìm kiếm pallet',
  'Suggestion SKUs': 'Gợi ý SKU',
  'SKUs in pallet': 'SKU trong pallet',
  'Add SKU': 'Thêm SKU',
  'SKUs in SFA': 'SKU trong SFA',
  'Total deposits': 'Tổng tiền nộp',
  'Total outlay': 'Tổng tiền chi',
  'Download file': 'Tải file',
  'Shelve': 'Kệ',
  'Pallets have not been stored': 'Pallet chưa được sắp xếp',
  'List to buy': 'Danh sách cần mua',
  'Next step': 'Bước tiếp theo',
  'Quantity in stock': 'Số lượng có trong kho',
  'Distributing goods from inventory': 'Phân hàng từ tồn kho',
  'Order quantity from supplier': 'Số lượng đặt từ NCC',
  'Purchase inventory': 'Hàng cần tìm báo giá',
  'Quantity purchased': 'Số lượng đã mua',
  'Quantity to buy': 'Số lượng cần mua',
  'Lowest price': 'Giá thấp nhất',
  'Selected': 'Đã chọn',
  'Quantity for sale': 'Số lượng cần bán',
  'Collect by customer': 'Thu theo khách hàng',
  'Amount cannot be more than': 'Số tiền không được lớn hơn',
  'Quantity from inventory': 'Số lượng từ tồn kho',
  'The quantity cannot be more than': 'Số lượng không được lớn hơn',
  'type(s) selected': 'loại được chọn',
  'Select files': 'Chọn tệp',
  'Upload receipts': 'Tải file chứng từ',
  'Order from supplier': 'Đặt hàng từ NCC',
  'No order': 'Không có đơn hàng',
  'Goods deliveries': 'Đơn xuất kho',
  'Update suppliers': 'Cập nhật nhà cung cấp',
  'Create contract': 'Tạo hợp đồng',
  'Contract detail': 'Chi tiết hợp đồng',
  'Commodity information': 'Thông tin hàng hoá',
  'Contract fees': 'Các loại phí',
  'Domestic delivery': 'Giao hàng nội địa',
  'Contracts': 'Hợp đồng',
  'Contract name': 'Tên hợp đồng',
  'New contract': 'Hợp đồng mới',
  'Open the contract': 'Mở hợp đồng',
  'Close the contract': 'Đóng hợp đồng',
  'Goods inventory': 'Hàng cần bán',
  'Statistics': 'Thống kê',
  'Management': 'Quản lý',
  'Agency': 'Đại lý',
  'Representative': 'Người đại diện',
  'Coordinate': 'Toạ độ',
  'Agency name': 'Tên đại lý',
  'Agency Id': 'Mã đại lý',
  'Create agency': 'Tạo đại lý',
  'Longitude': 'Kinh độ',
  'Latitude': 'Vĩ độ',
  'Volume': 'Thể tích',
  'Pick out container': 'Chọn ra khỏi container',
  'SKUs in invoice': 'SKU trong invoice',
  'Export': 'Xuất',
  'Import': 'Nhập',
  'boxes': 'thùng',
  'Search invoice': 'Tìm kiếm invoice',
  'Search tracking': 'Tìm kiếm tracking',
  'Create new SFA': 'Tạo mới SFA',
  'Next to input SKUs': 'Đi đến nhập SKUs',
  'Create lading bill': 'Tạo vận đơn',
  'Suggestion SKUs for lading bill': 'SKU gợi ý cho vận đơn',
  'New lading bill': 'Vận đơn mới',
  'SKUs in lading bill': 'SKU trong vận đơn',
  'Shipping date': 'Ngày vận chuyển',
  'Move to': 'Chuyển đến',
  'Storage volume (m3)': 'Thể tích lưu trữ (m3)',
  'Storage weight (kg)': 'Trọng lượng lưu trữ (kg)',
  'New invoice': 'Invoice mới',
  'Create new invoice': 'Tạo mới invoice',
  'Create invoice': 'Tạo invoice',
  'Lading bills in invoice': 'Vận đơn trong invoice',
  'Add lading bill': 'Thêm vận đơn',
  'Select partner': 'Chọn đối tác',
  'Partner': 'Đối tác',
  'Create new goods delivery': 'Tạo đơn giao hàng mới',
  'Create goods delivery': 'Tạo đơn giao hàng',
  'New goods delivery': 'Đơn giao hàng mới',
  'Picked': 'Đã lấy',
  'To': 'Đến',
  'SKUs in goods delivery': 'SKU trong đơn giao hàng',
  'Cancel': 'Huỷ',
  'Shipping': 'Đang giao hàng',
  'Pick out': 'Chọn xuất kho',
  'Pick in container': 'Chọn lên container',
  'Unlock invoice': 'Mở khoá invoice',
  'Lock invoice': 'Khoá invoice',
  'Outbound sale order': 'Xuất đơn bán',
  'Search sale order': 'Tìm kiếm đơn bán',
  'Owned boxes': 'Thùng sở hữu',
  'Suggestion SKUs for order': 'SKU gợi ý cho đơn hàng',
  'SKUs owned by order': 'SKU sỡ hữu của đơn đặt hàng',
  'Next to lading bills of': 'Đi đến vận đơn của',
  'Storage': 'Kho',
  'Unlocked': 'Mở khoá',
  'Locked': 'Khoá',
  'Create new lading bill': 'Tạo mới vận đơn',
  'Select lading bill': 'Chọn vận đơn',
  'No SKU': 'Không có SKU',
  'items': 'hàng hoá',
  'Does not exist in the boxes in lading bills': 'Không tồn tại thùng trong vận đơn',
  'Does not exist in the boxes in goods delivery': 'Không tồn tại thùng trong đơn giao hàng',
  'Invalid SKU': 'SKU không hợp lệ',
  'Size': 'Kích thước',
  'No lading bills': 'Không có vận đơn',
  'Suggestion SKUs by SFA': 'SKU gợi ý cho SFA',
  'Create pallet type': 'Tạo loại pallet',
  'Create shelve': 'Tạo kệ',
  'Go to invoice': 'Đi tới Invoice',
  'Spend money': 'Chi tiền',
  'Internal transfer': 'Chuyển tiền nội bộ',
  'Payable': 'Công nợ phải trả',
  'Supplier debt': 'Công nợ nhà cung cấp',
  'Receivable': 'Công nợ phải thu',
  'Customer debt': 'Công nợ khách hàng',
  'Debt comparison': 'Đối trừ công nợ',
  'Bank card': 'Thẻ ngân hàng',
  'exceeds': 'vượt quá',
  'Are you sure these numbers?': 'Bạn có chắc những số liệu này?',
  'Expiration date': 'Ngày hết hạn',
  'Set pallet location': 'Đặt vị trí pallet',
  'Current location': 'Vị trí hiện tại',
  'Remove location': 'Xoá pallet ra khỏi kho',
  'Pack': 'Đóng gói',
  'Add item': 'Thêm hàng hoá',
  'Packed': 'Đóng thùng',
  'Closed box': 'Thùng đóng',
  'Open box': 'Thùng mở',
  'Reset': 'Đặt lại',
  'Merge boxes': 'Gộp thùng',
  'New combine box': 'Tạo thùng gộp mới',
  'Split boxes': 'Tách thùng',
  'Sku has been distributed': 'Sku đã được phân hàng',
  'Sku is not a free box': 'Sku không phải là Sku tự do',
  'Sku has put on the pallet': 'Sku đã đặt lên pallet',
  'Quantity to split': 'Số lượng cần tách',
  'Freedom box': 'Thùng tự do',
  'Child box': 'Thùng con',
  'Combine box': 'Thùng gộp',
  'child boxes': 'thùng con',
  'Collect advance payment': 'Thu tiền tạm ứng',
  'Debt payment customer': 'Khách hàng trả nợ',
  'Debt payment customers': 'Nhiều khách hàng trả nợ',
  'Other collect': 'Thu khác',
  'Receivable for': 'Phải thu cho',
  'Count': 'Số lượng',
  'Add order': 'Thêm đơn hàng',
  'Total:': 'Tổng:',
  'Select orders': 'Chọn đơn hàng',
  'Paid/Shipping fee': 'Đã thanh toán/Phí vận chuyển',
  'Shipping fee outstanding': 'Phí vận chuyển chưa thanh toán',
  'Paid/Service fee': 'Đã thanh toán/Phí dịch vụ',
  'Service fee outstanding': 'Phí dịch vụ chưa thanh toán',
  'Selected: ': 'Đã chọn: ',
  'Service fee to pay': 'Thanh toán phí dịch vụ',
  'Shipping fee to pay': 'Thanh toán phí vận chuyển',
  'Debt collection for service': 'Thu tiền dịch vụ',
  'Debt collection for goods': 'Thu tiền hàng',
  'Paid/Cost of goods': 'Đã thanh toán/Tiền hàng',
  'Cost of goods to pay': 'Thanh toán tiền hàng',
  'Cost of goods outstanding': 'Tiền hàng chưa thanh toán',
  'Other payment': 'Chi khác',
  'Pre payment for supplier': 'Trả trước cho NCC',
  'Payment purchases': 'Thanh toán đơn mua hàng',
  'Select purchases': 'Chọn đơn mua hàng',
  'Purchase Id': 'Mã đơn mua hàng',
  'From card': 'Từ thẻ',
  'To card': 'Đến thẻ',
  'Deposit amount': 'Số tiền chuyển',
  'Accepting amount': 'Số tiền nhận',
  'Fee transfer': 'Phí chuyển tiền',
  'Search sku': 'Tìm kiếm sku',
  'SKUs separate from': 'SKU tách ra từ',
  'Decouple': 'Tách thùng',
  'Combine SKUs to': 'Gộp các SKU vào',
  'Combine': 'Gộp thùng',
  'SKUs in': 'SKU nằm trong',
  'Object': 'Đối tượng',
  'Type': 'Loại',
  'Reference': 'Tham chiếu',
  'Option': 'Tùy chọn',
  'Arising from': 'Phát sinh từ',
  'User': 'Người dùng',
  'Code ref': 'Mã giao dịch',
  'Lading bill detail': 'Chi tiết vận đơn',
  'SKU list': 'Danh sách thùng hàng',
  'Actual weight': 'Kg thực',
  'Volumetric weight': 'Kg thể tích',
  'Maximum weight': 'Kg lớn nhất',
  'Actual cubic meter': 'Khối thực',
  'Maximum cubic meter': 'Khối lớn nhất',
  'Cubic meter conversion': 'Khối quy đổi (350/ kg)',
  'Freight charge type': 'Đơn vị tính cước',
  'Freight charge discount': 'Giảm giá cước',
  'Freight rate': 'Đơn giá phí vận chuyển',
  'Freight range type': 'Đối tượng tính cước theo',
  'From': 'Từ',
  'Container': 'Container',
  'Closed': 'Đã đóng',
  'Open': 'Mở',
  'Charging method': 'Cách tính cước',
  'Total freight': 'Tổng cước',
  'Freight range': 'Giá trị tính cước',
  'Freight charge': 'Cước vận chuyển',
  'Value of Lading bill': 'Giá trị vận đơn',
  'Calculated value': 'Giá trị tính',
  'Quantity delivered': 'Số lượng đã giao',
  'Quantity not delivered': 'Số lượng chưa giao',
  'Order list': 'Danh sách đơn hàng',
  'Change contract': 'Đổi hợp đồng',
  'User wallet': 'Ví người dùng',
  'ID': 'Mã',
  'Arising': 'Phát sinh',
  'Departure date': 'Ngày khởi hành',
  'Date of shipment': 'Ngày bốc hàng',
  'Invoice detail': 'Chi tiết Invoice',
  'Cut off date': 'Ngày đóng container',
  'Container parameters': 'Thông số Container',
  'Extra volume (m3)': 'Thể tích thêm (m3)',
  'Extra weight (kg)': 'Khối lượng thêm (kg)',
  'Cost of goods paid': 'Tiền hàng đã thanh toán',
  'Cost of goods unsolved': 'Tiền hàng chưa ghi nợ',
  'Get unit price from price list': 'Lấy đơn giá từ bảng giá',
  'Price list': 'Bảng giá',
  'Add SKU or Pallet Id': 'Thêm mã SKU hoặc Pallet',
  'Add orders to the contract': 'Thêm đơn hàng vào hợp đồng',
  'Add to contract': 'Thêm vào hợp đồng',
  'Deselect all': 'Bỏ chọn tất cả',
  'Exchange rate': 'Tỷ giá',
  'Transfer amount': 'Số tiền gửi',
  'Receive amount': 'Số tiền nhận',
  'Goods delivery': 'Đơn giao hàng',
  'Shipping code': 'Mã vận chuyển nội địa',
  'Debt service fee': 'Nợ phí dịch vụ',
  'Debt for goods': 'Nợ tiền hàng',
  'Connect': 'Kết nối',
  'Connected': 'Đã kết nối',
  'Not connected': 'Chưa kết nối',
  'Printer server IP': 'IP server máy in',
  'The printer does not work, check the connection again': 'Máy in không hoạt động, kiểm tra lại kết nối',
  'Check the connection again': 'Kiểm tra lại kết nối máy in',
  'Successful connection': 'Kết nối máy in thành công',
  'Invalid IP address': 'Địa chỉ IP không hợp lệ',
  'There is no valid SKU in the pallet': 'Không tồn tại SKU hợp lệ trong pallet',
  'Invalid pallet': 'Pallet không hợp lệ',
  'Send print data': 'Gửi dữ liệu in',
  'Print': 'In',
  'Input pallet id': 'Nhập mã pallet',
  'Input sfa id': 'Nhập mã sfa',
  'Create and print bulk tracking': 'Tạo và in số lượng lớn Tracking',
  'From date': 'Từ ngày',
  'To date': 'Đến ngày',
  'Filter': 'Lọc',
  'Currency type of cost of goods': 'Loại tiền tệ của tiền hàng',
  'Currency type of service fee': 'Loại tiền tệ của phí dịch vụ',
  'Voucher': 'Chứng từ',
  'Paid/Debited': 'Đã thanh toán/Đã ghi nợ',
  'Order type': 'Loại đơn',
  'Add tracking': 'Thêm tracking',
  'Print labels after creating SFA': 'In nhãn sau khi tạo SFA',
  'Expected SKU': 'SKU dự kiến',
  'Completed SKU': 'SKU đã hoàn thành',
  'Uncompleted SKU': 'SKU chưa hoàn thành',
  'Export invoice': 'Xuất Invoice',
  'Export of goods': 'Xuất phân hàng',
  'Expected': 'Dự kiến',
  'Actual': 'Thực tế',
  'Actual in container': 'Thực tế lên container',
  'Put on pallets': 'Đặt trên pallet',
  'View Sfa': 'Xem Sfa',
  'Quantity in cont': 'Số lượng lên cont',
  'Quantity out cont': 'Số lượng xuống cont',
  'Quantity in lading bill': 'Số lượng trong vận đơn',
  'Parent category': 'Danh mục cha',
  'Category': 'Danh mục',
  'Create category': 'Tạo danh mục',
  'View': 'Xem',
  '#': '#',
  'Transactions': 'Giao dịch',
  'Box management': 'Quản lý thùng',
  'No items': 'Không có sản phẩm',
  'Quantity in pallets': 'Số lượng trên pallet',
  'Quantity outbound': 'Số lượng đã xuất kho',
  'Organization': 'Tổ chức',
  'Organizations': 'Tổ chức',
  'Create organization': 'Thêm tổ chức',
  'Total number of boxes loaded': 'SL đã lên container',
  'Total number of boxes above expected': 'Số thùng dự kiến',
  'Total number of boxes downloaded': 'Số lượng thùng đã tải xuống container',
  'Not ordered in stock yet': 'Chưa đặt hàng trong kho',
  'Not yet distributed': 'Chưa được phân hàng',
  'Inventory number': 'Số lượng tồn kho',
  'Next to distribute': 'Đi đến phân hàng',
  'Sort by pallet': 'Sắp xếp theo pallet',
  'Select pallet': 'Chọn pallet',
  'SFA Id': 'Mã SFA',
  'Goods delivery Id': 'Mã đơn giao hàng',
  'Aggregate box': 'Thùng gộp',
  'Aggregate box reference': 'Tham chiếu thùng gộp',
  'SKU created today': 'Thùng đã tạo hôm nay',
  'SFA created today': 'SFA đã tạo hôm nay',
  'Add pallet': 'Thêm pallet',
  'Orther receipt': 'Chứng từ khác',
  'The box must be a freedom box': 'Sku phải là thùng tự do',
  'Warehouse model': 'Mô hình kho',
  'Compensation amount': 'Số tiền đền bù',
  'Automatic add lading bill': "Tự động thêm vận đơn",
  'Confirmation': 'Xác nhận',
  'Are you sure you want to delete?': 'Bạn có chắc chắn muốn xoá?',
  'Distributed goods': 'Hàng đã phân',
  'Purchase cost': 'Chi phí mua hàng',
  'Fees': 'Các loại phí',
  'Collect advance payment (multipe customers)': 'Thu tiền tạm ứng (nhiều khách hàng)',
  'Add wallets': 'Thêm ví',
  'Select wallets': 'Chọn ví',
  'Debt collection for service lading bills': 'Thu tiền dịch vụ vận đơn',
  'Box available in picker': 'Chưa xuất kho',
  'Box available out picker': 'Chưa nhập kho',
  'Box overload out picker': 'Nhập kho vượt mức',
  "Add new child transaction": 'Thêm giao dịch phát sinh',
  'Reference type': 'Loại tham chiếu',
  'Add transaction reference': 'Thêm tham chiếu',
  'Purchase quantity': 'Số lượng mua',
  'Goods not yet distributed': 'Hàng chưa phân',
  'All customer': 'Tất cả khách hàng',
  'Add tags': 'Thêm nhãn',
  'Tags': 'Nhãn',
  'Oldest created date': 'Ngày tạo cũ nhất',
  'Latest created date': 'Ngày tạo mới nhất',
  'Order by': 'Sắp xếp theo',
  'Goods statistics': "Thống kê lượng hàng",
  'Total SKUs': 'Tổng số SKU',
  'Goods not load into containers yet': 'Hàng chưa tải lên container',
  'Total number of boxes': 'Tổng số lượng thùng',
  'Total volume': 'Tổng thể tích',
  'Total weight': 'Tổng trọng lượng',
  'Freight charge pay for order': 'Cước thanh toán cho đơn hàng',
  'Other': 'Khác',
  'Not in the contract': 'Không nằm trong hợp đồng',
  'Pay freight charge in lading bill': 'Thanh toán phí vận chuyển ở vận đơn',
  'Transportation company': 'Công ty vận chuyển',
  'Purchasing company': 'Công ty bán hàng',
  'Same currency transfer': 'Chuyển tiền nội tệ',
  'Other currency transfer': 'Chuyển tiền ngoại tệ',
  'Tracking code': 'Mã tracking',
  'Select tracking': 'Chọn tracking',
  'Add created tracking': 'Thêm tracking đã tạo',
  'Container inspection': 'Kiểm hàng',
  'Search container': 'Tìm kiếm container',
  'Comparison clearing debt': 'Đối trừ công nợ',
  'Fees can be consulted': 'Các loại phí có thể tham khảo',
  'Collect reason': 'Lý do thu',
  'Payment reason': 'Lý do chi',
  'Location': 'Vị trí',
  'Unload goods from pallets': 'Gỡ hàng ra khỏi Pallet',
  'Unload box from container': 'Gỡ thùng ra khỏi container',
  'Convert pallet': 'Chuyển pallet',
  'In stock': 'Trong kho',
  'Uncompensated': 'Chưa đền bù',
  'Other fees': 'Phí khác',
  'All goods in stock': 'Tất cả hàng trong kho',
  'Goods not on the lading bills': 'Hàng chưa lên vận đơn',
  'Goods have not been on the lading bills or have not been fully loaded': 'Hàng chưa lên vận đơn hoặc chưa lên đủ tại vận đơn có invoice',
  'Goods have not been loaded into the container or have not loaded up as expected (at lading bills)': 'Hàng chưa lên container hoặc chưa lên hết so với dự kiến (tại vận đơn)',
  'Goods have not been distributed or have not been fully distributed for the order': 'Hàng chưa được phân hoặc chưa phân hết cho đơn hàng',
  'Clear': 'Nhập lại',
  'Fund': 'Quỹ',
  'Cash fund': 'Quỹ tiền mặt',
  'Transfer deposit to cash': 'Rút tiền gửi về nhập quỹ',
  'Transfer cash to deposit': 'Mang tiền mặt gửi ngân hàng',
  'Fund account': 'Tài khoản quỹ',
  'Discounts on purchases': "Giảm giá hàng mua",
  'Discount sales': 'Giảm giá hàng bán',
  'Accounting inventory': 'Kiểm kê',
  'Accounting inventory distribution': 'Phân bổ',
  'Transactions remaining': "Số tiền còn lại",
  'Distribute transaction': 'Phân bổ transaction',
  'Create prices': 'Tạo giá mới',
  'Remove empty pallets from stock': 'Xoá pallet trống ra khỏi kho',
  'Start time': 'Thời gian bắt đầu',
  'Conditions to apply': 'Điều kiện để áp dụng',
  'Export excel': 'Xuất excel',
  'Export success': 'Xuất thành công',
  'Box quantity in picker': 'Số lượng nhập container',
  'Box quantity out picker': 'Số lượng xuất container',
  'Quantity of SKUs to enter in SFA': 'Số lượng SKU cần nhập vào SFA',
  'Do you want to change the page?': 'Bạn có muốn chuyển trang?',
  'Tally': 'Kiểm đếm',
  'Do you want to assemble on pallets?': 'Bạn có muốn tiếp tục đặt hàng vào pallet?',
  'There are': 'Còn',
  'packages left untested': "kiện chưa được kiểm hàng",
  'Under': 'Dưới',
  'Account aggregation': 'Tài khoản tổng hợp',
  'Last 30 days': '30 ngày gần nhất',
  'Last 90 days': '90 ngày gần nhất',
  'Custom': 'Tuỳ chỉnh',
  'All time': 'Tất cả thời gian',
  'Payment/Advance payment salary': 'Trả lương/Tạm ứng cho nhân viên',
  'Social, Health, Unemployment insurance': 'Chi tiền BHXH, BHYT, BHTN',
  'Pay taxes and fees to the state budget': 'Nộp thuế, phí ngân sách nhà nước',
  'Discount': 'Chiết khấu',
  'Buy supplies': 'Mua vật tư',
  'Buy instrument and tools': 'Mua công cụ, dụng cụ',
  'Office and warehouse rental costs': 'Chi phí thuê văn phòng, kho bãi',
  'Compensation unsolved': 'Chưa đền bù',
  'Collect domestic shipping fee': 'Thu phí vận chuyển nội địa',
  'Collect bank interest': 'Thu lãi ngân hàng',
  'Have not entered the purchase quantity for the order': 'Chưa nhập số lượng mua cho đơn hàng',
  'Expand': 'Mở rộng',
  'Receipts type': 'Loại chứng từ',
  'Binding conditions': "Điều kiện ràng buộc",
  'Value': 'Giá trị',
  'Key': 'Khoá',
  'Select product': 'Chọn sản phẩm',
  'Number of boxes': 'Số lượng thùng',
  'Use tax-inclusive price': 'Sử dụng giá bao gồm thuế',
  'Tax included': 'Bao gồm thuế',
  'Packing information': 'Thông tin đóng gói',
  'Number of boxes is': 'Số lượng thùng là',
  'Please collect enough boxes to save on shipping costs': 'Hãy gom đủ thùng để tiết kiệm chi phí vận chuyển',
  'Support': 'Hỗ trợ',
  'Support types': 'Loại hỗ trợ',
  'Support type': 'Loại hỗ trợ',
  'STT': "STT",
  'Create support type': 'Tạo loại hỗ trợ',
  'Support post': 'Bài hỗ trợ',
  'Deadline': 'Hạn cuối',
  'Creator': 'Người tạo',
  'Create a new support post': 'Tạo bài hỗ trợ mới',
  'New support': 'Bài hỗ trợ mới',
  'Title': 'Tiêu đề',
  'Active': 'Đang hoạt động',
  'Add new feedback': 'Thêm phản hồi mới',
  'Send': 'Gửi',
  'Suggested products': 'Sản phẩm gợi ý',
  'Create compensation': 'Tạo đền bù',
  "Packed date": 'Ngày đóng thùng',
  'Not seen': 'Chưa xem',
  'Seen': 'Đã xem',
  'people read': 'người đã xem',
  'comments': 'phản hồi',
  'created at': 'đã tạo lúc',
  'Missing': 'Còn thiếu',
  'Enough quantity': 'Số lượng đủ',
  'Received at the warehouse': 'Đã nhận tại kho',
  "Percent": "Phần trăm",
  'Accounting account': 'Tài khoản kế toán',
  'Received at warehouse': 'Đã nhận hàng tại kho',
  'Waiting to receive': 'Chờ nhận hàng tại kho',
  'Purchase cost outstanding': 'Chi phí mua hàng chưa thanh toán',
  'Purchase cost unsolved': 'Chi phí mua hàng chưa ghi nợ',
  'General accounting': 'Tổng hợp',
  'Bill of lading payment': 'Thanh toán vận đơn',
  'Expected quantity': 'Số lượng dự kiến',
  'Available quantity': 'Số lượng sẵn có',
  'Delivery quantity': 'Số lượng giao hàng',
  'Applies to all': 'Áp dụng cho tất cả',
  'Schedule change': 'Thay đổi thời gian',
  'Remaining distributable': 'Số lượng có thể phân hàng',
  'Remove the order from the contract': 'Xoá đơn hàng ra khỏi hợp đồng',
  'Create and add': 'Tạo và thêm',
  'Delivery partner': 'Đối tác giao hàng',
  'Total': 'Tổng',
  'The lading bill available for container': 'Vận đơn khả dụng cho container',
  'Tracking images': 'Hình ảnh tracking',
  'Notification': 'Thông báo',
  'Mark all read': 'Đánh dấu đã đọc tất cả',
  'Delete transaction': 'Xoá giao dịch',
  'Watch boxes was in container': 'Xem thùng đã lên container',
  'Watch data was been error': 'Xem dữ liệu lỗi',
  'Transaction statistics': 'Thống kê giao dịch',
  'Delivery address': 'Địa chỉ giao hàng',
  'Import excel': 'Nhập excel',
  'Tracking is not distributed': 'Tracking chưa được phân hàng',
  'Picking quantity': "Số lượng đang pick",
  'Allow order that already have another contract to change to this contract': 'Cho phép đơn hàng đã có hợp đồng khác thay đổi thành hợp đồng này',
  'Sale order': 'Đơn bán hàng',
  'Remove the children box': 'Loại bỏ thùng con',
  'Debited at': 'Ngày ghi nợ',
  'Watch boxes was not in container': 'Xem thùng chưa lên container',
  'Box with 1 lading bill': 'Thùng trên 1 vận đơn',
  'Box with multiple lading bill': 'Thùng trên nhiều vận đơn',
  'Remove all': 'Gỡ tất cả',
  'During balance': 'Số dư trong kỳ',
  'Closing balance': 'Số dư cuối kỳ',
  'Payment refund': 'Hoàn trả thanh toán',
  'Pallet Id': 'Mã pallet',
  'Pallet': 'Pallet',
  'Subscribe notification': 'Đăng ký thông báo',
  'Subscribe': 'Đăng ký',
  'Price with tax': 'Giá có thuế',
  'Price without tax': 'Giá chưa thuế',
  "Remove successfully": 'Xóa thành công',
  'Province id': 'Mã tỉnh thành',
  'Country': 'Quốc gia',
  'District id': 'Mã quận/huyện',
  'Ward id': 'Mã phường/xã',
  'Select all': 'Chọn tất cả',
  "Supplier price": 'Giá nhà cung cấp',
  "Create province": 'Tạo tỉnh thành',
  "Create district": 'Tạo quận/huyện',
  "Create ward": 'Tạo phường/xã',
  "Select country": 'Chọn quốc gia',
  "Service fee discount": 'Giảm giá phí dịch vụ',
  "Allow the use of the custom freight rate": 'Cho phép dùng đơn giá vận chuyển tuỳ chỉnh',
  "Distribution/Quantity": 'Đã phân/Số lượng mua',
  "Select SKU": "Chọn SKU",
  "Additional purchase order": 'Đơn mua thêm',
  "New purchase order": 'Đơn mua mới',
  "Distributed/Quantity": 'Đã phân/Số lượng mua',
  "Available for distribution": 'Hàng cần phân',
  "Enter province id": 'Nhập mã tỉnh thành',
  "Enter district id": 'Nhập mã quận/huyện',
  "Enter ward id": 'Nhập mã phường/xã',
  "Enter province name": 'Nhập tên tỉnh thành',
  "Enter district name": 'Nhập tên quận/huyện',
  "Enter ward name": 'Nhập tên phường/xã',
  "Default domestic money": "Nội tệ mặc định",
  "Undefined": 'Không xác định',
  "Quantity not in container": "Số lượng chưa lên cont",
  "Payment overdue": 'Quá hạn thanh toán',
  "Not payment due date yet": "Chưa đến hạn thanh toán",
  "Payment due date is today": 'Có hạn thanh toán hôm nay',
  "No payment due date": 'Chưa có hạn thanh toán',
  "Payment not completed": 'Chưa hoàn tất thanh toán',
  "Product/ Payment due date": 'Sản phẩm/ Hạn thanh toán',
  "Payment for container": 'Thanh toán cho container',
  "Container debt": 'Công nợ container',
  "Input": "Đầu vào",
  "Output": "Đầu ra",
  "Profit": "Lợi nhuận",
  "Order shipping fee": "Cước vận chuyển đơn hàng",
  "Move to a new bill of lading": 'Chuyển đến vận đơn mới',
  "Transfer the bill of lading": 'Chuyển vận đơn',
  "Desired delivery": 'Giao hàng mong muốn',
  "Are you sure you want to debit?": 'Bạn có chắc chắn muốn ghi nợ?',
  "Order credit": "Tín dụng đơn hàng",
  "Create order credit": "Tạo tín dụng đơn hàng",
  "Credit limit": "Hạn mức",
  "Payment for order credit": "Thanh toán tín dụng đơn hàng",
  "Select order credit": "Chọn tín dụng đơn hàng",
  "Credit card": "Tín dụng",
  "In use": "Đang sử dụng",
  "Used": "Đã dùng",
  "Decouple box item": 'Tách sản phẩm',
  "Picked quantity": 'Số lượng đã lên',
  "Referer quantity": 'Số lượng tham chiếu',
  "Regular price": 'Giá bán',
  "Product albums": 'Album sản phẩm',
  "Sale price": 'Giá khuyến mãi',
  "Percentage off": '% Giảm giá',
  "Album edit": 'Chỉnh sửa album',
  "Review": 'Đánh giá',
  "Star rating": 'Điểm đánh giá',
  "Add category": 'Thêm danh mục',
  "Featured image": 'Hình ảnh đại diện',
  "Card statistics": "Thống kê thẻ",
  "Split box items": "Tách sản phẩm",
  "Decouple box": "Thùng tách",
  "The box is move to": "Thùng được chuyển đến",
  "Update decouple box": "Cập nhật thùng được tách",
  "Update new box": "Cập nhật thùng mới",
  "Update box moved to": "Cập nhật thùng chuyển đến",
  "Move items to another box": "Chuyển sản phẩm tới thùng khác",
  "Tag": "Thẻ",
  "Add tag": "Thêm thẻ",
  "Remove from lading bill": "Gỡ khỏi vận đơn",
  "Add to goods delivery": "Thêm vào đơn giao hàng",
  "Add to lading bill": "Thêm vào vận đơn",
  "Are you sure?": "Bạn có chắc chắn không?",
  "Quantity in goods delivery": "Số lượng trên đơn giao hàng",
  "Exchange": 'Quy đổi',
  "Inventory": "Hàng tồn kho",
  "Official quota": "Chính ngạch",
  "Manifest date": "Ngày kê khai hải quan",
  "Booking date": "Ngày đặt cont",
  "Freight forwarder": "Đơn vị thông quan",
  "Container type": "Loại container",
  "Max volume": "Thể tích tối đa",
  "Form": "Hình thức",
  "Add customer": "Thêm khách hàng",
  "Black": "Cont lậu",
  "White": "Cont chính ngạch",
  "Flight code": "Mã chuyến bay",
  "Add HAWB": "Thêm HAWB",
  "Total days of storage": "Số ngày lưu kho",
  "Inventory by customer": "Hàng hoá theo khách",
  "Post": "Bài viết",
  "Post category": "Danh mục bài viết",
  "Create a new post": "Tạo bài viết mới",
  "New post": "Bài viết mới",
  "Publish": "Xuất bản",
  "Draft": "Nháp",
  "Distributed canceled": "Đã huỷ phân hàng",
  "Cancel a distribution": "Huỷ bỏ phân hàng",
  'Watch boxes was out container': 'Xem thùng đã xuống container',
  "Auction logs": "Lịch sử đấu giá",
  "Success": "Thành công",
  "End time": "Thời gian kết thúc",
  "Bidding": "Đang đấu giá",
  "Exported date": "Ngày xuất kho",
  "Loaded": "Đã tải lên",
  "Export CSV": "Xuất CSV",
  "View exported boxes": 'Xem các thùng đã xuất',
  "Container costs": 'Chi phí container',
  "Purchase surcharge": "Phụ phí mua hàng",
  "Purchase surcharge percentage": "% phụ phí mua hàng",
  "Auto add contract": "Tự động thêm hợp đồng",
  "Estimated freight charge": "Phí vận chuyển ước tính",
  "Brand": "Thương hiệu",
  "Link store": "Đường dẫn sản phẩm",
  "Ecommerce channel": "Sàn thương mại",
  "Manufacturing year": "Năm sản xuất",
  "Product code": "Mã sản phẩm của sàn",
  "Create ecommerce channel": "Tạo sàn thương mại",
  "Affiliate store": "Cửa hàng tiếp thị liên kết",
  "Create affiliate store": "Tạo cửa hàng tiếp thị liên kết",
  "Affiliate price": "Giá tiếp thị liên kết",
  "Affiliate": "Tiếp thị liên kết",
  "Affiliate product": "Sản phẩm tiếp thị liên kết",
  "Affiliate category": "Danh mục tiếp thị liên kết",
  "Initial price": "Giá gốc",
  "Delivery type": "Hình thức giao hàng",
  "Anonymous delivery": "Giao hàng ẩn danh",
  "Free delivery": "Miễn phí giao hàng",
  "Add banner": "Thêm banner",
  "Product ratio": "Tỉ lệ đánh giá sản phẩm",
  "NotYetApprove": "Chờ duyệt",
  "CompletedApprove": "Đã duyệt",
  "CompletedCancel": "Hủy đơn hàng",
  "CompletedPurchaseCostDebit": "Ghi nợ đơn hàng",
  "CompletedPurchaseCostPayment": "Thanh toán đơn hàng",
  "CompletedPurchases": "Mua hàng",
  "CompletedReceiveJp": "Nhập kho Nhật",
  "CompletedServiceDebit": "Ghi nợ tiền dịch vụ",
  "CompletedServicePayment": "Thanh toán tiền dịch vụ",
  "CompletedShippingJp": "Xuất kho Nhật",
  "CompletedReceiveVn": "Nhập kho Việt Nam",
  "CompletedShippingVn": "Xuất kho Việt Nam",
  "CompletedFinish": "Hoàn thành đơn",
  "Clean up warehouse": "Dọn kho",
};
