<template>
  <TModal
    title="Compensation"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="add"
  >
    <template #actions>
      <TButton
        content="Create"
        :options="{ disabled: loading }"
        icon="cil-plus"
        @click="add"
      />
    </template>
    <SSelectOrganization
      :value.sync="data.organization_id"
      :prepend="[]"
      label="Organization"
      class="mb-3"
    />
    <SSelectCurrency
      label="Currency"
      :value.sync="data.currency_id"
      :prepend="[]"
      class="mb-3"
    />
    <TInputMoney
      label="Compensation amount"
      :value.sync="data.amount"
      :currency="data.currency_id"
      class="mb-3"
    />
    <TInputTextarea label="Note" :value.sync="data.note" placeholder="Note" />
  </TModal>
</template>

<script>
import organizationStore from "@/core/services/tomoni/organization.local";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    lading_bill_id: {
      type: [String, Number],
      required: false,
      default: "",
    },
    order_id: {
      type: String,
      required: false,
      default: "",
    },
    type: {
      type: String,
      required: false,
      default: "warehouse",
      validator(value) {
        return ["warehouse", "order"].includes(value);
      },
    },
  },
  data() {
    return {
      data: {
        organization_id: organizationStore.get(),
      },
    };
  },
  watch: {
    lading_bill_id() {
      this.refreshData();
    },
    order_id() {
      this.refreshData();
    },
  },
  computed: {
    loading() {
      return this.$store.getters[
        this.type + ".lading_bill_compensations.loading"
      ];
    },
  },
  methods: {
    refreshData() {
      this.data = this.lodash.pick(this.data, ["organization_id"]);
    },
    add() {
      const resource =
        this.type == "order"
          ? "order_compensations"
          : "lading_bill_compensations";
      this.$store
        .dispatch(
          this.type + `.${resource}.create`,
          this.lodash.pickBy({
            lading_bill_id: this.lading_bill_id,
            order_id: this.order_id,
            ...this.data,
          })
        )
        .then(() => {
          this.$emit("updated");
          this.refreshData();
          this.$emit("update:show", false);
        });
    },
  },
};
</script>
