<template>
  <div>
    <div>
      <label>
        <TMessage v-if="label" bold :content="label" />
      </label>
      <CLink class="float-right" @click="showModal = true">
        <TMessage content="Add new" />
      </CLink>
    </div>
    <TSelect
      v-if="!editable"
      class="m-0"
      :options="list"
      :value="valueSelected"
      :selectOptions="{
        internalSearch: true,
        'custom-label': customLabel,
        preserveSearch: true,
        ...selectOptions,
      }"
      :normalizer="{ label: 'full_address', value: 'id' }"
      placeholder="Select"
      :loading="loading"
      @select="select"
      @search="search"
    >
      <template #singleLabel="{ option }">
        <div class="d-flex">
          <TMessage
            :content="option.consignee"
            noTranslate
            :truncate="1"
            size="small"
            class="text-break"
          >
            <template #suffix>
              -
              {{ option.full_address }}
            </template>
          </TMessage>
        </div>
      </template>
    </TSelect>
    <TSelectEditable
      v-else
      class="m-0"
      :options="list"
      :value="valueSelected"
      :selectOptions="{
        internalSearch: false,
        preserveSearch: true,
        'custom-label': customLabel,
        ...selectOptions,
      }"
      placeholder="Select"
      :loading="loading"
      @search="search"
      :normalizer="{ label: 'full_address', value: 'id' }"
      @select="select"
      @close="$emit('close')"
      @change="$emit('change', $event)"
    >
      <template #singleLabel="{ option }">
        <div class="d-flex">
          <TMessage
            :content="option.consignee"
            noTranslate
            :truncate="1"
            size="small"
            class="text-break"
          >
            <template #suffix>
              -
              {{ option.full_address }}
            </template>
          </TMessage>
        </div>
      </template>
    </TSelectEditable>
    <SModalAddress
      :show.sync="showModal"
      type="place_of_delivery"
      @created="created"
    />
  </div>
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    prepend: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    value: {
      type: [String, Number],
      required: false,
      default: function () {
        return "";
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    selectOptions: {
      type: Object,
      require: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
  },
  data() {
    return {
      valueSelected: null,
      showModal: false,
    };
  },
  created() {
    this.$store.dispatch("warehouse.place_of_deliveries.fetch.if-first-time");
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value == null) {
          this.checkValue("");
          return;
        }
        this.checkValue(value);
      },
    },
    valueSelected: {
      immediate: false,
      handler(value) {
        if (value) {
          this.$emit("update:value", value.id);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.place_of_deliveries.list",
      loading: "warehouse.place_of_deliveries.fetching",
    }),
    listC() {
      return [...this.prepend, ...this.list];
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change");
    },
    customLabel({ consignee, full_address }) {
      return (consignee += " - " + full_address);
    },
    checkValue(value) {
      const firstValue = this.listC.find((x) => x.id === value);
      // Nếu value rỗng
      if (!value && !this.prependAll) {
        this.valueSelected = null;
        return;
      }
      // Value k có trong list
      if (!firstValue) {
        this.$tomoni.warehouse.place_of_deliveries
          .all({ "filter[id]": value })
          .then(({ data }) => {
            this.firstValue = data.data[0];
            this.valueSelected = data.data[0];
          });
      }
      // Value có trong list
      if (firstValue) {
        this.valueSelected = firstValue;
      }
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("warehouse.place_of_deliveries.apply-query", {
        "filter[consignee]": param,
      });
    }, 300),
    created(data) {
      this.valueSelected = data;
      this.$emit("update:value", data.id);
      this.showModal = false;
    },
  },
};
</script>
