<template>
  <CBadge :color="getColor" class="px-2 p-1">
    <TMessage :content="getStatus" />
  </CBadge>
</template>

<script>
export default {
  props: {
    status: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    getColor() {
      return this.status ? "success" : "primary";
    },
    getStatus() {
      return this.status ? "Closed" : "Open";
    },
  },
};
</script>
