const Dashboard = () => import("../views/Dashboard");
const BankCollect = () => import("../views/bank/collect/Index");
const BankSpend = () => import("../views/bank/spend/Index");
const BankTransfer = () => import("../views/bank/transfer/Index");
const GeneralOrtherReceipt = () => import("../views/general/receipt/Index");
const WalletUser = () => import("../views/wallets/user/Index");
const WalletUserDetail = () => import("../views/wallets/user/Detail");
const SupplierWallet = () => import("../views/wallets/supplier/Index");
const SupplierWalletDetail = () => import("../views/wallets/supplier/Detail");
const Cards = () => import("../views/wallets/card/Index");
const CardDetail = () => import("../views/wallets/card/Detail");
const SettingBank = () => import("../views/setting/bank/Index");
const SupplierDebt = () => import("../views/purchase/SupplierDebt");
const CustomerDebt = () => import("../views/sale/CustomerDebt");
const PurchaseComparisonClearingDebt = () => import("../views/purchase/comparison-clearing-debt/Index");
const SaleComparisonClearingDebt = () => import("../views/sale/comparison-clearing-debt/Index");
const SaleCompensation = () => import("../views/sale/Compensation");
const FundCollect = () => import("../views/fund/collect/Index");
const FundSpend = () => import("../views/fund/spend/Index");
const Fund = () => import("../views/fund/management/Index");
const FundDetail = () => import("../views/fund/management/Detail")
const AccountingInventoryDistribution = () => import("../views/inventory/Distribution");
const WarehouseCompensation = () => import("../views/warehouse/Compensation");
const NewSupport = () => import('@/workspaces/admin/views/support/NewSupport')
const Supports = () => import('@/workspaces/admin/views/support/Index')
const SupportDetail = () => import('@/workspaces/admin/views/support/SupportDetail')
const TransationtStatistics = () => import("../views/statistics/TransactionStatistics");
const PurchaseCompensation = () => import("../views/purchase/compensation/Index");
const SalePaymentDueDate = () => import("../views/sale/PaymentDueDate");
const PurchasePaymentDueDate = () => import("../views/purchase/PaymentDueDate");
const AccountStatistics = () => import("../views/statistics/Account");
const ContainerDebt = () => import("../views/warehouse/ContainerDebt")
const Credit = () => import("../views/wallets/credit/Index");
const CreditDetail = () => import("../views/wallets/credit/Detail");
const CardStatistics = () => import("../views/statistics/Card");

export default [
  {
    path: "dashboard",
    name: "Accounting Dashboard",
    component: Dashboard
  },
  {
    path: 'bank',
    meta: {
      label: "Bank"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "collect-money",
        name: "Collect money",
        component: BankCollect,
      },
      {
        path: "spend-money",
        name: "Spend money",
        component: BankSpend,
      },
      {
        path: "internal-transfer",
        name: "Internal transfer",
        component: BankTransfer,
      },
    ]
  },
  {
    path: 'purchase',
    meta: {
      label: "Purchase"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "supplier-debt",
        name: "Supplier debt",
        component: SupplierDebt,
      },
      {
        path: "comparison-clearing-debt",
        name: "Comparison clearing debt",
        component: PurchaseComparisonClearingDebt,
      },
      {
        path: "compensation",
        name: "Compensation",
        component: PurchaseCompensation,
      },
      {
        path: "payment-due-date",
        name: "Payment due date",
        component: PurchasePaymentDueDate,
      },
    ]
  },
  {
    path: 'sale',
    meta: {
      label: "Sale"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "customer-debt",
        name: "Customer debt",
        component: CustomerDebt,
      },
      {
        path: "comparison-clearing-debt",
        meta: {
          label: "Comparison clearing debt",
          title: "Comparison clearing debt",
        },
        component: SaleComparisonClearingDebt,
      },
      {
        path: "compensation",
        name: "Compensation",
        component: SaleCompensation,
      },
      {
        path: "payment-due-date",
        name: "Payment due date",
        component: SalePaymentDueDate,
      },
    ]
  },
  {
    path: 'warehouse',
    meta: {
      label: "Warehouse"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "compensation",
        meta: {
          title: "Compensation",
          label: "Compensation",
        },
        component: WarehouseCompensation,
      },
      {
        path: "container-costs",
        name: "Container costs",
        component: ContainerDebt,
      },
    ]
  },
  {
    path: 'fund',
    meta: {
      label: "Cash fund"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "collect-money",
        meta: {
          label: "Collect money",
          title: "Collect money",
        },
        component: FundCollect,
      },
      {
        path: "spend-money",
        meta: {
          label: "Spend money",
          title: "Spend money",
        },
        component: FundSpend,
      },
    ]
  },
  {
    path: 'general',
    meta: {
      label: "General accounting"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "orther-receipt",
        name: "Orther receipt",
        component: GeneralOrtherReceipt,
      },
    ]
  },
  {
    path: 'inventory',
    meta: {
      label: "Accounting inventory"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "distribution",
        name: "Accounting inventory distribution",
        component: AccountingInventoryDistribution,
      },
    ]
  },
  {
    path: 'statistics',
    meta: {
      label: "Statistics"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "transaction",
        name: "Transaction statistics",
        component: TransationtStatistics,
      },
      {
        path: "account",
        name: "Accounting account",
        component: AccountStatistics,
      },
      {
        path: "card",
        name: "Card statistics",
        component: CardStatistics,
      },
    ]
  },
  {
    path: 'account',
    meta: {
      label: "Account"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "/accounting/account/customer",
        name: "User wallet",
        component: WalletUser,
      },
      {
        path: "/accounting/account/customer/:id",
        meta: {
          label: "User wallet",
          title: "User wallet",
        },
        component: WalletUserDetail,
      },
      {
        path: "/accounting/account/supplier",
        meta: {
          label: "Supplier wallet",
          title: "Supplier wallet",
        },
        component: SupplierWallet,
      },
      {
        path: "/accounting/account/supplier/:id",
        name: "Supplier wallet",
        component: SupplierWalletDetail,
      },
      {
        path: "/accounting/account/bank-card",
        name: "Bank card",
        component: Cards,
      },
      {
        path: "/accounting/account/bank-card/:id",
        meta: {
          label: "Bank card",
          title: "Bank card"
        },
        component: CardDetail,
      },
      {
        path: "/accounting/account/funds",
        name: "Cash fund",
        component: Fund,
      },
      {
        path: "/accounting/account/funds/:id",
        meta: {
          label: "Cash fund",
          title: "Cash fund"
        },
        component: FundDetail,
      },
      {
        path: "/accounting/account/credit",
        name: "Credit card",
        component: Credit,
      },
      {
        path: "/accounting/account/credit/:id",
        meta: {
          label: "Credit card",
          title: "Credit card"
        },
        component: CreditDetail,
      },
    ]
  },
  {
    path: 'setting',
    meta: {
      label: "Setting"
    },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "banks",
        name: "Bank",
        component: SettingBank,
      },
    ]
  },
  {
    path: "supports",
    meta: { label: "Support" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    children: [
      {
        path: "",
        meta: {
          label: "Support",
          title: "Support"
        },
        component: Supports
      },
      {
        path: 'new',
        meta: {
          label: 'New support',
          title: 'New support'
        },
        component: NewSupport
      },
      {
        path: ":id",
        meta: {
          label: "Support",
          title: 'Support'
        },
        component: SupportDetail
      }
    ]
  },
  {
    path: "/accounting/*",
    meta: {
      title: "Not found",
      label: "Not found"
    },
    component: () => import("@/workspaces/accounting/views/Page404"),
  }
];
