<template>
  <TModal
    title="Create province"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
      />
    </template>
    <CRow>
      <CCol col="12">
        <SSelectCountrySelectBox
            @update:value="data.country_id = $event"
          />

        <TInputText
          class="mt-3"
          placeholder="Enter province name"
          label="Province"
          :value.sync="data.name"
        >
          <template #prepend-content>
            <CIcon name="cil-view-list" />
          </template>
        </TInputText>

        <TInputText
          class="mt-3"
          placeholder="Enter province id"
          label="Province id"
          :value.sync="data.id"
        >
          <template #prepend-content>
            <CIcon name="cil-view-list" />
          </template>
        </TInputText>
      </CCol>
    </CRow>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    path: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      data: {
        name: null,
        country_id: null,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("helper.provinces.create", this.data)
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>