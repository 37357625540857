<template>
  <div class="mw-100 d-flex">
    <div class="input-group">
      <slot name="input">
        <TSelect
          class="m-0 w-100"
          :options="options"
          :normalizer="normalizer"
          :value="valueSelected"
          :selectOptions="selectOptions"
          @select="select"
          @search="$emit('search', $event)"
        >
          <template #singleLabel="{ option }">
            <slot name="singleLabel" :option="option"> </slot>
          </template>
        </TSelect>
      </slot>
    </div>
    <div class="input-group-append">
      <slot name="action">
        <TButtonSave @click="onChange" />
        <TButtonClose @click="$emit('close')" />
      </slot>
    </div>
  </div>
</template>

<script>
import actions from "../Button/actions";
export default {
  mixins: [actions],
  props: {
    value: {
      type: Object,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
    selectOptions: {
      type: Object,
      required: false,
    },
    normalizer: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) this.valueSelected = value;
      },
    },
  },
  methods: {
    onChange() {
      this.$emit("change", this.valueSelected.id);
    },
    select(option) {
      this.valueSelected = option;
    },
  },
};
</script>
