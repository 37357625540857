<template>
  <div v-if="!card" style="position: relative">
    <CLink v-if="showLocation && pallet.location" class="float-right">
      <SMessagePalletLocation :location="pallet.location" />
    </CLink>
    <div class="d-flex align-items-baseline">
      <CBadge
        v-if="showCountBox"
        color="info"
        style="position: absolute; right: -10px; top: -25px"
      >
        {{ lodash.sumBy(pallet.pivot_boxes, "quantity") }}
      </CBadge>
      <TLink
        v-if="refLink"
        :content="pallet.id"
        :to="lodash.getReferenceLink('assemble-to-stock.pallet', pallet.id)"
        class="h4 m-0"
        :messageOptions="{ bold: true }"
      />
      <TMessage v-else :content="pallet.id" bold noTranslate size="h4" />
      <div v-if="removable" class="ml-auto">
        <CLink
          title="Remove"
          class="text-danger"
          @click="$emit('remove', this.pallet)"
        >
          <CIcon name="cil-x-circle" width="16" />
        </CLink>
      </div>
    </div>
    <div v-if="pallet.type">
      <TMessage
        content="Volume"
        v-if="!tiny"
        :addClasses="['d-flex flex-wrap']"
      >
        <template #suffix>
          (m3):
          <TMessageVolume class="ml-1" :value="pallet.boxes_volume" suffix="" />
          <span class="mx-1">/</span>
          <TMessageVolume :value="pallet.type.max_volume" suffix="" />
        </template>
      </TMessage>
      <CProgress
        :value="pallet.boxes_volume"
        :max="pallet.type.max_volume"
        style="height: 5px"
      />
      <TMessage
        content="Weight"
        v-if="!tiny"
        :addClasses="['d-flex flex-wrap']"
      >
        <template #suffix>
          (kg):
          <TMessageNumber class="ml-1" :value="pallet.boxes_weight" />
          <span class="mx-1">/</span>
          <TMessageNumber :value="pallet.type.max_weight" />
        </template>
      </TMessage>
      <CProgress
        v-if="!tiny"
        :value="pallet.boxes_weight"
        :max="pallet.type.max_weight"
        style="height: 5px"
      />
    </div>
  </div>
  <CCard v-else class="overflow-hidden">
    <CCardHeader class="d-flex p-">
      <CBadge color="info" title="Quanity" class="my-auto">
        <TMessageNumber :value="pallet.boxes.length" />
      </CBadge>
      <div v-if="removable" class="mx-2 ml-auto">
        <CLink
          title="Remove"
          class="text-danger"
          @click="$emit('remove', pallet)"
        >
          <CIcon name="cil-x-circle" width="16" />
        </CLink>
      </div>
    </CCardHeader>
    <CCardBody class="p-2 px-3">
      <div v-if="pallet.type">
        <TMessage
          :content="pallet.id"
          bold
          noTranslate
          size="h4"
          :addClasses="['m-0']"
          alignment="center"
        />
        <div class="d-flex flex-nowrap">
          <span style="width: 15px">V</span>
          <CProgress
            :value="pallet.boxes_volume"
            :max="pallet.type.max_volume"
            style="height: 10px"
            class="mb-3 w-100 my-auto"
          />
        </div>
      </div>
      <div class="d-flex flex-nowrap">
        <span style="width: 15px">M</span>
        <CProgress
          :value="pallet.boxes_weight"
          :max="pallet.type.max_weight"
          style="height: 10px"
          class="mb-3 w-100 my-auto"
        />
      </div>
    </CCardBody>
    <CCardFooter
      class="p-0 px-3 py-1"
      v-if="pallet.boxes && pallet.boxes.length"
    >
      <TButtonQuickView
        class="float-right my-1"
        @click="showPalletBoxes = true"
      />
      <TMessage content="boxes" color="muted" class="font-xs mt-2" bold>
        <template #prefix>
          {{ pallet.boxes.length }}
        </template>
      </TMessage>
    </CCardFooter>
    <SModalPalletBoxes
      v-if="pallet.boxes.length"
      :show.sync="showPalletBoxes"
      :palletId="pallet.id"
    />
  </CCard>
</template>

<script>
export default {
  props: {
    pallet: {
      type: Object,
      required: true,
    },
    tiny: {
      type: Boolean,
      default: false,
    },
    showLocation: {
      type: Boolean,
      default: false,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    card: {
      type: Boolean,
      default: false,
    },
    refLink: {
      type: Boolean,
      default: false,
    },
    showCountBox: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPalletBoxes: false,
    };
  },
};
</script>
