const STORE_KEY = "warehouse_scope";

export const get = () => {
  return localStorage.getItem(STORE_KEY);
};

export const set = area_id => {
  return localStorage.setItem(STORE_KEY, area_id);
};

export default { get, set }
