<template>
  <CCard class="mb-0">
    <CCardHeader class="p-2">
      <div class="d-flex">
        <TMessage :content="purchase.id" noTranslate bold />
        <div class="ml-1">
          <SMessageOrderStatus :id="purchase.status_id" />
        </div>
      </div>
    </CCardHeader>
    <CCardBody class="p-2">
      <TTableAsForm :data="purchase" :fields="chargeFields" :splitLeft="7">
        <template #balance="{ value }">
          <TMessageMoney
            :amount="value"
            :currency="purchase.currency_id"
          />
        </template>
        <template #paid="{ value }">
          <TMessageMoney
            :amount="value"
            :currency="purchase.currency_id"
          />
        </template>
        <template #outstanding="{ value }">
          <TMessageMoney
            :amount="value"
            :currency="purchase.currency_id"
          />
        </template>
      </TTableAsForm>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  props: {
    purchase: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chargeFields: [
        { key: "balance", label: "Balance" },
        { key: "paid", label: "Paid" },
        { key: "outstanding", label: "Outstanding" },
      ],
    };
  },
};
</script>
