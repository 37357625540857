<template>
  <div>
    <div
      v-if="disabled"
      class="w-100 h-100 position-absolute"
      style="z-index: 999"
    ></div>
    <CSwitch
      :key="key"
      :color="color"
      :shape="shape"
      :size="size"
      :checked="checked"
      @update:checked="
        $emit('change', $event);
        resetSwitch();
      "
      :style="[disabled ? { opacity: '0.5' } : '']"
    />
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      required: true,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shape: {
      type: String,
      required: false,
      default: "pill",
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    size: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      key: "switchKey",
    };
  },
  methods: {
    resetSwitch() {
      this.key = "" + this.lodash.random(10000, 100000);
    },
  },
};
</script>
