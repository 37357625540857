import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'helper',
    resource: 'provinces',
    paginate: false,
    prefix: 'helper.provinces_sender',
    prefix_state: 'helper_provinces_sender',
  },
  {
    default_detail: {
      id: null,
      name: null,
      country_id: null,
    },
  },
).store()

export default resource
