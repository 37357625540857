import homeRoutes from '@/workspaces/home/mixins/routes'
import customerRoutes from '@/workspaces/customer/mixins/routes'
import purchaseRoutes from '@/workspaces/purchase/mixins/routes'
import saleRoutes from '@/workspaces/sale/mixins/routes'
import accountingRoutes from '@/workspaces/accounting/mixins/routes'
import adminRoutes from "@/workspaces/admin/mixins/routes";
import warehouseRoutes from "@/workspaces/warehouse/mixins/routes";
const Page404 = () => import("@/workspaces/home/views/Page404");

export default [
  {
    path: "/",
    component: () => import("@/workspaces/home/containers/TheContainer"),
    meta: {
      label: "Not found",
      authRequired: false
    },
    children: homeRoutes
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("@/workspaces/admin/containers/TheContainer"),
    meta: {
      authRequired: true
    },
    children: adminRoutes
  },
  {
    path: "/customer",
    name: "Customer",
    component: () => import("@/workspaces/customer/containers/TheContainer"),
    meta: {
      authRequired: true
    },
    children: customerRoutes
  },
  {
    path: "/purchase",
    name: "Purchase",
    component: () => import("@/workspaces/purchase/containers/TheContainer"),
    meta: {
      authRequired: true,
      verifyRequired: false
    },
    children: purchaseRoutes
  },
  {
    path: "/sale",
    name: "Sale",
    component: () => import("@/workspaces/sale/containers/TheContainer"),
    meta: {
      authRequired: true,
      verifyRequired: false
    },
    children: saleRoutes
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    component: () => import("@/workspaces/warehouse/containers/TheContainer"),
    meta: {
      authRequired: true,
      verifyRequired: false
    },
    children: warehouseRoutes,
  },
  {
    path: '/accounting',
    redirect: '/accounting/dashboard',
    name: 'Accounting',
    component: () => import('@/workspaces/accounting/containers/TheContainer'),
    meta: {
      authRequired: true,
      verifyRequired: false
    },
    children: accountingRoutes,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/workspaces/home/containers/TheContainer"),
    children: [
      {
        path: "*",
        meta: {
          title: "Not found",
          label: "Not found"
        },
        component: Page404
      }
    ]
  },
  {
    path: "/customer/*",
    component: () => import("@/workspaces/home/containers/TheContainer"),
    children: [
      {
        path: "/customer/*",
        meta: {
          title: "Not found",
          label: "Not found"
        },
        component: () => import("@/workspaces/customer/views/Page404")
      }
    ]
  },
  {
    path: "/*",
    component: () => import("@/workspaces/home/containers/TheContainer"),
    children: [
      {
        path: "",
        meta: {
          title: "Not found",
          label: "Not found"
        },
        component: Page404
      }
    ]
  }
];
