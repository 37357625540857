import { OrderService } from '../services'

export const SERVICE = OrderService
export const RESOURCE = 'order/shipments'

export default {
  detachTracking(id, attributes) {
    return SERVICE.put(`api/${RESOURCE}/${id}/detach-tracking`, attributes);
  },
  attachTracking(id, attributes) {
    return SERVICE.put(`api/${RESOURCE}/${id}/attach-tracking`, attributes);
  },
};
