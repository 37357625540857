<template>
  <div v-if="paginate.current < paginate.last" class="mt-3 d-flex">
    <TButton
      class="mx-auto px-3"
      tooltip="Load more"
      variant="outline"
      color="primary"
      icon="cidGripDots"
      iconSize="15"
      :options="options"
      @click="loadmore"
      v-if="!loading"
    />
  </div>
</template>

<script>
export default {
  props: {
    store: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
  },
  computed: {
    paginate() {
      return this.$store.getters[`${this.store}.paginate`];
    },
    loading() {
      return this.$store.getters[`${this.store}.loading`];
    },
  },
  methods: {
    loadmore() {
      this.$store.dispatch(`${this.store}.append-next-page`);
    },
  },
};
</script>
