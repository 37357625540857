<template>
  <div class="filter">
    <TButton
      icon="cil-filter"
      :variant="!active ? 'outline' : ''"
      size="sm"
      @click="$emit('click')"
    />
    <CBadge v-if="badge" class="filter-badge" color="danger">{{
      badge
    }}</CBadge>
  </div>
</template>

<script>
export default {
  props: {
    badge: {
      type: [String, Number],
      required: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.filter-badge {
  position: absolute;
  top: -7px;
  right: -10px;
}
.filter {
  position: relative;
}
</style>
