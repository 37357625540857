export default {
  "Dashboard": 'ダッシュボード',
  "Home": 'ホーム',
  "User info": 'アカウント情報',
  "Password": 'パスワード',
  "User id": 'アカウント',
  "Email address": 'メールアドレス',
  "Enter email": 'メール入力',
  "Enter password": 'パスワード入力',
  "Resend verification email": '認証メールの再送信',
  "Unverified": '未認証',
  "Verified": '認証済',
  "Disabled": '無効',
  "Reset password": 'パスワードのリセット',
  "Password confirmation": 'パスワードの確認',
  "Confirm": '認証',
  "Email empty": '空メール',
  "Customer": '顧客',
  "Admin": '管理',
  "Sale": '販売',
  "Purchase": '購入',
  "Warehouse": '倉庫',
  "Accounting": '会計',
  "Account": 'アカウント',
  "Services": 'サービス',
  "Pricing": '価格',
  "Policies": 'ポリシー',
  "New address": '追加',
  "Default": 'デフォルト',
  "Address": '住所',
  "Province": '都道府県',
  "District": '市区',
  "Ward": '町村',
  "Consignee": '受取者',
  "Tel": '電話番号',
  "Address detail": '具体的な住所',
  "Note": 'ノート',
  "Set as default": 'デフォルト設定',
  "View detail": '詳細',
  "Name or jancode of product you want to search": '商品名またはジャンコード',
  "Link or name of product you want to search": '商品名またはリンク',
  "Suggest for you": 'ヒント',
  "Top products": 'トップ商品',
  "Cost of goods": '商品コスト',
  "Shipment method": '発送方法',
  "Status": 'ステータス',
  "Updated at": '更新日',
  "Product": '商品',
  "Empty": '空',
  "User ID": 'アカウント',
  "Email": 'メール',
  "Name": '名前',
  "Role": 'ポジション',
  "Created at": '作成日',
  "Latest update": '更新日',
  "User information": 'ユーザー情報',
  "Authorization": '認可',
  "User detail": 'ユーザーの詳細',
  "Create User": 'アカウント作成',
  "Not found": '見つかりません',
  "other items": 'その他の商品',
  "All": 'すべて',
  "Start": '開始',
  "End": '終了',
  "Retail": '小売オーダー',
  "Wholesale": '卸売オーダー',
  "Shipment": '輸送オーダー',
  "Orders": 'オーダー',
  "Order": 'オーダー',
  "Order Id": 'オーダーID',
  "Tracking Id": '追跡番号',
  "Jancode": 'ジャンコード',
  "No data": 'データなし',
  "Goods money": '品代',
  "Paid goods": '支払った品代',
  "Goods debited": '借方記入した品代',
  "Debt money": '残高',
  "Unpaid goods": '未払いの品代',
  "Details": '詳細',
  "Service charge": 'サービス料',
  "Service fee paid": '支払ったサービス料',
  "Undebited service fee": '未払いのサービス料金',
  "Service fee debited": '借方記入したサービス料',
  "Service fee to be debited": '借方記入してないサービス料',
  "Service fee boxes": '配送サービス料',
  "Compensation": '補償',
  "Commodity compensation": '補償額',
  "Compensation debited": '借方記入した補償額',
  "Compensation to be debited": '借方記入してない補償額',
  "Additional cost": '追加費用',
  "Price": '価格',
  "Quantity": '数量',
  "Amount": '金額',
  "Total amount": '合計金額',
  "Total payment": '決済金額',
  "Tax": '税金',
  "Tax percent": '税率（パーセント）',
  "Discount tax": '税金の割引',
  "Special declaration": '特別商品',
  "Special goods fee": '特別商品の価格',
  "Insurance declaration": '保険',
  "Insurance fee": '保証金額',
  "Requests": 'リクエスト',
  "Repackage": '包装',
  "Merge package": 'リパック（まとめ）',
  "Retail Detail": '小売オーダーの詳細',
  "Wholesale Detail": '卸売オーダーの詳細',
  "Items": 'アイテム',
  "Add product": 'アイテム追加',
  "No products found": 'アイテムを見つからない',
  "Search product": 'アイテムを検索する',
  "Shipment info": '配送情報',
  "Write": '記入',
  "Content": '内容',
  "Order status": 'オーダーのステータス',
  "Order info": 'オーダーの情報',
  "Deposits": '入金',
  "Payments": '支払い',
  "Info": '情報',
  "Prepared by": '実施',
  "Transactions arise": '取引履歴',
  "Logs": 'ログ',
  "Load more": 'もっと読み込む',
  "SKU Id": 'SKU',
  "Service fee": 'サービス料金',
  "Storage cost": '保管費用',
  "Quantity assigned bill of lading": '運送証券にある数量',
  "Quantity assigned to order": 'オーダーにある数量',
  "Bill of lading code": '船荷証券コード',
  "List of bills of lading": '船荷証券リスト',
  "Lading bill": '船荷証券',
  "Yes": 'あり',
  "No": 'なし',
  "Ward Id": 'Ward Id',
  "Order detail": 'オーダーの詳細',
  "Refresh": '更新',
  "Change": '変更',
  "Select": '選択',
  "What are you looking for": '何を探している？',
  "Search": '検索',
  "Login": 'ログイン',
  "Sign in to your account": 'アカウントをログインする',
  "Forgot password": 'パスワードを忘れた',
  "Reset your password": 'パスワード更新',
  "Back to login": 'ログインに戻る',
  "Money account": 'マネー口座',
  "Wallet": 'ウォレット',
  "Wallet type": 'ウォレットのタイプ',
  "Balance": '残高',
  "Beginning balance": '開始残高',
  "Deposit": 'デポジット',
  "Total purchase amount": '支払総額',
  "Withdrawal": '引き落とし金額',
  "Payment for order": 'オーダーの支払',
  "Service fee payment": 'サービス料の支払',
  "Currency": '通貨',
  "Customer wallet": 'カスタムのウォレット',
  "Card": 'カード',
  "Bank & Currency": '銀行及び通貨',
  "Payment order": 'オーダーの支払',
  "Payment service": 'サービス料の支払',
  "Card Id": 'カードID',
  "Card name": 'カード名',
  "Bank Id": '銀行ID',
  "Bank": '銀行',
  "Bank name": '銀行名',
  "Currency Id": '通貨ID',
  "Symbol": '記号',
  "Create Wallet": 'ウォレット作成',
  "Create Bank": '銀行作成',
  "Transaction type": '取引のタイプ',
  "Credit": '貸方',
  "Debit": '借方',
  "Description": '説明',
  "Voucher date": '証書の日付',
  "Ref code": '取引のコード',
  "Recipient": '受領者',
  "Collect money": '入金',
  "Deposit collection": '入金',
  "Collect deposit according to invoice": '請求書に従って入金',
  "Attachments": '添付',
  "Detail": '詳細',
  "Transaction": '取引',
  "Executor": '実施者',
  "Credit account": '貸方勘定科目',
  "Debit account": '借方勘定科目',
  "Create transaction": '取引作成',
  "Select customer": '顧客の選択',
  "Add new": '追加',
  "New Retail": '新規の小売オーダー',
  "Create order": 'オーダー作成',
  "New order": '新規のオーダー',
  "Enter username": 'ユーザー名の入力',
  "Enter tel": '電話番号の入力',
  "Sort by role": '役割で並び替え',
  "Select role": '役割を選択',
  "Users": 'ユーザー',
  "NEW": '新規',
  "Permission": '権限',
  "Permissions": '権限',
  "Read": '読み',
  "Create": '作成日',
  "Delete": '削除',
  "Remove": '取り除く',
  "Update": '更新',
  "Updated": '更新した',
  "Created": '新規作成した',
  "Deleted": '削除された',
  "Group management": 'グループ管理',
  "User management": 'ユーザー管理',
  "Role management": '役割管理',
  "Function": '機能',
  "Error link": 'エラーリンク',
  "Purchases": '購入',
  "Auth": '確実',
  "Cart": 'カート',
  "Box": '箱',
  "Piece": '個',
  "Unit": '単位',
  "Contract": '契約',
  "Contract Id": '契約ID',
  "Major": '業務',
  "Start date": '開始日',
  "End date": '終了日',
  "Flight shipping fee": '航空便送料',
  "Sea shipping fee": '船便料金',
  "Sea freight rate": '海上運賃',
  "Air freight rate": '航空運賃',
  "Air shipping weight type": '空輸重量タイプ',
  "Sea shipping weight type": '海上輸送重量タイプ',
  "Purchase order": '購入オーダー',
  "Tracking list": '追跡番号リスト',
  "Tracking": '追跡番号',
  "Shipping fee": '送料',
  "Shipping fee to be debited": '借方記入していない送料',
  "Shipping fee debt": '負債の送料',
  "Weight": '重量',
  "Expected delivery": '配達予定',
  "Payment due date": '支払期限',
  "Shipment Detail": '輸送オーダーの詳細',
  "Sender name": '発送者',
  "Sender tel": '発送者の電話番号',
  "Enter tracking": '追跡番号の入力',
  "New Shipment": '新規の輸送オーダー',
  "Create new tracking": '追跡番号の作成',
  "Role id": '役割ID',
  "Role detail": '役割の詳細',
  "Roles": '役割',
  "Scope": '範囲',
  "New Wholesale": '新規の卸売オーダー',
  "Customer Id": '顧客ID',
  "Id": 'ID',
  "Max width": '最大の幅広さ',
  "Max length": '最大の長さ',
  "Max height": '最大の高さ',
  "Max weight": '最大の重さ',
  "Shelves": '棚',
  "Area": '倉庫',
  "Floor": 'フロア',
  "Row": '行',
  "Column": '列',
  "Pallet type": 'パレットのタイプ',
  "Warehouse settings": '倉庫設定',
  "General settings": '一般設定',
  "Domestic delivery fee": '国内配送料',
  "Date of receipt of goods": '入庫日',
  "Discount code": '割引券ID',
  "Goods receipt": '入庫',
  "Supplier": 'サプライヤー',
  "Buyer": 'バイヤー',
  "Sub total": '合計',
  "Tax balance": '税金の残高',
  "Attachment": '添付',
  "Due payment": '支払期限',
  "Costs": 'コスト',
  "Dates": '日付',
  "Distributed": '割り当てた',
  "Remaining": '残り',
  "File": 'ファイル',
  "Distributed quantity": '割り当てた数量',
  "Remaining quantity": '残りの数量',
  "Distribution quantity": '割り当てる数量',
  "Supply sale price": 'セラーに対する値段',
  "Voucher code": '明細書ID',
  "Remaining orders": '残りのオーダー',
  "Distributed orders": '割り当てたオーダー',
  "Distribution": '割当',
  "Order quantity": 'オーダー数量',
  "Purchase Detail": '購入オーダーの詳細',
  "Select order": 'オーダー選択',
  "Paid": '支払い済み',
  "Debited": '借方記入した',
  "Outstanding": '未払い',
  "Purchases Detail": '購入オーダーの詳細',
  "Unsolved": '借方記入していない',
  "Supplier Id": 'サプライヤーID',
  "Link": 'リンク',
  "Create Supplier": 'サプライヤー作成',
  "Enter link": 'リンク入力',
  "Enter address": '住所入力',
  "Enter name": '名前入力',
  "Enter note": 'ノート入力',
  "Buyer Id": 'バイヤーID',
  "Upload files": 'ファイルをアップロードする',
  "Money Accounting": '経理',
  "Refund": '返金',
  "Spend money from card": 'カードから出金',
  "Spend money from wallet": 'ウォレットから出金',
  "Purchases Accounting": '購入の会計',
  "Sales Accounting": '販売の会計',
  "Collection of deposits according to multi-customer invoices": '複数の顧客からまとめて入金',
  "Vendor returns money": 'サプライヤーからの返金',
  "Tax is deducted": '割引の税金',
  "Capital contribution": '資本拠出',
  "Collection": '入金(カード内送金)',
  "Financial income": '財務収益',
  "[Contract | Bill of Lading] Payment of service fee of contract & bill of lading": '契約またはLBのサービス料の支払',
  "Enter beginning balance": '初期残高',
  "Pay in": '支払',
  "Supplier wallet": 'サプライヤーのウォレット',
  "Cards": 'カード',
  "Banks": '銀行',
  "Wallets": 'ウォレット',
  "Accounting Dashboard": '会計のダッシュボード',
  "Receipts": '領収書',
  "Code": 'コード',
  "Invoice": 'インボイス',
  "Total amount refunded": '返金の合計',
  "Choose a supplier first": '最初にサプライヤーを選択してください',
  "Close": '閉じる',
  "Enter": '入力',
  "Save": '保存',
  "Add": '追加',
  "Clear filter": 'フィルターをクリア',
  "Edit": '編集',
  "Quick view": 'クイックビュー',
  "Origin": '原産',
  "Goods": '商品',
  "Ingredients": '成分',
  "Product Id": 'ジャンコード',
  "Product name": '商品名',
  "Product image": '商品イメージ',
  "other suppliers": '他のサプライヤー',
  "Origin Id": '原産ID',
  "Create origin": '新規の商品を作成する',
  "Create product": '商品を作成する',
  "New product": '新規の商品を作成する',
  "Private license": '許可証',
  "Banned air": '航空便禁止',
  "Banned sea": '船便禁止',
  "Banned export": '輸出禁止',
  "Embargo & more": '禁輸など',
  "Embargo": '禁輸',
  "Special goods": '特別商品',
  "Height": '高さ',
  "Width": '幅',
  "Length": '長さ',
  "Enter image url": 'イメージURLを入力する',
  "Product box specifications": '製品箱の仕様',
  "Change product image": '商品画像変更',
  "Customs clearance code": 'HSコード',
  "Supplier list": 'サプライヤーのリスト',
  "Items in box": 'アイテム イン ボックス',
  "Add supplier": 'サプライヤー追加',
  "Payment for purchases": '購入金額の支払',
  "Supplier returns money": 'サプライヤーが返金する',
  "View receipts": '領収書を見る',
  "Download": 'ダウンロード',
  "Opening balance": '初期残高',
  "Purpose": '目的',
  "Collect by": '受け取る',
  "Withdraw money from wallet": 'ウォレットから出金',
  "Withdraw money from card": 'カードから出金',
  "Refund for payment": '返金',
  "Refund for service": 'サービス料の返金',
  "Return for compensation": '補償の返金',
  "Pay into": 'に入金する',
  "Unpaid order list": '未支払いリスト',
  "Entries": 'アカウンティング',
  "View list": 'リストを見る',
  "The reason for collecting money": '請求の理由',
  "Compensated": '補償した',
  "Loading": '読み込み中',
  "Distribute": '割り当てる',
  "Input tracking": '追跡番号を入力',
  "Create new": '新規作成',
  "View order": 'オーダーを見る',
  "Selected order list": '選択中のオーダーリスト',
  "Refund type": '返金タイプ',
  "Refund amount": '返金金額',
  "Transfer fee": '振込手数料',
  "Payment for goods": '品代支払',
  "Payment to supplier": 'サプライヤーに支払',
  "Refund form": '返金形式',
  "Clearing debt": '債務整理',
  "Clearing according to order": 'オーダー清算',
  "Cash compensation": '現金引き出し',
  "Refund according to order & invoice": '請求書・オーダーによる返金',
  "Payment according to order & invoice": '請求書・オーダーに従って支払',
  "Payment by": 'で支払',
  "Available payment": '利用可能残高',
  "Auction Order": 'オークションオーダー',
  "Payment Order": 'ペイメントオーダー',
  "New Payment Order": '新規のペイメントオーダー',
  "New Auction Order": '新規のオークションオーダー',
  "Payment Detail": 'ペイメントオーダーの詳細',
  "Auction Detail": 'オークションオーダーの詳細',
  "Order file": 'オーダーのファイル',
  "Create purchase": '購入オーダーの作成',
  "SFA": 'SFA',
  "Weight (kg)": '質量 (kg)',
  "Width (cm)": '幅 (cm)',
  "Height (cm)": '高さ (cm)',
  "Length (cm)": '長さ (cm)',
  "Create SKU": 'SKU作成',
  "Inbound": '入庫',
  "Sales return order": '販売した商品を返還',
  "Inbound transfer order": '倉庫転送注文',
  "Internal": '在庫',
  "Assemble to stock": '保管',
  "Assemble to order": 'オーダーに割り当て',
  "Lading bills": '運送証券',
  "Invoices": 'インボイス',
  "Outbound": '出庫',
  "Sales order": '販売注文',
  "Outbound transfer order": '倉庫転送注文',
  "Purchase return order": '購入した商品を返還',
  "Setting": '設定',
  "General": '一般',
  "Total quantity": '合計',
  "Coupon": '割引券',
  "Arrival date": '配達日',
  "New SKU": '新規SKU',
  "Assemble to pallets": 'パレットに乗せる',
  "New pallet": 'パレット作成',
  "Search pallet": 'パレット検索',
  "Suggestion SKUs": 'SKUヒント',
  "SKUs in pallet": 'パレット上のSKU',
  "Add SKU": 'SKU追加',
  "SKUs in SFA": 'SFA内のSKU',
  "Total deposits": '入金合計',
  "Total outlay": '出金合計',
  "Download file": 'ファイルダウンロード',
  "Shelve": '棚',
  "Pallets have not been stored": 'パレットは並び替えていない',
  "List to buy": '注文すべくリスト',
  "Next step": '次',
  "Quantity in stock": '倉庫にある数量',
  "Distributing goods from inventory": '在庫から割り当て',
  "Order quantity from supplier": 'サプライヤーに注文する数量',
  "Purchase inventory": '在庫',
  "Quantity purchased": '購入した数量',
  "Quantity to buy": '購入すべく数量',
  "Lowest price": '最安値',
  "Selected": '選択した',
  "Quantity for sale": '販売できる数量',
  "Collect by customer": '顧客で集める',
  "Amount cannot be more than": '金額の上限',
  "Quantity from inventory": '在庫からの数量',
  "The quantity cannot be more than": '数量の上限',
  "type(s) selected": '選択するタイプ',
  "Select files": 'ファイル選択',
  "Upload receipts": '請求書アップロード',
  "Order from supplier": 'サプライヤーから注文',
  "No order": 'オーダーなし',
  "Goods deliveries": '配達依頼書',
  "Update suppliers": 'サプライヤー編集',
  "Create contract": '契約作成',
  "Contract detail": '契約詳細',
  "Commodity information": '商品情報',
  "Contract fees": '費用各種',
  "Domestic delivery": '国内配送',
  "Contracts": '契約',
  "Contract name": '契約名',
  "New contract": '新規契約',
  "Open the contract": '契約を開ける',
  "Close the contract": '契約を閉じる',
  "Goods inventory": '在庫品',
  "Statistics": '統計',
  "Management": '管理',
  "Agency": '代理店',
  "Representative": '代表者',
  "Coordinate": '座標',
  "Agency name": '代理店名',
  "Agency Id": '代理店ID',
  "Create agency": '代理店作成',
  "Longitude": '軽度',
  "Latitude": '緯度',
  "Volume": '体積',
  "Pick out container": 'コンテナーから取り出す',
  "SKUs in invoice": 'インボイスにあるSKU',
  "Export": '出',
  "Import": '入',
  "boxes": '箱',
  "Search invoice": 'インボイス検索',
  "Search tracking": '追跡番号検索',
  "Create new SFA": 'SFA作成',
  "Next to input SKUs": 'SKU入力に移動',
  "Create lading bill": '運送証券作成',
  "Suggestion SKUs for lading bill": 'ヒントのSKU',
  "New lading bill": '新規の運送証券',
  "SKUs in lading bill": '運送証券にあるSKU',
  "Shipping date": '輸送日',
  "Move to": 'に移動する',
  "Storage volume (m3)": '貯蔵の容積',
  "Storage weight (kg)": '保管質量',
  "New invoice": '新規インボイス',
  "Create new invoice": '新規インボイス作成',
  "Create invoice": 'インボイス作成',
  "Lading bills in invoice": 'インボイスにある運送証券',
  "Add lading bill": '運送証券追加',
  "Select partner": '相手選択',
  "Partner": '相手',
  "Create new goods delivery": '新規の配達依頼書を作成',
  "Create goods delivery": '配達依頼書を作成',
  "New goods delivery": '新規の配達依頼書',
  "Picked": '配達完了',
  "To": 'まで',
  "SKUs in goods delivery": '配達依頼書のSKU',
  "Cancle": 'キャンセル',
  "Shipping": '配達中',
  "Pick out": '出庫',
  "Pick in container": 'コンテナに乗せる',
  "Unlock invoice": 'インボイスをアンロック',
  "Lock invoice": 'インボイスをロック',
  "Outbound sale order": '販売注文によって出庫',
  "Search sale order": '販売注文検索',
  "Owned boxes": '所有ボックス',
  "Suggestion SKUs for order": 'オーダーのためヒントSKU',
  "SKUs owned by order": 'オーダーに属するSKU',
  "Next to lading bills of": '運送証券に移動',
  "Storage": '倉庫',
  "Unlocked": 'アンロック',
  "Locked": 'ロック',
  "Create new lading bill": '運送証券作成',
  "Select lading bill": '運送証券選択',
  "No SKU": 'SKUなし',
  "items": 'アイテム',
  "Does not exist in the boxes in lading bills": '運送証券に存在しない',
  "Does not exist in the boxes in goods delivery": '配達依頼書に存在しない',
  "Invalid SKU": '無効なSKU',
  "Size": 'サイズ',
  "No lading bills": '運送証券がない',
  "Suggestion SKUs by SFA": 'SFAのためなヒントSKU',
  "Create pallet type": 'パレットのタイプを作成',
  "Create shelve": '棚を作成',
  "Go to invoice": 'インボイスに移動',
  "Spend money": '出金',
  "Internal transfer": '内部取引',
  "Payable": '支払い可能',
  "Supplier debt": 'サプライヤー債務',
  "Receivable": '売掛金',
  "Customer debt": '未収金',
  "Debt comparison": '債務比較',
  "Bank card": '銀行カード',
  "exceeds": '超える',
  "Are you sure these numbers?": 'このデータで大丈夫ですか',
  "Expiration date": '有効期限',
  "Set pallet location": 'パレットの位置を設定',
  "Current location": '現在位置',
  "Remove location": '倉庫から削除',
  "Pack": '包装',
  "Add item": '商品追加',
  "Packed": '箱を閉じる',
  "Closed box": '閉じた箱',
  "Open box": '開いている箱',
  "Reset": 'リセット',
  "Merge boxes": '箱まとめ',
  "New combine box": '親の箱を作成',
  "Split boxes": '箱分解',
  "Sku has been distributed": '割り当てたSKU',
  "Sku is not a free box": 'フリーなSKUではない',
  "Sku has put on the pallet": 'パレットにSKUを乗せる',
  "Quantity to split": '分解すべき数量',
  "Freedom box": 'フリーSKU',
  "Child box": '子SKU',
  "Combine box": '親SKU',
  "child boxes": '子SKU',
  "Collect advance payment": '前払い金の回収',
  "Debt payment customer": '顧客が債務返済',
  "Debt payment customers": '複数の顧客が債務返済',
  "Other collect": 'その他の集金',
  "Receivable for": 'のために集金',
  "Count": '数量',
  "Add order": 'オーダー追加',
  "Total:": "合計:",
  "Select orders": 'オーダー選択',
  "Paid/Shipping fee": '支払った・送料',
  "Shipping fee outstanding": '未支払い送料',
  "Paid/Service fee": '支払った・サービス料',
  "Service fee outstanding": '未支払いサービス料',
  "Selected: ": "選択した: ",
  "Service fee to pay": 'サービス料を支払い',
  "Shipping fee to pay": '送料を支払い',
  "Debt collection for service": 'サービス料を集金',
  "Debt collection for goods": '品代を集金',
  "Paid/Cost of goods": '支払った品代',
  "Cost of goods to pay": '品代を支払い',
  "Cost of goods outstanding": '未支払い品代',
  "Other payment": 'その他の支払い',
  "Pre payment for supplier": 'サプライヤーに前払い',
  "Payment purchases": '購入オーダーを支払い',
  "Select purchases": '購入オーダー選択',
  "Purchase Id": '購入オーダーID',
  "From card": 'カードから',
  "To card": 'カードまで',
  "Deposit amount": '金額',
  "Accepting amount": '入金金額',
  "Fee transfer": '振込手数料',
  "Search sku": 'SKU検索',
  "SKUs separate from": 'から取り出すSKU',
  "Decouple": 'デカップル',
  "Combine SKUs to": 'SKUをまとめる',
  "Combine": '箱まとめ',
  "SKUs in": '内部のSKU',
  "Object": '対象',
  "Type": 'タイプ',
  "Reference": '参照',
  "Option": 'オプション',
  "Arising from": 'から発生',
  "User": 'ユーザー',
  "Code ref": '取引コード',
  "Lading bill detail": '運送証券の詳細',
  "SKU list": 'SKUリスト',
  "Actual weight": '質量kg',
  "Volumetric weight": '体積質量',
  "Maximum weight": '最大質量',
  "Actual cubic meter": '体積',
  "Maximum cubic meter": '最大体積',
  "Cubic meter conversion": '質量から換算した体積',
  "Freight charge type": '運賃計算タイプ',
  "Freight charge discount": '運賃割引',
  "Freight rate": '運賃',
  "Freight range type": '運賃計算タイプ',
  "From": 'から',
  "Container": 'コンテナ',
  "Closed": '閉じた',
  "Open": '開ける',
  "Charging method": '計算方法',
  "Total freight": '合計',
  "Freight range": '運賃計算用の数量',
  "Freight charge": '運賃',
  "Value of Lading bill": '運送証券の情報',
  "Calculated value": '計算した数値',
  "Quantity delivered": '配達した数量',
  "Quantity not delivered": '未配達な数量',
  "Order list": 'オーダーのリスト',
  "Change contract": '契約変更',
  "User wallet": 'ユーザーのウォレット',
  "ID": 'ID',
  "Arising": '発生',
  "Departure date": '出発日',
  "Date of shipment": '搬入日',
  "Invoice detail": 'インボイスの詳細',
  "Cut off date": 'バンニング日',
  "Container parameters": 'コンテナ寸法',
  "Extra volume (m3)": '追加体積',
  "Extra weight (kg)": '追加質量',
  "Cost of goods paid": '支払った品代',
  "Cost of goods unsolved": '借方記入していない品代',
  "Get unit price from price list": '価格表による単価',
  "Price list": '価格表',
  "Add SKU or Pallet Id": 'SKUまたはパレット追加',
  "Add orders to the contract": '契約に追加',
  "Add to contract": '契約に追加',
  "Deselect all": '全ての選択を削除',
  "Exchange rate": '為替レート',
  "Transfer amount": '払込金額',
  "Receive amount": '受取金額',
  "Goods delivery": '配送',
  "Shipping code": '配送ID',
  "Debt service fee": '債務サービス料',
  "Debt for goods": '債務品代',
  "Connect": '接続',
  "Connected": '接続した',
  "Not connected": '未接続',
  "Printer server IP": 'プリンターのサーバーIP',
  "The printer does not work, check the connection again": 'プリンターが動作しません。接続をもう一度確認してください',
  "Check the connection again": '接続をもう一度確認してください',
  "Successful connection": '接続成功',
  "Invalid IP address": '無効な IP アドレスです',
  "There is no valid SKU in the pallet": 'パレットに有効な SKU がありません',
  "Invalid pallet": '無効なパレット',
  "Send print data": '印刷データ送信',
  "Print": '印刷する',
  "Input pallet id": 'パレットID入力',
  "Input sfa id": 'SFA入力',
  "Create and print bulk tracking": '一括に追跡番号を作成して印刷する',
  "From date": '開始日',
  "To date": '終了日',
  "Filter": 'フィルター',
  "Currency type of cost of goods": '品代の通貨',
  "Currency type of service fee": 'サービス料の通貨',
  "Voucher": '領収書',
  "Paid/Debited": '支払った・借方記入した',
  "Order type": 'オーダーのタイプ',
  "Add tracking": '追跡番号を追加',
  "Print labels after creating SFA": 'SFA作成した後にラベル印刷',
  "Expected SKU": '予定のSKU',
  "Completed SKU": '完成なSKU',
  "Uncompleted SKU": '未完成なSKU',
  "Export invoice": 'インボイス抽出',
  "Export of goods": '分荷表抽出',
  "Expected": '予定',
  "Actual": '実際',
  "Actual in container": '載せた',
  "Put on pallets": 'パレットに乗せる',
  "View Sfa": 'SFAを見る',
  "Quantity in cont": 'コンテナに乗せた数量',
  "Quantity out cont": 'コンテナから降ろす数量',
  "Quantity in lading bill": '運送証券の数',
  "Parent category": '親カテゴリ',
  "Category": 'カテゴリ',
  "Create category": 'カテゴリ作成',
  "View": '見る',
  "#": '#',
  "Transactions": '取引',
  "Box management": 'ボックス管理',
  "No items": 'アイテムなし',
  "Quantity in pallets": 'パレット上に数量',
  "Quantity outbound": '出庫した数量',
  "Organization": '組織',
  "Organizations": '組織',
  "Create organization": '組織を作成',
  "Total number of boxes loaded": 'コンテナに乗せた数量',
  "Total number of boxes above expected": 'コンテナに乗せる予定',
  "Total number of boxes downloaded": 'コンテナから降ろす数量',
  "Not ordered in stock yet": '未保管',
  "Not yet distributed": '割り当てない',
  "Inventory number": '在庫の数量',
  "Next to distribute": '割り当てに移動する',
  "Sort by pallet": 'パレットに並び替え',
  "Select pallet": 'パレットを選択',
  "SFA Id": 'SFA',
  "Goods delivery Id": '配送ID',
  "Aggregate box": '集合箱',
  "Aggregate box reference": '集合箱の参照',
  "SKU created today": '今日に作成したSKU',
  "SFA created today": '今日に作成したSFA',
  "Add pallet": 'パレット追加',
  "Orther receipt": 'その他の領収書',
  "The box must be a freedom box": 'フリーなSKUを選択すべき',
  "Warehouse model": '倉庫のモデル',
  "Compensation amount": '保証金額',
  "Automatic add lading bill": '自動的な運送証券',
  "Confirmation": '確認',
  "Are you sure you want to delete?": '削除して大丈夫ですか',
  "Distributed goods": '割り当てた商品',
  "Purchase cost": '購入手数料',
  "Fees": '手数料',
  "Collect advance payment (multipe customers)": '前払いを回収する（複数の顧客）',
  "Add wallets": 'ウォレット追加',
  "Select wallets": 'ウォレット選択',
  "Debt collection for service lading bills": '追加の取引',
  "Box available in picker": 'まだ出荷していない',
  "Box available out picker": 'まだ入荷してない',
  "Box overload out picker": '過剰在庫',
  "Add new child transaction": '新しい取引を追加',
  "Reference type": '参照型',
  "Add transaction reference": '参照を追加',
  "Purchase quantity": '購入数量',
  "Goods not yet distributed": '分割されていない商品',
  "All customer": 'すべてのお客様',
  "Add tags": 'ラベルを追加',
  "Tags": 'ラベル',
  "Oldest created date": 'オーダーの数量',
  "Latest created date": 'タグ追加',
  "Order by": 'タグ',
  "Goods statistics": '商品の統計',
  "Total SKUs": '合計SKU',
  "Goods not load into containers yet": '商品はコンテナに積み込まれていません',
  "Total number of boxes": '箱の総数',
  "Total volume": '全容積',
  "Total weight": '総重量',
  "Freight charge pay for order": '注文の支払い',
  "Other": 'その他',
  "Not in the contract": '契約にない',
  "Pay freight charge in lading bill": '船荷証券で運賃を支払う',
  "Transportation company": '運送会社',
  "Purchasing company": '販売会社',
  "Same currency transfer": '現地通貨の送金',
  "Other currency transfer": '外貨送金',
  "Tracking code": 'お問い合わせ番号',
  "Select tracking": 'お問い合わせ番号を選択',
  "Add created tracking": '作成したお問い合わせ番号を追加',
  "Container inspection": '商品をチェック',
  "Search container": 'コンテナを探す',
  "Comparison clearing debt": '比較清算債務',
  "Fees can be consulted": '参考費用',
  "Collect reason": '集金理由',
  "Payment reason": '支払理由',
  "Location": '位位置',
  "Unload goods from pallets": 'パレットから商品を取り出す',
  "Convert pallet": 'パレット移し替え',
  "In stock": '在庫がある',
  "Uncompensated": '補償はまだない',
  "Other fees": 'その他費用',
  "All goods in stock": '倉庫内にある全商品',
  "Goods not on the lading bills": '船荷証券にない商品',
  "Goods have not been on the lading bills or have not been fully loaded": '船荷証券に記載されていない商品、またはインボイス付きの船荷証券に記載されていない商品',
  "Goods have not been loaded into the container or have not loaded up as expected (at lading bills)": '商品がコンテナに積み込まれていないか、期待どおりに積み込まれていない (船荷証券で)',
  "Goods have not been distributed or have not been fully distributed for the order": '商品が割り当てられていないか、注文に割り当てられていません',
  "Clear": '再入力',
  "Fund": '資金',
  "Cash fund": '手元現金',
  "Transfer deposit to cash": '預金を引き出して資金に入る',
  "Transfer cash to deposit": '銀行に現金を預け入れる',
  "Fund account": 'ファンド口座',
  "Discounts on purchases": '購入割引',
  "Discount sales": '割引セール',
  "Accounting inventory": '在庫品',
  "Accounting inventory distribution": '割当',
  "Transactions remaining": '残りの金額',
  "Distribute transaction": 'トランザクション割り当て',
  "Create prices": '新しい価格を作成',
  "Remove empty pallets from stock": '在庫から空のパレットを削除',
  "Start time": '開始時間',
  "Conditions to apply": '適用条件',
  "Export excel": 'エクセルを輸出',
  "Export success": '輸出成功',
  "Box quantity in picker": 'コンテナ輸入数',
  "Box quantity out picker": 'コンテナ輸出数',
  "Quantity of SKUs to enter in SFA": 'SFA に入る SKU の数',
  "Do you want to change the page?": 'ページを切り替えますか?',
  "Tally": '集計する',
  "Do you want to assemble on pallets?": 'パレットに組み立てますか？',
  "There are": '～がある',
  "packages left untested": 'チェックされていない商品',
  "Under": '下',
  "Account aggregation": '一般口座',
  "Last 30 days": '過去 30 日間',
  "Last 90 days": '過去 ９0 日間',
  "Custom": 'カスタム',
  "All time": 'すべての時間',
  "Payment/Advance payment salary": '従業員への支払い/前払い',
  "Social, Health, Unemployment insurance": '社会保険、健康保険、失業保険の支払い',
  "Pay taxes and fees to the state budget": '州予算に税金と手数料を支払う',
  "Discount": '割引',
  "Buy supplies": '消耗品の購入',
  "Buy instrument and tools": '工具や道具を買う',
  "Office and warehouse rental costs": 'オフィスと倉庫のレンタル費用',
  "Compensation unsolved": '補償未解決',
  "Collect domestic shipping fee": '国内配送の集荷',
  "Collect bank interest": '銀行の利子を得る',
  "Have not entered the purchase quantity for the order": '注文の購入数量が入力されていません',
  "Expand": '拡大',
  "Receipts type": '領収書の種類',
  "Binding conditions": '束縛する諸条件',
  "Value": '価値',
  "Key": 'キー',
  "Select product": '商品選択',
  "Number of boxes": '箱の数',
  "Use tax-inclusive price": '税込価格',
  "Tax included": '税込',
  "Packing information": '梱包情報',
  "Number of boxes is": '箱の数：',
  "Please collect enough boxes to save on shipping costs": '輸送コストを節約するために箱をくっつけるくっつけてください',
  "Support": 'サポート',
  "Support types": 'サポート種別',
  "Support type": 'サポート種別',
  "STT": '順番',
  "Create support type": 'サポート種別の作成',
  "Support post": 'サポートポスト',
  "Deadline": '締切日',
  "Creator": '作成者',
  "Create a new support post": 'サポートポストを作成する',
  "New support": '新しいサポートポスト',
  "Title": 'タイトル',
  "Active": '活動中',
  "Add new feedback": '新しいフィードバックの追加',
  "Send": '送信',
  "Suggested products": '商品の提案',
  "Create compensation": '補償を作成する',
  "Packed date": 'パックの日付',
  "Not seen": '未読',
  "Seen": '既読',
  "people read": '読んだ人',
  "comments": 'コメント',
  "created at": 'に作成した',
  "Missing": '不足',
  "Enough quantity": '十分な数量',
  "Received at the warehouse": '倉庫で受け取った',
  "Percent": 'パーセント',
  "Accounting account": '会計決算',
  "Received at warehouse": '倉庫で商品を受け取った',
  "Waiting to receive": '届くのを待ち望んでいる',
  "Purchase cost outstanding": '購入費用未払い',
  "Purchase cost unsolved": '未払いの購入費用',
  "General accounting": '総合会計',
  "Bill of lading payment": '船荷証券の支払い',
  "Expected quantity": '予定の数量',
  "Available quantity": '手に入る数量',
  "Delivery quantity": '納品数量',
  "Applies to all": 'すべてに当てはまる',
  "Schedule change": '日程変更',
  "Remaining distributable": '割り当てできる数量',
  "Remove the order from the contract": '契約からオーダーを削除する',
  "Create and add": '作成・追加',
  "Delivery partner": '配達パートナー',
  "Total": '合計',
  "The lading bill available for container": 'コンテナに積み込むできそう船荷証券',
  "Tracking images": '追跡画像',
  "Notification": 'お知らせ',
  "Mark all read": 'すべて既読',
  "Delete transaction": 'トランザクション削除',
  "Watch boxes was in container": 'コンテナに乗せた箱を見る',
  "Watch data was been error": 'エラーデータを読み込む',
  "Transaction statistics": '取引に関する統計',
  "Delivery address": '配達する住所',
  "Import excel": 'エクセルをインポートする',
  "Tracking is not distributed": 'まだ割り当てないトラッキング',
  "Picking quantity": '選択した数量',
  "Allow order that already have another contract to change to this contract": 'すでに別の契約を結んでいる注文をこの契約に変更できるようにする',
  "Sale order": '受注オーダー',
  "Remove the children box": '小箱を取り除く',
  "Debited at": '請求日',
  "During balance": '中期残高',
  "Closing balance": '末期残高',
  'Payment refund': '払い戻し',
  "Pallet Id": 'パレットコード',
  "Pallet": "パレット",
  "Subscribe notification": 'サブスクライブ通知',
  "Subscribe": '申し込む',
  "Price with tax": '税込み',
  "Price without tax": '税抜き',
  "Remove successfully": '正常に削除',
  "Service fee discount": "サービス料割引",
  "Allow the use of the custom freight rate": 'カスタム配送料の使用を許可します',
  "Additional purchase order": '追加注文',
  "New purchase order": '新しく注文',
  "Undefined": '未定義',
  "Payment overdue": '支払い期限切れ',
  "Not payment due date yet": "支払日未定",
  "Payment due date is today": '支払い期限は今日',
  "No payment due date": '支払期限なし',
  "Payment not completed": 'お支払いが完了していません',
  "Product/ Payment due date": 'ジャンコード/ 支払期限',
  "Input": "入力",
  "Output": "出力",
  "Profit": "利益",
  "Desired delivery": '配送希望日',
  "Are you sure you want to debit?": "借方してもよろしいですか？",
  "Order credit": "クレジット",
  "Create order credit": "クレジットを作成する",
  "Credit limit": "与信限度額",
  "Payment for order credit": "クレジット払い",
  "Select order credit": "クレジットを選択",
  "Credit card": "クレジット",
  "In use": "使用中で",
  "Used": "使用済み",
  "Container debt": "コンテナ債務",
  "Decouple box item": '製品の分離',
  "Card statistics": "銀行カードの統計",
  "Remove from lading bill": "船荷証券から削除",
  "Add to goods delivery": "配達注文に追加",
  "Add to lading bill": "船荷証券に追加",
  "Are you sure?": "本気ですか?",
  "Transfer the bill of lading": "船荷証券",
  "Move to a new bill of lading": "新しい船荷証券に移動します",
  "Split box items": "商品をスプリット",
  "Decouple box": "スプリットの箱",
  "The box is move to": "箱が移転された",
  "Update decouple box": "スプリットの箱の更新 ",
  "Update new box": "新しい箱の更新 ",
  "Update box moved to": "移転された箱が更新",
  "Move items to another box": "ほかの箱へ商品を移転",
  "Inventory": "在庫",
  "Exchange": '交換',
  "Official quota": "官位",
  "Manifest date": "税関申告日",
  "Booking date": "コンテナ発注日",
  "Freight forwarder": "税関",
  "Container type": "コンテナタイプ",
  "Max volume": "最大音量",
  "Form": "形状",
  "Add customer": "顧客を追加",
  "Add HAWB": "HAWBを追加",
  "Flight code": "フライトコード",
  "White": "チンガチ",
  "Total days of storage": "保管日数",
  "Inventory by customer": "顧客別在庫",
  "Post": "投稿",
  "Post category": "記事一覧",
  "Create a new post": "新しい投稿を作成する",
  "New post": "新しい投稿",
  "Publish": "公開",
  "Draft": "下書き",
  "Auction logs": "オークション履歴",
  "Success": "成功",
  "End time": "終了時間",
  "Bidding": "オークション開催中",
  "Exported date": "在庫の日付",
  "Loaded": "ロード済み",
  "Container costs": 'コンテナコスト',
  "Purchase surcharge":  "購入追加料金",
  "Purchase surcharge percentage":  "購入割増料金の割合",
  "Auto add contract": "契約を自動的に追加する",
  "Brand": "ブランド",
  "Link store": "リンクストア",
  "Ecommerce channel": "EC",
  "Manufacturing year": "製造年",
  "Product code": "製品コード",
  "Create ecommerce channel": "eコマースチャネルを作成する",
};
