const STORE_KEY = "organization_id";

export const get = () => {
  return localStorage.getItem(STORE_KEY);
};

export const set = organization_id => {
  return localStorage.setItem(STORE_KEY, organization_id);
};

export default { get, set }
