<template>
  <TModal
    title="District"
    :show="show"
    @update:show="$emit('update:show', $event)"
  >
    <template #actions>
      <div></div>
    </template>
    <div>
      <TTableAdvance
        :items="list"
        :fields="fields"
        store="helper.districts"
        @click-clear-filter="clearFilter"
        resource=""
        creatable
        @click-create="showModalCreate = true"
      >
        <template #append-actions="{ item }">
          <CCol class="d-flex px-2" col="12">
            <TButtonDelete @click="removeDistrict(item.id)" />
            <TButtonShow @click="clickShowWard(item)" />
          </CCol>
        </template>
        <template #name="{ item }">
          <td>
            <TMessageText
              :value="item.name"
              editable
              @change="
                $store.dispatch('helper.districts.update', {
                  id: item.id,
                  attributes: { name: $event },
                })
              "
            />
          </td>
        </template>
        <template #id-filter>
          <TInputText :value.sync="filter.id" @update:value="Change" />
        </template>

        <template #name-filter>
          <TInputText :value.sync="filter.name" @update:value="Change" />
        </template>
      </TTableAdvance>
      <SModalCreateDistrict
        :show.sync="showModalCreate"
        :value="filter.province_id"
      />
      <SModalShowWard :show.sync="showModalWard" :id.sync="getId" />
    </div>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    path: {
      type: String,
    },

    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showModalCreate: false,
      showModalWard: false,
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "District id", _style: "min-width: 150px" },
        {
          key: "name",
          label: "District",
          _style: "min-width: 350px",
        },
      ],

      filter: {
        province_id: null,
      },
    };
  },

  watch: {
    id() {
      this.filter.province_id = this.id;
      this.Change();
    },
  },

  computed: {
    ...mapGetters({
      list: "helper.districts.list",
    }),

    getId() {
      return this.$route.query.districts;
    },
  },
  methods: {
    Change() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterFields = this.lodash.pickBy({ ...filter });

      this.$store.dispatch("helper.districts.apply-query", filterFields);
    },

    clearFilter() {
      this.filter.name = null;
      this.filter.id = null;

      this.Change();
    },

    clickShowWard(item) {
      const query = { provinces: this.$route.query.provinces };
      this.$router.push({ query: { ...query, districts: item.id } });
      this.showModalWard = true;
    },

    removeDistrict(id) {
      this.$store.dispatch("helper.districts.delete", id);
    },
  },
};
</script>
