<template>
  <div title="Invoice">
    <div class="d-flex" v-if="container.had_cut_off">
      <SMessageShipmentMethod :id="container.shipment_method_id" iconOnly />
      <TMessage
        v-if="noBadge"
        :content="container.name"
        bold
        noTranslate
        :size="small ? 'small' : ''"
        class="my-auto"
      />
      <CBadge v-else color="success" class="my-auto">
        {{ container.name || '...' }}
      </CBadge>
    </div>
    <div class="d-flex" v-else>
      <SMessageShipmentMethod :id="container.shipment_method_id" iconOnly />
      <TMessage
        v-if="noBadge"
        :content="container.name"
        bold
        noTranslate
        :size="small ? 'small' : ''"
        class="my-auto"
      />
      <CBadge v-else color="primary" class="my-auto">
        {{ container.name || '...' }}
      </CBadge>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    container: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    noBadge: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
