<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <SSelectFreightChargeType
        v-if="editing"
        :value="id"
        @close="setEditing(false)"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        :editable="editable"
      />
    </slot>
    <TMessage
      :content="type"
      noTranslate
      color="info"
      bold
      :italic="editable"
      :uppercase="uppercase"
      v-show="!editing || dontHideWhenEditing"
      :creatable="creatable"
      :editable="editable"
      :removable="removable"
      :truncate="1"
      @click-edit="showEdit()"
      ><template #prefix><CIcon name="cis-calculator" class="mr-1" /></template
    ></TMessage>
  </div>
</template>

<script>
import mixin from "@/core/components/T/Button/actions";
import { mapGetters } from "vuex";

export default {
  mixins: [mixin],
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    uppercase: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  computed: {
    ...mapGetters({
      freight_charge_types: "warehouse.freight_charge_types.list",
    }),
    type() {
      return this.freight_charge_types.find(i => i.id === this.id)?.name;
    },
  },
};
</script>
