<template>
  <TSelect
    :label="label"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: true,
      preserveSearch: true,
    }"
    :normalizer="{ label: 'id', value: 'id' }"
    :placeholder="placeholder"
    :loading="loading"
    @select="select"
    @search="search"
  >
    <template #singleLabel="{ option }">
      <TMessage
        :content="option.id ? option.id : option.name"
        noTranslate
        :truncate="1"
        size="small"
      />
    </template>
    <template #option="{ option }">
      <TMessage
        :content="option.id ? option.id : option.name"
        noTranslate
        :truncate="1"
      />
    </template>
  </TSelect>
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: "",
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    noCustomLabel: {
      type: Boolean,
      default: false,
    },
    prependAll: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      firstValue: null,
      prepend: { id: "", name: this.$t("All") },
      valueSelected: null,
    };
  },
  created() {
    this.$store.dispatch("order.contracts.fetch.if-first-time");
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value == null) return;
        this.checkValue(value);
      },
    },
    valueSelected: {
      immediate: false,
      handler(value) {
        if (value) {
          this.$emit("update:value", value.id);
          this.$emit("change", value);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "order.contracts.list",
      loading: "order.contracts.fetching",
    }),
    listC() {
      const prepend = this.prependAll ? this.prepend : null;
      const firstValue = this.firstValue ? this.firstValue : null;
      const list = this.lodash.uniqBy(
        [prepend, firstValue, ...this.list],
        "id"
      );
      return this.lodash.filter(list, (x) => {
        return x != null;
      });
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
    },
    checkValue(value) {
      const firstValue = this.listC.find((x) => x.id === value);
      // Nếu value rỗng
      if (!value && !this.prependAll) {
        this.valueSelected = null;
        return;
      }
      // Value có trong list
      if (firstValue) {
        this.valueSelected = firstValue;
      }
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("order.contracts.push-query", {
        "filter[id]": param,
      });
    }, 300),
  },
};
</script>
