import { WarehouseService } from "../services";

export const SERVICE = WarehouseService;
export const RESOURCE = 'merge-package'

export default {
  merge(params) {
    return SERVICE.put(`api/${RESOURCE}`, params);
  },
}
