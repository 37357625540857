<template>
  <TSelectEditable
    v-if="editable"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: true,
      preserveSearch: true,
    }"
    :loading="loading"
    @select="select"
    @search="search"
    :normalizer="{ label: 'name', value: 'id' }"
    @close="$emit('close')"
    @change="$emit('change', $event)"
  />
  <TSelect
    v-else
    :label="label"
    class="m-0"
    :options="listC"
    :value="valueSelected"
    :selectOptions="{
      internalSearch: true,
      preserveSearch: true,
    }"
    :normalizer="{ label: 'name', value: 'id' }"
    placeholder="Select"
    :loading="loading"
    @select="select"
    @search="search"
  />
</template>
<script>
import lodash from "@/core/plugins/lodash";
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: function () {
        return "";
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    prepend: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  created() {
    this.$store.dispatch("warehouse.freight_forwarders.fetch.if-first-time");
  },
  watch: {
    valueMatch: {
      immediate: true,
      handler(status) {
        this.valueSelected = status;
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "warehouse.freight_forwarders.list",
      loading: "warehouse.freight_forwarders.fetching",
    }),
    valueMatch() {
      return this.listC.find((x) => x.id == this.value);
    },
    listC() {
      const list = this.lodash.uniqBy([...this.prepend, ...this.list], "id");
      return (
        this.lodash.filter(list, (x) => {
          return x != null;
        }) || []
      );
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
    customLabel({ id }) {
      return id;
    },
    search: lodash.debounce(function (param) {
      this.$store.dispatch("warehouse.freight_forwarders.apply-query", {
        "filter[id]": param,
      });
    }, 300),
  },
};
</script>
