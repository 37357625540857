<template>
  <CSpinner v-if="loading" color="primary" size="sm" class="mx-1" />
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
};
</script>
