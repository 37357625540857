var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.editable)?_c('TSelectEditable',{staticClass:"m-0 account-select",attrs:{"options":_vm.list,"value":_vm.valueSelected,"selectOptions":{
    internalSearch: true,
    preserveSearch: true,
    ..._vm.customOptionLabel,
    ..._vm.selectOptions,
  },"normalizer":{ label: 'id', value: 'id' },"placeholder":_vm.placeholder,"loading":_vm.loading},on:{"close":function($event){return _vm.$emit('close')},"change":function($event){return _vm.$emit('change', $event)}}}):_c('TSelect',{staticClass:"m-0 account-select",attrs:{"label":_vm.label,"options":_vm.listC,"value":_vm.valueSelected,"selectOptions":{
    internalSearch: true,
    preserveSearch: true,
    ..._vm.customOptionLabel,
    ..._vm.selectOptions,
  },"normalizer":{ label: 'id', value: 'id' },"placeholder":_vm.placeholder,"loading":_vm.loading},on:{"select":_vm.select},scopedSlots:_vm._u([{key:"singleLabel",fn:function({ option }){return [_c('TMessage',{attrs:{"content":option.id ? option.id : option.name,"noTranslate":"","truncate":1,"size":"small"}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }