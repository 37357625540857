export default {
  props: {
    prepend: {
      type: Object,
      required: false,
      default: function () {
        return { id: "", name: this.$t("All") };
      },
    },
    prependAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      default: "Select",
    },
    noFetchStore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      firstValue: null,
      valueSelected: null,
    };
  },
  watch: {
    valueSelected: {
      immediate: false,
      handler(value) {
        if (value) {
          this.$emit("update:value", value.id);
        }
      },
    },
  },
  computed: {
    listC() {
      const prepend = this.prependAll ? this.prepend : null;
      const firstValue = this.firstValue ? this.firstValue : null;
      const list = this.lodash.uniqBy(
        [prepend, firstValue, ...this.list],
        "id"
      );
      return this.lodash.filter(list, (x) => {
        return x != null;
      });
    },
    customOptionLabel() {
      return !this.noCustomLabel ? { "custom-label": this.customLabel } : {};
    },
  },
  methods: {
    select(value) {
      this.valueSelected = value;
      this.$emit("update:value", value.id);
      this.$emit("change", value);
    },
  }
}
