<template>
  <TModal
    :title="'Set pallet location'"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :key="key"
  >
    <template #actions>
      <TButton
        content="Change"
        variant="outline"
        @click="update"
        :options="{ disabled: loading || !locationSelected.id }"
      />
    </template>
    <div>
      <TSelect
        label="Shelve"
        class="m-0"
        :options="shelves"
        :value="valueSelected"
        :selectOptions="{
          internalSearch: true,
          preserveSearch: true,
        }"
        :normalizer="{ label: 'name', value: 'id' }"
        placeholder="Select"
        :loading="loadingLocation"
        @select="select"
      />
      <CRow class="mt-3">
        <CCol col="4">
          <TInputNumber
            label="Floor"
            :value.sync="filter.floor"
            @update:value="filterChange"
          />
        </CCol>
        <CCol col="4">
          <TInputNumber
            label="Column"
            :value.sync="filter.column"
            @update:value="filterChange"
          />
        </CCol>
        <CCol col="4">
          <TInputNumber
            label="Row"
            :value.sync="filter.row"
            @update:value="filterChange"
          />
        </CCol>
      </CRow>
      <TButton
        icon="cil-clear-all"
        class="d-flex ml-auto mt-2"
        content="Reset"
        @click="resetFilter"
      />
      <hr />
      <TMessage
        v-if="pallet.location"
        content="Current location"
        class="mt-4 h5"
        bold
      >
        <template #suffix
          >:
          <SMessagePalletLocation :location="pallet.location" />
          <CIcon
            class="mx-1"
            v-if="locationSelected && locationSelected.name"
            name="cil-arrow-right"
          />
          <SMessagePalletLocation
            v-if="locationSelected && locationSelected.name"
            color="primary"
            :location="locationSelected"
          />
        </template>
      </TMessage>

      <CRow class="mt-1">
        <div
          v-for="location in list"
          :key="location.id"
          class="flex-shrink-1 flex-grow-1 border-primary m-1"
          :class="{
            'bg-primary text-white': location.id == locationSelected.id,
          }"
          style="cursor: pointer; width: 80px"
          @click="selectLocation(location)"
        >
          <SMessagePalletLocation
            class="m-3 text-center h5"
            color="white"
            :location="location"
          />
        </div>
      </CRow>
      <TMessageNoData v-if="!list.length" />
    </div>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pallet: {
      type: Object,
      require: false,
    },
  },
  inputDefault: {
    location_id: "",
  },
  data() {
    return {
      key: "shelveModal",
      loading: false,
      valueSelected: null,
      filter: {},
      locationSelected: {},
    };
  },
  created() {
    this.$store.dispatch("warehouse.locations.apply-query", {
      "filter[empty_pallet]": 1,
    });
    this.$store.dispatch("warehouse.shelves.fetch.if-first-time");
  },
  watch: {
    list(list) {
      if (!list.find((x) => x.id == this.locationSelected.id))
        this.locationSelected = {};
    },
    pallet() {
      this.locationSelected = {};
    },
  },
  computed: {
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
    ...mapGetters({
      list: "warehouse.locations.list",
      shelves: "warehouse.shelves.list",
      loadingLocation: "warehouse.locations.fetching",
    }),
  },
  methods: {
    update() {
      this.$store
        .dispatch("warehouse.pallets.detail.update", {
          location_id: this.locationSelected.id,
        })
        .then((data) => {
          this.$store.dispatch("warehouse.pallets.detail.refresh");
          this.locationSelected = "";
          this.resetFilter();
          this.$emit("update:show", false);
          this.$emit("updated", data);
        });
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      return { ...this.pallet };
    },
    select(value) {
      this.valueSelected = value;
      this.filter["shelve_id"] = value.id;
      this.filterChange();
    },
    resetFilter() {
      this.filter = [];
      this.valueSelected = null;
      this.filterChange();
    },
    filterChange() {
      let defaultFilter = {
        "filter[empty_pallet]": 1,
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filterList,
        ...defaultFilter,
      });
      this.$store.dispatch("warehouse.locations.apply-query", filterFields);
    },
    selectLocation(location) {
      this.locationSelected = location;
    },
  },
};
</script>
