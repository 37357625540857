<template>
  <TSelect
    :label="label"
    class="m-0"
    placeholder="Select"
    :options="list"
    :value="valueSelected"
    :selectOptions="selectOptions"
    @select="onSelect"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: false,
    },
    selectOptions: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      require: false,
    },
    reasonList: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  computed: {
    list() {
      return this.lodash.map(this.reasonList, (field) => {
        return {
          ...field,
          name: this.$t(field.name),
        };
      });
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.checkValue(value);
      },
    },
    valueSelected: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$emit("update:value", value.id);
          this.$emit("change", value);
        }
      },
    },
  },
  methods: {
    onSelect(value) {
      this.valueSelected = value;
    },
    checkValue(value) {
      const valueSelected = this.list.find((x) => x.id === value);
      if (valueSelected) {
        this.valueSelected = valueSelected;
      }
    },
  },
};
</script>
