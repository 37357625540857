<template>
  <TModal
    title="Address"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="lg"
  >
    <template #header-actions>
      <TButtonAdd @click="showModalAddress = true" />
    </template>
    <template #actions>
      <TButtonSave
        :options="{ disabled: disabled }"
        variant="outline"
        @click="$emit('click-save', selected)"
      />
    </template>
    <div style="height: 70vh" class="overflow-auto pt-3">
      <CCol v-for="address in addresses" :key="address.id" col="12">
        <SCardAddress
          :address="address"
          :class="selected.id === address.id ? 'border-success' : ''"
          :deletable="false"
        >
          <template #append-actions>
            <TButton
              color="success"
              @click="select(address.id)"
              tooltip="Select"
              icon="cil-check"
              variant="ghost"
              size="sm"
              iconSize="15"
            />
          </template>
        </SCardAddress>
      </CCol>
      <CCol v-show="paginate.last > 1" col="12" class="mt-2">
        <TPagination store="auth.addresses" />
      </CCol>
    </div>
    <SModalAddress
      :show.sync="showModalAddress"
      :userId="userId"
      @created="showModalAddress = false"
    />
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      required: true,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModalAddress: false,
    };
  },
  watch: {
    userId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.$store.dispatch("auth.addresses.push-query", {
            "filter[user_id]": id,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      addresses: "auth.addresses.list",
      selected: "auth.addresses.detail",
      paginate: "auth.addresses.paginate",
    }),
  },
  methods: {
    select(value) {
      this.$emit("select", value);
      this.$store.commit("auth.addresses.select", value);
    },
  },
};
</script>
