import Resource from '@/core/store/Resource';

const resource = new Resource(
  {
    service: 'helper',
    resource: 'prices',
    paginate: false
  },
  {
    default_query: {
      include: 'timelines,timelinesCount,timelines.amounts,conditions',
    },
  },
).store()

export default resource;
