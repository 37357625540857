<template>
  <CInputRadioGroup
    :options="list"
    :checked="organization"
    @update:checked="selectWarehouse"
    custom
  />
</template>

<script>
import organizationStore from "@/core/services/tomoni/organization.local";

export default {
  created() {
    this.$store.dispatch("auth.organizations.fetch.if-first-time");
  },
  computed: {
    list() {
      return [
        {
          value: "",
          label: this.$t("All"),
        },
        ...this.$store.getters["auth.organizations.list"]?.map(
          (organization) => {
            return {
              value: organization.id,
              label: organization.name,
            };
          }
        ),
      ];
    },
    organization() {
      return organizationStore.get();
    },
  },
  methods: {
    selectWarehouse(organization_id) {
      organizationStore.set(organization_id);
      window.location.reload();
    },
  },
};
</script>
