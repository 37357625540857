var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.editable)?_c('TSelectEditable',{staticClass:"m-0",attrs:{"options":_vm.list,"value":_vm.valueSelected,"selectOptions":{
    internalSearch: false,
    'custom-label': _vm.customLabel,
    preserveSearch: true,
    ..._vm.selectOptions,
  },"normalizer":{ label: 'name', value: 'name' },"placeholder":_vm.placeholder,"loading":_vm.loading},on:{"search":_vm.search,"close":function($event){return _vm.$emit('close')},"change":function($event){return _vm.$emit('change', $event)}}}):_c('TSelect',{staticClass:"m-0",attrs:{"label":_vm.label,"options":_vm.listC,"value":_vm.valueSelected,"selectOptions":{
    internalSearch: false,
    'custom-label': _vm.customLabel,
    preserveSearch: true,
    ..._vm.selectOptions,
  },"normalizer":{ label: 'name', value: 'name' },"placeholder":_vm.placeholder,"loading":_vm.loading},on:{"select":_vm.select,"search":_vm.search,"close":function($event){return _vm.$emit('close')},"change":function($event){return _vm.$emit('change', $event)}}})
}
var staticRenderFns = []

export { render, staticRenderFns }