<template>
  <TButton
    content="Clear filter"
    icon="cil-clear-all"
    variant="outline"
    :messageOptions="{ bold: true }"
    @click="$emit('click')"
    :options="{ disabled: disabled }"
  />
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
