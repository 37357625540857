<template>
  <CInputCheckbox
    :disabled="disabled"
    :key="key"
    :checked="checked"
    custom
    :label="getLabel"
    addLabelClasses="font-weight-bold"
    @update:checked="
      $emit('change', $event);
      reset();
    "
  />
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    noTranslate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key: "checkboxKey",
    };
  },
  computed: {
    getLabel() {
      if (!this.noTranslate) {
        return this.$t(this.label) || "";
      }
      return this.label || "";
    },
  },
  methods: {
    reset() {
      this.key = "" + this.lodash.random(10000, 100000);
    },
  },
};
</script>

<style scoped>
.custom-checkbox
  >>> .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: var(--primary);
}
</style>
