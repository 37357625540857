<template>
  <TModal
    title=""
    :show="show"
    :size="createParentSku || is_edit ? '' : 'lg'"
    @update:show="$emit('update:show', $event)"
  >
    <template #title>
      <div class="d-flex w-100 my-auto">
        <TMessage
          :content="!is_edit ? 'Create SKU' : 'Box'"
          bold
          capitalize
          class="my-auto"
        />
        <div class="ml-auto">
          <TInputCheckbox
            v-if="!createParentSku && !is_edit"
            class="ml-auto"
            label="Packed"
            :checked="willCloseBox"
            @change="willCloseBox = !willCloseBox"
          />
        </div>
      </div>
    </template>
    <template #actions>
      <TButton
        content="Create"
        :options="{ disabled: loading || !validateParams }"
        variant="outline"
        icon="cil-plus"
        size="lg"
        v-if="!is_edit"
        @click="create"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-else
        :options="{ disabled: loading }"
      />
    </template>
    <div v-if="showError">
      <div class="bg-danger p-2 pt-3">
        <TMessage content="Are you sure these numbers?" bold size="h5" />
      </div>
      <div class="bg-light p-2 text-danger">
        <TMessage
          v-for="(error, index) in errorList"
          :key="index"
          :content="`${$t(error)}  ${$t('exceeds')}`"
          noTranslate
          bold
          class="mb-2"
        >
          <template #prefix>
            <CIcon name="cis-info-circle" class="mb-1 mr-2" />
          </template>
          <template #suffix>
            <span class="ml-1 h4 d-inline">100</span>
          </template>
        </TMessage>
      </div>
    </div>
    <form>
      <CRow>
        <CCol col="4">
          <TInputNumber
            size="lg"
            class="mt-3"
            label="Length (cm)"
            @keydown.enter.native="focusNext"
            :value.sync="data.length"
          />
        </CCol>
        <CCol col="4">
          <TInputNumber
            size="lg"
            class="mt-3"
            label="Height (cm)"
            @keydown.enter.native="focusNext"
            :value.sync="data.height"
          />
        </CCol>
        <CCol col="4">
          <TInputNumber
            size="lg"
            class="mt-3"
            label="Width (cm)"
            @keydown.enter.native="focusNext"
            :value.sync="data.width"
          />
        </CCol>
      </CRow>
      <TInputNumber
        size="lg"
        class="mt-3"
        label="Weight (kg)"
        @keydown.enter.native="focusNext"
        :value.sync="data.weight"
      />
      <TInputNumber
        v-if="!createParentSku"
        size="lg"
        class="mt-3"
        label="Number of boxes"
        :maskOptions="{ allowDecimal: false }"
        @keydown.enter.native="focusNext"
        :value.sync="data.duplicate"
      />
    </form>
    <div v-if="!createParentSku && !is_edit">
      <hr />
      <TMessage content="Goods" class="mt-3 h4" />
      <div
        v-if="
          purchaseItems.length > 1 ||
          (purchaseItems.length == 1 && !items.length)
        "
        class="border-info rounded p-2 mb-3"
      >
        <TMessage
          content="Suggested products"
          class="mb-3 my-2"
          size="h5"
          bold
        />
        <div class="d-flex flex-wrap">
          <CCol
            col="6"
            md="6"
            lg="4"
            v-for="item in purchaseItems"
            :key="item.id"
            class="cursor-pointe mb-3 p-0"
            :class="{
              'opacity-50': items.some((x) => x.product_id == item.product_id),
            }"
          >
            <SCardProductItems
              :item="item.product"
              widthAuto
              :showName="false"
              resource="/warehouse/management/products"
            >
              <template #append-content>
                <CRow>
                  <CBadge
                    color="info"
                    class="d-flex mt-1"
                    style="font-size: 90%"
                  >
                    <TMessageNumber :value="item.quantity_items_in_box" />
                    <TMessage class="ml-1" content="Piece" />
                    <span class="mx-1">/</span> <TMessage content="Box" />
                  </CBadge>
                </CRow>
                <CRow>
                  <TButton
                    content="Add"
                    icon="cil-plus"
                    size="sm"
                    class="mt-2"
                    @click="addItem(item)"
                  />
                </CRow>
              </template>
            </SCardProductItems>
          </CCol>
        </div>
      </div>

      <div class="d-flex">
        <SSelectProduct
          :id.sync="product_id"
          @update:id="updateItem"
          class="w-100"
          :showItem="false"
          placeholder="Add product"
          @keydown.enter.native="add"
          @keypress.native="presearch"
          @paste.native="onPaste"
          :key="selectProductKey"
        />
        <TButton
          content="Create new"
          icon="cil-plus"
          variant="outline"
          class="ml-3 px-3 w-25"
          @click="showModalCreateProduct = true"
        />
      </div>
      <div v-for="item in items" :key="item.product_id" class="mt-3">
        <CRow>
          <CCol col="12" md="5" class="mb-1">
            <SCardProductItems
              widthAuto
              :item="item.product"
              resource="/warehouse/management/products"
            />
          </CCol>
          <CCol col="12" md="3" class="mb-1">
            <TInputNumber label="Items in box" :value.sync="item.quantity" />
          </CCol>
          <CCol col="12" md="3" class="mb-1">
            <TInputDateTime
              label="Expiration date"
              :value.sync="item.expiry_date"
            />
          </CCol>
          <CCol class="my-auto">
            <TButtonRemove
              noConfirm
              variant="outline"
              @click="removeItem(item.product_id)"
            />
          </CCol>
        </CRow>
      </div>
    </div>
    <SCardCreateProduct
      mode="quicknew"
      :show.sync="showModalCreateProduct"
      :product_id="productIdCreateNew"
      @created="productCreated"
    />
  </TModal>
</template>

<script>
import lodash from "@/core/plugins/lodash";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    sfa_id: {
      type: [String, Number],
      required: false,
    },
    sku: {
      type: Object,
      required: false,
    },
    tracking_code: {
      type: String,
      required: false,
    },
    createParentSku: {
      type: Boolean,
      default: false,
    },
    purchaseItems: {
      type: Array,
      required: false,
    },
  },
  inputDefault: {
    length: 0,
    height: 0,
    width: 0,
    weight: 0,
    duplicate: 1,
  },
  data() {
    return {
      showModalCreateProduct: false,
      loading: false,
      errorList: [],
      data: this.getInput(),
      items: [],
      product: {},
      product_id: "",
      productIdPresearch: "",
      selectProductKey: "",
      willCloseBox: true,
      inputSearchProduct: "",
      productIdCreateNew: "",
    };
  },
  mounted() {
    window.addEventListener("keypress", this.searchProducts);
    if (this.$store.getters["printer.value"]?.readyState) {
      this.$store.getters["printer.value"].addEventListener(
        "message",
        this.handleReadDWS
      );
    } else {
      this.$store.commit("toasts.push", {
        message: "Check the connection again",
        type: "warning",
      });
    }
  },
  destroyed() {
    window.removeEventListener("keypress", this.searchProducts);
    if (this.$store.getters["printer.value"]?.readyState) {
      this.$store.getters["printer.value"].removeEventListener(
        "message",
        this.handleReadDWS
      );
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: false,
      handler(data) {
        const errorList = [];
        this.lodash.mapKeys(data, (value, key) => {
          if (["length", "height", "width", "weight"].includes(key))
            if (value > 100) {
              errorList.push(this.lodash.upperFirst(key));
            }
        });
        this.errorList = errorList;
      },
    },
    sku: {
      immediate: true,
      handler() {
        this.data = this.getInput();
      },
    },
    show: {
      immediate: true,
      handler(v) {
        if (!this.tracking_code) return;
        if (v && this.$store.getters["printer.value"]?.readyState) {
          this.$store.dispatch(
            "printer.send-message",
            JSON.stringify({
              type: "ReadDWS",
              Barcode: this.tracking_code,
            })
          );
        }
      },
    },
    sfa_id() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    purchaseItems: {
      immediate: true,
      handler(items) {
        if (items && items?.length == 1) {
          this.addItem(items[0]);
        }
      },
    },
    inputSearchProduct: lodash.debounce(function (value) {
      if (value) {
        this.inputSearchProduct = "";
      }
    }, 150),
  },
  computed: {
    showError() {
      return (
        this.data.length > 100 ||
        this.data.height > 100 ||
        this.data.width > 100 ||
        this.data.weight > 100
      );
    },
    is_edit() {
      if (!this.sku) {
        return false;
      }
      return !!this.sku.id;
    },
    validateParams() {
      return (
        this.data.length > 0 &&
        this.data.height > 0 &&
        this.data.width > 0 &&
        this.data.weight > 0 &&
        this.data.duplicate > 0
      );
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  methods: {
    handleReadDWS(e) {
      const data = JSON.parse(e.data);
      if (data.type == "DWS") {
        this.data = {
          ...this.data,
          height: data.ChieuCao,
          length: data.ChieuDai,
          width: data.ChieuRong,
          weight: data.CanNang,
        };
      }
    },
    create() {
      if (!this.validateParams) return;
      this.loading = true;
      const type = this.createParentSku ? "create_parent_box" : "boxes";
      const sfa = this.createParentSku ? {} : { sfa_id: this.sfa_id };
      let data = {};
      this.items.forEach((x, index) => {
        // Remove keys
        let obj = this.lodash.omit(x, ["product"]);
        const item = this.lodash.mapKeys(obj, (value, key) => {
          return `products[${index}][${key}]`;
        });
        data = { ...item, ...data };
      });
      data = {
        ...this.data,
        ...data,
        ...sfa,
      };
      this.$store
        .dispatch(
          `warehouse.${type}.create`,
          this.lodash.pickBy(data, this.lodash.identity)
        )
        .then((data) => {
          if (this.willCloseBox && this.items.length) {
            const itemsLength = this.items.length;
            this.$tomoni.warehouse.boxes.close(data.id).then(() => {
              this.$store.commit("toasts.push", {
                message: "Closed",
                type: "danger",
              });
              this.$emit("packed", data, itemsLength);
              this.$emit("created", data);
            });
          } else {
            this.$emit("created", data);
          }
          this.items = [];
          this.data = this.inputDefault();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.$store.commit("warehouse.boxes.select", this.sku.id);
      this.$store
        .dispatch("warehouse.boxes.update", {
          id: this.sku.id,
          attributes: {
            ...this.data,
          },
        })
        .then(() => {
          this.$emit("update:show", false);
        });
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return this.lodash.pick(this.sku, [
        "length",
        "height",
        "width",
        "duplicate",
        "weight",
      ]);
    },
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);
      if (index == inputs.length - 1) {
        inputs[index].blur();
        return;
      }
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    updateItem(id, product) {
      this.product_id = id;
      this.product = product;

      this.add();
      this.selectProductKey = this.lodash.resetKey("select-product");
    },
    add() {
      const itemIndex = this.items.findIndex(
        (x) => x.product_id == this.product_id
      );
      const purchaseItemIndex = this.purchaseItems.findIndex(
        (x) => x.product_id == this.product_id
      );
      const purchaseItemOfItemsIndex = this.items.findIndex(
        (x) => x.product_id == this.product_id
      );
      if (purchaseItemIndex != -1 && purchaseItemOfItemsIndex == -1) {
        this.items.push({
          product: this.purchaseItems[purchaseItemIndex].product,
          product_id: this.purchaseItems[purchaseItemIndex].product_id,
          quantity:
            this.purchaseItems[purchaseItemIndex].quantity_items_in_box || 1,
          expiry_date: null,
        });
        this.productIdPresearch = "";
        this.product_id = "";
        this.selectProductKey = this.lodash.resetKey("select-product");
        return;
      }
      if (purchaseItemIndex != -1 && purchaseItemOfItemsIndex != -1) {
        this.items[purchaseItemOfItemsIndex].quantity += 1;
        this.productIdPresearch = "";
        this.product_id = "";
        this.selectProductKey = this.lodash.resetKey("select-product");
        return;
      }
      if (!this.product_id) return;
      if (itemIndex == -1) {
        this.items.push({
          product: this.product,
          product_id: this.product_id,
          quantity: this.product.quantity_items_in_box || 1,
          expiry_date: null,
        });
        this.product_id = "";
      } else {
        this.items[itemIndex].quantity += 1;
        this.product_id = "";
      }
    },
    removeItem(id) {
      const itemIndex = this.items.findIndex((x) => x.product_id === id);
      this.items.splice(itemIndex, 1);
    },
    addItem(item) {
      if (!this.items.some((x) => x.product_id == item.product_id)) {
        this.product_id = item.product_id;
        this.product = {
          quantity_items_in_box: item.quantity_items_in_box,
          ...item.product,
        };
        this.add();
      }
    },
    presearch(e) {
      if (e.key === "Enter") return;
      this.productIdPresearch += e.key;
    },
    onPaste(e) {
      this.productIdPresearch = e.clipboardData.getData("Text");
    },
    productCreated(data) {
      this.updateItem(data.id, data);
      this.productIdCreateNew = "";
    },
    searchProducts(e) {
      if (
        this.showModalCreateProduct ||
        !this.show ||
        this.is_edit ||
        this.createParentSku
      )
        return;
      if (e.target.tagName == "INPUT") return;
      if (e.key == "Enter") {
        if (!this.inputSearchProduct) return;
        const input = this.inputSearchProduct.toString();
        const itemExist = this.items.find((x) => x.product_id == input);
        const purchaseItemExist = this.purchaseItems.find(
          (x) => x.product_id == input
        );
        if (this.inputSearchProduct && !itemExist && !purchaseItemExist) {
          this.$tomoni.product.products_search
            .all({ q: input })
            .then(({ data }) => {
              const result = data.data.find((x) => input == x.id);
              if (result) {
                this.updateItem(result.id, result);
                this.playSuccess();
              } else {
                this.productIdCreateNew = input;
                this.showModalCreateProduct = true;
                this.playFail();
              }
            });
        } else {
          let item;
          if (itemExist) item = itemExist;
          if (purchaseItemExist) item = purchaseItemExist;
          this.updateItem(item.product_id, item.product);
          this.playSuccess();
        }
        this.inputSearchProduct = "";
        return;
      }
      this.inputSearchProduct += e.key;
    },
    playSuccess() {
      new Audio(process.env.VUE_APP_ASSETS_URL + "/worf.mp3").play();
    },
    playFail() {
      new Audio(process.env.VUE_APP_ASSETS_URL + "/wrong-answer.mp3").play();
    },
  },
};
</script>

<style scoped>
.opacity-50 {
  opacity: 0.5;
}
</style>
