<template>
  <div>
    <slot name="edit" :editing="editing" :setEditing="setEditing">
      <SSelectProductOrigin
        v-if="editing"
        :value="originId"
        @close="setEditing(false)"
        @change="
          $emit('change', $event);
          setEditing(false);
        "
        :editable="editable"
      />
    </slot>
    <TMessage
      v-show="!editing || dontHideWhenEditing"
      :content="getOrigin"
      :editable="editable"
      noTranslate
      @click-edit="showEdit()"
    />
  </div>
</template>

<script>
import mixin from "@/core/components/T/Button/actions";
import { mapGetters } from 'vuex';

export default {
  mixins: [mixin],
  props: {
    originId: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      origins: "product.origins.list",
    }),
    getOrigin() {
      const origin = this.lodash.find(this.origins, { id: this.originId });
      if (!origin) {
        return this.originId;
      }
      if (origin) return origin.name;
      return this.$t("Empty");
    },
  },
};
</script>
