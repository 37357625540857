<template>
  <TSelectEditable
    v-if="editable"
    class="m-0"
    :options="freightChargeList"
    :value="valueSelected"
    :selectOptions="selectOptions"
    @close="$emit('close')"
    @change="$emit('change', $event)"
  />
  <TSelect
    v-else
    :label="label"
    class="m-0"
    placeholder="Select"
    :options="freightChargeList"
    :value="valueSelected"
    :selectOptions="selectOptions"
    @select="onSelect"
  />
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    prepend: {
      type: Array,
      required: false,
    },
    value: {
      type: String,
      required: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    selectOptions: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      require: false,
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.checkValue(value);
      },
    },
    valueSelected: {
      immediate: false,
      handler(value) {
        if (value) {
          this.$emit("update:value", value.id);
          this.$emit("change", value);
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      freightChargeList: "warehouse.freight_charge_types.list",
    }),
  },
  methods: {
    onSelect(value) {
      this.valueSelected = value;
    },
    checkValue(value) {
      const valueSelected = this.freightChargeList.find((x) => x.id === value);
      if (valueSelected) {
        this.valueSelected = valueSelected;
      }
    },
  },
};
</script>
