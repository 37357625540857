import Resource from "@/core/store/Resource";
import tomoni from "@/core/services/tomoni";

const resource = new Resource(
  {
    service: "product",
    resource: "products",
  },
  {
    default_query: {
      include: "suppliers,origin,categories,tags",
    },
    detail_query: {
      include: "suppliers,categories,tags,reviews,images,ecommerceChannel",
    },
    default_detail: {
      origin_id: "",
      suppliers: [],
      banned_air: false,
      banned_export: false,
      banned_sea: false,
      private_license: false,
      special: false,
    },
  }
).store();

const PREFIX = "product.products";

const actions = {
  [PREFIX + ".detail.attach-image"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.product.products
        .attachImage(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.detach-image"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.product.products
        .detachImage(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Deleted",
            type: "danger",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.attach-category"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.product.products
        .attachCategory(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.detach-category"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.product.products
        .detachCategory(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Deleted",
            type: "danger",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.attach-tag"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.product.products
        .attachTag(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.detach-tag"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.product.products
        .detachTag(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Deleted",
            type: "danger",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.add-supplier"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.product.products
        .addSupplier(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Created",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.update-supplier"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.product.products
        .updateSupplier(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Updated",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
  [PREFIX + ".detail.delete-supplier"](context, attributes) {
    return new Promise((resolve, reject) => {
      tomoni.product.products
        .deleteSupplier(context.getters[PREFIX + ".detail.id"], attributes)
        .then(({ data }) => {
          context.commit(PREFIX + '.detail.merge', data)
          context.commit("toasts.push", {
            message: "Deleted",
            type: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("errors.push-http-error", error);
          reject(error);
        });
    });
  },
};

export default {
  ...resource,
  actions: { ...resource.actions, ...actions },
};
