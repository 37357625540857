<template>
  <TSelect
    class="m-0"
    :options="statuses"
    :value="valueSelected"
    @select="select"
    :label="label"
    :selectOptions="selectOptions"
  />
</template>

<script>
import mixins from "./mixins";

export default {
  mixins: [mixins],
  props: {
    prepend: {
      type: Array,
      required: false,
      default: function () {
        return [{ id: "", name: this.$t("All") }];
      },
    },
    value: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
    selectOptions: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      valueSelected: null,
    };
  },
  watch: {
    status: {
      immediate: true,
      handler(value) {
        this.valueSelected = value;
      },
    },
  },
  computed: {
    statuses() {
      return [
        ...this.prepend,
        ...this.$store.getters["warehouse.goods_delivery_statuses.list"],
      ];
    },
    status() {
      return this.statuses.find((x) => x.id === this.value);
    },
  },
  methods: {
    select(status) {
      this.valueSelected = status;
      this.$emit("update:value", status.id);
      this.$emit("change");
    },
  },
};
</script>
